function residentLeaveHistoryDirective(): ng.IDirective {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            residentId: "=",
            fromIlu: "="
        },
        controller: "viewResidentHistory.controller",
        controllerAs: "vm",
        templateUrl: "app/residents/partials/viewResidentHistory.html"
    };
}
export = residentLeaveHistoryDirective;

