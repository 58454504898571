interface ICheckboxControllerScope extends ng.IScope {
    model: any;
    value: any;
    disabled: boolean;
    checked: boolean;
    onChecked: (checked: boolean) => {};
}

class CheckboxController {
    static $inject = ["$scope"];

    public scope: ICheckboxControllerScope;
    private checked: boolean;
    
    constructor(
        private $scope: ICheckboxControllerScope
    ) {
        this.scope = $scope;
    }

    public toggle = () => {
        if (this.scope.disabled)
            return;
        
        this.scope.checked = !this.scope.checked;
        
        if (this.scope.onChecked) {
            this.scope.onChecked(this.scope.checked);
        }
    };

    public getCheckboxClass = () => {
        return this.scope.disabled ?
            'disabled' : 
            this.scope.checked ?
                'true' : '';
    };
}

export = CheckboxController;