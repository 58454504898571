"use strict";
const angular = require("angular");
const moment = require("moment");
const _ = require("underscore");

module.exports = [
    "residents.service",
    "notification.service",
    "modalConfig",
    "$state",
    "dialog.service",
    function (residentsService, notification, modalConfig, $state, dialogService) {
        var vm = this;
        angular.extend(vm, modalConfig);

        vm.leave = {};

        vm.bindHide = function ($hide) {
            vm.$hide = $hide;
        };

        Object.defineProperty(vm, "minimumReturnDateUtc", {
            get: function () {
                var value = null;
                if (vm.resident.leave.startDateUtc) {
                    value = moment(vm.resident.leave.startDateUtc).add(1, "days").startOf("day").toDate();
                }
                return value;
            }
        });

        vm.leaveDays = function () {
            if (vm.leave.returnDateUtc) {
                var startDate = moment(vm.resident.leave.startDateUtc);
                var returnDate = moment(vm.leave.returnDateUtc);
                var leaveDaysCount = returnDate.diff(startDate, "days");
                if (leaveDaysCount === 1) {
                    return leaveDaysCount + " day";
                } else {
                    return leaveDaysCount + " days";
                }
            } else {
                return null;
            }
        };

        vm.returnFromLeave = function (isleaveSubmissionFormValid) {
            if (isleaveSubmissionFormValid) {
                vm.leave.isPrivateResidency = vm.resident.isPrivateResidency;
                residentsService.returnFromLeave(vm.resident.id, vm.leave)
                    .then(function () {
                        vm.$hide();
                        $state.reload();
                    }, function (error) {
                        if (!error.modelStateSummary) {
                            notification.error("Unable to return resident from leave. Either your computer is offline, or Resident Manager is having problems of its own.");
                        } else {
                            dialogService.openMessageDialog(
                                "Return resident from leave",
                                "The following errors occurred while returning resident from leave:",
                                "residentLeave-error",
                                _.values(error.modelStateSummary));
                        }
                    });
            }
        }
    }
];
