function closeOnClickDirective(): ng.IDirective {
    return {
        restrict: "A",
        controller: [
            '$scope', '$element', ($scope, $element) => {
                const ulElement = $element.find("ul");
                $element.bind('click',
                    () => {
                        ulElement.toggle();
                    });

                $element.bind('mouseover',
                    () => {
                        ulElement.removeAttr("style");
                    });
            }
        ]
    };
}

export = closeOnClickDirective;