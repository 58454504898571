import _ = require("underscore");
import moment = require('moment');
import { INotificationService } from "../../common/services/INotificationService";
import { AccommodationDto } from "../../independentLiving/Models";
import { DetailedDropdownSection, DropdownDetail, DetailedDropdownStyle } from "../Models";
import { IluEnquiryInfoDto } from "../../leads/Models";

class ILuDasshboardComponent implements ng.IComponentOptions {
    public templateUrl: string = 'app/dashboard/partials/iluDashboard.html';

    public controller: any = class ILuDasshboardComponent {
        static $inject = ["notification.service", "facility.service", "dashboard.service"];
        public selectedFacilityName: string; 
        public dashboardInfo : any = {};
        public vacancyText : string;
        public tourText : string;
        public depositText : string;
        public isExpandedUnitList : boolean;
        public isExpandedLeadList : boolean;
        public availableAccomodations : AccommodationDto[];
        public enquiryDetails : IluEnquiryInfoDto;
        public detailListName ;
        public vacanciesDropdownSections: DetailedDropdownSection[];
        public depositsEnquiriesAndTourSections: DetailedDropdownSection[];

        public residentsOnLeaveHospital: any;
        public residentsOnLeaveSocial: any;
        public residentsOnLeaveRespite: any;

        constructor(private readonly notificationService: INotificationService,
            private readonly facilityService: any,
            private readonly dashboardService : any) 
        {
            this.selectedFacilityName = this.facilityService.selectedFacility.name;
            this.getIluDashboardInfo();
        }

        private getIluDashboardInfo = () => {
            // on first redirect from log in screen on a new browser the selected facility and user facilities may be empty
            // loggedInUserInfo will select first facility and refresh dashboard
            if(this.facilityService.selectedFacility.id)
            {
                    this.dashboardService.getIluDashboardInfo().then((result) => {
                        this.dashboardInfo = result;
                        this.processResults();

                    },
                    () => {
                        this.notificationService.error("Unexpected error occurred while loading residents to view.");
                    }
                );
            }
        }

        public processResults = () => {
            this.vacanciesDropdownSections = [];
            let vacancyDropdownSection: DetailedDropdownSection = {
                heading: "Vacancies",
                style: DetailedDropdownStyle.Style1,
                details: [],
                subHeading: ""
            };

            _.forEach(this.dashboardInfo.iluFacilityStatistics.iLuOccupancyInfo.availableAccommodations, (accommodation) => {
                let accommodationNoType: any = <any>accommodation;
                let dropdownDetail: DropdownDetail = new DropdownDetail();
                dropdownDetail.detail = accommodationNoType.roomNumber;
                dropdownDetail.description =  accommodationNoType.accommodationName;
                
                vacancyDropdownSection.details.push(dropdownDetail)
            });

            this.vacanciesDropdownSections.push(vacancyDropdownSection);

            this.depositsEnquiriesAndTourSections = [];
            if(this.dashboardInfo.iluFacilityStatistics.iluEnquiryInfo.depositPaidAndUnitInfos.length > 0){
                let depositDropdownSection : DetailedDropdownSection = {
                    heading: "Deposits",
                    style: DetailedDropdownStyle.Style2,
                    details: [],
                    subHeading: ""
                };
    
                _.forEach(this.dashboardInfo.iluFacilityStatistics.iluEnquiryInfo.depositPaidAndUnitInfos, (deposit) => {
                    let depositNoType: any = <any>deposit;
                    let dropdownDetail: DropdownDetail = {
                        detail: depositNoType.firstResidentFullName  + (depositNoType.secondResidentFullName ? ' and ' + depositNoType.secondResidentFullName : ''),
                        description: 'Deposit paid on ' + this.getFormattedDate(depositNoType.depositPaidDateUtc) + ' on unit ' + depositNoType.roomNumber,
                        enquiryId : depositNoType.independentLivingAllocationId,
                        selectedResidentId : depositNoType.firstResidentId
                    };
                    depositDropdownSection.details.push(dropdownDetail)
                });
                this.depositsEnquiriesAndTourSections.push(depositDropdownSection);
            }

            if(this.dashboardInfo.iluFacilityStatistics.iluEnquiryInfo.iluTour.iluTourCompletedList.length > 0){
                let toursDropdownSection : DetailedDropdownSection = {
                    heading: "Tour completed",
                    style: DetailedDropdownStyle.Style2,
                    details: [],
                    subHeading: ""
                };
    
                _.forEach(this.dashboardInfo.iluFacilityStatistics.iluEnquiryInfo.iluTour.iluTourCompletedList, (tour) => {
                    let tourNoType: any = <any>tour;
                    let dropdownDetail: DropdownDetail = {
                        detail: tourNoType.firstResidentFullName + (tourNoType.secondResidentFullName ? ' and ' + tourNoType.secondResidentFullName : ''),
                        description: 'Toured facility ' + this.getFormattedDate(tourNoType.tourDateTime),
                        enquiryId : tourNoType.independentLivingAllocationId,
                        selectedResidentId : tourNoType.firstResidentId

                    };
                    toursDropdownSection.details.push(dropdownDetail)
                });
                this.depositsEnquiriesAndTourSections.push(toursDropdownSection);
            }

            if(this.dashboardInfo.iluFacilityStatistics.iluEnquiryInfo.enquiryDetails.length > 0){
                let enquiresDropdownSection : DetailedDropdownSection = {
                    heading: "Enquiries",
                    style: DetailedDropdownStyle.Style3,
                    details: [],
                    subHeading: this.dashboardInfo.iluFacilityStatistics.iluEnquiryInfo.enquiryDetails.length + " enquires this month"
                };
    
                _.forEach(this.dashboardInfo.iluFacilityStatistics.iluEnquiryInfo.enquiryDetails, (enquiry) => {
                    let enquiryNoType: any = <any>enquiry;
                    let dropdownDetail: DropdownDetail = {
                        detail: enquiryNoType.firstResidentFullName + (enquiryNoType.secondResidentFullName ? ' and '+ enquiryNoType.secondResidentFullName : ''),
                        description: 'Enquiry ' + this.getFormattedDate(enquiryNoType.createdOnUtc),
                        enquiryId : enquiryNoType.independentLivingAllocationId,
                        selectedResidentId : enquiryNoType.firstResidentId
                    };
                    enquiresDropdownSection.details.push(dropdownDetail)
                });
                this.depositsEnquiriesAndTourSections.push(enquiresDropdownSection);
            }

            this.dashboardInfo.iluFacilityStatistics.iLuOccupancyInfo.availableUnitCount = this.dashboardInfo.iluFacilityStatistics.iLuOccupancyInfo.totalUnitCount - 
                                                                                        this.dashboardInfo.iluFacilityStatistics.iLuOccupancyInfo.occupiedUnitCount;
            this.vacancyText = this.dashboardInfo.iluFacilityStatistics.iLuOccupancyInfo.availableUnitCount > 1 ? "vacancies" : "vacancy";
            this.tourText  = this.dashboardInfo.iluFacilityStatistics.iluEnquiryInfo.enquiriesOnATourCount > 1 ?  "Tours complete" : "Tour complete";
            this.depositText = this.dashboardInfo.iluFacilityStatistics.iluEnquiryInfo.enquiriesWithDepositPaidCount > 1 ? "Deposits" : "Deposit" ;
            this.availableAccomodations = this.dashboardInfo.iluFacilityStatistics.iLuOccupancyInfo.availableAccommodations;
            this.enquiryDetails = this.dashboardInfo.iluFacilityStatistics.iluEnquiryInfo;

            this.residentsOnLeaveHospital = _.where(this.dashboardInfo.residentsOnLeave, { type: "Hospital" });
            this.residentsOnLeaveHospital = _.sortBy(this.residentsOnLeaveHospital, "startDateUtc");
            this.residentsOnLeaveSocial = _.where(this.dashboardInfo.residentsOnLeave, { type: "Social" });
            this.residentsOnLeaveRespite = _.where(this.dashboardInfo.residentsOnLeave, { type: "Respite" });
        }

        private getFormattedDate = (date: any) => {
            const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            let jsDate = new Date(date);
            let formattedDate = jsDate.getDate() + " " + months[jsDate.getMonth()] + " " + jsDate.getFullYear()

            return formattedDate
        }

        private residentOnLeavDueInfo = (residentOnLeave: any) => {
            if (residentOnLeave.type === "Social" || residentOnLeave.type === "Respite") {
                var days = moment().startOf('day').diff(moment(residentOnLeave.expectedReturnDateUtc).startOf('day'), 'days');
                residentOnLeave.days = days;
                switch (days) {
                    case 0:
                        return "Due back today";
                    case -1:
                        return "Due back tomorrow";
                    case 1:
                        return "Due back yesterday";
                    default:
                        return "Due back " + moment(residentOnLeave.expectedReturnDateUtc).format("D MMM YYYY");
                }
            } else {
                var days = moment().startOf('day').diff(moment(residentOnLeave.startDateUtc).startOf('day'), 'days');
                switch (days) {
                    case 0:
                        return "Today";
                    case 1:
                        return "Yesterday";
                    default:
                        return days + " days ago (" + moment(residentOnLeave.startDateUtc).format("D MMM YYYY") + ")";
                }
            }
        };
    }
}
export = ILuDasshboardComponent;