"use strict";
var angular = require("angular");

var module = angular.module("authentication.module", ["common.module"]);
require("./states.js").configure(module);

//On every load, redirect to auth server login screen if there no logged in user
//and the current page is not login nor logout
//if current path is /login try processing login callback
module.run(["authentication.service", function (authenticationService) {
    authenticationService.applyAuthentication();
}]);

module.controller("loggedInUserInfo.controller", require("./controllers/loggedInUserInfo.controller"));
module.controller("forgotPassword.controller", require("./controllers/ForgotPasswordController"));
module.controller("resetPassword.controller", require("./controllers/ResetPasswordController"));
module.controller("redirectToLogin.controller", require("./controllers/RedirectToLoginController"));
module.factory("authentication.service", require("./services/authentication.service"));
module.factory("facility.service", require("./services/facility.service"));
module.directive("loggedInUserInfo", require("./directives/loggedInUserInfo.directive"));
module.directive("requiresPermission", require("./directives/requiresPermission.directive"));
module.factory("featureFlags.service", require("./services/featureFlags.service"));
