"use strict";

module.exports = function () {
    function showHidePassword(event) {
        if (event.data.showHide.text() === "show") {
            event.data.showHide.text("hide");
            event.data.textbox.attr("type","text");
        } else {
            event.data.showHide.text("show");
            event.data.textbox.attr("type", "password");
        }
    }

    return {
        restrict: "A",
        replace: false,
        link: function ($scope, element) {
            element.addClass("show-hide-password");
            element.attr("type", "password");
            element.attr("autocomplete", "new-password");
            element.after('<span class="show-hide-switch">show</span>');
            var showHide = element.next();
            showHide.on("click", { textbox: element, showHide: showHide }, showHidePassword);

        }
    };
}
