function backToTopDirective(): ng.IDirective {
    return {
        restrict: "E",
        replace: true,
        templateUrl: "app/common/directives/partials/backToTop.html",
        link: function ($scope: ng.IScope, element: JQuery, attrs: ng.IAttributes) {
            $(window).scroll(() => {
                if ($(window).scrollTop() <= 0) {
                    element.fadeOut();
                }
                else {
                    element.fadeIn();
                }
            });

            element.on('click', () => {
                $('html, body').animate({ scrollTop: 0 }, 'slow');
            });
        }
    };
}

export = backToTopDirective;
