import { ILeadsService } from "../services/ILeadsService";
import { INotificationService } from '../../common/services/INotificationService';
import {Constants, LeadDashboardDto, LeadDashboardFilterDto, EnquiryStatus, LeadCardDto} from '../Models';

class LeadsDashboardComponent implements ng.IComponentOptions {
    public templateUrl: string = 'app/leads/partials/leadsDashboard.html';

    public controller: any = class LeadsDashboardComponentController {
        static $inject = ["leads.service", "notification.service"];

        public leadsResult: LeadDashboardDto;
        public totalCount: number = 0;
        leadDashboardFilter : LeadDashboardFilterDto = new LeadDashboardFilterDto();
        public leadDashboardForm: ng.IFormController;

        constructor(private readonly leadsService: ILeadsService,
            private readonly notificationService: INotificationService) {
               this.loadLeads();  
            }

        private loadLeads = () => {
            this.leadsService.getDashboardLeads(Constants.CARDS_NUMBER_TAKE_EACH_TIME, this.leadDashboardFilter).then(result => {
                this.leadsResult = result;  
                                 
                this.totalCount = result.enquiries.totalCount + result.tourBooked.totalCount + result.tourCompleted.totalCount
                                    + result.waiting.totalCount + result.confirmed.totalCount;
            }, () => {
                    this.notificationService.error("Unexpected error while loading leads.");
            });
        }

        public refreshColumn = (status: EnquiryStatus) => {
            this.leadsService.getLeadsForDashboardByStatus(status, 0, Constants.CARDS_NUMBER_TAKE_EACH_TIME, 
                this.leadDashboardFilter, null).then(response => {
                    this.assignBindingValues(status, response);
                });
        }

        private assignBindingValues = (status: EnquiryStatus, response: BatchResult<LeadCardDto>) => {
            switch(status)
            {
                case EnquiryStatus.Enquiries:
                    this.leadsResult.enquiries = response;
                    break;
                case EnquiryStatus.TourBooked:
                    this.leadsResult.tourBooked = response;
                    break;
                case EnquiryStatus.TourCompleted:
                    this.leadsResult.tourCompleted = response;
                    break;    
                case EnquiryStatus.Waiting:
                    this.leadsResult.waiting = response;
                    break;
                case EnquiryStatus.Confirmed:
                    this.leadsResult.confirmed = response;
                    break;
            }
        }
    }
}
export = LeadsDashboardComponent;