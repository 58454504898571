"use strict";

module.exports = [
    "$timeout", function($timeout) {
        return {
            restrict: 'A',
            scope: {
                focusTrigger: "&",
                focusCallback: "&"
            },
            link: function($scope, $element, $attr) {
                if ($attr.focusTrigger) {
                    $scope.$watch($scope.focusTrigger, function() {
                        $timeout(function() {
                            $element[0].focus();
                            if ($scope.focusCallback) {
                                $scope.focusCallback();
                            }
                        }, 0);
                    });
                }
            }
        };
    }
];
