import type { IStateService } from "angular-ui-router";

class ResetPasswordController {
    static $inject = ["users.service", "$state", "$location"];

    constructor(
        private readonly usersService: any,
        private $state: IStateService,
        private $location: ng.ILocationService,
    ) {
        this.validateForgotPasswordLink();
    }


    private upperCharValidator = new RegExp(/^(?=.*[A-Z]).+$/);
    private lowerCharValidator = new RegExp(/^(?=.*[a-z]).+$/);
    private numberValidator = new RegExp(/^(?=.*\d).+$/);

    newPassword: string = "";
    success: boolean = false;
    error: any;
    email: string;
    invalidLink: boolean;
    salt: string;

    private atLeast8Chars = () => {
        return this.newPassword.length >= 8;
    };

    private newPasswordUpperAndLower = () => {
        return this.lowerCharValidator.test(this.newPassword) && this.upperCharValidator.test(this.newPassword);
    };

    private newPasswordNumbersValid = () => {
        return this.numberValidator.test(this.newPassword);
    };

    showError = (error: any) => {
        this.error = error;
    };

    showSuccess = () => {
        this.success = true;
        this.error = null;
    };

    markLinkInvalid = () => {
        this.error = "Invalid link";
        this.invalidLink = true;
    }

    resetPassword = () => {
        var scope = this;
        if (!this.atLeast8Chars() || !this.newPasswordUpperAndLower() || !this.newPasswordNumbersValid()) {
            this.showError("New password does not meet security requirements");
            return;
        }
        this.usersService.resetPassword(this.newPassword, this.salt).then((result) => {
            this.showSuccess();

        }, (error) => {
            if (!error || !error.modelStateSummary) {
                scope.showError("Network error");
            }
            else if (error.modelStateSummary.newPassword) {
                scope.showError(error.modelStateSummary.newPassword);
            }
            else if (error.modelStateSummary.salt) {
                scope.markLinkInvalid();
            }
        });
    };

    validateForgotPasswordLink = () => {
        var scope = this;
        this.salt = this.$location.search()['salt'];
        if (!this.salt || this.salt.length === 0) {
            scope.markLinkInvalid();
        } else {
            this.usersService.validateForgotPasswordLink(this.salt).then(() => {

            },
                () => {
                    scope.markLinkInvalid();
                });
        }
    };

    requestNewLink = () => {
        this.$state.go("forgotPassword");
    }

    signIn = () => {
        this.$state.go("redirectToLogin");
    }
}

export = ResetPasswordController;


