function statementDailySubsidyDirective(): ng.IDirective {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            statement: "="
        },
        templateUrl: "app/funding/directives/partials/statementDailySubsidy.html"
    };
}

export = statementDailySubsidyDirective;