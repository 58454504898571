"use strict";

module.exports = function ($rootScope) {
  return {
    restrict: "A",
    require: "ngModel",
    scope: {
      accountMappingGroups: "=",
      defaultAccount: "=",
      maxLength: "@",
    },
    link: function (scope, element, attrs, ngModelCtrl) {
      function getCostCentre(account) {
        if (account && account.includes("-")) {
          return account.split("-")[0];
        }
        return account;
      }

      // Function to validate the same cost centre
      function validateSameCostCentre() {
        if (!scope.defaultAccount) 
        {
            ngModelCtrl.$setValidity("sameCostCentre", true);
            return;
        }
        const costCentre = getCostCentre(ngModelCtrl.$modelValue);
        const defaultCostCentre = getCostCentre(scope.defaultAccount);
        let isSameCostCentre = !costCentre || costCentre == defaultCostCentre;
        ngModelCtrl.$setTouched();
        ngModelCtrl.$setValidity("sameCostCentre", isSameCostCentre);
      }

      // Costcentre presence validation
      ngModelCtrl.$validators.hyphen = function (modelValue) {
        return modelValue && modelValue.includes("-");
      };

      element.on("blur", function () {
        $rootScope.$broadcast("validate-all");
      });

      // Validate the current input when the event is triggered
      scope.$on("validate-all", function () {
        validateSameCostCentre();
      });

      // Max length validation
      scope.$watch(
        () => ngModelCtrl.$modelValue,
        function (newVal) {
          if (newVal && newVal.length > scope.maxLength) {
            ngModelCtrl.$setValidity("maxlength", false);
            ngModelCtrl.$setTouched();
          } else {
            ngModelCtrl.$setValidity("maxlength", true);
          }
        }
      );
    },
  };
};

module.exports.$inject = ["$rootScope"];
