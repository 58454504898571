import * as moment from 'moment';
import * as _ from "underscore";
import { IFundingService, IDialogService } from '../Interfaces';
import { INotificationService } from '../../common/services/INotificationService';
import { MedicareStatementSimple } from '../Models';
import { IAuthenticationService } from '../../authentication/services/IAuthenticationService';
import type { IStateService } from 'angular-ui-router';

class ViewMedicareStatementsController {
    medicareStatements: MedicareStatementSimple[];
    hasReconciliationPermission: boolean;
    public isSystemUser: boolean;

    static $inject = ["funding.service", "notification.service", "dialog.service", "$state", "authentication.service"];

    constructor(
        private readonly fundingService: IFundingService,
        private readonly notificationService: INotificationService,
        private readonly dialogService: IDialogService,
        private readonly $state: IStateService,
        private readonly authenticationService: IAuthenticationService
    ) {
        this.getMedicareStatements();
        this.setReconciliationPermission();
    }

    setReconciliationPermission = () => {
        this.authenticationService.userHasPermissionDeferred((x) => {
            return x.fundingReconciliation.canAction;
        }).then(() => {
            this.hasReconciliationPermission = true;
        }, () => {
            this.hasReconciliationPermission = false;
        });
    }


    getMedicareStatements() {
        this.fundingService.getMedicareStatements()
            .then(result => {
                // Statements are grouped by year and sorted in descending order.
                // Reduce them down to a single array as we don't need this grouping for now.
                let statements = result.reduce(function (acc, current) {
                    return acc.concat(current.medicareStatements);
                }, [] as MedicareStatementSimple[]);
                this.medicareStatements = statements.map((statement) => {
                    statement.statementVariance = null;
                    if (statement.expectedStatementTotal != null && statement.statementTotal != null) {
                        statement.statementVariance = statement.expectedStatementTotal - statement.statementTotal;
                    }
                    statement.statementFullMonthAndYear = this.getFullMonthAndYear(statement.statementMonth, statement.statementYear);
                    return statement;
                });
            }, () => {
                this.notificationService.error("Unable to display statements. Either your computer is offline, or Resident Manager is having problems of its own.");
            });
    }

    fetchPaymentStatement(month: number, year: number) {
        this.fundingService.fetchPaymentStatement(this.getFullMonthAndYear(month, year))
            .then(() => {
                this.$state.reload();
                this.notificationService.success("Payment statement was fetched successfully.");
            }, (error) => {
                if (!error.modelStateSummary) {
                    this.notificationService.error("Unable to get statement. Either your computer is offline, or Resident Manager is having problems of its own.");
                } else {
                    this.dialogService.openMessageDialog(
                        "Error",
                        "The following errors occurred when fetching the payment statement:",
                        "medicareStatement-error",
                        _.values(error.modelStateSummary));
                }
            });
    };

    getMonthName(month: number) {
        return moment().month(month - 1).format('MMMM');
    }

    getFullMonthAndYear(month: number, year: number) {
        var fullMonth = month.toString().length === 1 ? '0' + month : month;
        return fullMonth + '' + year;
    }
}

export = ViewMedicareStatementsController;
