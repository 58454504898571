"use strict";

module.exports = function () {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            residentId: "="
        },
        controller: "viewTimeline.controller",
        controllerAs: "vm",
        templateUrl: "app/residents/directives/partials/viewTimeline.html"
    };
}
