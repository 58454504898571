function statementProviderCommentsDirective(): ng.IDirective {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            statement: "="
        },
        templateUrl: "app/funding/directives/partials/statementProviderComments.html"
    };
}

export = statementProviderCommentsDirective;