function residentFinanceTransactionDirective(): ng.IDirective {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            residentId: "=",
            isPrivateResidency: "="
        },
        controller: "residentFinanceTransaction.controller",
        controllerAs: "vm",
        templateUrl: "app/residents/directives/partials/residentFinanceTransaction.html"
    };
}

export = residentFinanceTransactionDirective;
