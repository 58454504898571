module.exports = ["data.service", function (dataService) {

    function getRelationships() {
        return dataService.get("referenceData/relationships");
    };

    function getAcfiRates() {
        return dataService.get("referenceData/acfi-rates");
    };

    function getCountries() {
        return dataService.get("referenceData/countries");
    }

    function getGstRate() {
        return dataService.get("referenceData/gst-rate");
    }

    function getEnquirySources() {
        return dataService.get("referenceData/enquiry-sources");
    };

    function getReligions() {
        return dataService.get("referenceData/religions");
    };

    function getExtraServices() {
        return dataService.get("referenceData/extraServices");
    }

    return {
        getRelationships: getRelationships,
        getAcfiRates: getAcfiRates,
        getCountries: getCountries,
        getExtraServices: getExtraServices,
        getGstRate: getGstRate,
        getEnquirySources: getEnquirySources,
        getReligions: getReligions
    }
}];