"use strict";

module.exports = ["$templateCache", "$http", function ($templateCache, $http) {
    $http.get("app/common/partials/ng-validation-messages.html")
        .success(function (data) {
            $templateCache.put("validationMessages", data);
        });
    $http.get("app/common/partials/confirmation-modal.html")
    .success(function (data) {
        $templateCache.put("confirmation-modal", data);
    });
    $http.get("app/common/partials/action-modal.html")
    .success(function (data) {
        $templateCache.put("action-modal", data);
    });
    $http.get("app/common/partials/input-modal.html")
    .success(function (data) {
        $templateCache.put("input-modal", data);
    });
}];