function focusOnLoadDirective(): ng.IDirective {
    return {
        restrict: 'A',
        controller: ['$scope', '$element', '$timeout', ($scope, $element, $timeout) => {
            $timeout(() => {
                $element[0].focus();
            });
        }]
    };
}
export = focusOnLoadDirective;