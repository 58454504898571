import * as angular from 'angular';
import createEnquiryController = require("./controllers/CreateEnquiryController");
import viewEnquiryController = require("./controllers/ViewEnquiryController");
import editEnquiryController = require("./controllers/EditEnquiryController");
import assignUnitDialogController = require("./controllers/AssignUnitDialogController");
import enquiryFinanceEditorController = require("./controllers/EnquiryFinanceEditorController");
import enquiryFinanceDetailController = require("./controllers/EnquiryFinanceDetailController");
import independentLivingService = require("./services/IndependentLivingService");
import enquiryFinanceEditorDirective = require("./directives/enquiryFinanceEditor.directive")
import enquiryFinanceDetailDirective = require("./directives/enquiryFinanceDetail.directive");
import admitToUnitDialogController = require("./controllers/AdmitToUnitDialogController");
import transferEnquiryDialogController = require("./controllers/TransferEnquiryDialogController");
import settleUnitDialogController = require("./controllers/SettleUnitDialogController");
import viewResidentsComponent = require("./components/viewResidentsComponent");
import ViewDepartedResidentsComponent = require("./components/ViewDepartedResidentsComponent");
import settlementCostsDetailComponent = require("./components/settlementCostsDetailComponent");
import settlementCostsEditorComponent = require("./components/settlementCostsEditorComponent");
import contractEditorComponent = require("./components/contractEditorComponent");
import contractDetailComponent = require("./components/contractDetailComponent");
import state = require("./States");

var module = angular.module('independentLiving.module', ["common.module"]);
new state().Configure(module);

module.controller("markDepartedResidentAsIluEnquiry.controller", require("./controllers/MarkDepartedResidentAsIluEnquiryController"));
module.controller('createEnquiry.controller', createEnquiryController as ng.IControllerConstructor);
module.controller('viewEnquiry.controller', viewEnquiryController as ng.IControllerConstructor);
module.controller('editEnquiry.controller', editEnquiryController as ng.IControllerConstructor);
module.controller("transferEnquiryDialog.controller", transferEnquiryDialogController as ng.IControllerConstructor);
module.controller("settleUnitDialog.controller",settleUnitDialogController as ng.IControllerConstructor);
module.service("independentLiving.service", independentLivingService);
module.controller("assignUnitDialog.controller", assignUnitDialogController as ng.IControllerConstructor);
module.directive("enquiryFinanceEditor.directive", enquiryFinanceEditorDirective);
module.directive("enquiryFinanceDetail.directive", enquiryFinanceDetailDirective );
module.controller("enquiryFinanceEditor.controller", enquiryFinanceEditorController as ng.IControllerConstructor);
module.controller("enquiryFinanceDetail.controller", enquiryFinanceDetailController as ng.IControllerConstructor);
module.controller("admitToUnitDialogController.controller", admitToUnitDialogController as ng.IControllerConstructor);
module.component("viewIluResidents", new viewResidentsComponent()); 
module.component("viewDepartedIluResidents", new ViewDepartedResidentsComponent()); 
module.component("settlementCostsDetail", new settlementCostsDetailComponent);
module.component("settlementCostsEditor", new settlementCostsEditorComponent);
module.component("contractEditor", new contractEditorComponent);
module.component("contractDetail", new contractDetailComponent);
