"use strict";

module.exports = function() {
  return {
    restrict: "AE",
    replace: true,
    scope: {
      documentController: "@",
      documentParentId: "=",
      documentUploadController: "@",
      documentUploadParentId: "="
    },
    templateUrl: "app/common/directives/partials/documentUpload.html",
    controller: "documentUpload.controller"
  };
}
