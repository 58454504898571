import type { IStateService } from "angular-ui-router";

interface IResidentSearchRowControllerScope extends ng.IScope {
    resident: {
        id: number;
        name: string;
        firstName: string;
        lastName: string;
        preferredName: string;
        careType: string;
        additionalInfo: string;
        admissionDateUtc: Date;
        departureDateUtc: Date;
        independentLivingAllocationId: number;
        isArchived: boolean;
    };
    navigateToLeadOrResident: () => void;
    onCheckboxChange: (selected: boolean) => void;
    onResidentNameClick: () => void;
    checkSelected: boolean;
}

interface IRootScopeService extends ng.IRootScopeService
{
    selectedResidentIds : any;
    selectedResidentNames : any;
}

class ResidentSearchRowController {
    static $inject = ["$scope", "$state", "$rootScope", "facility.service"];

    constructor(
        private $scope: IResidentSearchRowControllerScope,
        private $state: IStateService,
        private $rootScope: IRootScopeService,
        private facilityService: any
    ) {

        $scope.resident.name = $scope.resident.firstName + " " + $scope.resident.lastName;

        if ($scope.resident.preferredName) {
            $scope.resident.name += ` (${$scope.resident.preferredName})`;
        }

        if(facilityService.selectedFacility.facilityType == 'AgeCare') {
            if ($scope.resident.admissionDateUtc && !$scope.resident.departureDateUtc) {
                $scope.resident.additionalInfo = $scope.resident.careType + " resident";
            }
            else if (!$scope.resident.admissionDateUtc && $scope.resident.isArchived) {
                $scope.resident.additionalInfo = `Archived lead`;
            }
            else if (!$scope.resident.admissionDateUtc) {
                $scope.resident.additionalInfo = `Enquiry for ${$scope.resident.careType} care`;
            }
            else if ($scope.resident.departureDateUtc) {
                $scope.resident.additionalInfo = "Departed";
            }
        }
        else if(facilityService.selectedFacility.facilityType == 'IndependentLiving') {
            if ($scope.resident.admissionDateUtc && !$scope.resident.departureDateUtc) {
                $scope.resident.additionalInfo = "Resident";
            }
            else if (!$scope.resident.admissionDateUtc) {
                $scope.resident.additionalInfo = "Enquiry";
            }
            else if ($scope.resident.departureDateUtc) {
                $scope.resident.additionalInfo = "Departed";
            }
        }

        $scope.navigateToLeadOrResident = () => {
            if(facilityService.selectedFacility.facilityType == 'AgeCare'){
                if ($scope.resident.admissionDateUtc)
                    $state.go("viewResident", { "residentId": $scope.resident.id });
                else
                    $state.go("viewLead", { "residentId": $scope.resident.id });
            }
            else if(facilityService.selectedFacility.facilityType == 'IndependentLiving'){
                if ($scope.resident.admissionDateUtc)
                {
                    let viewDepartedMode = $scope.resident.departureDateUtc ? true : false;
                    $state.go("viewIndependentLivingAllocation", { "enquiryId": $scope.resident.independentLivingAllocationId, "residentId": $scope.resident.id, "viewDepartedMode": viewDepartedMode });
                }
                else
                    $state.go("viewEnquiry", { "enquiryId": $scope.resident.independentLivingAllocationId, "residentId": $scope.resident.id });
            }
        }

        $scope.onCheckboxChange = (selected: boolean) => {
            if (!$rootScope.selectedResidentIds) {
                $rootScope.selectedResidentIds = [];
                $rootScope.selectedResidentNames = [];
            }

            if (selected) {
                if ($rootScope.selectedResidentIds.indexOf($scope.resident.id) === -1) {
                    $rootScope.selectedResidentIds.push($scope.resident.id);
                    $rootScope.selectedResidentNames.push($scope.resident.name);
                }
            }
            else {
                var index = $rootScope.selectedResidentIds.indexOf($scope.resident.id);
                $rootScope.selectedResidentIds.splice(index, 1);
                $rootScope.selectedResidentNames.splice(index, 1);
            }
        }

        $scope.onResidentNameClick = () => {
            $scope.checkSelected = !$scope.checkSelected;
            $scope.onCheckboxChange($scope.checkSelected)
        }

        $scope.checkSelected = $rootScope.selectedResidentIds && $rootScope.selectedResidentIds.indexOf($scope.resident.id) > -1;
    }

}

export = ResidentSearchRowController;
