import { IResidentsService } from "../services/IResidentsService";
import { INotificationService } from '../../common/services/INotificationService';
import { IncomeAssetStatus, IncomeAssetPanel } from "../../leads/Models";

class IncomeAssetComponent implements ng.IComponentOptions {
    public bindings: any = {
        residencyId: "=",
        isResidentDeparted: "=",
        incomeAssetInfo: "="
    };

    public templateUrl: string = 'app/residents/partials/incomeAsset.html';

    public controller: any = class IncomeAssetComponentController {
        static $inject = ["residents.service","notification.service"];

        public residencyId: number;
        public isResidentDeparted: boolean;
        public incomeAssetInfo: IncomeAssetPanel;           
        public isEditMode: boolean;
        
        public viewModeStatus: string;
        public toggleButtonStatus: string;
        public incomeAssetDict: {[incomeAsset: string]: string} = {
            Unknown: "Unknown",
            Completed: "Completed"
        }
        public incomeAssetForm: ng.IFormController;

        constructor(private readonly residentsService: IResidentsService,
            private readonly notificationService: INotificationService) {     
            this.isEditMode = false;   
            this.viewModeStatus = this.incomeAssetInfo.status!=null ? this.incomeAssetDict[this.incomeAssetInfo.status] : null;         
            this.toggleButtonStatus = this.incomeAssetInfo.status!=null ? this.incomeAssetDict[this.incomeAssetInfo.status] : null;
        }

        public showEditMode = () => {
            this.isEditMode = true;
            this.toggleButtonStatus = this.viewModeStatus;
        }

        public hideEditMode = () => {
            this.isEditMode = false;
        }

        public updateStatus = () => {            
            if (this.incomeAssetForm.$valid) {
                let incomeAssetStatus = this.getIncomeAssetStatusKeyByValue(this.toggleButtonStatus);                
                this.residentsService.updateIncomeAssetStatus(this.residencyId, incomeAssetStatus).then(response => {
                    this.isEditMode = false;
                    this.viewModeStatus = this.toggleButtonStatus;
                    this.incomeAssetInfo.status = incomeAssetStatus;
                    this.notificationService.success("Income Asset updated successfully!");
                },
                () => {
                    this.notificationService.error("Unexpected error occurred while updating income asset.");
                });
            }
        }

        private getIncomeAssetStatusKeyByValue = (value: string): IncomeAssetStatus => { 
            if (value == "Unknown")
                return IncomeAssetStatus.Unknown;
            else if (value == "Completed")
                return IncomeAssetStatus.Completed;
          
        } 
    }
}
export = IncomeAssetComponent;