import * as moment from 'moment';
import * as _ from "underscore";
import { IDialogService, IFundingService} from '../Interfaces';
import { INotificationService } from '../../common/services/INotificationService';
import type { IStateService } from "angular-ui-router";
class ViewExpectedMonthlyStatementComponent implements ng.IComponentOptions {
    public bindings: any;
    public templateUrl: string = 'app/funding/partials/viewExpectedMonthlyStatement.html';

    constructor() {
        this.bindings = {
            monthAndYear: '=',
            statement: '=',
            statementLoaded: '=',
            setExpectedTotalCallback: '&'
        };
    }
    public controller: any =  class ViewExpectedMonthlyStatementComponentController {
        static $inject = ["funding.service", "notification.service", "dialog.service", "$state"];
    
        public monthAndYear: string;
        public statement: any; 
        public statementLoaded: boolean;  
        public setExpectedTotalCallback:any;
        public period: string;
    
        constructor(
            private readonly fundingService: IFundingService,
            private readonly notificationService: INotificationService,
            private readonly dialogService: IDialogService,
            private readonly state: IStateService) {
            this.monthAndYear = this.state.params.monthAndYear;
            this.period = moment(this.monthAndYear, "MYYYY").format("MMMM YYYY");
    
            if (!this.statementLoaded) {
                this.getExpectedMedicareStatement();
            }
        }
    
        private getExpectedMedicareStatement = () => {
            this.fundingService.getExpectedMedicareStatement(this.monthAndYear)
                .then((result) => {
                        this.statement = result;
                        this.setExpectedTotalCallback({totalAmountPaid: this.statement.summary.totals.totalAmountPaid, isClaimFinalised: this.statement.isClaimFinalised});
                        // need to update parent
                        this.state.go(this.state.current.name,
                            { monthAndYear: this.monthAndYear, statement: this.statement, statementLoaded: true },
                            {});
                    },
                    (error) => {
                        if (error?.data?.exception?.isPopUp) {
                            this.dialogService.openMessageDialog(
                                "Error",
                                error.data.exception.message,
                                "expectedStatement-error",
                                null);
                        }
                        else
                        {
                            this.notificationService.error(
                                "Unable to open statement. Either your computer is offline, or Resident Manager is having problems of its own.");
                        }
                    }
                );
        };
    }
}
export = ViewExpectedMonthlyStatementComponent;
