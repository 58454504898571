"use strict";

module.exports = [
    "funding.service",
    "$stateParams",
    "notification.service",
    "$state",
    function (fundingService, $stateParams, notification, $state) {
        var vm = this;
        var monthAndYear = $stateParams.monthAndYear;
        vm.statement = $stateParams.statement;

    }
];
