"use strict";
var angular = require("angular");

var module = angular.module("funding.module", ["common.module", "ui.bootstrap"]);
require("./states.js").configure(module);

module.controller("viewMedicareEvents.controller", require("./controllers/viewMedicareEvents.controller"));
module.controller("viewMonthlyStatement.controller", require("./controllers/viewMonthlyStatement.controller"));
module.controller("viewStatementSummary.controller", require("./controllers/viewStatementSummary.controller"));
module.controller("viewStatementResidents.controller", require("./controllers/viewStatementResidents.controller"));
module.controller("viewStatementSupportedRatio.controller", require("./controllers/viewStatementSupportedRatio.controller"));
module.controller("viewStatementDailySubsidies.controller", require("./controllers/viewStatementDailySubsidies.controller"));
module.controller("viewStatementProviderComments.controller", require("./controllers/viewStatementProviderComments.controller"));
module.controller("viewExpectedStatementSupportedRatio.controller", require("./controllers/viewExpectedStatementSupportedRatio.controller"));
module.controller("reverseEvent.controller", require("./controllers/reverseEvent.controller"));
module.controller("viewFundingRates.controller", require("./controllers/viewFundingRates.controller"));

module.directive("statementResidents", require("./directives/statementResidents.directive"));
module.directive("statementDailySubsidyAcfi", require("./directives/statementDailySubsidyAcfi.directive"));

module.factory("funding.service", require("./services/funding.service"));

module.filter("emptyIfZero", require("./filters/emptyIfZero.filter"));

//Converted to TypeScript
module.controller("viewExpectedStatementPayment.controller", require("./controllers/ViewExpectedStatementPaymentController"));
module.controller("viewExpectedStatementDailySubsidies.controller", require("./controllers/ViewExpectedStatementDailySubsidiesController"));
module.controller("viewExpectedStatementResidents.controller", require("./controllers/ViewExpectedStatementResidentsController"));
module.controller("viewExpectedStatementSummary.controller", require("./controllers/ViewExpectedStatementSummaryController"));
module.controller("AccommodationFundingRates.controller", require("./controllers/AccommodationFundingRatesController"));
module.controller("FeesAndCharges.controller", require("./controllers/FeesAndChargesController"));
module.directive("statementDailySubsidy", require("./directives/statementDailySubsidyDirective"));
module.directive("statementProviderComments", require("./directives/statementProviderCommentsDirective"));
module.directive("statementSupportedRatio", require("./directives/statementSupportedRatioDirective"));
module.directive("statementSummary", require("./directives/statementSummaryDirective"));
module.directive("expectedStatementSummary", require("./directives/expectedStatementSummaryDirective"));
module.controller("viewMedicareStatements.controller", require("./controllers/ViewMedicareStatementsController"));
module.controller("matchValuesDialog.controller", require("./controllers/MatchValuesDialogController"));
module.controller("adjustmentSummary.controller", require("./controllers/AdjustmentSummaryController"));
module.controller("viewAndAddVarianceNote.controller", require("./controllers/ViewAndAddVarianceNoteController"));

//components
module.component("varianceStatement", new (require("./components/varianceStatementComponent"))());
module.component("varianceStatementSummary", new (require("./components/varianceStatementSummaryComponent"))());
module.component("varianceStatementPayment", new (require("./components/varianceStatementPaymentComponent"))());
module.component("expectedStatementPayment", new (require("./components/expectedStatementPaymentComponent"))());
module.component("actualStatementPayment", new (require("./components/actualStatementPaymentComponent"))());
module.component("statementNavigator", new (require("./components/statementNavigatorComponent"))());
module.component("viewExpectedMonthlyStatement", new (require("./components/viewExpectedMonthlyStatementComponent"))());
