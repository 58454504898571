import * as moment from 'moment';
import { IDaysInPastComponentController } from "./IDaysInPastComponentController"

class DaysInPastComponentController implements IDaysInPastComponentController {

    public date: Date;

    daysInPast = () => {
        if (!this.date) {
            return "";
        }
        var days = moment().startOf('day').diff(moment(this.date).startOf('day'), "days");
        if (days > 1) {
            return days.toString() + " days ago";
        }
        if (days === 1) {
            return "Yesterday";
        }
        if (days === 0) {
            return "Today";
        }
        return "";
    };
}

export = DaysInPastComponentController;
