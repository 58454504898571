module.exports = [
    function () {

        function newGuid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });

        }

        function getStateDescription(state) {
            var shortDesc;
            switch (state) {
                case "Australian Capital Territory":
                    shortDesc = "ACT";
                    break;
                case "New South Wales":
                    shortDesc = "NSW";
                    break;
                case "Northern Territory":
                    shortDesc = "NT";
                    break;
                case "Queensland":
                    shortDesc = "QLD";
                    break;
                case "South Australia":
                    shortDesc = "SA";
                    break;
                case "Tasmania":
                    shortDesc = "TAS";
                    break;
                case "Victoria":
                    shortDesc = "VIC";
                    break;
                case "Western Australia":
                    shortDesc = "WA";
                    break;
                default:
                    shortDesc = state;
                    break;
            }
            return shortDesc;
        }

        function convertMonthYearToYearMonth(inputString) {
            // Extract year and month
            const year = inputString.slice(-4); // Get last 4 characters for year
            let month = "";
            if (inputString.length > 5) {
                month = inputString.slice(0, 2); // Get first 2 characters for month
            } else {
                month = "0" + inputString.slice(0, 1); // Get only first character and concat with preceding 0
            }

            // Reformat into "yyyyMM" format
            const formattedString = year + month;

            return formattedString;
        };
        return {
            newGuid: newGuid,
            getStateDescription: getStateDescription,
            convertMonthYearToYearMonth: convertMonthYearToYearMonth
        }
    }
];