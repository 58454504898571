"use strict";

module.exports = function () {
    return function(input) {
        if (typeof input === 'undefined'){
            return;
        }
        if (!input) {
            return "-";
        }
        return input;
    }
}