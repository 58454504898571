"use strict";
const _ = require("underscore");

module.exports = ["$rootScope", function ($rootScope) {

    var modals = [];

    $rootScope.$on("resman.modal.show", function (e, $modal) {
        if (modals.indexOf($modal) === -1) {
            modals.push($modal);
        }
    });

    $rootScope.$on("resman.modal.hide", function (e, $modal) {
        var modalIndex = modals.indexOf($modal);
        modals.splice(modalIndex, 1);
    });

    //hide open modals if state is about to change
    $rootScope.$on("$stateChangeStart", function () {
        if (modals.length > 0) {
            _.each(modals, function ($modal) {
                $modal.$promise.then($modal.hide);
            });
            modals = [];
        }
    });

}];
