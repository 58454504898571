/* HACK to load JQuery before angular to avoid using JQLite
   https://docs.angularjs.org/api/ng/function/angular.element
*/
window.$ = window.jQuery = require("jquery");
declare var global_GitCommitHash: any;
var templateRequestInterceptor = require('../app/common/interceptors/TemplateRequestInterceptor');

let angularjs = require("angular");

var app = angularjs.module("app", [
    "common.module",
    "authentication.module",
    "dashboard.module",
    "residents.module",
    "leads.module",
    "accr.module",
    "funding.module",
    "roles.module",
    "organisation.module",
    "billing.module",
    "reporting.module",
    "ui.validate",
    "independentLiving.module",
    "customerCare.module"]);

require("./common");
require("./authentication");
require("./dashboard");
require("./residents");
require("./leads");
require("./accr");
require("./funding");
require("./organisation");
require("./roles");
require("./billing");
require("./reporting");
require("./independentLiving");
require("./customerCare");

app.config(['$httpProvider', function ($httpProvider) {
    templateRequestInterceptor.gitCommitHash = global_GitCommitHash; //global_GitCommitHash is a global variable and loads automatically from webpack.config
    $httpProvider.interceptors.push(templateRequestInterceptor.factory);
}]);

app.config([
    '$datepickerProvider', function($datepickerProvider) {
        angularjs.extend($datepickerProvider.defaults,
            {
                timezone: "UTC"
            });
    }
]);

/** 
 * Between each state transition (changing routes) reset the value of hideFooter nobody needs to remember to do it. 
 * 
 * onRetain occurs prior to entering any new states (https://ui-router.github.io/guide/transitions)
*/
app.run(['$transitions', '$rootScope', ($transitions, $rootScope) => {
    $transitions.onRetain({}, function() {
        $rootScope.hideFooter = undefined;
    })
  }]);