import * as angular from 'angular';
import * as _ from 'underscore';
import { IReportingService } from '../services/IReportingService';
import { IInfiniteLoaderFactory, IInfiniteLoaderService } from '../../common/services/IInfiniteLoaderService';
import { UnitsReportFilter, FacilityLocation, RoomStatus} from '../Models';
import { IDownloadFileService } from '../../common/services/IDownloadFileService';

class UnitsReportController {
    static $inject = [
        "reporting.service",
        "downloadFile.service",
        "infiniteLoader.service"
    ];
    infiniteLoader: IInfiniteLoaderService;
    unitsReportData: any[] = [];
    unitTypes: any[] = [];
    facilityLocations: any[] = [];
    unitStatus: any[] = [];
    reportFilters: UnitsReportFilter;
    totalCount: number;
    filters: any[] = [{
            "id": 1,
            "name": "facilityLocations",
            "isCollapsed": true
        },
        {
            "id": 2,
            "name": "unitTypes",
            "isCollapsed": true
        },
        {
            "id": 3,
            "name": "unitStatus",
            "isCollapsed": true
        }];
    all: any[] = [{
            "id": 1,
            "name": "FacilityLocations",
            "checked": true
        },
        {
            "id": 2,
            "name": "UnitTypes",
            "checked": true
        },
        {
            "id": 3,
            "name": "UnitStatus",
            "checked": true
        }];

    constructor(
        private readonly reportingService: IReportingService,
        private readonly downloadFileService : IDownloadFileService,
        private readonly infiniteLoaderFactory: IInfiniteLoaderFactory
    ) {
        this.initialiseFilters();
        this.loadLocationUnitTypes();
        this.loadUnitStatusList();
        this.loadReportData();
    }

    private loadReportData = () => {
        this.unitsReportData = [];

        if ((!this.all[0].checked && this.reportFilters.facilityLocations.length == 0) ||
            (!this.all[1].checked && this.reportFilters.unitTypes.length == 0) ||
            (!this.all[2].checked && this.reportFilters.unitStatus.length == 0)) {
            this.unitsReportData = [];
        } else {
            this.infiniteLoader = this.infiniteLoaderFactory.instance(this.loadUnits,
                (result) => {
                    this.unitsReportData = this.unitsReportData.concat(result);
                },
                () => {
                    console.log('Unexpected error occurred while loading report data.');
                });
        }
    }

    private loadUnits = (notIncludingItemsAfter: string, skip: number, take: number) => {
        return this.reportingService.getUnitsReportData(notIncludingItemsAfter, skip, take, this.reportFilters);
    }

    private initialiseFilters() {
        this.all[0].checked = true;
        this.all[1].checked = true;
        this.all[2].checked = true;
        this.reportFilters = new UnitsReportFilter();
        this.reportFilters.facilityLocations = [];
        this.reportFilters.unitTypes = [];
        this.reportFilters.unitStatus = [];
    }

    private updateLocationFilter() {
        if (this.isFacilityLocationAvailable) {
            var selectedFacilityLocationArray = _.filter(this.facilityLocations,
                (element) => {
                    return element.checked;
                });
            if (this.facilityLocations.length === selectedFacilityLocationArray.length) {
                // Load unit Type list for all facility location
                this.loadFacilityLocationUnitTypes(this.facilityLocations);
                this.all[0].checked = true;
            }
            else {
                // load unit Type list based on the selected Facility location           
                this.loadFacilityLocationUnitTypes(selectedFacilityLocationArray);
                this.all[0].checked = false;
             }
             this.reportFilters.facilityLocations = selectedFacilityLocationArray.map(({id}) => id);
        }
        else {
            this.reportFilters.facilityLocations = selectedFacilityLocationArray.map(({id}) => id);
        }
        this.updateUnitTypeFilter(false);
        this.loadReportData();
    }

    private updateUnitTypeFilter(isLoadData: boolean) {
        var selectedUnitTypeArray = _.filter(this.unitTypes,
            (element) => {
                return element.checked;
            });
        if (this.unitTypes.length === selectedUnitTypeArray.length) {
            this.reportFilters.unitTypes = selectedUnitTypeArray.map(({id}) => id);
            this.all[1].checked = true;
        }
        else {
            this.reportFilters.unitTypes = selectedUnitTypeArray.map(({id}) => id);
            this.all[1].checked = false;
        }
        if (isLoadData)
            this.loadReportData();
    }

    private updateUnitStatusFilter() {
        var selectedUnitStatusArray = _.filter(this.unitStatus,
            (element) => {
                 return element.checked;
            });
        if (this.unitStatus.length === selectedUnitStatusArray.length) {
            this.all[2].checked = true;
        }
        else {
            this.all[2].checked = false;
        }
        this.reportFilters.unitStatus = selectedUnitStatusArray.map(({id}) => id);
        this.loadReportData();
    }  

    private toggleSign = (filtersIndex) => {
        if (this.filters[filtersIndex].isCollapsed === true)
            this.filters[filtersIndex].isCollapsed = false;
        else
            this.filters[filtersIndex].isCollapsed = true;
    }

    private loadLocationUnitTypes = () => {
        this.reportingService.getFacilityLocationsWithAccommodationTypes()
            .then((result) => {
                this.facilityLocations = result;
                _.forEach(this.facilityLocations,
                    (facilityLocation) => {
                        facilityLocation.checked = true;
                    });

                this.loadFacilityLocationUnitTypes(this.facilityLocations);
                this.reportFilters.facilityLocations = this.facilityLocations.map(({id}) => id);
                this.reportFilters.unitTypes = this.unitTypes.map(({id}) => id);
                _.forEach(this.unitTypes,
                    (unitType) => {
                        unitType.checked = true;
                    });
            });
    }

    private isFacilityLocationAvailable = () => {
        return !(this.facilityLocations.length === 1 && this.facilityLocations[0].id === -1);
    }

    private loadFacilityLocationUnitTypes(facilityLocations : FacilityLocation[]) {
        this.unitTypes = [];
        _.forEach(facilityLocations, (facilityLocation) => {
            _.forEach(facilityLocation.accommodationTypes, (unitType) => {
                this.unitTypes.push(unitType);
            });
        });

        var uniqueUnitTypesArray = _.uniq(this.unitTypes, function (x) {
            return x.name;
        });

        this.unitTypes = _.sortBy(uniqueUnitTypesArray, "name");

        _.forEach(this.unitTypes, (roomType) => {
            roomType.checked = true;
        });
    }

    
    private loadUnitStatusList = () => {       
        this.unitStatus.push(new RoomStatus(0, "Available", true));
        this.unitStatus.push(new RoomStatus(1, "Occupied", true));
        this.unitStatus.push(new RoomStatus(2, "OutOfService", true));
        this.reportFilters.unitStatus = this.unitStatus.map(({id}) => id);  
    
    }   

    private exportReport = () => {
        var cloneReportFilter = angular.copy(this.reportFilters);
        if(this.all[0].checked) cloneReportFilter.facilityLocations = null; 
        if(this.all[1].checked) cloneReportFilter.unitTypes = null;
        if(this.all[2].checked) cloneReportFilter.unitStatus = null;
        this.reportingService.getUnitReportExcelStream(cloneReportFilter).then((response) => {
            this.downloadFileService.downloadFile(response, 'UnitReport.xlsx');
        });
    }
}

export = UnitsReportController;
