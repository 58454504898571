import * as angular from 'angular';
import state = require("./States");

import clinicalIntegrationComponent = require("./components/clinicalIntegrationComponent");
import downloadUploadActualStatementComponent = require("./components/downloadUploadActualStatementComponent");
import customerCareService = require("./services/customerCareService");

var module = angular.module('customerCare.module', ["common.module"]);
new state().Configure(module);

module.service("customerCare.service", customerCareService);

module.component("clinicalIntegration", new clinicalIntegrationComponent);
module.component("downloadUploadActualStatement", new downloadUploadActualStatementComponent);