"use strict";

var angular = require("angular");
var moment = require("moment");

module.exports = [
    "residents.service",
    "notification.service",
    "modalConfig",
    function (residentsService, notification, modalConfig) {
        var vm = this;
        vm.residentId = modalConfig.residentId;
        vm.mode = modalConfig.mode;
        vm.currentDailyRateOxygen = modalConfig.finance.currentDailyRateOxygen;
        vm.supplement = angular.extend({}, modalConfig.finance.supplementOxygen);

        vm.bindHide = function ($hide) {
            vm.$hide = $hide;
        };

        vm.getDuration = function () {
            if (!vm.supplement.endDateUtc) {
                return "";
            }
            var days = moment(vm.supplement.endDateUtc).startOf('day').diff(moment(vm.supplement.startDateUtc).startOf('day'), "days");
            if (days > 1) {
                return days.toString() + " days";
            }
            if (days === 1) {
                return "1 day";
            }
            if (days === 0) {
                return "0 days";
            }
            return "";
        };

        vm.getMinEndDate = function () {
            return moment(vm.supplement.startDateUtc).add(1, "day").startOf('day');
        };

        vm.save = function (isvalid) {
            if (isvalid) {
                if (vm.mode === "end") {
                    residentsService.endSupplementOxygen(vm.residentId, vm.supplement).then(
                    function (data) {
                        modalConfig.finance.supplementOxygen = null;
                        modalConfig.finance.supplementOxygenPreviousEntries = true;
                        vm.$hide();
                    },
                    function () {
                        notification.error("Unexpected error while saving Assisted Oxygen details.");
                    });
                } else {
                    residentsService.saveSupplementOxygen(vm.residentId, vm.supplement).then(
                        function(data) {
                            modalConfig.finance.supplementOxygen = angular.extend({}, vm.supplement, { id: data.result });
                            vm.$hide();
                        },
                        function() {
                            notification.error("Unexpected error while saving Assisted Oxygen details.");
                        });
                }
            }
        };
       
    }
];
