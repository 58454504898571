"use strict";
module.exports = {

    configure: function (module) {

        module.config(["$stateProvider", function ($stateProvider) {

            $stateProvider.state("viewRoles", {
                url: "/roles",
                templateUrl: "app/roles/partials/viewRoles.html",
                controller: "viewRoles.controller as vm",
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.manageRoles.canView; });
                    }]
                }
            });

            $stateProvider.state("addRole", {
                url: "/roles/add",
                templateUrl: "app/roles/partials/viewEditRole.html",
                controller: "viewEditRole.controller as vm",
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.manageRoles.canModify; });
                    }]
                }
            });

            $stateProvider.state("editRole", {
                url: "/roles/edit/{roleId:int}",
                templateUrl: "app/roles/partials/viewEditRole.html",
                controller: "viewEditRole.controller as vm",
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.manageRoles.canModify; });
                    }]
                }
            });

            $stateProvider.state("viewRole", {
                url: "/roles/view/{roleId:int}",
                templateUrl: "app/roles/partials/viewEditRole.html",
                controller: "viewEditRole.controller as vm",
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.manageRoles.canView; });
                    }]
                }
            });

        }]);
    }

};