import { IInfiniteLoaderService, InfiniteLoaderAction, InfiniteLoaderFilterAction, IInfiniteLoaderFactory } from "./IInfiniteLoaderService";

class InfiniteLoaderService implements IInfiniteLoaderService {
    notIncludingItemsAfter = "";
    skip = 0;
    busy = false;
    noMoreRecords = false;

    constructor(
        private action: InfiniteLoaderAction,
        private successCallback,
        private errorCallback,
        private take = 25) {
        this.nextPage();
    }

    nextPage(): void {
        if (this.busy) {
            return;
        }
        this.busy = true;

        this.action(this.notIncludingItemsAfter, this.skip, this.take)
            .then((response) => {
                if (!response || !response.result || response.result.length === 0) {
                    this.noMoreRecords = true;
                    return;
                }
                this.notIncludingItemsAfter = response.notIncludingItemsAfter;
                this.successCallback(response.result, response.totalCount);
            }, () => {
                this.errorCallback();
            })
            .finally(() => {
                this.busy = this.noMoreRecords;
            });

        this.skip += this.take;
    }

    showLoadingIndicator(): boolean {
        return this.busy && !this.noMoreRecords;
    }
}


class InfiniteLoaderFilterService implements IInfiniteLoaderService {
    notIncludingItemsAfter = "";
    skip = 0;
    busy = false;
    noMoreRecords = false;

    constructor(        
        private action: InfiniteLoaderFilterAction,
        private filter,
        private successCallback,
        private errorCallback,
        private take = 25) {
        this.nextPage();
    }

    nextPage(): void {
        if (this.busy) {
            return;
        }
        this.busy = true;

        this.action(this.filter, this.notIncludingItemsAfter, this.skip, this.take)
            .then((response) => {
                if (!response || !response.result || response.result.length === 0) {
                    this.noMoreRecords = true;
                    return;
                }
                this.notIncludingItemsAfter = response.notIncludingItemsAfter;
                this.successCallback(response.result);
            }, () => {
                this.errorCallback();
            })
            .finally(() => {
                this.busy = this.noMoreRecords;
            });

        this.skip += this.take;
    }

    showLoadingIndicator(): boolean {
        return this.busy && !this.noMoreRecords;
    }
}

class InfiniteLoaderFactory implements IInfiniteLoaderFactory {
    instance(action: InfiniteLoaderAction, successCallback, errorCallback, itemsToFetch): IInfiniteLoaderService {
        return new InfiniteLoaderService(action, successCallback, errorCallback, itemsToFetch);
    }

    instanceWithFilter(action: InfiniteLoaderFilterAction, filter, successCallback, errorCallback, itemsToFetch): IInfiniteLoaderService {
        return new InfiniteLoaderFilterService(action, filter, successCallback, errorCallback, itemsToFetch);
    }
}
export = InfiniteLoaderFactory
