"use strict";
const _ = require("underscore");
const moment = require("moment");
const angular = require("angular");

module.exports = [
    "funding.service", "notification.service", "$window",
    function (fundingService, notification, $window) {
        var vm = this;
        var selectedFundingRate;
        vm.july2024 = '20240701';

        // this function shapes acfi rates and supplements in collection which simplyfies drawing table in angular
        var reorganizeAcfiRatesAndSupplements = function (fundingRate) {
            if (!fundingRate.isProcessedForUi) {
                if (fundingRate.fundingModel === "ACFI") {
                    fundingRate.interimAcfiRate = _.findWhere(fundingRate.acfiRates,
                        { acfiDomain: "Default", acfiLevel: "Default" });

                    fundingRate.acfiRatesForView = {};
                    _.each(vm.acfiLevelsMetadata,
                        function (acfiLevel) {
                            _.each(vm.acfiDomainsMetadata,
                                function (acfiDomain) {
                                    if (!fundingRate.acfiRatesForView[acfiLevel.name]) {
                                        fundingRate.acfiRatesForView[acfiLevel.name] = {};
                                    }
                                    fundingRate.acfiRatesForView[acfiLevel.name][acfiDomain.name] =
                                        _.findWhere(fundingRate.acfiRates,
                                            { acfiDomain: acfiDomain.name, acfiLevel: acfiLevel.name })
                                            .dailyRate;
                                });
                        });

                    fundingRate.supplementsForView = {};
                    _.each(vm.supplementsMetadata,
                        function (supplement) {
                            var rate = _.findWhere(fundingRate.supplements, { name: supplement.name });
                            if (rate != undefined) {
                                fundingRate.supplementsForView[supplement
                                    .name] = rate.amount;
                            }
                        });
                }

                if (fundingRate.fundingModel === "ANACC") {
                    _.each(fundingRate.anaccRegisteredNurse24x7Supplements,
                        function (anaccRegisteredNurse24x7Supplement) {
                            if (anaccRegisteredNurse24x7Supplement.maximumAverageResidents) {
                                anaccRegisteredNurse24x7Supplement.label = `${anaccRegisteredNurse24x7Supplement.minimumAverageResidents} - ${anaccRegisteredNurse24x7Supplement.maximumAverageResidents}`;
                            } else {
                                anaccRegisteredNurse24x7Supplement.label = `${anaccRegisteredNurse24x7Supplement.minimumAverageResidents}+ residents`;
                            }
                        });

                    fundingRate.displayAnaccFixedComponentRates = [];

                    _.each(fundingRate.anaccFixedComponentRates,
                        function (anaccFixedComponentRate, index) {
                            if (anaccFixedComponentRate.fundingBasis === 'Approved Beds') {
                                anaccFixedComponentRate.fundingBasisLabel = 'Operational beds';
                            } else if (anaccFixedComponentRate.fundingBasis === 'Occupied Beds') {
                                anaccFixedComponentRate.fundingBasisLabel = 'Occupied beds';
                            } else {
                                anaccFixedComponentRate.fundingBasisLabel = anaccFixedComponentRate.fundingBasis;
                            }

                            if (anaccFixedComponentRate.numberOfBeds) {
                                let anaccFixedComponentRateH = { ...anaccFixedComponentRate };
                                anaccFixedComponentRateH.facilityMMMClassificationText = `${anaccFixedComponentRateH.facilityMMMClassificationText} (<${anaccFixedComponentRateH.numberOfBeds} beds)`;
                                anaccFixedComponentRateH.saClass = `${anaccFixedComponentRateH.saClass}H`;
                                fundingRate.displayAnaccFixedComponentRates.push(anaccFixedComponentRateH);

                                let anaccFixedComponentRateL = { ...anaccFixedComponentRate };
                                anaccFixedComponentRateL.facilityMMMClassificationText = `${anaccFixedComponentRateL.facilityMMMClassificationText} (${anaccFixedComponentRateL.numberOfBeds}+ beds)`;
                                anaccFixedComponentRateL.saClass = `${anaccFixedComponentRateL.saClass}L`;
                                anaccFixedComponentRateL.nwau = anaccFixedComponentRateL.nwauForOverNumberOfBeds;
                                anaccFixedComponentRateL.baseCareTariff = anaccFixedComponentRateL.baseCareTariffForOverNumberOfBeds;
                                fundingRate.displayAnaccFixedComponentRates.push(anaccFixedComponentRateL);
                            } else {
                                fundingRate.displayAnaccFixedComponentRates.push(anaccFixedComponentRate);
                            }
                        });

                    // Hard code Specialised Homeless to the end of the list to match designs 
                    const specialisedHomelessIndex = _.findIndex(fundingRate.displayAnaccFixedComponentRates,
                        function (anaccFixedComponentRate) {
                            return anaccFixedComponentRate.facilityMMMClassificationText === 'Specialised Homeless';
                        });
                    fundingRate.displayAnaccFixedComponentRates.push(fundingRate.displayAnaccFixedComponentRates.splice(specialisedHomelessIndex, 1)[0]);

                    _.each(fundingRate.supplements,
                        function (supplement) {
                            var metaData = _.findWhere(vm.supplementsMetadata, { name: supplement.name });
                            if (metaData) {
                                supplement.label = metaData.description;
                            } else {
                                supplement.label = supplement.name;
                            }
                        });
                }

                fundingRate.isProcessedForUi = true;
            }
            return fundingRate;
        }
        // end date is moving target and is computed based on start date and immediate successor
        var computeEndDate = function (startDateUtc, id) {
            if (!vm.sortedFundingRates || vm.sortedFundingRates.length === 0 || !startDateUtc) {
                return "";
            }

            var sortedFundingRates = _.reject(vm.sortedFundingRates,
                function (fundingRate) {
                    return fundingRate.id === id;
                });

            if (sortedFundingRates.length === 0)
                return "";


            var overlappingRateIndex = _.findIndex(sortedFundingRates,
                function (fundingRate) {
                    return moment(fundingRate.startDateUtc).isSame(moment(startDateUtc));
                });

            if (overlappingRateIndex !== -1) {
                return undefined;
            }

            var immediateSuccessorIndex = _.findLastIndex(sortedFundingRates,
                function (fundingRate) {
                    return moment(fundingRate.startDateUtc).isAfter(moment(startDateUtc));
                });

            if (immediateSuccessorIndex === -1) {
                return "";
            }

            var endDateUtc = sortedFundingRates[immediateSuccessorIndex].startDateUtc;
            return moment(endDateUtc).subtract(1, "second").toDate();
        }

        // this function shapes upda data to match for dto
        var formatDataToSave = function () {
            vm.selectedFundingRate.acfiRates = [];
            vm.selectedFundingRate.supplements = [];
            vm.selectedFundingRate.acfiRates.push(vm.selectedFundingRate.interimAcfiRate);
            _.each(vm.acfiLevelsMetadata,
                function (acfiLevel) {
                    _.each(vm.acfiDomainsMetadata,
                        function (acfiDomain) {
                            var acfiRate = {
                                acfiLevel: acfiLevel.name,
                                acfiDomain: acfiDomain.name,
                                dailyRate: vm.selectedFundingRate.acfiRatesForView[acfiLevel.name][acfiDomain.name]
                            }
                            vm.selectedFundingRate.acfiRates.push(acfiRate);
                        });
                });
            _.each(vm.supplementsMetadata,
                function (supplementMetadata) {
                    var supplement = {
                        name: supplementMetadata.name,
                        amount: vm.selectedFundingRate.supplementsForView[supplementMetadata.name]
                    };
                    vm.selectedFundingRate.supplements.push(supplement);
                });
        }

        vm.acfiLevelsMetadata = fundingService.getAcfiLevelMetadata();
        vm.acfiDomainsMetadata = fundingService.getAcfiDomainMetadata();
        vm.supplementsMetadata = fundingService.getSupplementsMetadata();
        vm.sortedFundingRates = [];

        fundingService.getAllCareFundingRates()
            .then(function (fundingRates) {
                if (fundingRates && fundingRates.length > 0) {
                    vm.sortedFundingRates = _.sortBy(fundingRates, "startDateUtc").reverse();
                    vm.selectedFundingRate = reorganizeAcfiRatesAndSupplements(_.first(vm.sortedFundingRates));
                }
            },
                function () {
                    notification.error("Error occurred while fetching care funding rates");
                });
        vm.mode = "view";

        var allowClosingForm = function (unsavedChanges) {
            if (unsavedChanges) {
                return $window.confirm("Do you want to leave this page? Changes you made may not be saved.");
            } else {
                return true;
            }
        };

        vm.cancel = function () {
            var unsavedChanges = vm.saveFundingRateForm.$dirty;
            if (allowClosingForm(unsavedChanges)) {
                vm.saveFundingRateForm.$setPristine();
                vm.selectedFundingRate = selectedFundingRate;
                vm.mode = "view";
            }
        };

        vm.selectFundingRate = function (fundingRate) {
            fundingRate = reorganizeAcfiRatesAndSupplements(fundingRate);
            vm.selectedFundingRate = fundingRate;
            vm.mode = "view";
        };

        vm.editCareFundingRate = function () {
            selectedFundingRate = vm.selectedFundingRate;
            vm.selectedFundingRate = reorganizeAcfiRatesAndSupplements(angular.copy(selectedFundingRate));
            vm.mode = "edit";
        };

        vm.saveFundingRate = function (isFormValid) {
            var computedEndDate = computeEndDate(vm.selectedFundingRate.startDateUtc, vm.selectedFundingRate.id);
            if (isFormValid && computedEndDate !== undefined) {
                formatDataToSave();
                vm.selectedFundingRate.isProcessedForUi = true;
                vm.selectedFundingRate.startDateUtc = moment(vm.selectedFundingRate.startDateUtc).toISOString();
                vm.selectedFundingRate.endDateUtc = computedEndDate;
                fundingService.saveFundingRate(vm.selectedFundingRate)
                    .then(function (response) {
                        fundingService.getAllCareFundingRates()
                            .then(function (fundingRates) {
                                if (fundingRates && fundingRates.length > 0) {
                                    vm.sortedFundingRates = _.sortBy(fundingRates, "startDateUtc").reverse();

                                    var index = _.findIndex(vm.sortedFundingRates,
                                        function (fundingRate) {
                                            return fundingRate.id === vm.selectedFundingRate.id;
                                        });
                                    vm.selectedFundingRate = reorganizeAcfiRatesAndSupplements(vm.sortedFundingRates[index]);

                                    selectedFundingRate = vm.selectedFundingRate;
                                    vm.saveFundingRateForm.$setPristine();
                                    vm.mode = "view";
                                }
                            },
                                function () {
                                    notification.error("Error occurred while fetching care funding rates");
                                });
                    },
                        function () {
                            notification.error("Error occurred while saving the funding rate. please try again");
                        });
            }
        };

        vm.getEndDate = function (startDateUtc, id) {
            var endDate = computeEndDate(startDateUtc, id);
            if (!endDate) {
                vm.hideEndDate = true;
            } else {
                vm.hideEndDate = false;
            }
            return endDate;
        }
    }
];
