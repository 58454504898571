"use strict";
module.exports = {

    configure: function (module) {

        // Server side Enums
        module.constant(
            'EffectiveDateTypes',
            {
                MeansTestedCareFee: 'MeansTestCareFee',
                Pre2014IncomeTestedFee: 'Pre2014IncomeTestFee',
                DailyAccommodationContribution: 'DailyAccommodationContribution',
                SupportLevel: 'SupportLevel',
                AgreedRoomPrice: 'AgreedRoomPrice',
                DailyAccommodationPayment: 'DailyAccommodationPayment',
                TopupDailyAccommodationContribution: 'TopupDailyAccommodationContribution',
                RadPaid: 'RadPaid',
                RacPaid: 'RacPaid',
                BasicDailyCareFee: 'BasicDailyCareFee',
                IngoingContribution: 'IngoingContribution',
                PrivateDailyCareFee: 'PrivateDailyCareFee'
            });
        module.constant(
            'Titles',
            [
                "Miss",
                "Mr",
                "Ms",
                "Mrs",
                "Mx",
                "Br",
                "Dame",
                "Dr",
                "Fr",
                "Lady",
                "Lord",
                "Madam",
                "Prof",
                "Pr",
                "Rabbi",
                "Rev",
                "Sir",
                "Sr"
            ]);
        module.constant({
            DefaultTime: '12:30 PM'
        });
        module.constant(
            'RoomTypePriceSetting',
            {
                room: 0,
                bed: 1
            });
        module.constant(
            'SpecialisedCareList',
            {
                "Court managed": 1,
                "Homeless": 2,
                "NDIS": 3,
                "Safety net": 4,
                "TCP": 5
            }
        );
        module.constant(
            {
                July2024: 202407
            }
        )
    }
};