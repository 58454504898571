import { IIndependentLivingService } from './IIndependentLivingService';
import { UpdateEnquiryDto, CreateEnquiryDto, FeesAndChargesDto, UnitDto, IluAdmissionDto, IluSettleUnitDto, SimpleResidentDto, DepartedResidentDto, AssignedAccommodationDto, 
         IluStatus, ResidencyFinanceOptionalServiceDto, IluAccommodationFinancialEventsDto, ILUResidentDto, TransferFacilityToFacilityDto} from '../Models';
import {OptionalService, OptionalServiceRate} from '../../organisation/Models';
import _ = require('underscore');
import moment = require('moment');

class IndependentLivingService implements IIndependentLivingService {
    static $inject = ["data.service"];

    constructor(
        private readonly dataService: any
    ) {
    }

    public viewResidents =  (notIncludingItemsAfter: any, skip: number, take: number): ng.IPromise<Array<SimpleResidentDto>> => {
        return this.dataService.get("allocations", skip == 0 ? false : true,
        {
            notIncludingItemsAfter: notIncludingItemsAfter,
            skip: skip,
            take: take
        });
    }

    public viewDepartedResidents =  (notIncludingItemsAfter: any, skip: number, take: number): ng.IPromise<Array<DepartedResidentDto>> => {
        return this.dataService.get("allocations/departed-residents", skip == 0 ? false : true,
        {
            notIncludingItemsAfter: notIncludingItemsAfter,
            skip: skip,
            take: take
        });
    }

    public saveEnquiry(enquiry: CreateEnquiryDto) {
        return this.dataService.post("enquiries", enquiry);
    };

    public getEnquiry(enquiryId: number, viewDepartedMode = false, residentId = null) {
        if (residentId == null) {
            return this.dataService.get("enquiries/" + enquiryId, false,
            {
                viewDepartedMode: viewDepartedMode
            });
        }
        else {
            return this.dataService.get("enquiries/" + enquiryId, false,
            {
                viewDepartedMode: viewDepartedMode,
                residentId: residentId
            });
        }
    };

    public updateEnquiry(enquiry: UpdateEnquiryDto) {
        return this.dataService.put("enquiries", enquiry);
    };

    public deleteEnquiry(enquiryId: number) {
        return this.dataService.delete("enquiries/" + enquiryId);
    }

    public addResidentToEnquiry(iLUResidentDto: ILUResidentDto) {
        return this.dataService.post("enquiries/add-resident", iLUResidentDto);
    };

    public transferEnquiry(transferFacilityToFacilityinfo: TransferFacilityToFacilityDto) {
        return this.dataService.post("enquiries/transfer-enquiry", transferFacilityToFacilityinfo);
    }

    public getAvailableUnits() {
        return this.dataService.get("accommodations").then((result) => {
            var accommodations = new Array<UnitDto>();
            result.forEach(accommodationAndLocation => {
                for (var i = 0; i < accommodationAndLocation.accommodations.length; i++) 
                {
                    var unit= new UnitDto();
                    unit = accommodationAndLocation.accommodations[i];
                    unit.location = accommodationAndLocation.locationName;
                    accommodations.push(unit);
                }
            });
            return accommodations;
        },
        function (error) {
            return this.reject(error);
        });
    }
    
    public getUnitsByStatus = (status: IluStatus) => {
        return this.dataService.get("accommodations/units-by-status/" + status);
    }

    public saveFeesAndCharges(feesAndCharges: FeesAndChargesDto) {
        return this.dataService.post("enquiries/finance/fees-and-charges", feesAndCharges);
    };

    public saveAssignUnit(assignUnit: AssignedAccommodationDto) {
        return this.dataService.post("accommodations/assign-unit", assignUnit);
    };
    public getFeesAndCharges(enquiryId : number, viewDepartedMode = false, residentId = null) {
        if (residentId == null) {
            return this.dataService.get("enquiries/finance/fees-and-charges/" + enquiryId, true,
            {
                viewDepartedMode: viewDepartedMode
            });
        }
        else {
            return this.dataService.get("enquiries/finance/fees-and-charges/" + enquiryId, true,
            {
                viewDepartedMode: viewDepartedMode,
                residentId: residentId
            });
        }
    }

    public admit = (iluAdmissionDto: IluAdmissionDto) => {
        return this.dataService.post("enquiries/admit", iluAdmissionDto);
    }

    public correctAdmission(iluAdmissionDto: IluAdmissionDto){
        return this.dataService.post("allocations/admission-correction", iluAdmissionDto);
    }

    public reverseAdmission = (allocationId: number) => {
        return this.dataService.post(`allocations/${allocationId}/reverse-admission`);
    }

    public reverseDeparture(residencyId) {
        let url = "/allocations/departure/reversal/";
        return this.dataService.post(url,residencyId);
    }

    public getAccommodationFinancialEvents(status?: IluStatus) {
        return this.dataService.get("enquiries/finance/accommodation-financial-events/" + status);
    }
    
    public addNote = (allocationId:number, note:string) => {
        return this.dataService.post("allocations/" + allocationId + "/notes", JSON.stringify(note));
    }
    
    public settleUnit = (iluSettleUnitDto: IluSettleUnitDto) => {
        return this.dataService.post("allocations/settlement", iluSettleUnitDto);
    }

    public initialiseFacilityOptionalServices(facilityOptionalService: OptionalService) {
        let currentTime = (new Date()).getTime();
       
        // convert the dates to Date objects
        facilityOptionalService.ratesList.forEach(rate => {
            rate.startDate = new Date(rate.startDate.toString());
            rate.endDate = rate.endDate ? new Date(rate.endDate.toString()) : null;
        });

        // sort rates, most recent first
        facilityOptionalService.ratesList = _.sortBy(facilityOptionalService.ratesList, rate => -rate.startDate.getTime());

        // determine today's rate
        let useRate = facilityOptionalService.ratesList[facilityOptionalService.ratesList.length - 1];
        facilityOptionalService.ratesList.slice().reverse().forEach(rate => {
            if (currentTime >= rate.startDate.getTime()) {
                useRate = rate;
            }
        });
        facilityOptionalService.rate = useRate.value;
        facilityOptionalService.todayRateIndex = facilityOptionalService.ratesList.indexOf(useRate);; 
    }

    public initialiseResidencyOptionalServices(optionalService: ResidencyFinanceOptionalServiceDto, 
                                               optionalServices: ResidencyFinanceOptionalServiceDto[],
                                               facilityOptionalServicesLookupById: {[optionalServiceId: number]: OptionalService}) {
        let currentTime = (new Date());

        optionalService.startDateUtc = new Date(optionalService.startDateUtc.toString());
        optionalService.endDateUtc = optionalService.endDateUtc ? new Date(optionalService.endDateUtc.toString().replace("23:59:59", "00:00:00")) : null;

        let endDateUtcEndOfDay = null;
        if (optionalService.endDateUtc != null) {
            optionalService.endDateUtc = new Date(Date.UTC(optionalService.endDateUtc.getUTCFullYear(), optionalService.endDateUtc.getUTCMonth(), optionalService.endDateUtc.getUTCDate(), optionalService.endDateUtc.getUTCHours(), optionalService.endDateUtc.getUTCMinutes(), optionalService.endDateUtc.getUTCSeconds()));
            endDateUtcEndOfDay = new Date(optionalService.endDateUtc);
            endDateUtcEndOfDay.setHours(23);
            endDateUtcEndOfDay.setMinutes(59);
            endDateUtcEndOfDay.setSeconds(59);
        }

        // these two fields are used as utility fields by the form
        optionalService.index = optionalServices.indexOf(optionalService);
        optionalService.description = facilityOptionalServicesLookupById[optionalService.optionalServiceId].typeDescription;

        // Use the facility rate, unless we're outside our service date range, then use 0.
        optionalService.rate = facilityOptionalServicesLookupById[optionalService.optionalServiceId].rate;

        if (currentTime.getTime() < optionalService.startDateUtc.getTime() || (endDateUtcEndOfDay != null && currentTime.getTime() > endDateUtcEndOfDay)) {
            optionalService.rate = "0";
        }
        
        optionalService.applicableRatesList = this.prepareRatesListForResidentServiceWindow(
            facilityOptionalServicesLookupById[optionalService.optionalServiceId].ratesList,
            optionalService.startDateUtc,
            optionalService.endDateUtc
        );
    }

    public prepareRatesListForResidentServiceWindow = (ratesList: OptionalServiceRate[], serviceStartDate: Date, serviceEndDate: Date | null) => {
        // Make a deep copy of the rateList, 'cos we will be seeking to make some data changes :)
        // Assuming rateList is sorted by startDate descendeing (most recent startDate to earlest startDate)
        let output = JSON.parse(JSON.stringify(ratesList)) as OptionalServiceRate[];

        // Remove any rates that ended before the serviceStartDate.
        // rate.endDate could be a string, but serviceStartDate a date type.
        output = output.filter((rate) => !rate.endDate || new Date(rate.endDate.toString()) >= serviceStartDate);

        // If the service has an end date then only keep rates that start before or on the end date.
        // rate.startDate could be a string, but serviceEndDate a date type.
        if (serviceEndDate) {
            output = output.filter((rate) => new Date(rate.startDate.toString()) <= serviceEndDate);
        }

        // If there is atleast one rate left then set the set the start date of the last (earlest startDate) rate to the serviceStartDate.
        if (output.length) {
            output[output.length - 1].startDate = serviceStartDate;
        }

        // If the service has an end date then display a $0 rate effective from the day after the end date as the first rate.
        if (serviceEndDate) {
            // And if there is atleast one rate then set the end date of the existing first rate (most recent startDate) to the serviceEndDate.
            if (output.length) {
                output[0].endDate = serviceEndDate;
            }

            // Insert the $0 rate as the first rate (most recent startDate).
            const zeroRateFrom = new Date(serviceEndDate);

            zeroRateFrom.setDate(zeroRateFrom.getDate() + 1);            
            output.unshift(new OptionalServiceRate(0, zeroRateFrom, null, "0"))
        }

        return output;
    }

    public getTotalDMFPercentageForTheTimePeriod = (index, deferredManagementFeesSchedule) => {
        
        let calculatedTotal : number = 0;
        if(deferredManagementFeesSchedule[index].value && deferredManagementFeesSchedule[index].effectiveMonth)
        {
            var rate = deferredManagementFeesSchedule[index].value;
            var month = deferredManagementFeesSchedule[index].effectiveMonth;
            calculatedTotal = (rate * (month/12));
            if(index == 0 ) 
            {
                deferredManagementFeesSchedule[index].total = calculatedTotal;

            }
            else
            {
                var previousValidRow = index-1;
                var rowIndex = 0;
                // if the previous row is left as blank before, need to find previous valid row 
                if(!deferredManagementFeesSchedule[index-1].value)
                {
                    for(rowIndex = index-1; rowIndex>=0 ; rowIndex--)
                    {
                        if(deferredManagementFeesSchedule[rowIndex].value)
                        {
                            previousValidRow = rowIndex;
                            break;
                        }
                    }
                }
                deferredManagementFeesSchedule[index].total = calculatedTotal+ deferredManagementFeesSchedule[previousValidRow].total;
            }
        }
        else
        {
            deferredManagementFeesSchedule[index].total =null;
        }
        return deferredManagementFeesSchedule[index].total;
    }   

    public getUnitNotes = (units: UnitDto[], events: IluAccommodationFinancialEventsDto[]) => {
        units.forEach(unit => {
            let unitEvents = _.findWhere(events, { accommodationId: unit.accommodationId });
            let noteString = "";
            if (unitEvents) {
                var x = unitEvents.events[unitEvents.events.length - 1];
                if (x.noticeToVacateDateUtc) {
                    noteString =  "Vacating " + moment(x.noticeToVacateDateUtc).format("DD MMM YYYY");
                }
                else if (x.balancePaidDateUtc) {
                    noteString = "Balance paid";
                }
                else if (x.depositPaidDateUtc) {
                    noteString = "Deposit paid";
                }
                else if (x.contractSignedDateUtc) {
                    noteString = "Contract signed";
                }           
            }
            else if(unit.iluStatus == "OutOfService")
            {
                noteString = "Out of service";
            };  
            unit.briefNotes = noteString;
        });
    }  
}



export = IndependentLivingService;
