import { IResidentsService } from '../services/IResidentsService';
import { INotificationService } from '../../common/services/INotificationService';
import type { IStateParamsService } from 'angular-ui-router';

class ViewTimelineController {
    static $inject = ["residents.service", "notification.service", "$stateParams"];

    constructor(
        private readonly residentsService: IResidentsService,
        private readonly notificationService: INotificationService,
        private readonly stateParams: IStateParamsService) {
        this.resident = {
            id: this.stateParams.residentId  // used for resident documents directive rendered by parent state
        }
        this.getTimeline();
    }

    public resident: { id: number };
    public timeline: object;
    public medicareTimeZone: string;

    private getTimeline = () => {
        this.residentsService.getTimelineForResident(this.resident.id)
            .then(result => {
                this.timeline = result;
            },
            () => {
                this.notificationService.error("Error occurred while fetching timeline information for resident");
            });
    }
}

export = ViewTimelineController;
