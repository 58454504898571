import { ILeadsService } from "../services/ILeadsService";
import { IResidentsService } from "../../residents/services/IResidentsService";
import { INotificationService } from '../../common/services/INotificationService';
import { IDialogService } from "../../common/services/IDialogService";
import {LeadCardDto, Constants, EnquiryStatus, TourDto, LeadDashboardFilterDto} from '../Models';
import _ = require("underscore");


class LeadsDashboardColumnComponent implements ng.IComponentOptions {
    public bindings: any = {
        columnTitle: "<",
        leadDashboardFilter: "<",
        leadCards: "<",
        totalCount: "=",
        notIncludingItemsAfter : "=",
        refreshColumnCallback: "&"
    };

    public templateUrl: string = 'app/leads/partials/leadsDashboardColumn.html';

    public controller: any = class LeadsDashboardColumnComponentController {
        static $inject = ["leads.service", "residents.service", "notification.service", "dialog.service"];

        public columnTitle: EnquiryStatus;
        public totalCount: number;
        public leadCards: LeadCardDto[];
        public notIncludingItemsAfter: Date;        
        public leadDashboardFilter : LeadDashboardFilterDto;
        public refreshColumnCallback: ({status: EnquiryStatus}) => any;
        
        public columnTitleDict: {[status: string]: string} = {
            Enquiries: "Enquiries",
            TourBooked: "Tour booked",
            TourCompleted: "Tour completed",
            Waiting: "Waiting",
            Confirmed: "Confirmed" 
        };         

        constructor(private readonly leadsService: ILeadsService,
            private readonly residentsService: IResidentsService,
            private readonly notificationService: INotificationService,
            private readonly dialogService: IDialogService) {      
       
        }
  
        public loadLeadsForDashboardColumn = () => {
            let skip = this.leadCards.length;
            this.leadsService.getLeadsForDashboardByStatus(this.columnTitle, skip, Constants.CARDS_NUMBER_TAKE_EACH_TIME, 
                this.leadDashboardFilter, this.notIncludingItemsAfter).then(response => {
                this.leadCards = this.leadCards.concat(response.result);
                this.notIncludingItemsAfter = response.notIncludingItemsAfter;
            }, () => {
                this.notificationService.error("Unexpected error while loading leads for column.");
            });
        }
        
        public archive = (residenId: number) => {
            this.leadsService.archiveLead(residenId).then(() => {
                this.notificationService.success("Lead archived.");
                this.totalCount = this.totalCount - 1;
                this.leadCards.splice(_.findIndex(this.leadCards, (card)=> {return card.residentId == residenId}), 1);
            }, () => {
                this.notificationService.error("Unexpected error occurred while archiving a lead record.");
            });
        }

        public removeFromLeads = (residentId: number) => {
            const leadCard = _.find(this.leadCards, (leadCard) => {
                return leadCard.residentId == residentId;
            });

            let title = 'Move record to departed resident list';
            let confirmation = "Removing this record from the lead list will return it to the departed resident list."
            let buttonText = "Move to departed list";
            let buttonStyle = "btn-primary";
            
            this.dialogService.openActionDialog(title, confirmation, "lead-archive", buttonText,
                () => {
                    this.leadsService.sendToDepartedList(residentId).then(() => {
                        this.notificationService.success(`${leadCard.firstName} ${leadCard.lastName} has been returned to the departed residents list`);
                        this.totalCount = this.totalCount - 1;
                        this.leadCards.splice(_.findIndex(this.leadCards, (card)=> {return card.residentId == residentId}), 1);
                    }, () => {
                        this.notificationService.error("Unexpected error occurred while moving a lead record into departed list.");
                    });
                },
                buttonStyle);
        }

        public markAsDeceased = (residentId: number) => {
            this.residentsService.updateResidentDeceased(residentId, true).then(result => {
                let cardIndex = _.findIndex(this.leadCards, (card) => { return card.residentId == residentId});              
                this.totalCount = this.totalCount - 1;
                this.leadCards.splice(cardIndex, 1);

                this.notificationService.success("Lead is marked as deceased successfully!");
            },
            () => {
                this.notificationService.error("Unexpected error occurred while marking lead as deceased.");
            });
        }

        public deleteCard = (residentId: number) => {
            const leadCard = _.find(this.leadCards, (leadCard) => {
                return leadCard.residentId == residentId;
            });

            let title = 'Delete enquiry';
            let confirmation = `Are you sure you want to delete the record for ${leadCard.firstName} ${leadCard.lastName.toUpperCase()}?`;
            let buttonText = "Delete";
            let buttonStyle = "btn-danger btn-inline-block-xsm";
            
            this.dialogService.openActionDialog(title, confirmation, "lead-delete", buttonText,
                () => {
                    this.leadsService.deleteLead(residentId).then(() => {
                        this.notificationService.success("Lead deleted.");
                        this.totalCount = this.totalCount - 1;
                        this.leadCards.splice(_.findIndex(this.leadCards, (card)=> {return card.residentId == residentId}), 1);
                    }, () => {
                        this.notificationService.error("Unexpected error occurred while deleting lead.");
                    });
                },
                buttonStyle);
        }

        public updateSortable = (e, ui) => {
            let dropTargetModel = ui.item.sortable.droptargetModel;
            let sourceModel = ui.item.sortable.sourceModel;
            let index = ui.item.sortable.index;
            let dropIndex = ui.item.sortable.dropindex;
            let movingCard = ui.item.sortable.model;            

            movingCard.cardMoved();

            if (sourceModel == dropTargetModel)  { //move in the same column
                let siblingOrderNumbers = this.getSiblingOrderNumberWhenMovingInSameColumn(index, dropIndex, sourceModel); 

                this.leadsService.updateLeadOrderNumber(movingCard.residencyId, 
                    siblingOrderNumbers.predecessor, siblingOrderNumbers.successor, this.columnTitle).then((response) => { 
                    movingCard.orderNumber = response.result;    
                },
                () => {
                    this.notificationService.error("Unexpected error occurred while ordering lead list.");
                });
            }
            else {  //move in different columns
                if (!ui.item.sortable.isCanceled()) {
                    let sourceStatus: EnquiryStatus = ui.item.sortable.source.attr("column-name");
                    let targetStatus: EnquiryStatus = ui.item.sortable.droptarget.attr("column-name");
                    if (this.isMoveAcrossColumnsAllowed(targetStatus, movingCard.tourInfo)) {                   
                        if (ui.item.sortable.received) {  //when the card is dropped
                            this.updateTargetColumn(dropTargetModel, movingCard, dropIndex, sourceStatus, targetStatus);
                        }
                        else {
                            this.totalCount -= 1;
                        } 
                    } 
                    else {
                        ui.item.sortable.cancel();
                        if (movingCard.tourInfo.isCompleted)
                            this.notificationService.error("Tour already completed.....");
                        else
                            this.notificationService.error("Tour already booked.....");
                    } 
                }
            }
        }

        private isMoveAcrossColumnsAllowed = (targetStatus: EnquiryStatus, tourInfo: TourDto): boolean => {
            if (tourInfo.id != 0) {
                if (!tourInfo.isCompleted) {   //Tour booked
                    if (targetStatus == EnquiryStatus.Enquiries)
                        return false;
                }
                else {  //tour completed
                    if (targetStatus == EnquiryStatus.Enquiries || targetStatus == EnquiryStatus.TourBooked)
                        return false;
                }
            }  
                      
            return true;
        }

        private getSiblingOrderNumberWhenMovingInSameColumn = (index, dropIndex, sourceModel) => {
            let successorOrderNumber: string;
            let predecessorOrderNumber: string;

            if(index > dropIndex) { // move up               
                if(dropIndex > 0) {
                    successorOrderNumber = sourceModel[dropIndex-1].orderNumber;
                    predecessorOrderNumber = sourceModel[dropIndex].orderNumber;
                }
                else {
                    successorOrderNumber = "";
                    predecessorOrderNumber = sourceModel[dropIndex].orderNumber;
                }
            }
            else { // move down
                if(dropIndex < sourceModel.length-1) {
                    successorOrderNumber = sourceModel[dropIndex].orderNumber; 
                    predecessorOrderNumber = sourceModel[dropIndex+1].orderNumber;
                }
                else {
                    predecessorOrderNumber = "";
                    successorOrderNumber = sourceModel[dropIndex].orderNumber;
                }
            }

            return {
                successor: successorOrderNumber,
                predecessor: predecessorOrderNumber
            }
        }

        private getSiblingOrderNumberWhenMovingToOtherColumn = (dropIndex, dropTargetModel) => {
            let successorOrderNumber: string;
            let predecessorOrderNumber: string;

            if (dropTargetModel.length == 1)  { //drop into an empty column
                successorOrderNumber = "";
                predecessorOrderNumber = "";
            }
            else if (dropIndex == 0) {  //drop at the first card
                successorOrderNumber = "";
                predecessorOrderNumber = dropTargetModel[dropIndex + 1].orderNumber;
            }
            else if (dropIndex == dropTargetModel.length -1) {  //drop at the last card
                successorOrderNumber = dropTargetModel[dropIndex - 1].orderNumber;
                predecessorOrderNumber = "";
            }
            else {
                successorOrderNumber = dropTargetModel[dropIndex - 1].orderNumber;
                predecessorOrderNumber = dropTargetModel[dropIndex + 1].orderNumber;
            }

            return {
                successor: successorOrderNumber,
                predecessor: predecessorOrderNumber
            }
        }

        private updateTargetColumn = (dropTargetModel, model, dropIndex, sourceStatus, targetStatus) => {  
            let siblingOrderNumbers = this.getSiblingOrderNumberWhenMovingToOtherColumn(dropIndex, dropTargetModel); 

            this.leadsService.updateLeadOrderNumber(model.residencyId, 
                siblingOrderNumbers.predecessor, siblingOrderNumbers.successor, this.columnTitle).then((response) => { 
                this.leadsService.updateLeadStatus(model.residencyId, this.columnTitle).then(() => {
                    this.totalCount += 1;

                    let originalOrderNumber: string = model.orderNumber;
                    model.orderNumber = response.result; 

                    if ( (targetStatus == EnquiryStatus.TourBooked && model.tourInfo.id == 0)
                        ||(targetStatus == EnquiryStatus.TourCompleted && (model.tourInfo.id == 0 || !model.tourInfo.isCompleted))) {
                    this.openTourBookingDialog(model.residencyId, model.firstName + " " + model.lastName, model.tourInfo, 
                            sourceStatus, targetStatus, originalOrderNumber);
                    };   
                });                                  
            },
            () => {
                this.notificationService.error("Unexpected error occurred while ordering lead list.");
            });
        }

        public sortableOptions = {
            update : this.updateSortable,  
            connectWith: ".sortable-container"                     
        };     

        public tourBookingDialogCancelCallback = (residencyId, sourceStatus, originalOrderNumber) => {  
            this.leadsService.updateLeadStatus(residencyId, sourceStatus, originalOrderNumber).then(() => {
                this.refreshColumnCallback({status: sourceStatus});
                this.refreshColumnCallback({status: this.columnTitle});
            });
        }

        public tourBookingDialogsuccessfulCallback = () => {
            this.refreshColumnCallback({status: this.columnTitle});
        }

        public openTourBookingDialog = (residencyId, residentName, tourInfo, sourceStatus, targetStatus, originalOrderNumber) => {                   
            this.dialogService.openStaticDialog("app/leads/partials/tourBookForLeadsDashboardDialog.html",
                "tourBookForLeadsDashboardDialog.controller",
                {
                    residencyId: residencyId,
                    residentName: residentName,
                    tourInfo: tourInfo,
                    sourceStatus: sourceStatus,
                    targetStatus: targetStatus,
                    originalOrderNumber: originalOrderNumber,
                    cancelCallback: this.tourBookingDialogCancelCallback,
                    successfulCallback: this.tourBookingDialogsuccessfulCallback
                });
        }
    }
}
export = LeadsDashboardColumnComponent;