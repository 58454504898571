"use strict";

module.exports = function () {
    return {
        restrict: "AE",
        replace: true,
        scope: {},
        controller: ["$scope", function ($scope) {
            $scope.active = false;
            $scope.$on("loadingStarts", function () {
                $scope.active = true;
            });
            $scope.$on("loadingStops", function () {
                $scope.active = false;
            });
        }],
        template: "<span ng-class=\"{active: active}\" class=\"loading-indicator\"><div></div></span>"
    };
}
