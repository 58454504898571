class AutoResizeTextareaDirective implements ng.IDirective {
    restrict = 'A';
    scope = {
        resizeControl: '='
    };
    constructor(private $timeout: ng.ITimeoutService) {
    }

    link = (scope: ng.IScope, element: JQuery, attrs: ng.IAttributes) => {
        let resizeCallback = () => {
            this.$timeout(() => {
                // set the height to auto before setting it to the scroll height
                // this seems to allow shrinking of the textarea as well as growing
                element.css('height', 'auto');
                element.css('height', element[0].scrollHeight + 'px');
            });
        }
        
        this.$timeout(() => {
                element.css({
                    'height': element[0].scrollHeight + 'px',
                    'overflow-y': 'hidden',
                    'resize': 'none'
                });
        });

        element.on('input', resizeCallback);
        
        scope.$on('$destroy', () => {
            element.off('input');
        });
        if (scope['resizeControl']) {
            scope['resizeControl'].resize = resizeCallback;
        }
    }

    static factory(): ng.IDirectiveFactory {
        const directive = ($timeout: ng.ITimeoutService) => new AutoResizeTextareaDirective($timeout);
        directive.$inject = ['$timeout'];
        return directive;
    }
}
export = AutoResizeTextareaDirective;
