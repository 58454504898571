import _ from 'underscore';
import { INotificationService } from "../../common/services/INotificationService";
import { IResidentsService } from "../services/IResidentsService";
import { IDialogService } from "../../common/services/IDialogService";

class FundingEstimateComponent implements ng.IComponentOptions {
    public bindings: any;
    public templateUrl: string = "app/residents/partials/fundingEstimate.html";

    constructor() {
        this.bindings = {
            residentId: "<",
            residencyId: "<",
            residentFirstName: "<",
            residentLastName: "<",
            careType: "<",
            palliativeStatus: "<",
            expectedAnaccVariableComponent: "="
        };
    }

    public controller: any = class FundingEstimateComponentController {
        static $inject = ["$scope", "residents.service", "notification.service", "fundingService", "dialog.service"];
        public isEditing: boolean;
        public residentId: number;
        public residencyId: number;
        public residentFirstName: string;
        public residentLastName: string;
        public careType: string;
        public palliativeStatus: string;
        public permanentAnaccClasses: any;
        public respiteAnaccClasses: any;
        public expectedAnaccVariableComponent: any;
        public fundingEstimateForm: ng.IFormController;
        public editExpectedAnaccVariableComponent: any | null;
        public anaccFixedComponent: any | null;
        public dailyTotal: number | null;

        constructor($scope: any, private readonly residentsService: IResidentsService,
            private readonly notificationService: INotificationService,
            readonly anaccService: any,
            private readonly dialogService: IDialogService) {
            this.isEditing = false;

            anaccService.getAnaccVariableComponents(true).then(result => {
                this.permanentAnaccClasses = _.filter(result, (anaccVariableComponent) => anaccVariableComponent.isPermanent);
                this.permanentAnaccClasses.unshift({
                    anaccClass: "Unknown",
                    anaccClassText: "Unknown",
                });

                this.respiteAnaccClasses = _.filter(result, (anaccVariableComponent) => anaccVariableComponent.isRespite);
                this.respiteAnaccClasses.unshift({
                    anaccClass: "Unknown",
                    anaccClassText: "Unknown",
                });
            }, () => {
                notificationService.error("Unexpected error while loading AN-ACC classes.");

                this.permanentAnaccClasses = [];
                this.respiteAnaccClasses = [];
            });

            anaccService.getAnaccFixedComponentForCurrentFacility().then(result => {
                this.anaccFixedComponent = result;
                this.anaccClassChange();
            }, () => {
                notificationService.error("Unexpected error while loading MMM classification.");
                this.anaccFixedComponent = null;
            });

            $scope.$watch("expectedAnaccVariableComponent", () => {
                this.editExpectedAnaccVariableComponent = this.expectedAnaccVariableComponent;
                this.anaccClassChange();
            });
        }

        public setIsEditing(status) {
            this.isEditing = status;

            if (this.isEditing) {
                this.editExpectedAnaccVariableComponent = this.expectedAnaccVariableComponent;
            }

            this.anaccClassChange();
        }

        public cancel = () => {
            if (this.isEditing) {
                this.editExpectedAnaccVariableComponent = this.expectedAnaccVariableComponent;
                this.setIsEditing(false);
                this.fundingEstimateForm.$setPristine();
            }
        }

        public submit = () => {
            if (this.isEditing && this.fundingEstimateForm.$valid) {
                this.residentsService.saveLeadAnaccClassification(this.residencyId, this.editExpectedAnaccVariableComponent!.anaccClass).then(() => {
                    this.expectedAnaccVariableComponent = this.editExpectedAnaccVariableComponent;
                    this.setIsEditing(false);
                    this.notificationService.success("Funding estimate save successfully.");
                }, () => {
                    this.notificationService.error("Unexpected error while saving pre-admission funding estimate.");
                });
            }
        }

        public anaccClassChange = () => {
            this.dailyTotal = (this.anaccFixedComponent && this.editExpectedAnaccVariableComponent?.rate) ? Number(this.anaccFixedComponent?.baseCareTariff) + Number(this.editExpectedAnaccVariableComponent?.rate) : null;
        }

        public openAnaccCalculatorDialog = () => {
            this.dialogService.openDialog("app/residents/partials/anaccCalculatorDialog.html",
                "anaccCalculatorDialog.controller",
                {
                    residentId: this.residentId,
                    residentFirstName: this.residentFirstName,
                    residentLastName: this.residentLastName,
                    residentCareType: this.careType,
                    palliativeStatus: this.palliativeStatus,
                    anaccFixedComponent: this.anaccFixedComponent,
                    expectedAnaccVariableComponent: this.editExpectedAnaccVariableComponent,
                    callback: this.openAnaccCalculatorDialogCallback
                });
        }

        public openAnaccCalculatorDialogCallback = (expectedAnaccVariableComponent: any | null) => {
            this.editExpectedAnaccVariableComponent = expectedAnaccVariableComponent;
            this.anaccClassChange();
        }
    }
}
export = FundingEstimateComponent