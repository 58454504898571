"use strict";
module.exports = ["$rootScope", function ($rootScope) {
        return {
            restrict: "A",
            link: function (scope, element, attrs) {
                var args = attrs['navPublisher'];
                $rootScope.$broadcast('highlight_navigator', args);

                scope.$on('$destroy', function () {
                    $rootScope.$broadcast('highlight_navigator', null);
                });
            }
        };
    }
]