"use strict";
const angular = require("angular");
const _ = require("underscore");

module.exports = [
    "residents.service",
    "notification.service",
    "modalConfig",
    "$state",
    function (residentsService, notification, modalConfig, $state) {
        var vm = this;
        angular.extend(vm, modalConfig);

        vm.bindHide = function ($hide) {
            /* 
            Medicare has only three states for gender, Male, Female & unknown. 
            we have four states, Male, Female, Indeterminate & Unknown.
            From Medicare perspective, Indeterminate needs to be mapped to Unknown.
            */
            if (vm.resident.sex !== 'Male' && vm.resident.sex !== 'Female')
                vm.resident.sex = 'Unknown';
            vm.$hide = $hide;
            vm.errorList = null;
        };

        vm.fetchCareRecipientId = function (isGetCridFormValid) {
            if (isGetCridFormValid) {
                vm.fetchCridInfo = {
                    dateOfBirth: vm.resident.dateOfBirth,
                    gender: vm.resident.sex,
                    state: vm.resident.state,
                    postCode: vm.resident.postCode,
                    careRecipientId: vm.resident.careRecipientId
                };
                vm.errorList = null;
                residentsService.fetchCareRecipientId(vm.resident.id, vm.fetchCridInfo)
                    .then(function () {
                        vm.$hide();
                        $state.reload();
                    }, function (error) {
                        if (!error.modelStateSummary) {
                            notification.error(
                                "Unable to get residential care ID. Either your computer is offline, or Resident Manager is having problems of its own."
                            );
                        } else {
                            vm.errorList = _.values(error.modelStateSummary);
                        }
                    });
            }
        }
    }
];
