import * as _ from 'underscore';
import { INotificationService } from '../../common/services/INotificationService';
import BillingService = require('../services/BillingService');
import { SundryCharge } from '../Models';
import { Constants } from '../../common/Constants';
import { IDialogService } from '../../common/services/IDialogService';
import { IOrganisationFacilityService } from '../../organisation/services/IOrganisationFacilityService';
import { SimpleGLAccountMapping } from '../../organisation/Models';
import type { IStateParamsService, IStateService } from 'angular-ui-router';

class ViewSundryChargeController {
    static $inject = ["billingService", "notification.service", "$stateParams", "$state", "$scope", "$rootScope", "dialog.service", "organisation.facility.service"];

    private sundryChargeId: number;
    public sundryCharge: SundryCharge;
    public noPhotoImage: string;
    public pageHeader: string;
    public saveSundryChargeForm: ng.IFormController;
    public subtotal: number;
    public perResident: number;
    public gst: number;
    public totalAmount: number;
    public totalFraction: number;
    public totalDecimal: string;
    public sundryChargeMappings: Array<SimpleGLAccountMapping>;

    constructor(
        private billingService: BillingService,
        private notificationService: INotificationService,
        private stateParams: IStateParamsService,
        private $state: IStateService,
        private $scope: ng.IScope,
        private $rootScope: ng.IRootScopeService,
        private dialogService: IDialogService,
        private organisationFacilityService: IOrganisationFacilityService) {

        if (this.isReviewSundryCharge() && !this.stateParams.sundryCharge) {
            this.$state.go("viewSundryCharges");
            return;
        }

        this.pageHeader = "Sundry charge";
        this.sundryChargeId = stateParams.sundryChargeId;
        this.noPhotoImage = Constants.NO_PHOTO_IMAGE;
        this.getSundryCharge();
        if (this.isReviewSundryCharge()) {
            this.pageHeader = "Review sundry charge";
            let locationChangeSuccessDeregisterer = this.$rootScope.$on("$locationChangeSuccess", () => {
                if (this.sundryCharge.isDirty)
                    this.saveSundryChargeForm.$setDirty();
            });

            this.$scope.$on("$destroy", () => {
                locationChangeSuccessDeregisterer();
            });
        }
    }

    public deleteSundryCharge = function () {
        this.dialogService.openActionDialog("Delete Charge?",
            "Are you sure you want to delete this charge?",
            "sundry-delete",
            "Delete",
            () => {
                this.billingService.deleteSundryCharge(this.sundryCharge.id)
                    .then(
                    () => {
                        this.$state.go("viewSundryCharges");
                    },
                    () => {
                        this.notificationService.error("Unexpected error while saving the sundry charge.");
                    });
            },
            "btn-danger btn-inline-block-xsm");
    }

    private isReviewSundryCharge = () => {
        return this.$state.current.name === "reviewSundryCharge";
    }

    private getSundryCharge() {
        this.sundryCharge = null;
        if (this.stateParams.sundryCharge) {
            this.sundryCharge = this.stateParams.sundryCharge;
            this.updateAmounts();
        }
        else {
            //get list of sundry charge mapping, then get sundry charge
            this.organisationFacilityService.getSundryChargesGLAccountMappings().then(response => {
                this.sundryChargeMappings = response;
                this.billingService.getSundryCharge(this.sundryChargeId)
                    .then(result => {
                        this.sundryCharge = result;
                        _.each(this.sundryCharge.residents, (resident) => {
                            if (this.sundryCharge.postedDateUtc) {
                                resident.sundryAccountDescription = resident.postedSundryAccountDescription;
                            }
                            else {
                                resident.sundryAccountDescription = _.find(this.sundryChargeMappings, (mapping) => {
                                    return mapping.id === resident.sundryAccountId;
                                }).otherInfo;
                            }
                        });
                        this.updateAmounts();
                    }, () => {
                        this.notificationService.error("Unexpected error while loading sundry charge details.");
                    });
            }, () => {
                this.notificationService.error("Unexpected error while getting the sundry account.");
            });
        }
    }

    public updateAmounts = () => {
        this.subtotal = 0;
        this.gst = 0;

        if (this.sundryCharge.total && this.sundryCharge.splitBillEvenly) {
            this.subtotal = this.sundryCharge.total;
        }
        else {
            this.subtotal = _.chain(this.sundryCharge.residents)
                .map((obj) => { return Number(obj.amount); })
                .flatten()
                .reduce((memo, amnt) => { return memo + this.getAmount(amnt); }, 0)
                .value();
        }

        if (this.sundryCharge.gstInclusive || this.sundryCharge.splitBillEvenly) {
            this.totalAmount = this.subtotal;
        }
        else {
            this.gst = _.chain(this.sundryCharge.residents)
                .map((obj) => {
                    if (obj.gstType == "Free") {
                        return Number(0);
                    }
                    else {
                        return Number(obj.amount * this.sundryCharge.gstRate / 100);
                    }
                })
                .flatten()
                .reduce((memo, amnt) => {
                    return memo + this.getAmount(amnt);
                }, 0)
                .value();

            this.totalAmount = this.subtotal + this.gst;
        }

        this.totalFraction = this.getTotalFraction();
        this.totalDecimal = this.getTotalDecimal();
        this.perResident = this.getPerResident();
    }

    private getAmount = (amnt: number) => {
        if (!amnt || amnt === NaN) {
            return 0;
        }

        return amnt;
    }

    public getPerResident = (): number => {
        if (this.sundryCharge.residents.length > 0) {
            return this.totalAmount / this.sundryCharge.residents.length;
        }
        else
            return 0;
    }

    // TODO
    // check if this save function is actually called (don't think it is) and remove it
    private save = () => {
        this.billingService.saveSundryCharge(this.sundryCharge).then((result) => {
            this.$state.go("viewSundryCharges");
        },
            () => {
                this.notificationService.error("Unexpected error while saving the sundry charge.");
            });
    };

    public getTotalFraction = (): number => {
        if (!this.sundryCharge && !this.totalAmount && this.totalAmount === NaN) {
            return 0;
        }

        return parseInt(this.totalAmount.toString());
    };

    public getTotalDecimal(): string {
        if (!this.sundryCharge && !this.totalAmount && this.totalAmount === NaN) {
            return "00";
        }

        var numParts = this.totalAmount.toString().split(".");
        if (numParts.length <= 1) {
            return "00";
        }
        if (numParts[1].length == 1) {
            return numParts[1] + "0";
        }
        return numParts[1];
    }

    public navigateToEdit() {
        if (this.isReviewSundryCharge()) {
            this.saveSundryChargeForm.$setPristine();
            this.$state.go("addOrEditSundryCharge", {
                sundryChargeId: this.sundryChargeId,
                sundryCharge: this.sundryCharge
            });
        }
        else {
            this.$state.go("addOrEditSundryCharge", { sundryChargeId: this.sundryChargeId });
        }
    }

    public postCharges() {
        this.billingService.postSundryCharge(this.sundryCharge)
            .then(result => {
                this.saveSundryChargeForm.$setPristine();
                this.$state.go("viewSundryCharges");
            }, () => {
                this.notificationService.error("Unexpected error while posting sundry charge details.");
            });
    }
}

export = ViewSundryChargeController;
