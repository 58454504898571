import * as _ from "underscore";
import * as moment from 'moment';
import {INotificationService} from '../../common/services/INotificationService';
import { IFundingService, IFundingRate} from '../Interfaces';

class AccommodationFundingRatesController {
    static $inject = ["funding.service", "notification.service", "$window"];

    constructor(
        private readonly fundingService: IFundingService,
        private readonly notificationService: INotificationService,
        private readonly $window: ng.IWindowService
    ) {
        this.getAccommodationFundingRates();
        this.mode = "view";
    }

    private selectedFundingRatePlaceHolder: IFundingRate;
    public fundingRates: IFundingRate[];
    public selectedFundingRate: IFundingRate;
    public mode: string;
    public saveFundingRateForm: ng.IFormController;
    public hideEndDate: boolean;

    private getAccommodationFundingRates = () => {
        this.fundingService.getAllAccommodationFundingRates()
            .then(result => {
                    if (result && result.length > 0) {
                        this.fundingRates = result;
                        this.selectedFundingRate = _.first(this.fundingRates);
                    }
                },
                () => {
                    this.notificationService.error("Error occurred while fetching accommodation funding rates");
                });
    }

    public selectFundingRate = (fundingRate: IFundingRate) => {
        this.selectedFundingRate = fundingRate;
        this.mode = "view";
    }

    public editCareFundingRate = () => {
        this.selectedFundingRatePlaceHolder = _.extend({}, this.selectedFundingRate);
        this.mode = "edit";
    }

    public enterNewRates = () => {
        this.selectedFundingRatePlaceHolder = _.extend({}, this.selectedFundingRate);
        this.selectedFundingRate = <IFundingRate>{id:0};
        this.mode = "add";
    }

    public saveFundingRate = (isFormValid: boolean) => {
        const computedEndDate = this.computeEndDate(this.selectedFundingRate.startDateUtc, this.selectedFundingRate.id);
        if (isFormValid && computedEndDate !== undefined) {
            this.selectedFundingRate.endDateUtc = computedEndDate;
            this.fundingService.saveAccommodationFundingRate(this.selectedFundingRate).then(
                response => {
                    this.fundingService.getAllAccommodationFundingRates()
                        .then(result => {
                                if (result && result.length > 0) {
                                    this.fundingRates = result;
                                    if (this.mode === "add") {
                                        this.selectedFundingRate = _.first(this.fundingRates);
                                    } else if (this.mode === "edit") {
                                        const index = _.findIndex(this.fundingRates,
                                            fundingRate => fundingRate.id === this.selectedFundingRate.id);
                                        this.selectedFundingRate = this.fundingRates[index];
                                    }
                                }
                                this.selectedFundingRatePlaceHolder = _.extend({}, this.selectedFundingRate);
                                this.saveFundingRateForm.$setPristine();
                                this.mode = "view";
                            },
                            () => {
                                this.notificationService.error(
                                    "Error occurred while fetching accommodation funding rates");
                            });
                },
                e => {
                    this.notificationService.error("Error occurred while saving the funding rate. please try again");
                });
        }
    }

    public cancel = () => {
        const unsavedChanges = this.saveFundingRateForm.$dirty;
        if (this.allowClosingForm(unsavedChanges)) {
            this.saveFundingRateForm.$setPristine();
            this.selectedFundingRate = this.selectedFundingRatePlaceHolder;
            this.mode = "view";
        }
    }

    public getEndDate = (startDateUtc: Date, id: number) : Date => {
        const endDate = this.computeEndDate(startDateUtc, id);
        if (!endDate) {
            this.hideEndDate = true;
        } else {
            this.hideEndDate = false;
        }
        return endDate;
    }

    private allowClosingForm = (unsavedChanges:boolean) => {
        if (unsavedChanges) {
            return this.$window.confirm("Do you want to leave this page? Changes you made may not be saved.");
        } else {
            return true;
        }
    }

    private computeEndDate = (startDateUtc: Date, id: number): Date => {
        if (!this.fundingRates || this.fundingRates.length === 0 || !startDateUtc) {
            return null;
        }

        let sortedAccFundingRates = _.reject(this.fundingRates,
            fundingRate => fundingRate.id === id);

        if (sortedAccFundingRates.length === 0) {
            return null;
        }

        let overlappingRateIndex = _.findIndex(sortedAccFundingRates,
            fundingRate => moment(fundingRate.startDateUtc).isSame(moment(startDateUtc)));

        if (overlappingRateIndex !== -1) {
            return undefined;
        }

        let immediateSuccessorIndex = _.findLastIndex(sortedAccFundingRates,
            fundingRate => moment(fundingRate.startDateUtc).isAfter(moment(startDateUtc)));

        if (immediateSuccessorIndex === -1) {
            return null;
        }

        let endDateUtc = sortedAccFundingRates[immediateSuccessorIndex].startDateUtc;
        return moment(endDateUtc).subtract(1, "second").toDate();
    }
}

export = AccommodationFundingRatesController;
