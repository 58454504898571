import * as _ from "underscore";
import * as moment from 'moment';
import * as angular from 'angular';
import { INotificationService } from '../../common/services/INotificationService';
import { IInfiniteLoaderService } from '../../common/services/IInfiniteLoaderService';
import { IResidentsService } from '../services/IResidentsService';
import { ILeadsService } from '../../leads/services/ILeadsService';
import { IReferenceDataService } from '../../common/services/IReferenceDataService';
import { ResidentFinanceFees, Resident, ResidentContact } from '../Models';
import { Relationship } from '../../common/Models';
import type { IStateService } from "angular-ui-router";


interface IAddContactDialogConfig {
    payerType: string;
    payerTypeName: string;
    state: string;
    resident: Resident;
    callBack: any;
    persist: boolean;
    cancelCallback: any;
}

class AddContactDialogController {
    static $inject = ["$state", "residents.service", "leads.service", "notification.service", "referenceData.service", "modalConfig", "Titles"];

    public resident: Resident = new Resident();
    public payerTypeName: string;
    public contact: ResidentContact = new ResidentContact();    
    public relationships: Array<Relationship> = new Array<Relationship>();
    public $hide: any;
    public titleOptions: Array<string>;
    
    constructor(
        private $state: IStateService,
        private residentsService: IResidentsService,
        private leadsService: ILeadsService,
        private notificationService: INotificationService,
        private referenceDataService: IReferenceDataService,
        private modalConfig: IAddContactDialogConfig,
        private titles: Array<string>
    ) {
        this.payerTypeName = modalConfig.payerTypeName.toLowerCase();
        this.contact = this.isPrimaryContact() ?
            modalConfig.resident.primaryContact :
            modalConfig.resident.secondaryContact;
        referenceDataService.getRelationships().then((data) => {
            this.relationships = data;
        },
        () => {
            this.notificationService.error("Unable to display relationship types. Either your computer is offline, or Resident Manager is having problems of its own.");
        });
        this.titleOptions = titles;
    }
        
    public bindHide = (hide) => {
        this.$hide = hide;
    };
    
    public close = () => {
        if(this.modalConfig.cancelCallback){
            this.modalConfig.cancelCallback();
        }
        this.$hide();
    }

    public saveContact = (isValid: boolean) => {
        if (isValid) {
            angular.extend(this.resident, this.modalConfig.resident);
            if (this.isPrimaryContact()) {
                this.resident.primaryContact = this.contact;
            }
            else {
                this.resident.secondaryContact = this.contact;
            }

            let updateResident = this.isEditResidentFinancialsFees() ? 
                this.residentsService.updateResident : this.leadsService.updateLead;
                
            if(this.modalConfig.persist) {
                let getResident = this.isEditResidentFinancialsFees() ? 
                    this.residentsService.getResident : this.leadsService.getLead;

                updateResident({ "resident": this.resident }).then((result) => {
                    getResident(this.resident.id).then((data) => {
                        this.modalConfig.callBack(this.modalConfig.payerType, 
                        this.isPrimaryContact() ? data.primaryContact : data.secondaryContact);
                        this.$hide();
                        this.notificationService.success(this.modalConfig.payerTypeName + " added successfully");
                    });
                },
                () => {
                    this.notificationService.error("Unexpected error occurred while saving " + this.payerTypeName + ".");                
                });
            }
            else {
                this.modalConfig.callBack(this.modalConfig.payerType, this.isPrimaryContact() ? this.resident.primaryContact : this.resident.secondaryContact);
                this.$hide();
                this.notificationService.success(this.modalConfig.payerTypeName + " added successfully");
            }
        }
    };

    private isEditResidentFinancialsFees = () => {
        return this.modalConfig.state === "editResident.financialsFees";
    };

    private isPrimaryContact = () => {
        return this.modalConfig.payerType === "PrimaryContact";
    };
}
export = AddContactDialogController;