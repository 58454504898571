function checkboxDirective(): ng.IDirective {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            disabled: "=",
            checked: "=",
            onChecked: "="
        },
        controller: "checkbox.controller",
        controllerAs: "vm",
        templateUrl: "app/common/directives/partials/checkbox.html"
    };
}

export = checkboxDirective;