function residentFilterSearchDirective(): ng.IDirective {
    return {
        restrict: "AE",
        scope: {
            selectedResidents: '=',
        },
        controller: "residentSearch.controller",
        controllerAs: "vm",
        replace: false,
        templateUrl: "app/common/directives/partials/residentFilterSearch.html"
    };
}

export = residentFilterSearchDirective;
