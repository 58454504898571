"use strict";
const angular = require("angular");
const moment = require("moment");
const _ = require("underscore");

module.exports = [
    "leads.service",
    "notification.service",
    "modalConfig",
    "$state",
    "dialog.service",
    "residents.service",
    "EffectiveDateTypes",
    "$rootScope",
    "featureFlags.service",
    function (leadsService, notification, modalConfig, $state, dialogService, residentsService, EffectiveDateTypes, $rootScope, featureFlagsService) {
        var vm = this;
        angular.extend(vm, modalConfig);

        vm.bindHide = function ($hide) {
            vm.$hide = $hide;
        };
        
        vm.privateResidentEnabled = featureFlagsService.featureFlags.systemConfigurationFlags.enablePrivateResidents;
        vm.careRecipientIdFieldMaxLength = 10;
        vm.residentialCareIdFieldMaxLength = 10;

        residentsService.getResidentAdmissionInformation(vm.residentId)
            .then(function (result) {
                vm.residentAdmissionInfo = result;
                if(!vm.isMakeResidentFunded)
                {
                    vm.residentAdmissionInfo.admissionDateUtc = null;
                }
                vm.isBillingIntegratedFacility = $rootScope.isBillingIntegratedFacility;

                vm.displayMissingBedError = false;
                if (result.careType === "Respite") {
                    vm.residentAdmissionInfo.expectedDepartureDateUtc = result.requestedDepartureDateUtc;
                 } else {
                    vm.residentAdmissionInfo.expectedDepartureDateUtc = null;
                    if (vm.residentAdmissionInfo.preEntryLeaveDateUtc) {
                        vm.hasPreEntryLeaveDate = true;
                        var admissionDate = moment(vm.residentAdmissionInfo.admissionDateUtc);
                        var preEntryLeaveDateUtc = moment(vm.residentAdmissionInfo.preEntryLeaveDateUtc);
                        vm.preEntryDays = admissionDate.diff(preEntryLeaveDateUtc, "days");
                    }
                }

                if (vm.residentAdmissionInfo.palliativeStatus === "Unknown") {
                    vm.residentAdmissionInfo.palliativeStatus = null;
                }
            },
                function () {
                    notification.error("Unable to display admission details. Either your computer is offline, or Resident Manager is having problems of its own.");
                });


        vm.expectedDurationOfStayForRespiteCare = function () {
            if (vm.residentAdmissionInfo.admissionDateUtc && vm.residentAdmissionInfo.expectedDepartureDateUtc) {
                var expectedDepartureMoment = moment(vm.residentAdmissionInfo.expectedDepartureDateUtc);
                var admissionDateMoment = moment(vm.residentAdmissionInfo.admissionDateUtc);
                var dateDiffInDays = expectedDepartureMoment.diff(admissionDateMoment, "days");
                if (dateDiffInDays > 0) {
                    return dateDiffInDays === 1 ? dateDiffInDays + " day" : dateDiffInDays + " days";
                }
            }
            return "-";
        }

        vm.minDepartureDate = function () {
            return moment(vm.residentAdmissionInfo.admissionDateUtc).add(1, 'd').startOf('day').toDate();
        }

        vm.submitResidentAdmission = function (residentAdmissionForm) {
            if (!vm.residentAdmissionInfo.bedId && !vm.isCorrection) {
                vm.displayMissingBedError = true;
                return;
            }
            if (residentAdmissionForm.$valid) {
                if (vm.preEntryDays) {
                    vm.residentAdmissionInfo.preEntryLeaveDateUtc = moment(vm.residentAdmissionInfo.admissionDateUtc).subtract(vm.preEntryDays, 'd').startOf('day').toDate();
                }

                leadsService.admitResident(vm.residentAdmissionInfo)
                .then(function () {
                    vm.$hide();
                    $state.go("viewResident", { residentId: vm.residentId });
                },
                    function (error) {
                        if (!error.modelStateSummary) {
                            notification.error("Unable to admit resident. Either your computer is offline, or Resident Manager is having problems of its own.");
                        } else {
                            dialogService.openMessageDialog(
                                "Admit resident",
                                "Resident cannot be admitted.",
                                "residentEntry-error",
                                _.values(error.modelStateSummary));
                        }
                    });
            }
        }; 

        vm.submitMakeResidentFunded = function (residentAdmissionForm) {
            if (residentAdmissionForm.$valid) {
                vm.residentAdmissionInfo.isPrivateResidency = false;
                residentsService.makeResidentFunded(vm.residentAdmissionInfo)
                .then(function () {
                    vm.$hide();
                    $state.reload();
                },
                    function (error) {
                        if (!error.modelStateSummary) {
                            notification.error("Unable to admit resident. Either your computer is offline, or Resident Manager is having problems of its own.");
                        } else {
                            dialogService.openMessageDialog(
                                "Make resident funded",
                                "The following errors occurred when submitting the admit resident event:",
                                "residentEntry-error",
                                _.values(error.modelStateSummary));
                        }
                    });
            }
        }; 
        vm.isValidDateBasedOnEffectiveDates = function(value) { 
            // effective dates check is always valid on admission
            return true;
        };
    }
];