import DaysInPastComponentController = require('../controllers/daysInPastComponentController');

class DaysInPastComponent implements ng.IComponentOptions {

    public bindings: any;
    public controller: any;
    public template: string;

    constructor() {
        this.bindings = {
            date: '<'
        };
        this.controller = DaysInPastComponentController;
        this.template = "{{$ctrl.daysInPast()}}";
    }

}

export = DaysInPastComponent;