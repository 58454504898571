import * as _ from "underscore";
import * as moment from 'moment';
import * as angular from 'angular';
import { INotificationService } from '../../common/services/INotificationService';
import { IInfiniteLoaderService } from '../../common/services/IInfiniteLoaderService';
import { IResidentsService } from '../services/IResidentsService';
import { IReferenceDataService } from '../../common/services/IReferenceDataService';
import { ILeadsService } from '../../leads/services/ILeadsService';
import { ResidentFinanceFees, Resident, ResidentContact } from '../Models';
import { Relationship, Country } from '../../common/Models';
import type { IStateService } from "angular-ui-router";

interface IAddEditContactDetailDialogConfig {
    payerType: string;
    payerTypeName: string;
    contactField: string;
    state: string;
    resident: Resident;
    callBack: any;
    persist: boolean;
}

class AddEditContactDetailDialogController {
    static $inject = ["$state", "residents.service", "leads.service", "notification.service", "modalConfig", "referenceData.service", "utilities.service"];

    public payerTypeName: string;
    public contact: ResidentContact = new ResidentContact();
    public countries: Array<Country> = new Array<Country>();
    public $hide: any;
    public selectedCountry: string ;

    constructor(
        private $state: IStateService,
        private residentsService: IResidentsService,
        private leadsService: ILeadsService,
        private notificationService: INotificationService,
        private modalConfig: IAddEditContactDetailDialogConfig,
        private referenceDataService: IReferenceDataService,
        private readonly utilitiesService: any
    ) {
        this.payerTypeName = modalConfig.payerTypeName;

        referenceDataService.getCountries().then( (data)=> {
            this.countries = data;
            const emptyItem: Country = {
                id: null,
                displayName: ""
            }
               
            this.countries.splice(0, 0, emptyItem);

            switch (modalConfig.payerType) {
                case 'Resident': {
                    this.contact.email = modalConfig.resident.email;
                    this.contact.street1 = modalConfig.resident.street1;
                    this.contact.street2 = modalConfig.resident.street2;
                    this.contact.suburb = modalConfig.resident.suburb;
                    this.contact.stateDescription = this.utilitiesService.getStateDescription(modalConfig.resident.state);
                    this.contact.state = modalConfig.resident.state;
                    this.contact.postCode = modalConfig.resident.postCode;
                    this.contact.countryId = modalConfig.resident.countryId;
                    break;
                }
                case 'PrimaryContact': {
                    angular.extend(this.contact, modalConfig.resident.primaryContact);
                    break;
                }
                case 'SecondaryContact': {
                    angular.extend(this.contact, modalConfig.resident.secondaryContact);
                    break;
                }
            }   
            
            this.selectedCountry =  _.findWhere(this.countries, {id: this.contact.countryId}).displayName;  

        },
        () => {
            this.notificationService.error("Unexpected error occurred while loading the countries.");
        });
    }

    public bindHide = (hide) => {
        this.$hide = hide;
    };

    public saveContact = (isValid: boolean) => {
        if (isValid) {

            var selectedCountry = this.countries.filter(c=>c.id == this.contact.countryId);
            if (selectedCountry.length>0){
                this.contact.country = selectedCountry[0].displayName;
            }

            let resident = this.prepareDataBeforeUpdate();
            let updateResident = (this.modalConfig.state === "editResident.financialsFees") ? 
                this.residentsService.updateResident : this.leadsService.updateLead;
            
            if(this.modalConfig.persist) {
                updateResident({ "resident": resident }).then((result) => {
                    this.modalConfig.callBack(this.modalConfig.payerType, this.contact);
                    this.$hide();
                    this.notificationService.success(this.modalConfig.payerTypeName + " " + this.modalConfig.contactField + " updated successfully");
                },
                () => {
                    this.notificationService.error("Unexpected error occurred while saving " + this.modalConfig.payerTypeName + " " + this.modalConfig.contactField + ".");
                });
            }
            else {
                this.modalConfig.callBack(this.modalConfig.payerType, this.contact);
                this.$hide();
            }
        }
    };

    private prepareDataBeforeUpdate = () => {
        let resident: Resident = new Resident();
        angular.extend(resident, this.modalConfig.resident);        

        switch (this.modalConfig.payerType) {
            case 'Resident': {
                resident.email = this.contact.email;
                resident.street1 = this.contact.street1;
                resident.street2 = this.contact.street2;
                resident.suburb = this.contact.suburb;
                resident.state = this.contact.state;
                resident.stateDescription = this.utilitiesService.getStateDescription(this.contact.state);
                resident.postCode = this.contact.postCode;
                resident.countryId = this.contact.countryId;
                resident.country = this.contact.country;

                break;
            }
            case 'PrimaryContact': {
                resident.primaryContact = this.contact;

                break;
            }
            case 'SecondaryContact': {
                resident.secondaryContact = this.contact;

                break;
            }
        }

        this.contact.stateDescription = this.utilitiesService.getStateDescription(this.contact.state);

        return resident;
    }

    public onCountrySelectCallback = ($item) => {
        this.selectedCountry = $item.displayName;
    }
}
export = AddEditContactDetailDialogController;