"use strict";

var moment = require("moment");

module.exports = [
    "residents.service",
    "notification.service",
    "dateFormatting.service",
    "infiniteLoader.service",
    function (residentsService, notificationService, dateFormattingService, infiniteLoaderService) {
        var vm = this;
        vm.residents = [];

        function populateDisplayNameAndAgeInformationForView(residents) {
            angular.forEach(residents, function(resident) {
                resident.age = dateFormattingService.getYearsFromDate(resident.dateOfBirth);
                resident.dateOfBirth = dateFormattingService.getFormattedDate(resident.dateOfBirth);
                resident.ageInformation = resident.dateOfBirth + " (Age " + resident.age + ")";
            });
        }

        vm.infiniteLoader = infiniteLoaderService.instance(residentsService.getResidents,
            function (result) {
                if (result && result.length > 0) {
                    populateDisplayNameAndAgeInformationForView(result);
                }

                vm.residents = vm.residents.concat(result);
            },
            function () {
                notificationService.error("Unexpected error occurred while loading residents to view.");
            });
    }
];