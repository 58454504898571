"use strict";
const _ = require("underscore");
module.exports = ["data.service", function (dataService) {

    function viewLeads(notIncludingItemsAfter, skip, take) {
        return dataService.get("leads", skip == 0  ? false : true,
            {
                notIncludingItemsAfter: notIncludingItemsAfter,
                skip: skip,
                take: take
            });
    };

    function getDashboardLeads(take, filter) {
        const parameterObject = _.extend({}, filter, {
            take: take
        });
         return dataService.get("leads/dashboard/", false, parameterObject);
    };

    function getLeadsForDashboardByStatus(status, skip, take, filter, notIncludingItemsAfter) {
        const parameterObject =  _.extend({}, filter, {
            status: status,
            skip: skip,
            take: take,        
            notIncludingItemsAfter: notIncludingItemsAfter
        });

        return dataService.get("leads/dashboard/status/", false, parameterObject);
    }

    function getLead(residentId) {
        return dataService.get("leads/" + residentId);
    };

    function saveLead(lead) {
        return dataService.post("leads", lead);
    };

    function updateLead(lead) {
        return dataService.put("leads", lead);
    };

    function deleteLead(residentId) {
        return dataService.delete("leads/" + residentId);
    }

    function updatePreAdmissionStatus(residencyId, isContractSigned, status, isDeceased) {
        return dataService.put("leads/pre-admission-status", {
            residencyId: residencyId,
            isContractSigned: isContractSigned,
            status: status,
            isDeceased: isDeceased
        });
    };

    function updateLeadStatus(residencyId, status, orderNumber = null) {
        return dataService.put("leads/lead-status", {
            residencyId: residencyId,
            status: status,
            orderNumber: orderNumber
        });
    }

    function updatePreferredFacilityLocation(residencyId, locationId) {
        return dataService.put("leads/preferred-facility-location", {
            residencyId: residencyId,
            preferredFacilityLocationId: locationId
        });
    };

    function admitResident(residentToAdmit) {
        return dataService.put("leads/admit/", residentToAdmit);
    };

    function sendToDepartedList(residentId) {
        return dataService.put("leads/" + residentId + "/send-to-departed-list");
    };

    function archiveLead(residentId) {
        return dataService.put("leads/" + residentId + "/archive");
    };

    function sendToLeads(residentId) {
        return dataService.put("leads/" + residentId + "/send-to-leads")
    }

    function markLeadAsProspect(residentId, lead) {
        return dataService.post("leads/" + residentId + "/mark-as-prospect", lead);
    }

    function getTour(residencyId) {
        return dataService.get("tour/" + residencyId);
    };

    function saveTour(tourDto) {
        return dataService.post("tour/save", tourDto);
    };

    function deleteTour(tourId) {
        return dataService.delete("tour/" + tourId)
    }

    function getLeadsReportExcelStream() {
        return dataService.getArrayBuffer("leads/leads-report-excel", false);
    }
    
    function updateLeadOrderNumber(currentResidencyId, predecessorOrderNumber, 
        successorOrderNumber, status) {
        return dataService.put("leads/lead-order", {
            currentResidencyId: currentResidencyId,
            predecessorOrderNumber: predecessorOrderNumber,
            successorOrderNumber: successorOrderNumber,
            status: status
        });        
    }

    function getArchivedLeads() {
        return dataService.get('leads/archived');
    }

    function getLeadFiltersData() {      
        return dataService.get("leads/lead-dashboard-filters");
    }

    return {
        saveLead: saveLead,
        viewLeads: viewLeads,
        getLead: getLead,
        updateLead: updateLead,
        deleteLead: deleteLead,
        admitResident: admitResident,
        sendToDepartedList: sendToDepartedList,
        markLeadAsProspect: markLeadAsProspect,
        getTour: getTour,
        saveTour: saveTour,
        deleteTour: deleteTour,
        getDashboardLeads: getDashboardLeads,
        getLeadsForDashboardByStatus: getLeadsForDashboardByStatus,
        updatePreAdmissionStatus: updatePreAdmissionStatus,
        updateLeadStatus: updateLeadStatus,
        updatePreferredFacilityLocation: updatePreferredFacilityLocation,
        getLeadsReportExcelStream: getLeadsReportExcelStream,
        updateLeadOrderNumber: updateLeadOrderNumber,
        getArchivedLeads: getArchivedLeads,
        sendToLeads: sendToLeads,
        archiveLead: archiveLead,
        getLeadFiltersData: getLeadFiltersData
    }

}];