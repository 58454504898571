import * as angular from "angular";
import { DropdownOption } from "../../common/Models";
import { FacilityDto } from "../../authentication/Models";
import { IFacilityService } from "../../authentication/services/IFacilityService";
import { INotificationService } from "../../common/services/INotificationService";
import { IReportingService } from "../services/IReportingService";
import { ClassificationHistoryReportFilter } from "../Models";
import { IDownloadFileService } from '../../common/services/IDownloadFileService';

class ClassificationHistoryReportComponent implements ng.IComponentOptions {
    public bindings: any = {};

    public templateUrl: string = "app/reporting/partials/classifificationHistoryReport.html";

    public controller: any = class ClassificationHistoryReportController {
        static $inject = ["facility.service", "notification.service", "reporting.service", "downloadFile.service", "$filter"];

        public careTypes: DropdownOption[];
        public selectedCareType: DropdownOption;
        public facilities: FacilityDto[];
        public selectedFacility: FacilityDto;
        public fromDate: Date;
        public toDate: Date;

        constructor(
            private readonly facilityService: IFacilityService,
            private readonly notificationService: INotificationService,
            private readonly reportingService: IReportingService,
            private readonly downloadFileService: IDownloadFileService
        ) {
            this.setCareTypes();
            this.selectedCareType = this.careTypes[0];
            this.facilities = facilityService.activeUserFacilities.filter((x) => x.facilityType == "AgeCare");
            if (this.facilities.length == 1) this.selectedFacility = this.facilities[0];
        }

        private setCareTypes = (): void => {
            this.careTypes = [
                { value: "0", displayText: "Both" },
                { value: "1", displayText: "Permanent" },
                { value: "2", displayText: "Respite" },
            ];
        };

        public export = (): void => {
            let filter = new ClassificationHistoryReportFilter();
            filter.fromDate = this.fromDate;
            filter.toDate = this.toDate;
            filter.careType = this.selectedCareType.displayText;
            filter.facilityId = this.selectedFacility == null ? 0 : this.selectedFacility.id;

            this.reportingService.getClassificationHistoryReportCsvStream(filter).then(
                (response) => {
                    let dateTime = new Date();
                    let month = ("0" + (dateTime.getMonth() + 1)).slice(-2);
                    this.downloadFile(response, 'Classification history - ' + dateTime.getFullYear() + '-' + month + '-' + ("0" + dateTime.getDate()).slice(-2) +
                        '_' + ("0" + dateTime.getHours()).slice(-2) + '.' + ("0" + dateTime.getMinutes()).slice(-2) + '.' + ("0" + dateTime.getSeconds()).slice(-2) + '.xlsx');
                },
                () => {
                    this.notificationService.error("Error occurred while exporting classification history");
                }
            );
        };

        private downloadFile = (response: ArrayBuffer, fileName: string) => {
            this.downloadFileService.downloadFile(response, fileName);
        }
    };
}

export = ClassificationHistoryReportComponent;
