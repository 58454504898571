"use strict";

module.exports = [
    "$filter", function($filter) {
        return function (input) {
            if (!input){
                return;
            }
            if (input % 1 == 0)
                return $filter('number')(input, 0) + '%';
            else if ((input * 10) % 1 == 0)
                return $filter('number')(input, 1) + '%';
            else 
                return $filter('number')(input, 2) + '%';
        };
    }
];