import * as angular from 'angular';
class DownloadFileService {
    constructor()
    {

    }

    createLink = () => {
        return angular.element('<a></a>');
    }

    downloadFile = (response: ArrayBuffer, fileName: string) => {
        var blob = new Blob([response], { type: 'application/vnd.ms-excel;' });
        if (window.navigator.msSaveOrOpenBlob) { //IE11 only supports download via msSaveOrOpenBlob
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            var downloadLink = this.createLink(); //created method to help with unit test cases
            downloadLink.attr('href', window.URL.createObjectURL(blob));
            downloadLink.attr('download', fileName);
            downloadLink[0].click();
        }
    }
}
export = DownloadFileService