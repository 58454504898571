"use strict";
const angular = require("angular");
const _ = require("underscore");

module.exports = [
    "residents.service",
    "notification.service",
    "modalConfig",
    "$state",
    'authentication.service',
    function (residentsService, notification, modalConfig, $state, authenticationService) {
        var vm = this;
        vm.resident = {};
        angular.extend(vm.resident, modalConfig.resident);
        var residentId = vm.resident.id;
        residentsService.getResidentFinanceFunding(residentId).then(function (finance) {
            vm.finance = finance;
            if (vm.finance.residentANACCReassessmentRequest.status === 'Complete') {
                vm.finance.residentANACCReassessmentRequest.status = null;
                vm.finance.residentANACCReassessmentRequest.requestDate = null;
                vm.finance.residentANACCReassessmentRequest.anaccReassessmentRequestReason = null;
            }
        });
        vm.canRecordReassessmentRequest = false;
        setCanRecordReassessmentRequest();

        vm.bindHide = function ($hide) {
            vm.$hide = $hide;
        };

        vm.recordANACCReassessmentRequest = function (isReassessmentRequestValid) {
            if (isReassessmentRequestValid) {
                var promise; 
                if (vm.finance.residentANACCReassessmentRequest.id) {
                    promise = residentsService.updateANACCReassessmentRequest(vm.resident.id, vm.finance.residentANACCReassessmentRequest);
                } else {
                    promise = residentsService.addANACCReassessmentRequest(vm.resident.id, vm.finance.residentANACCReassessmentRequest);
                }

                promise.then(function () {
                    vm.$hide();
                    $state.reload();
                    notification.success("AN-ACC Reassessment request details saved.");
                },
                    function () {
                        notification.error("Unable to save AN-ACC Reassessment request details. Either your computer is offline, or Resident Manager is having problems of its own.");
                    });
            }
        };

        function setCanRecordReassessmentRequest() {
            authenticationService.userHasPermissionDeferred((x) => {
                return x.residentsRecordReassessmentRequest.canAction;
            }).then(() => {
                vm.canRecordReassessmentRequest = true;
            }, () => {
                vm.canRecordReassessmentRequest = false;
            });
        }
    }
];
