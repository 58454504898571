module.exports = [
    "data.service", function (dataService) {
        
        function getTrainingManualUrl() {
            return dataService.get("lms/TrainingManualUrl");
        }
        function getReleaseInformationUrl() {
            return dataService.get("lms/ReleaseInformationUrl");
        }

        return {
            getTrainingManualUrl: getTrainingManualUrl,
            getReleaseInformationUrl: getReleaseInformationUrl
        }
    }
];