"use strict";

module.exports = function () {
    return {
        restrict: "AE",
        require: "ngModel",
        replace: true,
        scope: {
            id: "@",
            max: "@",
            ngModel: "=",
            isRequired: "=",
            change: "="
        },
        templateUrl: "app/common/directives/partials/currencyInput.html"
    };
}
