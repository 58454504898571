import { INotificationService } from '../../common/services/INotificationService';
import BillingService = require('../services/BillingService');
import { Constants } from '../../common/Constants';
import { Resident } from '../Models';
import * as _ from "underscore";
import moment = require('moment');
import type { IStateService } from 'angular-ui-router';

class modalConfig {
    data: Resident[];            
    callback:  (residents: Resident[]) => void
}
class SelectResidentSundryChargeController {
    static $inject = ["$state", "billingService", "notification.service", "modalConfig"];   
    public sundryResidents: Resident[];
    public selectedSundryResidents: Resident[];
    public $hide: any;
    public selectedCount : number;

    constructor(
        private $state: IStateService,
        private billingService: BillingService,
        private notificationService: INotificationService,
        private modalConfig: modalConfig        
    ) {
        this.getSundryResidents();
    }

    public bindHide = (hide) => {
        this.$hide = hide;
    };

    private getSundryResidents = () => { 
        this.sundryResidents = new Array<Resident>();

        this.billingService.getSundryResidents().then((result) => {          
            this.sundryResidents = this.sundryResidents.concat(result);     

            if(this.modalConfig.data.length > 0)
            {
                this.selectedSundryResidents = this.modalConfig.data;                        
                this.selectedSundryResidents.forEach(element => {
                    var item = _.find(this.sundryResidents,function(selectedresident){
                        return selectedresident.residencyId == element.residencyId;
                    });
                    item.isChecked = true;
            });

        }
        },
        () => {
            this.notificationService.error("Unexpected error while loading sundry residents.");
        });
    };

    public navigateToAdd = () => {
        this.modalConfig.callback(_.filter(this.sundryResidents, function(sundryResident) {
            return sundryResident.isChecked == true; }
        ));       
        this.$hide();
    };

    private getSundryResidentsSelected = () => {
        this.selectedCount =  _.filter(this.sundryResidents, function(sundryResident) {
            return sundryResident.isChecked == true; }
        ).length;
    };

    
}

export = SelectResidentSundryChargeController; 
