import { Resident, UpdateResident, TourDto, LeadDashboardDto, EnquiryStatus, LeadCardDto, LeadDashboardFilterDto} from '../Models';

export interface ILeadsService {
    getLead: (residentId: number) => ng.IPromise<Resident>;
    deleteLead: (residentId: number) => ng.IPromise<any>;
    updateLead: (updateResident: UpdateResident) => ng.IPromise<any>;
    sendToDepartedList: (residentId: number) => ng.IPromise<any>;
    getTour: (residencyId: number) => ng.IPromise<TourDto>;
    saveTour: (tour: TourDto) => ng.IPromise<any>;
    deleteTour:(tourId: number) => ng.IPromise<any>;
    getDashboardLeads: (take: number, filter : LeadDashboardFilterDto) => ng.IPromise<LeadDashboardDto>; 
    getLeadsForDashboardByStatus: (status: EnquiryStatus, skip: number, take: number, filter : LeadDashboardFilterDto,
                                   notIncludingItemsAfter: Date) => ng.IPromise<BatchResult<LeadCardDto>>;
    updatePreAdmissionStatus: (residencyId: number, isContractSigned: boolean, status: EnquiryStatus, isDeceased: boolean) => ng.IPromise<any>;
    updateLeadStatus: (residencyId: number, status: EnquiryStatus, orderNumber?: string) => ng.IPromise<any>;
    updatePreferredFacilityLocation: (residencyId: number, locationId?: number) => ng.IPromise<any>;
    getLeadsReportExcelStream: () => ng.IPromise<any>;
    updateLeadOrderNumber: (residencyId: number, predecessorOrderNumber: string, successorOrderNumber: string, status : EnquiryStatus) => ng.IPromise<any>;
    getArchivedLeads: () => ng.IPromise<LeadCardDto[]>
    sendToLeads: (residentId: number) => ng.IPromise<any>;
    archiveLead: (residentId: number) => ng.IPromise<any>;
    getLeadFiltersData: () => ng.IPromise<any>;
}