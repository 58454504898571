function statementSupportedRadioDirective(): ng.IDirective {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            statement: "=",
            yearAndMonth: "=",
            july2024: "="
        },
        templateUrl: "app/funding/directives/partials/statementSummary.html"
    };
}
export = statementSupportedRadioDirective;
