import { IIndependentLivingService } from "../services/IIndependentLivingService";
import { UnitDto, SelectedResidentDto, FeesAndChargesDto, AssignedAccommodationDto } from "../Models";
import { INotificationService } from "../../common/services/INotificationService";
import type { IStateService } from "angular-ui-router";

class AssignUnitDialogController {
  static $inject = ["independentLiving.service", "$state", "notification.service", "modalConfig", "$q"];

  public enquiryId: number;
  public residentId: number;
  public $hide: any;
  public title: any;
  public availableUnits: Array<UnitDto> = new Array<UnitDto>();
  public selectedUnit: UnitDto = new UnitDto();
  public residents: Array<SelectedResidentDto> = new Array<SelectedResidentDto>();
  public selectedAccomodation: AssignedAccommodationDto = new AssignedAccommodationDto();        

  constructor(private readonly independentLivingService: IIndependentLivingService,
    private $state: IStateService, private readonly notificationService: INotificationService, private readonly modalConfig: any, private readonly $q: any) {

    this.title = "Assign Unit";
    this.enquiryId = this.modalConfig.enquiryId;
    this.residentId = this.modalConfig.residentId;
    this.residents = this.modalConfig.selectedResidents;

    // load units    
    let availableUnitsPromise = this.independentLivingService.getAvailableUnits().then(result => {
      return result;
    }, () => {
      this.notificationService.error("Unexpected error while loading units.");
    });

    let accommodationEventsPromise = this.independentLivingService.getAccommodationFinancialEvents().then(events => {
      return events;
    }, () => {
      this.notificationService.error("Unexpected error while loading unit events.");
    });

    $q.all([availableUnitsPromise, accommodationEventsPromise]).then(result => {
      this.availableUnits = result[0];
      this.independentLivingService.getUnitNotes(this.availableUnits, result[1]);
    });
  }

  public bindHide = (hide) => {
    this.$hide = hide;
  };

  public navigateToEdit = () => {
    this.$state.go("editEnquiry", { enquiryId: this.enquiryId, selectedResidentId: this.residentId, selectedUnit: this.selectedUnit, 
                                    selectedResidents: this.residents, doesFeeAndChargeExist : true, financialTabSelected : true,
                                    'enableAddingNewResident': false});
  }
  public assignUnit = (isValid) => {
    if (isValid) {
      this.$hide();
      if (this.selectedUnit.accommodationId != undefined) {
        // save fee and charges and the redirect to edit finance page
        if(this.saveSelectedUnit(this.enquiryId))
        {
          this.navigateToEdit();
        }
      }

    }
  }

  public saveSelectedUnit(enquiryId : number)
  {
    this.selectedAccomodation = new AssignedAccommodationDto();
    this.selectedAccomodation.accommodationId = this.selectedUnit.accommodationId;
    this.selectedAccomodation.independentLivingAllocationId = enquiryId;
    this.selectedAccomodation.price = this.selectedUnit.price;

    this.independentLivingService.saveAssignUnit(this.selectedAccomodation).then(result => {
        this.notificationService.success("Unit assigned successfully!");
        }, () => {
            this.notificationService.error("Unexpected error occurred while assigning unit.");
            return false;
        });
        return true;
  }
}
export = AssignUnitDialogController;