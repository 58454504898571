export class Resident {
    id: number;
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    street1: string;
    street2: string;
    suburb: string;
    stateDescription: string;
    postCode: string;
    countryId?: number;
    country?: string;
    state:string;
    primaryContact: ResidentContact;
    secondaryContact: ResidentContact;
    departureReasonDescription: string;
    departureDateUtc: Date;
    admittedFrom : AdmittedFrom;
    nameOfCare : string;
    otherContacts : ResidentContact[]
}

export class ResidentSimpleDto {
    id: number;    
    firstName: string;
    lastName: string;
    careType: string;
    residencyId: number;
}

export class ResidentContact {
    title: string
    firstName: string;
    lastName: string;
    email: string;
    street1: string;
    street2: string;
    suburb: string;
    stateDescription: string;
    postCode: string;
    countryId?: number;
    country: string;
    phoneNumber: string;
    relationships: Array<Relationship>;
    state:string;
}

export class Relationship {
    id: number;
    name: string;
}

export class ResidentFinanceFeesView {
    financeFees: ResidentFinanceFees;
    facility: Facility;    
}

export class ResidentFinanceFees {
    supportLevel: string;
    refundableAccommodationDepositPaid: number;
    refundableAccommodationDepositRemaining: number;
    agreedRoomPrice: number;
    unsupportedBalanceProgressPercentage: number;
    refundableAccommodationContributionPaid: number;
    refundableAccommodationContributionRemaining: number;
    refundableAccommodationContributionCalculated: number;
    supportedBalanceProgressPercentage: number;
    pre2014CareLevel: string;
    pre2014Care: boolean;
    pre2014ContinueCare?: boolean;
    pre2014BondBalanceProgressPercentage: number;
    pre2014BondOrRoomPrice: number;
    pre2014RefundableBondPaid: number;
    pre2014RefundableBondDepositRemaining: number;
    drawdowns: number;
    refundedAmountFromLumpSumDeposit: number;
    effectiveDates: ResidentFinanceEffectiveDate[];
    latestSupportLevel: ResidentFinanceEffectiveDate;
    latestAgreedRoomPrice: ResidentFinanceEffectiveDate;
}

export class ResidentFinanceEffectiveDate {
    effectiveDateType: string;
    effectiveFromDate: Date;
    value: string;
}

export class ResidentFinanceTransaction {
    transactionDateUtc: Date;
    reference: string;
    description1: string;
    description2: string;
    rate: number;
    charge: number;
    payment: number;
    paymentType: string;
    //TODO - as part of the refactoring it is better to change to  isHeaderVisible name
    addHeader: boolean;
    lastInGroup: boolean;
}

export class ResidentTransactionsFilter {
    public ResidentId: number;
}

export class UpdateResident {
    resident: Resident;
}

export class Facility {
    street1: string;
    street2: string;
    suburb: string;
    stateDescription: string;
    postCode: string;
    country: string;
}

export class SearchResult {
    id: number;
    residencyId: number;
    firstName: string;
    lastName: string;
    careType: any
    preferredName: string;
    departureDateUtc: Date;
    admissionDateUtc: Date;
}

export class EventHistory {
    leaves : LeaveHistory[];
    eventsHistory: EventsHistoryRow[];
    latestResidency : Residency;
    currentLoggedInFaclityId: number;
}

export class EventsHistoryRow {
    id: number;
    eventDate: Date;
    eventDateString: String;
    eventTypeDescription: String;
    enteredBy: string;
    enteredDateString: string;
    facility: string;
    medicareResponse: string;
    medicareResponseDescription: string;
    isReversed: boolean;
    eventType: ResidentEventType;
    uniqueEventId: string;
    residencyId: number;
    facilityId: number;
    careType: CareType;
    medicareRequestType: string;
    respiteLeaveFacilityIdWithinResman : number;
    isRespiteLeaveWithinOrganisation : boolean;
    respiteLeaveExternalFacilityDetails : string;
    enquiryId: number;
    isPrivateResident: boolean;
    isEditable: boolean;
    isDeparted: boolean;
    independentLivingAllocationWasSettled: boolean;
}

export class LeaveHistory {
    id: number;
    startDateUtc: Date;
    returnDateUtc: Date;
    startDateString: string;
    returnDateString: string;
    days: number;
    leaveTypeDescription: string;
    comments: string;
    enteredBy: string;
    enteredDateString: Date;
    facility: string;
    facilityId: number;
    medicareResponse: string;
    medicareResponseDescription: string;
    isReversed: boolean;
    careType: CareType
    leaveStartTimeLocalString: string;
    leaveReturnTimeLocalString: string;
    type: string;
    expectedReturnDateUtc: Date;
    uniqueEventId: string;
    respiteLeaveFacilityIdWithinResman : number;
    isRespiteLeaveWithinOrganisation : boolean;
    respiteLeaveExternalFacilityDetails : string;
}

export class Residency {
    id: number;
    facilityName: string;
    facilityId: number;
    admissionDate: Date;
    departureDate: Date;
    firstName: string;
    lastName: string;
}

export enum CareType {
    Permanent = 0,
    Respite = 1,
    ILU = 2
}

export enum ResidentEventType {
    ResidentAdmitted = 5,
    ResidentDeparted = 6,
    AcfiSubmitted = 21,
    ResidentAdmissionCorrected = 22,
    ResidentAdmissionReversed = 23,
    ResidentDepartureCorrected = 24,
    ResidentDepartureReversed = 25,
    LeaveReversed = 26
}

export enum TransferType {
    Current = "ActiveResidency",
    Departed = "DepartedResidency",
    Lead = "Lead"
}

export class TransferResidentDto {
    toFacilityId: number;
    transferResidentNotes: string;
    transferType: TransferType;
    fromILU: boolean;
}
export class AcfiRateDto
{
    acfiDomain : AcfiDomain;
    acfiLevel : AcfiLevel;
    dailyRate : number;
    startDateUtc : Date;
    endDateUtc : Date;
}

export enum AdmittedFrom {
    PrivateResidence = "Private residence",
    OtherAgedCareService = "Other aged care service",
    Hospital = "Hospital",
    Other = "Other"
}
export enum AcfiLevel {
    Default = "Default",
    Nil = "Nil",
    Low = "Low",
    Medium = "Medium",
    High = "High"
}

export enum AcfiDomain {
    Default = 0,
    ADL = 1,
    BEH = 2,
    CHC = 3
}