function residentFilterSearchRowDirective(): ng.IDirective {
    return {
        restrict: "AE",
        scope: {
            resident:"="
        },
        controller: "residentSearchRow.controller",
        controllerAs: "vm",
        replace: false,
        templateUrl: "app/common/directives/partials/residentFilterSearchRow.html"
    };
}

export = residentFilterSearchRowDirective;