"use strict";
const _ = require("underscore");

module.exports =
    [
        "$scope",
        "dialog.service",
        function ($scope, dialogService) {
            $scope.onAssignBedSaveCallBack = function (roomChangeDto) {
                $scope.residentAdmissionInfo.bedId = roomChangeDto.selectedBedId;
                $scope.selectedRoom = roomChangeDto;
            }

            $scope.openAssignBedDialog = function () {
                dialogService.openDialog("app/residents/partials/assignBedDialog.html",
                    "assignBedDialog.controller",
                    {
                        requestedAdmissionDate: $scope.residentAdmissionInfo.admissionDateUtc,
                        residentId: $scope.residentAdmissionInfo.residentId,
                        expectedDepartureDate: $scope.residentAdmissionInfo.expectedDepartureDateUtc,
                        origin: "residentDetails",
                        onSaveAssignedBedEventCallBack: $scope.onAssignBedSaveCallBack
                    });
            };
        }
    ];
