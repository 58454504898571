class ViewExpectedStatementPaymentController {
    static $inject = ["$stateParams"];

    public statement: any;
    public monthAndYear: any;

    constructor(
        private readonly stateParams: ng.ui.IStateParamsService,
    ) {
        this.statement = this.stateParams.statement;
        this.monthAndYear = this.stateParams.monthAndYear;
    }
}

export = ViewExpectedStatementPaymentController