"use strict";

var moment = require("moment");

module.exports = [
    "funding.service",
    "$stateParams",
    "notification.service",
    "$state",
    function (fundingService, $stateParams, notification, $state) {
        var vm = this;
        vm.monthAndYear = $stateParams.monthAndYear;
        vm.period = moment(vm.monthAndYear, "MYYYY").format("MMMM YYYY");
        vm.$state = $state;

        function getMedicareStatement() {
            fundingService.getMedicareStatement(vm.monthAndYear)
                .then(function (result) {
                    vm.statement = result;
                    $state.go($state.current, { monthAndYear: vm.monthAndYear, statement: result }, {});
                    },
                function () {
                    notification.error("Unable to display this statement. Either your computer is offline, or Resident Manager is having problems of its own.");
                });
        }

        getMedicareStatement();

    }
];
