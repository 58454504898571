function calenderValidationDirective(): ng.IDirective {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            name: "@",
            form: "="
        },
        templateUrl: "app/common/directives/partials/calenderValidation.html"
    };
}

export = calenderValidationDirective;