import { CareType } from './../../reporting/Models';
import { IResidentsService } from '../services/IResidentsService';
import { INotificationService } from '../../common/services/INotificationService';
import { IOrganisationFacilityService } from '../../organisation/services/IOrganisationFacilityService';
import { TransferResidentDto, TransferType } from '../Models';
import type { IStateService } from 'angular-ui-router';

class TransferResidentDialogController {
    static $inject = ["residents.service", "notification.service", "organisation.facility.service", "$state", "modalConfig"];

    public allOrganisationFacilities: any[];
    public transferResidentDto: TransferResidentDto = new TransferResidentDto();
    public resident: any;
    public $hide: any;
    public title: any;
    public isLead: boolean;
    public fromILU: boolean;

    constructor(
        private readonly residentsService: IResidentsService,
        private readonly notificationService: INotificationService,
        private readonly organisationFacilityService: IOrganisationFacilityService,
        private $state: IStateService,
        private readonly modalConfig: any) {

        this.resident = modalConfig.resident;
        if (modalConfig.fromILU) {
            this.fromILU = true;
            this.transferResidentDto.fromILU = true;
        }
        
        this.isLead = this.resident.admissionDateUtc == null;

        this.title = this.isLead ? "Transfer lead" : "Transfer resident";

        this.organisationFacilityService.getAllOrganisationFacilities(true).then(
            response => {
                this.allOrganisationFacilities = response;
            });
    }
    public bindHide = (hide) => {
        this.$hide = hide;
    };

    public transfer = (isValid) => {
        if (isValid) {
            
            this.transferResidentDto.transferType = this.isLead ? TransferType.Lead : TransferType.Departed;
            this.residentsService.transferResident(this.resident.id, this.transferResidentDto).then(result => {
                this.$hide();
                if (this.isLead) {
                    if (this.fromILU) {
                        this.$state.go("viewEnquiries");
                    }
                    else {
                        this.$state.go("leadsDashboard");
                    }
                    this.notificationService.success("Lead transferred");
                } 
                else {
                    this.$state.reload();
                    this.notificationService.success("Resident transferred");
                }
            },
            (error : any) => {
                if(error.data.modelState && error.data.modelState.mpir){
                    this.notificationService.error("Unable to transfer aged care resident. " + error.data.modelState.mpir[0]);
                }
                else {
                    this.notificationService.error("Unexpected error occurred while saving");
                }
            });
        }
    }
}

export = TransferResidentDialogController;
