import { IFundingService } from "../Interfaces";
import { INotificationService } from "../../common/services/INotificationService";
import { VarianceStatementPayments, StatementAdjustment, MedicareStatementType, CareType, ReconciliationAdjustmentAction,
     PaymentLine, PermanentPaymentLine, StatementVarianceRespitePayment, RespiteAdjustment, 
     PermanentAdjustment, StatementVariancePermanentPayment, NoteInfo } from "../Models";
import _ = require("underscore");
import { IDialogService } from "../../common/services/IDialogService";
import { IReportingService } from "../../reporting/services/IReportingService";
import { IDownloadFileService } from "../../common/services/IDownloadFileService";
import { DropdownOption } from "../../common/Models";
import { ExpectedStatementPaymentTypes } from '../enum/ExpectedStatementPaymentTypes';

class VarianceStatementPaymentComponent implements ng.IComponentOptions {
    public bindings: any;
    public templateUrl: string = 'app/funding/partials/varianceStatementPayment.html';

    constructor() {
        this.bindings = {
            monthAndYear: '='
        };
    }

    public controller: any = class VarianceStatementPaymentComponentController {
        static $inject = ["funding.service", "notification.service", "dialog.service", "reporting.service",
            "utilities.service", "downloadFile.service"];

        public monthAndYear: string;
        public varianceStatementPayments: VarianceStatementPayments;
        public acceptButtonDisabled: boolean = true;
        public undoButtonEnabled: boolean = false;
        public matchButtonDisabled: boolean = true;
        public carryOverButtonDisabled: boolean = true;
        public writeOffButtonDisabled: boolean = true;
        public finaliseButtonDisabled: boolean = true;
        public paymentFilters: DropdownOption[];
        public selectedFilter: DropdownOption;
        private selectedAdjustments: Array<StatementAdjustment> = [];
        private selectAllRespiteActual: boolean;
        private selectAllRespiteExpected: boolean;
        private selectAllPermanentActual: boolean;
        private selectAllPermanentExpected: boolean;

        constructor(private readonly fundingService: IFundingService,
            private readonly notificationService: INotificationService,
            private readonly dialogService: IDialogService,
            private readonly reportingService: IReportingService,
            private readonly utilitiesService: any,
            private readonly downloadFileService: IDownloadFileService) {
            this.getPayments();
            this.setPaymentFilters();
        }

        private getPayments = () => {
            this.fundingService.getVariancePayment(this.monthAndYear).then((result) => {
                this.varianceStatementPayments = result;
                this.finaliseButtonDisabled = !this.areAllLinesActioned();
                this.setToDefaults();
            },
                () => {
                    this.notificationService.error("Unexpected error occurred while fetching the variance in payments.");
                }
            );
        }

        private setPaymentFilters = (): void => {
            this.paymentFilters = [{ value: "0", displayText: "Not actioned" },
            { value: "1", displayText: "Actioned" },
            { value: "2", displayText: "Accepted" },
            { value: "3", displayText: "Carried over" },
            { value: "4", displayText: "Written off" },
            { value: "6", displayText: "Auto matched" },
            { value: "5", displayText: "All" }
            ];

            this.selectedFilter = this.paymentFilters[0];
        }

        public shouldShowBasedOnFilters = (processedAdjustmentAction: ReconciliationAdjustmentAction): boolean => {
            if (this.selectedFilter.value == "5")
                return true;

            if (this.selectedFilter.value == "0" && processedAdjustmentAction == ReconciliationAdjustmentAction.None)
                return true;

            if (this.selectedFilter.value == "1" && processedAdjustmentAction != ReconciliationAdjustmentAction.None
                && processedAdjustmentAction != ReconciliationAdjustmentAction.AutoMatched)
                return true;

            if (this.selectedFilter.value == "2" && (processedAdjustmentAction == ReconciliationAdjustmentAction.Accept
                || processedAdjustmentAction == ReconciliationAdjustmentAction.Match || processedAdjustmentAction == ReconciliationAdjustmentAction.MatchAccept))
                return true;

            if (this.selectedFilter.value == "3" && processedAdjustmentAction == ReconciliationAdjustmentAction.CarryOver)
                return true;

            if (this.selectedFilter.value == "4" && processedAdjustmentAction == ReconciliationAdjustmentAction.Writeoff)
                return true;

            if (this.selectedFilter.value == "6" && processedAdjustmentAction == ReconciliationAdjustmentAction.AutoMatched)
                return true;

            return false;
        }

        public shouldShowResidentBasedOnFilters = (crid: String, careType: CareType): boolean => {
            if (careType == CareType.Respite) {
                const respitePerson = _.find(this.varianceStatementPayments.respitePaymentVariance, (person) => {
                    return person.careRecipientId == crid;
                });

                const hasRespiteExpectedPaymentsToShow = _.some(respitePerson.expectedPaymentsVariance, (payment) => {
                    return this.shouldShowBasedOnFilters(payment.action);
                });

                const hasRespiteActualPaymentsToShow = _.some(respitePerson.actualPaymentsVariance, (payment) => {
                    return this.shouldShowBasedOnFilters(payment.action);
                });

                const hasRespteExpectedCarriedOverPaymentsToShow = _.some(respitePerson.expectedCarriedOver, (carriedOverPeriod) => {
                    const hasCarriedOverPayments = _.some(carriedOverPeriod.payments, (payment) => {
                        return this.shouldShowBasedOnFilters(payment.action);
                    });

                    return hasCarriedOverPayments;
                });

                const hasRespiteActualCarriedOverPaymentsToShow = _.some(respitePerson.actualCarriedOver, (carriedOverPeriod) => {
                    const hasCarriedOverPayments = _.some(carriedOverPeriod.payments, (payment) => {
                        return this.shouldShowBasedOnFilters(payment.action);
                    });

                    return hasCarriedOverPayments;
                });

                const hasRespiteAdjustmentsToShow = _.some(respitePerson.adjustments, (adjustmentPeriod) => {
                    const hasCarriedOverPayments = _.some(adjustmentPeriod.payments, (payment) => {
                        return this.shouldShowBasedOnFilters(payment.action);
                    });

                    return hasCarriedOverPayments;
                });

                return hasRespiteExpectedPaymentsToShow ||
                    hasRespiteActualPaymentsToShow ||
                    hasRespteExpectedCarriedOverPaymentsToShow ||
                    hasRespiteActualCarriedOverPaymentsToShow ||
                    hasRespiteAdjustmentsToShow;
            }

            const permanentPerson = _.find(this.varianceStatementPayments.permanentPaymentVariance, (person) => {
                return person.careRecipientId == crid;
            });

            const hasPermanentExpectedPaymentsToShow = _.some(permanentPerson.expectedPaymentsVariance, (payment) => {
                return this.shouldShowBasedOnFilters(payment.action);
            });

            const hasPermanentActualPaymentsToShow = _.some(permanentPerson.actualPaymentsVariance, (payment) => {
                return this.shouldShowBasedOnFilters(payment.action);
            });

            const hasPermanentExpectedCarriedOverPaymentsToShow = _.some(permanentPerson.expectedCarriedOver, (carriedOverPeriod) => {
                const hasCarriedOverPayments = _.some(carriedOverPeriod.payments, (payment) => {
                    return this.shouldShowBasedOnFilters(payment.action);
                });

                return hasCarriedOverPayments;
            });

            const hasPermanentActualCarriedOverPaymentsToShow = _.some(permanentPerson.actualCarriedOver, (carriedOverPeriod) => {
                const hasCarriedOverPayments = _.some(carriedOverPeriod.payments, (payment) => {
                    return this.shouldShowBasedOnFilters(payment.action);
                });

                return hasCarriedOverPayments;
            });

            const hasPermanentAdjustmentsToShow = _.some(permanentPerson.adjustments, (adjustmentPeriod) => {
                const hasCarriedOverPayments = _.some(adjustmentPeriod.payments, (payment) => {
                    return this.shouldShowBasedOnFilters(payment.action);
                });

                return hasCarriedOverPayments;
            });

            return hasPermanentExpectedPaymentsToShow ||
                hasPermanentActualPaymentsToShow ||
                hasPermanentExpectedCarriedOverPaymentsToShow ||
                hasPermanentActualCarriedOverPaymentsToShow ||
                hasPermanentAdjustmentsToShow;
        }

        public shouldShowAdjustmentsPeriod = (crid: String, period: String, careType: CareType) => {
            if (careType == CareType.Respite) {
                const respitePerson = _.find(this.varianceStatementPayments.respitePaymentVariance, (person) => {
                    return person.careRecipientId == crid;
                });

                const adjustmentPeriod = _.find(respitePerson.adjustments, (adjPeriod) => {
                    return adjPeriod.adjustmentMonth == period;
                });

                const hasNotActionedPayments = _.some(adjustmentPeriod.payments, (payment) => {
                    return this.shouldShowBasedOnFilters(payment.action);
                });

                return hasNotActionedPayments;
            }

            const permanentPerson = _.find(this.varianceStatementPayments.permanentPaymentVariance, (person) => {
                return person.careRecipientId == crid;
            });

            const adjustmentPeriod = _.find(permanentPerson.adjustments, (adjPeriod) => {
                return adjPeriod.adjustmentMonth == period;
            });

            const hasNotActionedPayments = _.some(adjustmentPeriod.payments, (payment) => {
                return this.shouldShowBasedOnFilters(payment.action);
            });

            return hasNotActionedPayments;
        }

        public shouldShowExpectedCarriedOverPeriod = (crid: String, period: String, careType: CareType) => {
            if (careType == CareType.Respite) {
                const respitePerson = _.find(this.varianceStatementPayments.respitePaymentVariance, (person) => {
                    return person.careRecipientId == crid;
                });

                const adjustmentPeriod = _.find(respitePerson.expectedCarriedOver, (adjPeriod) => {
                    return adjPeriod.adjustmentMonth == period;
                });

                const hasNotActionedPayments = _.some(adjustmentPeriod.payments, (payment) => {
                    return this.shouldShowBasedOnFilters(payment.action);
                });

                return hasNotActionedPayments;
            }

            const permanentPerson = _.find(this.varianceStatementPayments.permanentPaymentVariance, (person) => {
                return person.careRecipientId == crid;
            });

            const adjustmentPeriod = _.find(permanentPerson.expectedCarriedOver, (adjPeriod) => {
                return adjPeriod.adjustmentMonth == period;
            });

            const hasNotActionedPayments = _.some(adjustmentPeriod.payments, (payment) => {
                return this.shouldShowBasedOnFilters(payment.action);
            });

            return hasNotActionedPayments;
        }

        public shouldShowActualCarriedOverPeriod = (crid: String, period: String, careType: CareType) => {
            if (careType == CareType.Respite) {
                const respitePerson = _.find(this.varianceStatementPayments.respitePaymentVariance, (person) => {
                    return person.careRecipientId == crid;
                });

                const adjustmentPeriod = _.find(respitePerson.actualCarriedOver, (adjPeriod) => {
                    return adjPeriod.adjustmentMonth == period;
                });

                const hasNotActionedPayments = _.some(adjustmentPeriod.payments, (payment) => {
                    return this.shouldShowBasedOnFilters(payment.action);
                });

                return hasNotActionedPayments;
            }

            const permanentPerson = _.find(this.varianceStatementPayments.permanentPaymentVariance, (person) => {
                return person.careRecipientId == crid;
            });

            const adjustmentPeriod = _.find(permanentPerson.actualCarriedOver, (adjPeriod) => {
                return adjPeriod.adjustmentMonth == period;
            });

            const hasNotActionedPayments = _.some(adjustmentPeriod.payments, (payment) => {
                return this.shouldShowBasedOnFilters(payment.action);
            });

            return hasNotActionedPayments;
        }

        public markedForAction = (statementType: MedicareStatementType, selectedPayment: any, crid: String, careType: CareType, firstName: String, lastName: String, originalAdjustmentMonth?: String, isCarriedOver: boolean = false): void => {
            this.processPaymentLineSelection(statementType, selectedPayment, crid, careType, firstName, lastName, originalAdjustmentMonth, isCarriedOver)
            this.resetSelectAll(crid, careType);
        }

        public selectAllPaymentsInResident = (crid: string, statementType: MedicareStatementType, careType: CareType) => {
            let selectNotActioned: boolean = true;
            let action: ReconciliationAdjustmentAction = ReconciliationAdjustmentAction.None;
            switch (this.selectedFilter.value) {
                case "0":
                    selectNotActioned = true;
                    break;
                case "1":
                    selectNotActioned = false;
                    break;
                case "2":
                    action = ReconciliationAdjustmentAction.Accept
                    selectNotActioned = false;
                    break;
                case "3":
                    action = ReconciliationAdjustmentAction.CarryOver
                    selectNotActioned = false;
                    break;
                case "4":
                    action = ReconciliationAdjustmentAction.Writeoff
                    selectNotActioned = false;
                    break;
                case "5":
                    selectNotActioned = !this.areAllLinesActionedInStatementTypeForResident(statementType, careType, crid);
                    break;
            }

            if (careType == CareType.Respite) {

                const respitePerson = _.find(this.varianceStatementPayments.respitePaymentVariance, (person) => {
                    return person.careRecipientId == crid;
                });

                const markedForAction = statementType == MedicareStatementType.Actual ? respitePerson.selectAllActual :
                    respitePerson.selectAllExpected;

                this.selectAllPaymentsInRespiteCare(statementType, selectNotActioned, respitePerson, action, markedForAction);
            }
            else {
                const permanentPerson = _.find(this.varianceStatementPayments.permanentPaymentVariance, (person) => {
                    return person.careRecipientId == crid;
                });

                const markedForAction = statementType == MedicareStatementType.Actual ? permanentPerson.selectAllActual :
                    permanentPerson.selectAllExpected;

                this.selectAllPaymentsInPermanentCare(statementType, selectNotActioned, permanentPerson, action, markedForAction);
            }
        }

        public selectAllPayments = (statementType: MedicareStatementType, careType: CareType) => {
            let selectNotActioned: boolean = true;
            let action: ReconciliationAdjustmentAction = ReconciliationAdjustmentAction.None;
            switch (this.selectedFilter.value) {
                case "0":
                    selectNotActioned = true;
                    break;
                case "1":
                    selectNotActioned = false;
                    break;
                case "2":
                    action = ReconciliationAdjustmentAction.Accept
                    selectNotActioned = false;
                    break;
                case "3":
                    action = ReconciliationAdjustmentAction.CarryOver
                    selectNotActioned = false;
                    break;
                case "4":
                    action = ReconciliationAdjustmentAction.Writeoff
                    selectNotActioned = false;
                    break;
                case "5":
                    selectNotActioned = !this.areAllLinesActionedInStatementType(statementType, careType);
                    break;

            }

            if (careType == CareType.Respite) {
                const markedForAction = statementType == MedicareStatementType.Actual ? this.selectAllRespiteActual : this.selectAllRespiteExpected;
                _.each(this.varianceStatementPayments.respitePaymentVariance, (person) => {
                    person.selectAllActual = this.selectAllRespiteActual;
                    person.selectAllExpected = this.selectAllRespiteExpected;
                    this.selectAllPaymentsInRespiteCare(statementType, selectNotActioned, person, action, markedForAction);
                });
            }
            else {
                const markedForAction = statementType == MedicareStatementType.Actual ? this.selectAllPermanentActual : this.selectAllPermanentExpected;
                _.each(this.varianceStatementPayments.permanentPaymentVariance, (person) => {
                    person.selectAllActual = this.selectAllPermanentActual;
                    person.selectAllExpected = this.selectAllPermanentExpected;
                    this.selectAllPaymentsInPermanentCare(statementType, selectNotActioned, person, action, markedForAction);
                });
            }
        }

        private selectAllPaymentsInRespiteCare = (statementType: MedicareStatementType, selectNotActioned: boolean, respitePerson: StatementVarianceRespitePayment, action: ReconciliationAdjustmentAction = ReconciliationAdjustmentAction.None, markedForAction: boolean) => {
            if (statementType == MedicareStatementType.Actual) {
                _.each(this.getPaymentsByActionedState(selectNotActioned, respitePerson.actualPaymentsVariance, action, !markedForAction), (payment) => {
                    payment.markedForAction = markedForAction;
                    this.processPaymentLineSelection(statementType, payment, respitePerson.careRecipientId, CareType.Respite, respitePerson.firstName, respitePerson.lastName);
                });

                _.each(respitePerson.adjustments, (adjustment: any) => {
                    _.each(this.getPaymentsByActionedState(selectNotActioned, adjustment.payments, action, !markedForAction), (payment) => {
                        payment.markedForAction = markedForAction;
                        this.processPaymentLineSelection(statementType, payment, respitePerson.careRecipientId, CareType.Respite, respitePerson.firstName, respitePerson.lastName, adjustment.adjustmentMonth);
                    });
                });
                
                _.each(respitePerson.actualCarriedOver, (adjustment: any) => {
                    _.each(this.getPaymentsByActionedState(selectNotActioned, adjustment.payments, action, !markedForAction), (payment) => {
                        payment.markedForAction = markedForAction;
                        this.processPaymentLineSelection(statementType, payment, respitePerson.careRecipientId, CareType.Respite, respitePerson.firstName, respitePerson.lastName, adjustment.adjustmentMonth, true);
                    });
                });
            }
            else {
                _.each(this.getPaymentsByActionedState(selectNotActioned, respitePerson.expectedPaymentsVariance, action, !markedForAction), (payment) => {
                    payment.markedForAction = markedForAction;
                    this.processPaymentLineSelection(statementType, payment, respitePerson.careRecipientId, CareType.Respite, respitePerson.firstName, respitePerson.lastName);
                });

                _.each(respitePerson.expectedCarriedOver, (adjustment: any) => {
                    _.each(this.getPaymentsByActionedState(selectNotActioned, adjustment.payments, action, !markedForAction), (payment) => {
                        payment.markedForAction = markedForAction;
                        this.processPaymentLineSelection(statementType, payment, respitePerson.careRecipientId, CareType.Respite, respitePerson.firstName, respitePerson.lastName, adjustment.adjustmentMonth, true);
                    });
                });
            }
        }

        private selectAllPaymentsInPermanentCare = (statementType: MedicareStatementType, selectNotActioned: boolean, permanentPerson: StatementVariancePermanentPayment, action: ReconciliationAdjustmentAction = ReconciliationAdjustmentAction.None, markedForAction: boolean) => {
            if (statementType == MedicareStatementType.Actual) {
                _.each(this.getPaymentsByActionedState(selectNotActioned, permanentPerson.actualPaymentsVariance, action, !markedForAction), (payment) => {
                    payment.markedForAction = markedForAction;
                    this.processPaymentLineSelection(statementType, payment, permanentPerson.careRecipientId, CareType.Permanent, permanentPerson.firstName, permanentPerson.lastName);
                });
                _.each(permanentPerson.adjustments, (adjustment: any) => {
                    _.each(this.getPaymentsByActionedState(selectNotActioned, adjustment.payments, action, !markedForAction), (payment) => {
                        payment.markedForAction = markedForAction;
                        this.processPaymentLineSelection(statementType, payment, permanentPerson.careRecipientId, CareType.Permanent, permanentPerson.firstName, permanentPerson.lastName, adjustment.adjustmentMonth);
                    });
                });
                _.each(permanentPerson.actualCarriedOver, (adjustment: any) => {
                    _.each(this.getPaymentsByActionedState(selectNotActioned, adjustment.payments, action, !markedForAction), (payment) => {
                        payment.markedForAction = markedForAction;
                        this.processPaymentLineSelection(statementType, payment, permanentPerson.careRecipientId, CareType.Permanent, permanentPerson.firstName, permanentPerson.lastName, adjustment.adjustmentMonth, true);
                    });
                });
            }
            else {
                _.each(this.getPaymentsByActionedState(selectNotActioned, permanentPerson.expectedPaymentsVariance, action, !markedForAction), (payment) => {
                    payment.markedForAction = markedForAction;
                    this.processPaymentLineSelection(statementType, payment, permanentPerson.careRecipientId, CareType.Permanent, permanentPerson.firstName, permanentPerson.lastName);
                });
                _.each(permanentPerson.expectedCarriedOver, (adjustment: any) => {
                    _.each(this.getPaymentsByActionedState(selectNotActioned, adjustment.payments, action, !markedForAction), (payment) => {
                        payment.markedForAction = markedForAction;
                        this.processPaymentLineSelection(statementType, payment, permanentPerson.careRecipientId, CareType.Permanent, permanentPerson.firstName, permanentPerson.lastName, adjustment.adjustmentMonth, true);
                    });
                });
            }
        }

        private getPaymentsByActionedState = (notActioned: boolean, payments: PaymentLine[],
            action: ReconciliationAdjustmentAction = ReconciliationAdjustmentAction.None, marked:boolean): PaymentLine[] => {
            let paymentLines: PaymentLine[] = [];
            _.each(payments, (payment: PaymentLine) => {
                if (notActioned) {
                    if (payment.action == ReconciliationAdjustmentAction.None && !payment.restrictFromAction && payment.markedForAction == marked)
                        paymentLines.push(payment);
                }
                else {
                    if (action == ReconciliationAdjustmentAction.None && payment.action != action
                        && payment.action != ReconciliationAdjustmentAction.AutoMatched && !payment.restrictFromAction && payment.markedForAction == marked) {
                        paymentLines.push(payment);
                    }
                    else if (action != ReconciliationAdjustmentAction.None && (payment.action == action || (action == ReconciliationAdjustmentAction.Accept && payment.action == ReconciliationAdjustmentAction.Match)) &&
                        payment.action != ReconciliationAdjustmentAction.AutoMatched && !payment.restrictFromAction && payment.markedForAction == marked)
                        paymentLines.push(payment);
                }
            });
            return paymentLines;
        }

        private setTheActionableStateOfTheLines = (payments: PaymentLine[], statementType: MedicareStatementType, crid: String) => {
            _.each(payments, (payment) => {
                this.setTheActionableStateOfTheLine(payment, statementType, crid);
            });
        }

        private processPaymentLineSelection = (statementType: MedicareStatementType, selectedPayment: any, crid: String, careType: CareType, firstName: String, lastName: String, originalAdjustmentMonth?: String, isCarriedOver: boolean = false) => {
            const isChecked: boolean = selectedPayment.markedForAction;
            this.storeSelectedPaymentLines(selectedPayment, statementType, crid, !isChecked, careType, firstName, lastName, isCarriedOver, originalAdjustmentMonth);

            _.each(this.varianceStatementPayments.respitePaymentVariance, (person) => {
                this.setTheActionableStateOfTheLines(person.actualPaymentsVariance, MedicareStatementType.Actual, person.careRecipientId);
                this.setTheActionableStateOfTheLines(person.expectedPaymentsVariance, MedicareStatementType.Expected, person.careRecipientId);

                _.each(person.adjustments, (adjustment) => {
                    this.setTheActionableStateOfTheLines(adjustment.payments, MedicareStatementType.Actual, person.careRecipientId);
                });

                _.each(person.expectedCarriedOver, (adjustment) => {
                    this.setTheActionableStateOfTheLines(adjustment.payments, MedicareStatementType.Expected, person.careRecipientId);
                });

                _.each(person.actualCarriedOver, (adjustment) => {
                    this.setTheActionableStateOfTheLines(adjustment.payments, MedicareStatementType.Actual, person.careRecipientId);
                });
            });

            _.each(this.varianceStatementPayments.permanentPaymentVariance, (person) => {
                this.setTheActionableStateOfTheLines(person.actualPaymentsVariance, MedicareStatementType.Actual, person.careRecipientId);
                this.setTheActionableStateOfTheLines(person.expectedPaymentsVariance, MedicareStatementType.Expected, person.careRecipientId);

                _.each(person.adjustments, (adjustment) => {
                    this.setTheActionableStateOfTheLines(adjustment.payments, MedicareStatementType.Actual, person.careRecipientId);
                });

                _.each(person.expectedCarriedOver, (adjustment) => {
                    this.setTheActionableStateOfTheLines(adjustment.payments, MedicareStatementType.Expected, person.careRecipientId);
                });

                _.each(person.actualCarriedOver, (adjustment) => {
                    this.setTheActionableStateOfTheLines(adjustment.payments, MedicareStatementType.Actual, person.careRecipientId);
                });
            });

            this.setButtons();
        }

        public processAction = (action: ReconciliationAdjustmentAction) => {
            if (action == ReconciliationAdjustmentAction.Match) {
                const groupIdentifier = this.utilitiesService.newGuid();
                _.each(this.selectedAdjustments, (adjustment) => {
                    adjustment.groupIdentifier = groupIdentifier;
                });
            }

            this.fundingService.actionAdjustments(this.monthAndYear, action, this.selectedAdjustments).then((result) => {
                action = action == ReconciliationAdjustmentAction.Undo ? ReconciliationAdjustmentAction.None : action;
                this.markTheProcessedAction(action);
                this.selectedAdjustments = [];
                this.setAllLinesSelectable();
                this.setButtons();
                this.resetSelectAllCheckBoxes();
            }, () => {
                this.notificationService.error("Unexpected error occurred while saving adjustments.");
            });
        }

        public setToDefaults = () => {
            this.selectedAdjustments = [];
            this.unselectAllLines();
            this.setAllLinesSelectable();
            this.setButtons();
            this.resetSelectAllCheckBoxes();
        }

        private resetSelectAllCheckBoxes = () => {
            this.selectAllRespiteActual = false;
            this.selectAllRespiteExpected = false;
            this.selectAllPermanentActual = false;
            this.selectAllPermanentExpected = false;
        }

        private resetSelectAll = (crid: String, careType: CareType) => {
            if (careType == CareType.Respite) {
                const selectedPerson = _.find(this.varianceStatementPayments.respitePaymentVariance, (person) => {
                    return person.careRecipientId == crid;
                });

                selectedPerson.selectAllActual = false;
                selectedPerson.selectAllExpected = false;
                this.selectAllRespiteExpected = false;
                this.selectAllRespiteActual = false;
            }
            else {
                const selectedPerson = _.find(this.varianceStatementPayments.permanentPaymentVariance, (person) => {
                    return person.careRecipientId == crid;
                });

                selectedPerson.selectAllActual = false;
                selectedPerson.selectAllExpected = false;
                this.selectAllPermanentExpected = false;
                this.selectAllPermanentActual = false;
            }
        }

        public openMatchDialog = () => {
            const firstSelectedAdjustment = this.selectedAdjustments[0];
            let modalConfig: any = {
                acceptActual: this.acceptActual
            };

            if (firstSelectedAdjustment.careType == CareType.Respite) {
                const selectedResident = _.find(this.varianceStatementPayments.respitePaymentVariance, (person) => {
                    return person.careRecipientId == firstSelectedAdjustment.careRecipientId;
                });

                modalConfig.respite = {
                    careRecipientId: selectedResident.careRecipientId,
                    lastName: selectedResident.lastName,
                    firstName: selectedResident.firstName,
                    expectedPaymentsVariance: [],
                    actualPaymentsVariance: [],
                    adjustments: []
                };

                const selectedExpectedAdjustments = _.filter(this.selectedAdjustments, (adjustment) => {
                    return adjustment.medicareStatementType == MedicareStatementType.Expected;
                });

                const selectedActualAdjustments = _.filter(this.selectedAdjustments, (adjustment) => {
                    return adjustment.medicareStatementType == MedicareStatementType.Actual;
                });

                _.each(selectedExpectedAdjustments, (expectedAdjustment) => {
                    modalConfig.respite.expectedPaymentsVariance.push({
                        paymentType: expectedAdjustment.paymentType,
                        paidCare: expectedAdjustment.paidCare,
                        unpaidCare: expectedAdjustment.unpaidCare,
                        dailyRate: expectedAdjustment.dailyRate,
                        amountDue: expectedAdjustment.amountDue
                    });
                });

                _.each(selectedActualAdjustments, (actualAdjustment) => {
                    modalConfig.respite.actualPaymentsVariance.push({
                        paymentType: actualAdjustment.paymentType,
                        paidCare: actualAdjustment.paidCare,
                        unpaidCare: actualAdjustment.unpaidCare,
                        dailyRate: actualAdjustment.dailyRate,
                        amountDue: actualAdjustment.amountDue
                    });
                });
            }
            else if (firstSelectedAdjustment.careType == CareType.Permanent) {
                const selectedResident = _.find(this.varianceStatementPayments.permanentPaymentVariance, (person) => {
                    return person.careRecipientId == firstSelectedAdjustment.careRecipientId;
                });

                modalConfig.permanent = {
                    careRecipientId: selectedResident.careRecipientId,
                    lastName: selectedResident.lastName,
                    firstName: selectedResident.firstName,
                    expectedPaymentsVariance: [],
                    actualPaymentsVariance: [],
                    adjustments: []
                };

                const selectedExpectedAdjustments = _.filter(this.selectedAdjustments, (adjustment) => {
                    return adjustment.medicareStatementType == MedicareStatementType.Expected;
                });

                const selectedActualAdjustments = _.filter(this.selectedAdjustments, (adjustment) => {
                    return adjustment.medicareStatementType == MedicareStatementType.Actual;
                });

                _.each(selectedExpectedAdjustments, (expectedAdjustment) => {
                    modalConfig.permanent.expectedPaymentsVariance.push({
                        paymentType: expectedAdjustment.paymentType,
                        paidCare: expectedAdjustment.paidCare,
                        unpaidCare: expectedAdjustment.unpaidCare,
                        dailyRate: expectedAdjustment.dailyRate,
                        amountDue: expectedAdjustment.amountDue,
                        paidLeaveSocial: expectedAdjustment.paidLeaveSocial,
                        paidLeaveHospital: expectedAdjustment.paidLeaveHospital,
                        paidLeaveTransition: expectedAdjustment.paidLeaveTransition,
                    });
                });

                _.each(selectedActualAdjustments, (actualAdjustment) => {
                    modalConfig.permanent.actualPaymentsVariance.push({
                        paymentType: actualAdjustment.paymentType,
                        paidCare: actualAdjustment.paidCare,
                        unpaidCare: actualAdjustment.unpaidCare,
                        dailyRate: actualAdjustment.dailyRate,
                        amountDue: actualAdjustment.amountDue,
                        paidLeaveSocial: actualAdjustment.paidLeaveSocial,
                        paidLeaveHospital: actualAdjustment.paidLeaveHospital,
                        paidLeaveTransition: actualAdjustment.paidLeaveTransition,
                    });
                });
            }

            this.dialogService.openDialog("app/funding/partials/matchValuesDialog.html", "matchValuesDialog.controller", modalConfig);
        }

        public openReconciliationSummary = (isReadOnly: boolean) => {
            this.dialogService.openDialog("app/funding/partials/adjustmentSummary.html", "adjustmentSummary.controller", {
                monthAndYear: this.monthAndYear,
                finalise: this.finalise,
                isReadOnly: isReadOnly
            });
        }

        private finalise = () => {
            this.fundingService.finaliseReconciliation(this.monthAndYear).then((result) => {
                this.varianceStatementPayments.isReconciliationFinalised = true;
            }, () => {
                this.notificationService.error("Unexpected error occurred while saving adjustments.");
            });
        }

        private acceptActual = () => {
            this.processAction(ReconciliationAdjustmentAction.Match);
        }

        private storeSelectedPaymentLines = (payment: PaymentLine, paymentLineStatementType: MedicareStatementType, crid: String, remove: boolean,
            careType: CareType, firstName: String, lastName: String, isCarriedOver: boolean, originalAdjustmentMonth?: String) => {
            if (payment.groupIdentifier == null) {
                this.addOrRemoveSelectedPaymentLines(payment, paymentLineStatementType, crid, remove, careType, firstName, lastName, isCarriedOver, originalAdjustmentMonth);
                return;
            }

            if (careType == CareType.Respite) {
                const selectedPerson = _.find(this.varianceStatementPayments.respitePaymentVariance, (person) => {
                    return person.careRecipientId == crid;
                });

                const actualPayments = _.filter(selectedPerson.actualPaymentsVariance, (pmt) => {
                    return pmt.groupIdentifier == payment.groupIdentifier;
                });

                _.each(actualPayments, (pmt) => {
                    pmt.markedForAction = !remove;
                    this.addOrRemoveSelectedPaymentLines(pmt, MedicareStatementType.Actual, crid, remove, careType, firstName, lastName, isCarriedOver, null);
                });

                const expectedPayments = _.filter(selectedPerson.expectedPaymentsVariance, (pmt) => {
                    return pmt.groupIdentifier == payment.groupIdentifier;
                });

                _.each(expectedPayments, (pmt) => {
                    pmt.markedForAction = !remove;
                    this.addOrRemoveSelectedPaymentLines(pmt, MedicareStatementType.Expected, crid, remove, careType, firstName, lastName, isCarriedOver, null);
                });

                _.each(selectedPerson.adjustments, (adj) => {
                    const adjustmentPayments = _.filter(adj.payments, (pmt) => {
                        return pmt.groupIdentifier == payment.groupIdentifier;
                    });

                    _.each(adjustmentPayments, (pmt) => {
                        pmt.markedForAction = !remove;
                        this.addOrRemoveSelectedPaymentLines(pmt, MedicareStatementType.Actual, crid, remove, careType, firstName, lastName, false, adj.adjustmentMonth);
                    });
                });

                _.each(selectedPerson.expectedCarriedOver, (adj) => {
                    const carriedOverPayments = _.filter(adj.payments, (pmt) => {
                        return pmt.groupIdentifier == payment.groupIdentifier;
                    });

                    _.each(carriedOverPayments, (pmt) => {
                        pmt.markedForAction = !remove;
                        this.addOrRemoveSelectedPaymentLines(pmt, MedicareStatementType.Expected, crid, remove, careType, firstName, lastName, true, adj.adjustmentMonth);
                    });
                });

                _.each(selectedPerson.actualCarriedOver, (adj) => {
                    const carriedOverPayments = _.filter(adj.payments, (pmt) => {
                        return pmt.groupIdentifier == payment.groupIdentifier;
                    });

                    _.each(carriedOverPayments, (pmt) => {
                        pmt.markedForAction = !remove;
                        this.addOrRemoveSelectedPaymentLines(pmt, MedicareStatementType.Actual, crid, remove, careType, firstName, lastName, true, adj.adjustmentMonth);
                    });
                });
            }
            else if (careType == CareType.Permanent) {
                const selectedPerson = _.find(this.varianceStatementPayments.permanentPaymentVariance, (person) => {
                    return person.careRecipientId == crid;
                });

                const actualPayments = _.filter(selectedPerson.actualPaymentsVariance, (pmt) => {
                    return pmt.groupIdentifier == payment.groupIdentifier;
                });

                _.each(actualPayments, (pmt) => {
                    pmt.markedForAction = !remove;
                    this.addOrRemoveSelectedPaymentLines(pmt, MedicareStatementType.Actual, crid, remove, careType, firstName, lastName, isCarriedOver, null);
                });

                const expectedPayments = _.filter(selectedPerson.expectedPaymentsVariance, (pmt) => {
                    return pmt.groupIdentifier == payment.groupIdentifier;
                });

                _.each(expectedPayments, (pmt) => {
                    pmt.markedForAction = !remove;
                    this.addOrRemoveSelectedPaymentLines(pmt, MedicareStatementType.Expected, crid, remove, careType, firstName, lastName, isCarriedOver, null);
                });

                _.each(selectedPerson.adjustments, (adj) => {
                    const adjustmentPayments = _.filter(adj.payments, (pmt) => {
                        return pmt.groupIdentifier == payment.groupIdentifier;
                    });

                    _.each(adjustmentPayments, (pmt) => {
                        pmt.markedForAction = !remove;
                        this.addOrRemoveSelectedPaymentLines(pmt, MedicareStatementType.Actual, crid, remove, careType, firstName, lastName, false, adj.adjustmentMonth);
                    });
                });

                _.each(selectedPerson.expectedCarriedOver, (adj) => {
                    const carriedOverPayments = _.filter(adj.payments, (pmt) => {
                        return pmt.groupIdentifier == payment.groupIdentifier;
                    });

                    _.each(carriedOverPayments, (pmt) => {
                        pmt.markedForAction = !remove;
                        this.addOrRemoveSelectedPaymentLines(pmt, MedicareStatementType.Expected, crid, remove, careType, firstName, lastName, true, adj.adjustmentMonth);
                    });
                });

                _.each(selectedPerson.actualCarriedOver, (adj) => {
                    const carriedOverPayments = _.filter(adj.payments, (pmt) => {
                        return pmt.groupIdentifier == payment.groupIdentifier;
                    });

                    _.each(carriedOverPayments, (pmt) => {
                        pmt.markedForAction = !remove;
                        this.addOrRemoveSelectedPaymentLines(pmt, MedicareStatementType.Actual, crid, remove, careType, firstName, lastName, true, adj.adjustmentMonth);
                    });
                });
            }
        }

        private getNumberOfIdenticalLineItems(crid: String, payment: PaymentLine, paymentLineStatementType: MedicareStatementType, careType: CareType) : number {
            if(careType === CareType.Permanent) {
                const selectedPerson = _.find(this.varianceStatementPayments.permanentPaymentVariance, (person) => {
                    return person.careRecipientId == crid;
                });

                if(paymentLineStatementType === MedicareStatementType.Actual) {
                    const matchingPaymentItems = this.getPermanentMatchingLineItems(selectedPerson.actualPaymentsVariance, payment);
                    let matchingAdjustmentCount = 0;
                    _.each(selectedPerson.adjustments, (adjustment) => {
                        const matchingAdjustments = this.getPermanentMatchingLineItems(adjustment.payments, payment);
                        matchingAdjustmentCount = matchingAdjustmentCount + _.size(matchingAdjustments);
                    });

                    let matchingCarriedOverCount = 0;
                    _.each(selectedPerson.actualCarriedOver, (carriedOverItem) => {
                        const matchingCarriedOverItems = this.getPermanentMatchingLineItems(carriedOverItem.payments, payment);
                        matchingCarriedOverCount = matchingCarriedOverCount + _.size(matchingCarriedOverItems);
                    });

                    return _.size(matchingPaymentItems) + matchingAdjustmentCount + matchingCarriedOverCount;
                }
                else {
                    const matchingPaymentItems = this.getPermanentMatchingLineItems(selectedPerson.expectedPaymentsVariance, payment);
                    let matchingCarriedOverCount = 0;
                    _.each(selectedPerson.expectedCarriedOver, (carriedOverItem) => {
                        const matchingCarriedOverItems = this.getPermanentMatchingLineItems(carriedOverItem.payments, payment);
                        matchingCarriedOverCount = matchingCarriedOverCount + _.size(matchingCarriedOverItems);
                    });

                    return _.size(matchingPaymentItems) + matchingCarriedOverCount;
                }
            }
            else {
                const selectedPerson = _.find(this.varianceStatementPayments.respitePaymentVariance, (person) => {
                    return person.careRecipientId == crid;
                });

                if(paymentLineStatementType === MedicareStatementType.Actual) {
                    const matchingPaymentItems = this.getRespiteMatchingLineItems(selectedPerson.actualPaymentsVariance, payment);
                    let matchingAdjustmentCount = 0;
                    _.each(selectedPerson.adjustments, (adjustment) => {
                        const matchingAdjustments = this.getRespiteMatchingLineItems(adjustment.payments, payment);
                        matchingAdjustmentCount = matchingAdjustmentCount + _.size(matchingAdjustments);
                    });

                    let matchingCarriedOverCount = 0;
                    _.each(selectedPerson.actualCarriedOver, (carriedOverItem) => {
                        const matchingCarriedOverItems = this.getRespiteMatchingLineItems(carriedOverItem.payments, payment);
                        matchingCarriedOverCount = matchingCarriedOverCount + _.size(matchingCarriedOverItems);
                    });

                    return _.size(matchingPaymentItems) + matchingAdjustmentCount + matchingCarriedOverCount;
                }
                else {
                    const matchingPaymentItems = this.getRespiteMatchingLineItems(selectedPerson.expectedPaymentsVariance, payment);
                    let matchingCarriedOverCount = 0;
                    _.each(selectedPerson.expectedCarriedOver, (carriedOverItem) => {
                        const matchingCarriedOverItems = this.getRespiteMatchingLineItems(carriedOverItem.payments, payment);
                        matchingCarriedOverCount = matchingCarriedOverCount + _.size(matchingCarriedOverItems);
                    });
                    return _.size(matchingPaymentItems) + matchingCarriedOverCount;
                }
            }
        }

        private getPermanentMatchingLineItems(permanentLineItems: PermanentPaymentLine[], payment: PaymentLine) : PermanentPaymentLine[] {
            const matchingItems = _.filter(permanentLineItems, (line) => {
                return line.paymentType == payment.paymentType
                && line.paidCare == payment.paidCare
                && line.unpaidCare == payment.unpaidCare
                && line.dailyRate == payment.dailyRate
                && line.amountDue == payment.amountDue
                && line.paidLeaveSocial == (<any>payment).paidLeaveSocial
                && line.paidLeaveHospital == (<any>payment).paidLeaveHospital
                && line.paidLeaveTransition == (<any>payment).paidLeaveTransition;
            });

            return matchingItems;
        }

        private getRespiteMatchingLineItems(respiteLineItems: PaymentLine[], payment: PaymentLine) : PaymentLine[] {
            const matchingItems = _.filter(respiteLineItems, (line) => {
                return line.paymentType == payment.paymentType
                && line.paidCare == payment.paidCare
                && line.unpaidCare == payment.unpaidCare
                && line.dailyRate == payment.dailyRate
                && line.amountDue == payment.amountDue;
            });

            return matchingItems;
        }

        private findSelectedAdjustmentPosition = (payment: PaymentLine, paymentLineStatementType: MedicareStatementType, crid: String, 
            careType: CareType, firstName: String, lastName: String, isCarriedOver: boolean, originalAdjustmentMonth?: String): number => {
            
            const numberOfSimilarItems = this.getNumberOfIdenticalLineItems(crid, payment, paymentLineStatementType, careType);
            const selectedItemsCount = _.size(_.filter(this.selectedAdjustments, (line) => {
                return line.careRecipientId == crid
                    && line.medicareStatementType == paymentLineStatementType
                    && line.paymentType == payment.paymentType
                    && line.paidCare == payment.paidCare
                    && line.unpaidCare == payment.unpaidCare
                    && line.dailyRate == payment.dailyRate
                    && line.amountDue == payment.amountDue
                    && line.careType == careType
                    && (line.careType == CareType.Respite || (line.paidLeaveSocial == (<any>payment).paidLeaveSocial
                        && line.paidLeaveHospital == (<any>payment).paidLeaveHospital
                        && line.paidLeaveTransition == (<any>payment).paidLeaveTransition))
                    && line.originalAdjustmentMonth == originalAdjustmentMonth
                    && line.firstName == firstName
                    && line.lastName == lastName
                    && line.isCarriedOver == isCarriedOver;
            }));

            if(numberOfSimilarItems > selectedItemsCount)
                return -1;

            return _.findIndex(this.selectedAdjustments, (line) => {
                return line.careRecipientId == crid
                    && line.medicareStatementType == paymentLineStatementType
                    && line.paymentType == payment.paymentType
                    && line.paidCare == payment.paidCare
                    && line.unpaidCare == payment.unpaidCare
                    && line.dailyRate == payment.dailyRate
                    && line.amountDue == payment.amountDue
                    && line.careType == careType
                    && (line.careType == CareType.Respite || (line.paidLeaveSocial == (<any>payment).paidLeaveSocial
                        && line.paidLeaveHospital == (<any>payment).paidLeaveHospital
                        && line.paidLeaveTransition == (<any>payment).paidLeaveTransition))
                    && line.originalAdjustmentMonth == originalAdjustmentMonth
                    && line.firstName == firstName
                    && line.lastName == lastName
                    && line.isCarriedOver == isCarriedOver;
            });
        }   

        private addOrRemoveSelectedPaymentLines = (payment: PaymentLine, paymentLineStatementType: MedicareStatementType, crid: String, remove: boolean, 
            careType: CareType, firstName: String, lastName: String, isCarriedOver: boolean, originalAdjustmentMonth?: String) => {
            const index = this.findSelectedAdjustmentPosition(payment, paymentLineStatementType, crid, careType, firstName, lastName, isCarriedOver, originalAdjustmentMonth);
            if (remove) {
                this.selectedAdjustments.splice(index, 1);
                return;
            }

            if(index != -1)
                return;
                
            this.selectedAdjustments.push({
                careRecipientId: crid,
                medicareStatementType: paymentLineStatementType,
                paymentType: payment.paymentType,
                paidCare: payment.paidCare,
                unpaidCare: payment.unpaidCare,
                dailyRate: payment.dailyRate,
                amountDue: payment.amountDue,
                careType: careType,
                paidLeaveSocial: (<any>payment).paidLeaveSocial,
                paidLeaveHospital: (<any>payment).paidLeaveHospital,
                paidLeaveTransition: (<any>payment).paidLeaveTransition,
                adjustmentAmount: payment.amountDue,
                action: payment.action,
                originalAdjustmentMonth: originalAdjustmentMonth,
                firstName: firstName,
                lastName: lastName,
                isCarriedOver: isCarriedOver
            });
        }

        public viewAndAddNote = (statementType: MedicareStatementType, payment: PaymentLine, crid: String, careType: CareType, firstName: String, lastName: String, 
                                 originalAdjustmentMonth?: String) => {
            let lineItem = {
                careRecipientId: crid,
                medicareStatementType: statementType,
                paymentType: payment.paymentType,
                paidCare: payment.paidCare,
                unpaidCare: payment.unpaidCare,
                dailyRate: payment.dailyRate,
                amountDue: payment.amountDue,
                careType: careType,
                paidLeaveSocial: (<any>payment).paidLeaveSocial,
                paidLeaveHospital: (<any>payment).paidLeaveHospital,
                paidLeaveTransition: (<any>payment).paidLeaveTransition,
                adjustmentAmount: payment.amountDue,
                originalAdjustmentMonth: originalAdjustmentMonth,
                firstName: firstName,
                lastName: lastName,
            }

            this.dialogService.openDialog("app/funding/partials/viewAndAddVarianceNote.html",
                "viewAndAddVarianceNote.controller",
                {
                    monthAndYear: this.monthAndYear,
                    lineItem: lineItem,
                    payment: payment,                    
                    successfulCallback: this.updateVarianceNotes
                });
        }

        public updateVarianceNotes = (note: NoteInfo, payment: PaymentLine) => {
            if (payment.notes == null)
                payment.notes = new Array<NoteInfo>();

            payment.notes.push(note);
        }

        private setTheActionableStateOfTheLine = (payment: PaymentLine, paymentLineStatementType: MedicareStatementType, crid: String) => {
            if (payment.markedForAction)
                return;

            if (this.selectedAdjustments.length == 0) {
                payment.restrictFromAction = false;
                return;
            }

            if (this.isAlreadyActionedLineSelected()) {
                if (payment.action == ReconciliationAdjustmentAction.None) {
                    payment.restrictFromAction = true;
                }
                return;
            }

            if (!this.isAlreadyActionedLineSelected() && payment.action != ReconciliationAdjustmentAction.None) {
                payment.restrictFromAction = true;
                return;
            }

            if (this.isOppositSidePaymentSelectedWithADifferentCrid(crid, paymentLineStatementType)) {
                payment.restrictFromAction = true;
            }
            else {
                payment.restrictFromAction = false;
            }
        }

        private isOppositSidePaymentSelectedWithADifferentCrid = (crid: String, paymentLineStatementType: MedicareStatementType): boolean => {
            return _.some(this.selectedAdjustments, (line) => {
                return line.careRecipientId != crid && line.medicareStatementType != paymentLineStatementType;
            });
        };

        private isAlreadyActionedLineSelected = () => {
            return _.some(this.selectedAdjustments, (line) => {
                return line.action != ReconciliationAdjustmentAction.None;
            });
        }

        private isSideSelected = (statementType: MedicareStatementType): boolean => {
            return _.some(this.selectedAdjustments, (adjustment) => {
                return adjustment.medicareStatementType == statementType;
            });
        }

        private areAllLinesActioned = () => {
            return this.areAllLinesActionedInStatementType(MedicareStatementType.Expected, CareType.Respite) &&
                this.areAllLinesActionedInStatementType(MedicareStatementType.Actual, CareType.Respite) &&
                this.areAllLinesActionedInStatementType(MedicareStatementType.Expected, CareType.Permanent) &&
                this.areAllLinesActionedInStatementType(MedicareStatementType.Actual, CareType.Permanent);
        }

        private areAllLinesActionedInStatementType = (medicareStatementType: MedicareStatementType, careType: CareType) => {
            if (careType == CareType.Respite) {
                const allRespiteLinesNotActioned = _.some(this.varianceStatementPayments.respitePaymentVariance, (person) => {
                    if (medicareStatementType == MedicareStatementType.Expected) {
                        const hasExpectedNotActioned = _.some(person.expectedPaymentsVariance, (payment) => {
                            return payment.action == ReconciliationAdjustmentAction.None || payment.action == 0;
                        });

                        const hasExpectedCarriedOverNotActioned = _.some(person.expectedCarriedOver, (adjustment) => {
                            return _.some(adjustment.payments, (payment) => {
                                return payment.action == ReconciliationAdjustmentAction.None || payment.action == 0;
                            });
                        });

                        return hasExpectedNotActioned || hasExpectedCarriedOverNotActioned;
                    }
                    else {
                        const hasActualNotActioned = _.some(person.actualPaymentsVariance, (payment) => {
                            return payment.action == ReconciliationAdjustmentAction.None || payment.action == 0;
                        });

                        const hasAdjustmentsNotActioned = _.some(person.adjustments, (adjustment) => {
                            return _.some(adjustment.payments, (payment) => {
                                return payment.action == ReconciliationAdjustmentAction.None || payment.action == 0;
                            })
                        });

                        const hasActualCarriedOverNotActioned = _.some(person.actualCarriedOver, (adjustment) => {
                            return _.some(adjustment.payments, (payment) => {
                                return payment.action == ReconciliationAdjustmentAction.None || payment.action == 0;
                            });
                        });

                        return hasActualNotActioned || hasAdjustmentsNotActioned || hasActualCarriedOverNotActioned;
                    }
                });

                return !allRespiteLinesNotActioned;
            }
            else {
                const allPermanentLinesNotActioned = _.some(this.varianceStatementPayments.permanentPaymentVariance, (person) => {
                    if (medicareStatementType == MedicareStatementType.Expected) {
                        const hasExpectedNotActioned = _.some(person.expectedPaymentsVariance, (payment) => {
                            return payment.action == ReconciliationAdjustmentAction.None || payment.action == 0;
                        });

                        const hasExpectedCarriedOverNotActioned = _.some(person.expectedCarriedOver, (adjustment) => {
                            return _.some(adjustment.payments, (payment) => {
                                return payment.action == ReconciliationAdjustmentAction.None || payment.action == 0;
                            });
                        });

                        return hasExpectedNotActioned || hasExpectedCarriedOverNotActioned;
                    }
                    else {
                        const hasActualNotActioned = _.some(person.actualPaymentsVariance, (payment) => {
                            return payment.action == ReconciliationAdjustmentAction.None || payment.action == 0;
                        });

                        const hasAdjustmentsNotActioned = _.some(person.adjustments, (adjustment) => {
                            return _.some(adjustment.payments, (payment) => {
                                return payment.action == ReconciliationAdjustmentAction.None || payment.action == 0;
                            })
                        });

                        const hasActualCarriedOverNotActioned = _.some(person.actualCarriedOver, (adjustment) => {
                            return _.some(adjustment.payments, (payment) => {
                                return payment.action == ReconciliationAdjustmentAction.None || payment.action == 0;
                            });
                        });

                        return hasActualNotActioned || hasAdjustmentsNotActioned || hasActualCarriedOverNotActioned;
                    }
                });

                return !allPermanentLinesNotActioned;
            }
        }

        private areAllLinesActionedInStatementTypeForResident = (medicareStatementType: MedicareStatementType, careType: CareType, crid: string) : boolean => {
            if (careType == CareType.Respite) {
                const respitePerson = _.find(this.varianceStatementPayments.respitePaymentVariance, (person) => {
                    return person.careRecipientId == crid;
                });

                if (medicareStatementType == MedicareStatementType.Expected) {
                    const hasExpectedNotActioned = _.some(respitePerson.expectedPaymentsVariance, (payment) => {
                        return (payment.action == ReconciliationAdjustmentAction.None || payment.action == 0)
                            && !payment.restrictFromAction;
                    });

                    const hasExpectedCarriedOverNotActioned = _.some(respitePerson.expectedCarriedOver, (adjustment) => {
                        return _.some(adjustment.payments, (payment) => {
                            return (payment.action == ReconciliationAdjustmentAction.None || payment.action == 0)
                                && !payment.restrictFromAction;
                        });
                    });

                    return !hasExpectedNotActioned && !hasExpectedCarriedOverNotActioned;
                }
                else {
                    const hasActualNotActioned = _.some(respitePerson.actualPaymentsVariance, (payment) => {
                        return (payment.action == ReconciliationAdjustmentAction.None || payment.action == 0)
                            && !payment.restrictFromAction;
                    });

                    const hasAdjustmentsNotActioned = _.some(respitePerson.adjustments, (adjustment) => {
                        return _.some(adjustment.payments, (payment) => {
                            return (payment.action == ReconciliationAdjustmentAction.None || payment.action == 0)
                                && !payment.restrictFromAction;
                        })
                    });

                    const hasActualCarriedOverNotActioned = _.some(respitePerson.actualCarriedOver, (adjustment) => {
                        return _.some(adjustment.payments, (payment) => {
                            return (payment.action == ReconciliationAdjustmentAction.None || payment.action == 0)
                                && !payment.restrictFromAction;
                        });
                    });

                    return !hasActualNotActioned && !hasAdjustmentsNotActioned && !hasActualCarriedOverNotActioned;
                }
            }
            else {
                const permanentPerson = _.find(this.varianceStatementPayments.permanentPaymentVariance, (person) => {
                    return person.careRecipientId == crid;
                });

                if (medicareStatementType == MedicareStatementType.Expected) {
                    const hasExpectedNotActioned = _.some(permanentPerson.expectedPaymentsVariance, (payment) => {
                        return (payment.action == ReconciliationAdjustmentAction.None || payment.action == 0)
                            && !payment.restrictFromAction;
                    });

                    const hasExpectedCarriedOverNotActioned = _.some(permanentPerson.expectedCarriedOver, (adjustment) => {
                        return _.some(adjustment.payments, (payment) => {
                            return (payment.action == ReconciliationAdjustmentAction.None || payment.action == 0)
                                && !payment.restrictFromAction;
                        });
                    });

                    return !hasExpectedNotActioned && !hasExpectedCarriedOverNotActioned;
                }
                else {
                    const hasActualNotActioned = _.some(permanentPerson.actualPaymentsVariance, (payment) => {
                        return (payment.action == ReconciliationAdjustmentAction.None || payment.action == 0)
                            && !payment.restrictFromAction;
                    });

                    const hasAdjustmentsNotActioned = _.some(permanentPerson.adjustments, (adjustment) => {
                        return _.some(adjustment.payments, (payment) => {
                            return (payment.action == ReconciliationAdjustmentAction.None || payment.action == 0)
                                && !payment.restrictFromAction;
                        })
                    });

                    const hasActualCarriedOverNotActioned = _.some(permanentPerson.actualCarriedOver, (adjustment) => {
                        return _.some(adjustment.payments, (payment) => {
                            return (payment.action == ReconciliationAdjustmentAction.None || payment.action == 0)
                                && !payment.restrictFromAction;
                        });
                    });

                    return !hasActualNotActioned && !hasAdjustmentsNotActioned && !hasActualCarriedOverNotActioned;
                }
            }
        }

        private setButtons = () => {
            this.finaliseButtonDisabled = !this.areAllLinesActioned();

            if (this.varianceStatementPayments.isReconciliationFinalised || this.selectedAdjustments.length == 0) {
                this.setButtonState(true, true, true, true, false);
                return;
            }

            if (this.isAlreadyActionedLineSelected()) {
                this.setButtonState(true, true, true, true, true);
                return;
            }

            if (this.isSideSelected(MedicareStatementType.Expected) && this.isSideSelected(MedicareStatementType.Actual)) {
                this.setButtonState(true, false, true, false, false);
                return;
            }

            if (this.isSideSelected(MedicareStatementType.Expected) && !this.isSideSelected(MedicareStatementType.Actual)) {
                this.setButtonState(true, true, false, false, false);
                return;
            }

            if (!this.isSideSelected(MedicareStatementType.Expected) && this.isSideSelected(MedicareStatementType.Actual)) {
                this.setButtonState(false, true, true, false, false);
            }
        }

        private setButtonState = (acceptButtonDisabled: boolean, matchButtonDisabled: boolean, writeOffButtonDisabled: boolean, carryOverButtonDisabled: boolean, undoButtonEnabled: boolean, ) => {
            this.acceptButtonDisabled = acceptButtonDisabled;
            this.matchButtonDisabled = matchButtonDisabled;
            this.writeOffButtonDisabled = writeOffButtonDisabled;
            this.carryOverButtonDisabled = carryOverButtonDisabled;
            this.undoButtonEnabled = undoButtonEnabled;
        }

        private markTheProcessedAction = (action: ReconciliationAdjustmentAction) => {
            const allRespites = _.filter(this.selectedAdjustments, (adjustment) => {
                return adjustment.careType == CareType.Respite;
            });

            const allPermanents = _.filter(this.selectedAdjustments, (adjustment) => {
                return adjustment.careType == CareType.Permanent;
            });

            _.each(allRespites, (adjustment) => {
                let isActual = false;
                const person = _.filter(this.varianceStatementPayments.respitePaymentVariance, (person) => {
                    return person.careRecipientId == adjustment.careRecipientId
                        && person.firstName == adjustment.firstName
                        && person.lastName == adjustment.lastName;
                });

                if (person.length > 0) {
                    let payments: PaymentLine[];
                    if (adjustment.medicareStatementType == MedicareStatementType.Actual) {
                        isActual = true;
                        if (adjustment.originalAdjustmentMonth != null) {
                            let adjustmentPeriod: RespiteAdjustment;
                            if (adjustment.isCarriedOver) {
                                adjustmentPeriod = _.find(person[0].actualCarriedOver, (adj) => {
                                    return adj.adjustmentMonth == adjustment.originalAdjustmentMonth;
                                });
                            }
                            else {
                                adjustmentPeriod = _.find(person[0].adjustments, (adj) => {
                                    return adj.adjustmentMonth == adjustment.originalAdjustmentMonth;
                                });
                            }

                            payments = adjustmentPeriod.payments;
                        }
                        else {
                            payments = person[0].actualPaymentsVariance;
                        }
                    }
                    else {
                        if (adjustment.originalAdjustmentMonth != null) {
                            const adjustmentPeriod = _.find(person[0].expectedCarriedOver, (adj) => {
                                return adj.adjustmentMonth == adjustment.originalAdjustmentMonth;
                            });

                            payments = adjustmentPeriod.payments;
                        }
                        else {
                            payments = person[0].expectedPaymentsVariance;
                        }
                    }

                    const matchedPayments = _.filter(payments, (payment) => {
                        return payment.paymentType == adjustment.paymentType
                            && payment.paidCare == adjustment.paidCare
                            && payment.unpaidCare == adjustment.unpaidCare
                            && payment.dailyRate == adjustment.dailyRate
                            && payment.amountDue == adjustment.amountDue
                            && ((action !== ReconciliationAdjustmentAction.None && payment.action == ReconciliationAdjustmentAction.None) ||
                            (action === ReconciliationAdjustmentAction.None && payment.action !== ReconciliationAdjustmentAction.None));
                    });
                    
                    matchedPayments.forEach(payment => {
                        this.markTheAction(payment, action, isActual, adjustment);
                    });
                }
            });

            _.each(allPermanents, (adjustment) => {
                let isActual = false;
                const person = _.filter(this.varianceStatementPayments.permanentPaymentVariance, (person) => {
                    return person.careRecipientId == adjustment.careRecipientId
                        && person.firstName == adjustment.firstName
                        && person.lastName == adjustment.lastName;
                });

                if (person.length > 0) {
                    let payments: PermanentPaymentLine[];
                    if (adjustment.medicareStatementType == MedicareStatementType.Actual) {
                        isActual = true;
                        if (adjustment.originalAdjustmentMonth != null) {
                            let adjustmentPeriod: PermanentAdjustment;
                            if (adjustment.isCarriedOver) {
                                adjustmentPeriod = _.find(person[0].actualCarriedOver, (adj) => {
                                    return adj.adjustmentMonth == adjustment.originalAdjustmentMonth;
                                });
                            }
                            else {
                                adjustmentPeriod = _.find(person[0].adjustments, (adj) => {
                                    return adj.adjustmentMonth == adjustment.originalAdjustmentMonth;
                                });
                            }

                            payments = adjustmentPeriod.payments;
                        }
                        else {
                            payments = person[0].actualPaymentsVariance;
                        }
                    }
                    else {
                        if (adjustment.originalAdjustmentMonth != null) {
                            const adjustmentPeriod = _.find(person[0].expectedCarriedOver, (adj) => {
                                return adj.adjustmentMonth == adjustment.originalAdjustmentMonth;
                            });

                            payments = adjustmentPeriod.payments;
                        }
                        else {
                            payments = person[0].expectedPaymentsVariance;
                        }
                    }

                    const matchedPayments = _.filter(payments, (payment) => {
                        return payment.paymentType == adjustment.paymentType
                            && payment.paidCare == adjustment.paidCare
                            && payment.unpaidCare == adjustment.unpaidCare
                            && payment.dailyRate == adjustment.dailyRate
                            && payment.amountDue == adjustment.amountDue
                            && payment.paidLeaveSocial == adjustment.paidLeaveSocial
                            && payment.paidLeaveHospital == adjustment.paidLeaveHospital
                            && payment.paidLeaveTransition == adjustment.paidLeaveTransition;
                    });

                    matchedPayments.forEach(payment => {
                        this.markTheAction(payment, action, isActual, adjustment);
                    });
                }
            });
        };

        private markTheAction = (payment: PaymentLine, action: ReconciliationAdjustmentAction, isActual: boolean, adjustment: StatementAdjustment) => {
            if (action == ReconciliationAdjustmentAction.Match) {
                payment.action = isActual ? ReconciliationAdjustmentAction.MatchAccept : action;
                payment.groupIdentifier = adjustment.groupIdentifier;
            }
            else {
                if (action == ReconciliationAdjustmentAction.None) {
                    payment.groupIdentifier = null;
                }
                payment.action = action;
            }
            payment.markedForAction = false;
        }

        private unselectAllLines = () => {
            _.each(this.varianceStatementPayments.respitePaymentVariance, (person) => {
                person.selectAllActual = false;
                person.selectAllExpected = false;

                this.unselectLines(person.actualPaymentsVariance);
                this.unselectLines(person.expectedPaymentsVariance);

                _.each(person.adjustments, (adjustment) => {
                    this.unselectLines(adjustment.payments);
                });

                _.each(person.expectedCarriedOver, (adjustment) => {
                    this.unselectLines(adjustment.payments);
                });

                _.each(person.actualCarriedOver, (adjustment) => {
                    this.unselectLines(adjustment.payments);
                });
            });

            _.each(this.varianceStatementPayments.permanentPaymentVariance, (person) => {
                person.selectAllActual = false;
                person.selectAllExpected = false;

                this.unselectLines(person.actualPaymentsVariance);
                this.unselectLines(person.expectedPaymentsVariance);

                _.each(person.adjustments, (adjustment) => {
                    this.unselectLines(adjustment.payments);
                });

                _.each(person.expectedCarriedOver, (adjustment) => {
                    this.unselectLines(adjustment.payments);
                });

                _.each(person.actualCarriedOver, (adjustment) => {
                    this.unselectLines(adjustment.payments);
                });
            });
        }

        private setAllLinesSelectable = () => {
            _.each(this.varianceStatementPayments.respitePaymentVariance, (person) => {
                person.selectAllActual = false;
                person.selectAllExpected = false;

                this.setSelectable(person.actualPaymentsVariance);
                this.setSelectable(person.expectedPaymentsVariance);

                _.each(person.adjustments, (adjustment) => {
                    this.setSelectable(adjustment.payments);
                });

                _.each(person.expectedCarriedOver, (adjustment) => {
                    this.setSelectable(adjustment.payments);
                });

                _.each(person.actualCarriedOver, (adjustment) => {
                    this.setSelectable(adjustment.payments);
                });
            });

            _.each(this.varianceStatementPayments.permanentPaymentVariance, (person) => {
                person.selectAllActual = false;
                person.selectAllExpected = false;

                this.setSelectable(person.actualPaymentsVariance);
                this.setSelectable(person.expectedPaymentsVariance);

                _.each(person.adjustments, (adjustment) => {
                    this.setSelectable(adjustment.payments);
                });

                _.each(person.expectedCarriedOver, (adjustment) => {
                    this.setSelectable(adjustment.payments);
                });

                _.each(person.actualCarriedOver, (adjustment) => {
                    this.setSelectable(adjustment.payments);
                });
            });
        }

        private setSelectable = (payments: PaymentLine[]) => {
            _.each(payments, (payment) => {
                payment.restrictFromAction = false;
            });
        }

        private unselectLines = (payments: PaymentLine[]) => {
            _.each(payments, (payment) => {
                payment.markedForAction = false;
            });
        }

        public exportStatement = () => {
            this.reportingService.getVarianceStatementExcelStream(this.monthAndYear, this.selectedFilter.value).then((response) => {
                this.downloadFileService.downloadFile(response, 'VarianceStatement.xlsx');
            });
        }

        public isNOTExcluded(payment:any)
        {
            let paymentType = payment.paymentType.toString();
            return paymentType !== ExpectedStatementPaymentTypes.AnACC &&
                   paymentType !== ExpectedStatementPaymentTypes.FacilityTotalApprovedBedsPayment
        }
    }
}
export = VarianceStatementPaymentComponent;