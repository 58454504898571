const _ = require("underscore");

module.exports = ["data.service", "dialog.service", "$rootScope", "notification.service", "$q", function (dataService, dialogService, $rootScope, notification, $q) {

    function getAssessmentOtherEntry(entries) {
        return _.find(entries, function (entry) {
            return entry.activity === "Other";
        });
    }

    function getAccr(residentId) {
        return dataService.get("accr/" + residentId).then(function (result) {
            var accrDetails = {};
            accrDetails.accr = result;
            //expose assistance 'Other' row to be used in ui
            accrDetails.assistanceOtherEntry = getAssessmentOtherEntry(accrDetails.accr.assistanceSummaryEntries);
            //filter out assistance 'Other' row to not be included in the table
            if (accrDetails.assistanceOtherEntry) {
                accrDetails.accr.assistanceSummaryEntries = _.filter(accrDetails.accr.assistanceSummaryEntries, function (entry) {
                    return entry.activity !== accrDetails.assistanceOtherEntry.activity;
                });
            }
            return accrDetails;
        }, function (errorResult) {
            notification.error("Unable to open ACCR. Either your computer is offline, or Resident Manager is having problems of its own.");
            return $q.reject(errorResult);
        });
    };

    function updateFromAccr(residentId) {
        return dataService.put("residents/UpdateFromAccr/" + residentId);
    };

    function showNoAccrWarning(errorMessage, closeCallback) {
        dialogService.openMessageDialog("Unable to get ACCR from Medicare", errorMessage, "no-accr-modal");

        var modalHideDeregister;
        if (closeCallback) {
            modalHideDeregister = $rootScope.$on("resman.modal.hide", function (e, target) {
                if (target.$options.tag === "no-accr-modal") {
                    closeCallback();
                }
                modalHideDeregister();
            });
        }
    }

    return {
        getAccr: getAccr,
        updateFromAccr: updateFromAccr,
        showNoAccrWarning: showNoAccrWarning
    }
}];
