interface IAutoCompleteFreeTextControllerScope extends ng.IScope {
    model: string;
    localData: any;
    initialValue: string;
    required: boolean;
    inputName: string;
    displayText: string;
    inputClass: string;
    maxLength: number;
}

class AutoCompleteFreeTextController {
    static $inject = ["$scope"];
    public localData: any;

    constructor(private $scope: IAutoCompleteFreeTextControllerScope) {
        this.localData = $scope.localData;
    }

    public setSelected = (selected) => {
        if(selected) {
            if(selected.originalObject[this.$scope.displayText]) {
                this.$scope.model = selected.originalObject[this.$scope.displayText];
            }
            else {
                this.$scope.model = selected.originalObject;
            }
        }
        else {
            this.$scope.model = null;
        }
    }
}

export = AutoCompleteFreeTextController;