import type { IStateService } from "angular-ui-router";

interface IResidentSearchSundryRowControllerScope extends ng.IScope {
    resident: {
        id: number;
        residencyId: number;
        name: string;
        firstName: string;
        lastName: string;
        preferredName: string;
    };
}

class ResidentSearchSundryRowController {
    static $inject = ["$scope", "$state"];

    constructor(
        private $scope: IResidentSearchSundryRowControllerScope,
        private $state: IStateService
    ) {

        $scope.resident.name = $scope.resident.firstName + " " + $scope.resident.lastName;

        if ($scope.resident.preferredName) {
            $scope.resident.name += ` (${$scope.resident.preferredName})`;
        }
    }
}

export = ResidentSearchSundryRowController;
