import { MedicareStatementsByYear, VarianceStatementSummary, VarianceStatementPayments, ReconciliationAdjustmentAction, StatementAdjustment, ReconciliationAdjustmentsSummary, MedicareMonthYearByYearDto } from "./Models";

export interface IFundingRate {
    startDateUtc: Date;
    endDateUtc: Date;
    id: number;
}

export interface IFeesAndCharges {
    startDateUtc: Date;
    endDateUtc: Date;
    id: number;
    mpirRates: IRateForPeriod[];
    maximumPenaltyInterestRates: IRateForPeriod[];
}

export interface IRateForPeriod {
    startDateUtc: Date;
    endDateUtc: Date;
    id: number;
    rate: number;
}

export interface IFundingService {
    getAllAccommodationFundingRates: () => ng.IPromise<IFundingRate[]>;
    saveAccommodationFundingRate: (accommodationFundingRates: IFundingRate) => any;
    saveFeesAndCharges: (feeAndCharge: IFundingRate) => any;
    getAllFeesAndCharges: () => ng.IPromise<IFeesAndCharges[]>;
    getExpectedMedicareStatement: (monthAndYear: any) => any;
    downloadStatement: (statementId: number) => any;
    finaliseClaim: (monthAndYear: any) => any;
    getMedicareStatements: () => Promise<MedicareStatementsByYear[]>;
    getFinalisedMonths: (facilityId : number) => Promise<MedicareMonthYearByYearDto[]>;
    fetchPaymentStatement: (claimMonth: string) => Promise<void>;
    getVarianceSummary: (monthAndYear: string) => ng.IPromise<VarianceStatementSummary>;
    getVariancePayment: (monthAndYear: string) => ng.IPromise<VarianceStatementPayments>;
    actionAdjustments: (monthAndyear: string, action: ReconciliationAdjustmentAction, adjustments: StatementAdjustment[]) => ng.IPromise<any>;
    getAdjustmentSummary: (monthAndYear: String) => ng.IPromise<ReconciliationAdjustmentsSummary[]>;
    finaliseReconciliation: (monthAndYear: String) => ng.IPromise<any>;
    addVariancePaymentNote: (monthAndyear: string, note: string, lineItem: StatementAdjustment, isCarringOver: boolean) => ng.IPromise<any>;
}

export interface IDialogService {
    openMessageDialog: (title: string, message: string, tag: string, innserMessage: any) => void
}