"use strict";
const angular = require("angular");
const _ = require("underscore");

module.exports = [
    "modalConfig",

    function (modalConfig) {
        var vm = this;
        angular.extend(vm, modalConfig);
    }
];