import { ResidentFinanceFeesView, ResidentTransactionsFilter, Resident, ResidentSimpleDto, UpdateResident, SearchResult, TransferResidentDto, EventHistory } from '../Models';
import { IncomeAssetStatus } from '../../leads/Models';

export interface IResidentsService {
    getTimelineForResident: (number) => ng.IPromise<any>;
    markDepartedResidentAsEnquiry(residentId: number, markDepartedResidentEnquiry: any): ng.IPromise<any>;
    getResidentNotes(residentId: number, notIncludingItemsAfter: string, skip: number, take: number): ng.IPromise<any>;
    toggleResidentNotesPinStatus(residentNoteId: number, isNotePinned: boolean);
    addResidentNote(residentId: number, note: string): ng.IPromise<any>;
    getLegalNoticeDateInformation(residencyId: number): ng.IPromise<any>;
    saveNoticeDate(residencyId: number, noticeDateUtc: Date);
    getResidentFinanceFees: (residentId: number) => ng.IPromise<ResidentFinanceFeesView>;
    getResidentFinanceTransactions: (filter: ResidentTransactionsFilter,
        notIncludingItemsAfter: string,
        skip: number,
        take: number) => ng.IPromise<any>;
    getResidentFinanceTransactionsBalance: (residentId: number) => ng.IPromise<number>;
    updateResident: (updateResident: UpdateResident) => ng.IPromise<any>;
    getResident: (residentId: number) => ng.IPromise<Resident>;
    getResidentSimple: (residentId: number) => ng.IPromise<ResidentSimpleDto>;
    searchCurrentAndDepartedResidents: (searchString: string, httpCancelerPromise: number) => ng.IPromise<SearchResult[]>;
    transferResident: (residentId: number, transferDto: TransferResidentDto) => ng.IPromise<any>;
    getIfResidentHasOpenOptionalServices: (residentId: number) => ng.IPromise<boolean>;
    getResidentFinanceFunding: (residentId: number) => ng.IPromise<any>;
    saveLeadAnaccClassification: (residencyId: number, anaccClass: string) => ng.IPromise<any>;
    updateContractSigned: (residencyId: number, isContractSigned: boolean) => ng.IPromise<any>;
    updateResidentDeceased: (residentId: number, isDeceased: boolean) => ng.IPromise<any>;
    updateIncomeAssetStatus: (residencyId: number, status: IncomeAssetStatus) => ng.IPromise<any>;
    getEventHistory : (residentId: number) => ng.IPromise<EventHistory>;
}
