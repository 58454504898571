export class AuthenticatedUser {
    public name: string;
}

export class FacilityDto {
    public id: number;
    public name: string;
    public facilityType: string;
    public isBillingIntegratedFacility: boolean;
    public facilityMMMClassification: number;
    public facilityRegisteredBeds: number;
}

export class SystemConfigurationsDto {
    public sendPensionerStatusOnAdmission: boolean;
    public enablePrivateResidents: boolean;
    public disableSendingExtraServiceToMedicare: boolean;
}

export class CurrentFacilityFeatureFlagsDto {
    public sendAdjustmentFundingJournalToTechone: boolean;
    public sendExpectedFundingJournalToTechone: boolean;
}

export class FeatureFlagsDto {
    public systemConfigurationFlags: SystemConfigurationsDto;
    public currentFacilityFeatureFlags: CurrentFacilityFeatureFlagsDto;
}
