import * as moment from 'moment';
import { IReportingService } from '../../reporting/services/IReportingService';
import { IDownloadFileService } from '../../common/services/IDownloadFileService';

class ActualStatementPaymentComponent implements ng.IComponentOptions {
    public bindings: any;
    public templateUrl: string = 'app/funding/partials/viewStatementPayments.html';

    constructor() {
        this.bindings = {
            monthAndYear: '=',
            statement: '='
        };
    }

    public controller: any = class VarianceStatementComponentController {
        static $inject = ["reporting.service", "downloadFile.service"];

        public statement: any;
        public monthAndYear: string;
        public period: string;

        constructor(
            private readonly reportingService: IReportingService,
            private readonly downloadFileService : IDownloadFileService) {

                this.period = moment(this.monthAndYear, "MYYYY").format("MMMM YYYY");  
        }

        public exportToExcel = () => {
            this.reportingService.getActualPaymentsReportExcelStream(this.monthAndYear).then((response) => {
                this.downloadFileService.downloadFile(response, 'ActualFundingPaymentsReport.xlsx');
            });
        }
    }
}
export = ActualStatementPaymentComponent;