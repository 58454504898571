var moment = require("moment");

module.exports = [
    function() {
        var serverDateFormat = "YYYY/MM/DD";
        var emptyStringReturn = "";

        function snapToClosestUTCMidnight(date) {

            // sometimes the date isn't exactly midnight
            // specifically, because when some code performs "epoch shifting"
            // where they add/subtract offsets from a local time to get the UTC time, which is buggy around DST boundaries
            
            // this code "snaps" the date to the closest instance of UTC midnight by zeroing out the hour, minute and second

            var useMoment = moment.isMoment(date);
            var useDate = useMoment ? date.utc() : moment(date).utc();
            var year = useDate.year();
            var month = useDate.month();
            var day = useDate.date();
            var hour = useDate.hour();
            var minute = useDate.minute();
            var second = useDate.second();
            var millisecond = useDate.millisecond();

            if (hour === 0 && minute === 0 && second === 0 && millisecond === 0) {
                return date;
            }
            if (hour >= 12) {
                day = day + 1;
            }

            useDate = moment().utc().year(year).month(month).date(day).hour(0).minute(0).second(0).millisecond(0);

            return useMoment ? useDate : useDate.toDate();
        }

        function getYearsFromDate(date) {

            if (date) {
                return moment().diff(moment(date, serverDateFormat), "years");
            }

            return emptyStringReturn;
        }

        function getFormattedDate(date) {

            if (date) {
                return moment(date, serverDateFormat).format("DD MMM YYYY");
            }
            return emptyStringReturn;
        }

        return {
            snapToClosestUTCMidnight: snapToClosestUTCMidnight,
            getYearsFromDate: getYearsFromDate,
            getFormattedDate: getFormattedDate
        }
    }
];