"use strict";
var angular = require("angular");

var module = angular.module("organisation.module", ["common.module"]);
require("./states.js").configure(module);

module.controller("viewFacilities.controller", require("./controllers/viewFacilities.controller"));
module.controller("viewAndEditUsers.controller", require("./controllers/viewAndEditUsers.controller"));
module.controller("viewAndEditAccommodationTypes.controller", require("./controllers/viewAndEditAccommodationTypes.controller"));
module.controller("getAccommodationTypePriceHistoryDialog.controller", require("./controllers/getAccommodationTypePriceHistoryDialog.controller"));
module.controller("viewAndEditAccommodations.controller", require("./controllers/viewAndEditAccommodations.controller"));
module.controller("viewAndEditRoomDetails.controller", require("./controllers/viewAndEditRoomDetails.controller"));
module.controller("editBedStatusDialog.controller", require("./controllers/editBedStatusDialog.controller"));
module.factory("organisation.facility.service", require("./services/facility.service"));
module.factory("organisation.users.service", require("./services/users.service"));
module.factory("organisation.rooms.service", require("./services/rooms.service"));
module.directive("focusOnTrigger", require("./directives/focusOnTrigger.directive"));
module.directive("validateAccount", require("./directives/validateAccount.directive"));

//Converted to TypeScript
module.controller("viewAndEditGLAccountMapping.controller", require("./controllers/ViewAndEditGLAccountMappingController"));
module.controller("viewAndEditOptionalServices.controller", require("./controllers/viewAndEditOptionalServicesController"));
module.controller("viewAndEditUnitDialog.controller", require("./controllers/ViewAndEditUnitDialogController"));