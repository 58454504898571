function autoCompleteFreeTextDirective(): ng.IDirective {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            model: "=model",
            localData: "=",
            initialValue: "=",
            required: "=",
            inputName: "@",
            displayText: "@",
            inputClass:"@",
            maxLength:"@"
        },
        controller: "autoCompleteFreeText.controller",
        controllerAs: "vm",
        templateUrl: "app/common/directives/partials/autoCompleteFreeText.html"
    };
}

export = autoCompleteFreeTextDirective;