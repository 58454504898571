"use strict";

var moment = require("moment");

module.exports = [
    "residents.service",
    "notification.service",
    "infiniteLoader.service",
    function (residentsService, notificationService, infiniteLoaderService) {
        var vm = this;
        vm.residents = [];

        vm.infiniteLoader = infiniteLoaderService.instance(residentsService.getDepartedResidents,
            function (result) {
                vm.residents = vm.residents.concat(result);
            },
            function () {
                notificationService.error("Unexpected error occurred while loading departed residents to view.");
            });
    }
];