import * as _ from "underscore";
import { INotificationService } from '../../common/services/INotificationService';
import {IRoomsService} from '../services/IRoomsservice';
import {
    IluStatus, 
    ViewOrEditAccommodationDto, 
    UnitOccupantDTO,
    AccommodationDto, 
    LocationWithAccommodationsDto, 
    AccommodationTypeDto, 
    AccommodationFeatureDto, 
    EditAccommodationDto } from '../Models';
import type { IStateService } from "angular-ui-router";


interface IViewOrEditDialogConfig {
    unitId: number;
    units: LocationWithAccommodationsDto[];
    unitTypes: AccommodationTypeDto[];
    callBack: () => void;
}

class IluStatusItem {
    id: string;
    name: string;
}

class ViewAndEditUnitDialogController {

    static $inject = ["organisation.rooms.service",
    "notification.service",
    "modalConfig",
    "$state",
    "$scope",
    "$window"];

    public $hide: any;
    public unitFeatures: AccommodationFeatureDto[] = [];
    public statusDict: {[id: string]: string} = {};
    public statusList: IluStatusItem[] = [];
    public unit: ViewOrEditAccommodationDto;
    public selectedUnitType: AccommodationTypeDto;
    public viewAndEditUnitForm: ng.IFormController;

    constructor( private roomsService: IRoomsService,
        private notificationService: INotificationService,
        private modalConfig: IViewOrEditDialogConfig,
        private $state: IStateService,
        private $scope: ng.IScope,
        private $window: ng.IWindowService)
    {
        this.initialize();   
    }  

    public initialize = () => {
        Object.keys(IluStatus).map(key => {
            let statusItem: IluStatusItem = {id: key, name: IluStatus[key]};
            this.statusList.push(statusItem);
            this.statusDict[key] = IluStatus[key];
        });

        let emptyFacilityLocationUnit:LocationWithAccommodationsDto = _.findWhere(this.modalConfig.units, { id: null });
        if (emptyFacilityLocationUnit) {
            emptyFacilityLocationUnit.id = 0;
        }
        
        this.roomsService.getRoom(this.modalConfig.unitId)
            .then(result => {
                this.unit = result;                    
                this.selectedUnitType = _.findWhere(this.modalConfig.unitTypes, { id: this.unit.accommodationTypeId });
                let facilityLocationId: any = this.unit.facilityLocationId != null ? this.unit.facilityLocationId : 0;
                this.unit.facilityLocation = _.findWhere(this.modalConfig.units, { id: facilityLocationId });
            }, () => {
                    this.notificationService.error("Unexpected error occurred while loading unit.");
            });
        this.roomsService.getRoomFeatures()
            .then(result => {
                this.unitFeatures = result;
            }, () => {
                    this.notificationService.error("Unexpected error occurred while loading unit features.");
            });
    }

    public bindHide = (hide) => {
        this.$hide = hide;
    };
        
    public addFeature = (text: string) => {
        this.$scope.$apply(() => {
            let feature: AccommodationFeatureDto = { name: text, id: "new_" + text };
            this.unitFeatures.push(feature);
            this.unit.features.push(feature);
        });
    };

    public delete =  (id: number) => {
        if(this.unit.iluStatus == 'Occupied')
            this.notificationService.error("Occupied units cannot be deleted.");
        else 
        {
            if (this.unit.occupants.length == 0) {
                this.roomsService.deleteRoom(id)
                    .then(() => {
                        this.$hide();
                        this.$state.reload();
                    }, (error) => {
                            if(!error.modelStateSummary)
                            {
                                this.notificationService.error("Error occurred while deleting unit");
                                
                            }
                            else 
                            {
                                this.notificationService.error(error.modelStateSummary.id);
                            }
                    });
            } else {
                this.notificationService.error("Units with residents assigned to them can't be deleted.");
            }
        }
    }

    private allowClosingForm = (unsavedChanges: any) => {
        if (unsavedChanges) {
            return this.$window.confirm("Do you want to leave this page? Changes you made may not be saved.");
        } else {
            return true;
        }
    }; 

    public cancel = () =>  {
        let unsavedChanges = this.viewAndEditUnitForm.$dirty;
        if (this.allowClosingForm(unsavedChanges)) {
            this.$hide();
        }
    };

    public isUnitNumberDuplicate = (unitNumber: string) => {
        if (unitNumber) {
            let existingUnitObj = _.find(this.unit.facilityLocation.accommodations,
                (unit: AccommodationDto) => {
                    return unit.accommodationId !== this.unit.id && unit.roomNumber === this.unit.number;
                });

            if (existingUnitObj) {
                return true;
            }
        }
        return false;
    }

    public saveUnit = (isFormValid: boolean) => {
        if (isFormValid && !this.isUnitNumberDuplicate(this.unit.number)) {
            let unitToPost: EditAccommodationDto = {
                id: this.unit.id,
                accommodationTypeId: this.selectedUnitType.id,
                number: this.unit.number,
                facilityLocationId: this.unit.facilityLocation.id == 0 ? null : this.unit.facilityLocation.id,
                iluStatus: this.unit.iluStatus,
                features: this.unit.features
            };

            this.roomsService.editRoom(unitToPost)
                .then(() => {
                   this.modalConfig.callBack();
                   this.$hide();
                }, () => {
                        this.notificationService.error("Unexpected error occurred while saving unit.");
                });
        }
    };
}

export = ViewAndEditUnitDialogController;

