import * as _ from "underscore"; 
import moment = require('moment');
import { IValueWithEffectiveDate } from '../interfaces'

class ValueWithEffectiveDateController {
    static $inject = ["$scope", "modalConfig"];

    public header: string;
    public data: IValueWithEffectiveDate[];
    public inputType: number;
    public amount: number;
    public startDate: Date;
    public valuesWithEffectiveDateForm: ng.IFormController;
    public isMandatory: boolean;
    public showNewRow = true;
    private outputData: IValueWithEffectiveDate[];
    private percentageInputType = 1;
    private integerInputType = 2;
    private currencyInputType = 3;

    constructor(
        private $scope: ng.IScope,
        private modalConfig: any
    ) {
        this.data = <IValueWithEffectiveDate[]>[];
        this.outputData = <IValueWithEffectiveDate[]>[];

        for (let val of modalConfig.data) {
            let parsedValue = modalConfig.inputType == this.percentageInputType  || modalConfig.inputType == this.currencyInputType
                ? parseFloat(val.value)
                : parseInt(val.value)
            let target = <IValueWithEffectiveDate>{
                id: val.id,
                value: parsedValue,
                startDate: moment(val.startDate).toDate(),
            }
            if (val.hasOwnProperty('isEditable')) {
                target['isEditable'] = val.isEditable; // for some reason, "target.isEditable" trips up the compiler even though it's a member of IValueWithEffectiveDate
            }
            this.data.push(target);
        }

        this.header = modalConfig.header;
        this.inputType = parseInt(modalConfig.inputType);
        this.isMandatory = modalConfig.isMandatory;
    }

    public removeRow = (index: number) => {
        this.data.splice(index, 1);
    }

    public removeNewRow() {
        this.startDate = null;
        this.amount = null;
        this.showNewRow = false; 
    }

    public save = (isFormValid: boolean) => {
        if (!isFormValid)
            return;

        if (this.amount != null && this.startDate) {
            this.outputData.push(<IValueWithEffectiveDate>{
                id: 0,
                value: this.amount,
                startDate: moment(this.startDate).toDate()
            });
        }

        for (let val of this.data) {
            this.outputData.push(<IValueWithEffectiveDate>{
                id: val.id,
                value: val.value,
                startDate: moment(val.startDate).toDate()
            });
        }

        var sortedList = _.sortBy(this.outputData, "startDate").reverse();
        this.modalConfig.callback(sortedList, this.modalConfig.callbackData);
        (<any>this.$scope).$hide();
    }

    public isEditable(val) {
        return !val.hasOwnProperty('isEditable') || val.isEditable;
    }

    private isInvalidStartDate = (startDate, index, ctrl): boolean => {
        //clear all startDate error messages
        let startDateCtrls = _.filter(<any>this.valuesWithEffectiveDateForm,
            (item) => {
                if (item && typeof item == "object" && (<any>item).$name) {
                    let name = (<string>(<any>item).$name);
                    if (name.substr(0, 9) === 'startDate') {
                        return true;
                    }
                }
                return false;
            });

        _.forEach(startDateCtrls,
            (value) => {
                this.valuesWithEffectiveDateForm[(<any>value).$name].$setValidity('startDate', true);
            });
             
        if(index != -1 && moment.utc(startDate).format() == moment.utc(this.startDate).format()) {
            this.valuesWithEffectiveDateForm[ctrl].$setValidity('startDate', false);
            return true;
        }

        for(let i=0; i < this.data.length; i++){
            if(index == i)
                continue;

            let value = this.data[i];
            if(moment.utc(startDate).format() == moment.utc(value.startDate).format()){
                this.valuesWithEffectiveDateForm[ctrl].$setValidity('startDate', false);
                return true;
            }
        }

        this.valuesWithEffectiveDateForm[ctrl].$setValidity('startDate', true);
        return false;
    }
}

export = ValueWithEffectiveDateController;