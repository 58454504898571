"use strict";

module.exports = ["$rootScope", "$window", "$log", "$location", "$state",
  function ($rootScope, $window, $log, $location, $state) {
    return {
      restrict: "A",
      require: ["form"],
      link: function ($scope, element, $attrs, formCtrls) {

        var prompt = "Do you want to leave this page? Changes you made may not be saved.";
        var form = formCtrls[0];
        var newUrl = null;
        var disableNavigationPromptForSamePage = $scope.$eval($attrs.disableNavigationPromptForSamePage);
        
        function isDirty() {
          return form.$dirty && (form.$submitted === false || form.$valid === false);
        }

        // fires when someone for example click on browser refresh, close tape or close window buttons
        function onBeforeUnload(e) {
          if (isDirty()) {
            e.returnValue = prompt;
            return prompt;
          }
          return undefined;
        }

        // fires when someone for example click on browser back, forward buttons
        function onlocationChanging(event) {
          if (isDirty()) {
            newUrl = $location.absUrl();
            if (disableNavigationPromptForSamePage && $location.$$hash != "" && newUrl.includes($location.$$hash)) 
            {
              return;
            }
            if (!event.defaultPrevented && !$window.confirm(prompt)) {
              event.preventDefault();
            }
          }
        }

        // fires when angular ui router state is about to change
        function onStateChanging(event, toState, toParams) {
          if (isDirty()) {
            if (newUrl && newUrl === $state.href(toState.name, toParams, { absolute: true })) {
              // this means a call to onStateChanging was made right after a call to onlocationChanging. User have been already prompted with the warning, so we just continue.
              newUrl = null;
              return;
            }
            if (!event.defaultPrevented && !$window.confirm(prompt)) {

              event.preventDefault();
            }
          }
          newUrl = null;
        }

        $window.addEventListener("beforeunload", onBeforeUnload);
        var stateChangeStartDeregisterer = $rootScope.$on("$stateChangeStart", onStateChanging);
        var locationChangeStartDeregisterer = $rootScope.$on("$locationChangeStart", onlocationChanging);

        element.on("$destroy", function () {
          $window.removeEventListener("beforeunload", onBeforeUnload);
          stateChangeStartDeregisterer();
          locationChangeStartDeregisterer();
        });
      }
    };
  }]