import { ILeadsService } from "../services/ILeadsService";
import { IResidentsService } from "../../residents/services/IResidentsService";
import { INotificationService } from "../../common/services/INotificationService";
import { IDialogService } from "../../common/services/IDialogService";
import { LeadCardDto } from "../Models";
import _ = require("underscore");

class ArchivedLeadsComponent implements ng.IComponentOptions {
    public templateUrl: string = 'app/leads/partials/archivedLeads.html';

    public controller: any = class ArchivedLeadsComponentController {
        static $inject = ["leads.service", "residents.service", "notification.service", "dialog.service"];

        public archivedLeads: LeadCardDto[];
        public totalCount: number = 0;

        constructor(private readonly leadsService: ILeadsService,
            private readonly residentsService: IResidentsService,
            private readonly notificationService:INotificationService,
            private readonly dialogService: IDialogService) {
                this.loadArchiveLeads();
        }

        private loadArchiveLeads = () => {
            this.leadsService.getArchivedLeads().then((response) => {
                this.archivedLeads = response;
                this.totalCount = response.length;
            }, (error) => {
                this.notificationService.error("Unexpected error while loading archived leads.");
            })
        };

        public sendToLeads =(residenId: number) => {
            this.leadsService.sendToLeads(residenId).then(() => {
                this.notificationService.success("Archived lead restored.");
                this.totalCount = this.totalCount - 1;
                this.archivedLeads.splice(_.findIndex(this.archivedLeads, (card)=> {return card.residentId == residenId}), 1);
            }, () => {
                this.notificationService.error("Unexpected error occurred while deleting a lead record.");
            });
        }

        public markAsDeceased = (residentId: number) => {
            this.residentsService.updateResidentDeceased(residentId, true).then(result => {
                let cardIndex = _.findIndex(this.archivedLeads, (card) => { return card.residentId == residentId});
                this.archivedLeads[cardIndex].isDeceased = true;
                this.notificationService.success("Lead is marked as deceased successfully!");
            },
            () => {
                this.notificationService.error("Unexpected error occurred while marking lead as deceased.");
            });
        }

        public deleteCard = (residentId: number) => {
            const leadCard = _.find(this.archivedLeads, (leadCard) => {
                return leadCard.residentId == residentId;
            });

            let title = 'Delete enquiry';
            let confirmation = `Are you sure you want to delete the record for ${leadCard.firstName} ${leadCard.lastName.toUpperCase()}?`;
            let buttonText = "Delete";
            let buttonStyle = "btn-danger btn-inline-block-xsm";
            
            this.dialogService.openActionDialog(title, confirmation, "lead-delete", buttonText,
                () => {
                    this.leadsService.deleteLead(residentId).then(() => {
                        this.notificationService.success("Lead deleted.");
                        this.totalCount = this.totalCount - 1;
                        this.archivedLeads.splice(_.findIndex(this.archivedLeads, (card)=> {return card.residentId == residentId}), 1);
                    }, () => {
                        this.notificationService.error("Unexpected error occurred while deleting lead.");
                    });
                },
                buttonStyle);
        }
    }
}

export = ArchivedLeadsComponent;