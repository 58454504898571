"use strict";

module.exports = function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "url": "=",
            "callback": "="
        },
        link: function ($scope, element) {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.onload = function () {
                $scope.callback();
            };
            script.src = $scope.url;
            document.getElementsByTagName('head')[0].appendChild(script);
        }
    };
}
