import * as _ from "underscore";
import moment = require('moment');
import { ResidentFinanceFees, Resident, ResidentContact, ResidentFinanceEffectiveDate } from '../Models';
import { IResidentsService } from '../services/IResidentsService';
import type { IStateService } from "angular-ui-router";

interface IAddEditFeeEffectiveDatesDialogConfig {
  effectiveDateType: string;
  effectiveDates: ResidentFinanceEffectiveDate[];
  callBack: any;
  isAdmitted: boolean;
  admissionDate: Date;
  isEmptyCollectionValid : boolean;
  isFirstValueBasedOnAdmissionDate : boolean;
  isIncludeResidentName: boolean;
  residentName : string;
}

enum EffectiveDateInvalidReason {
  None = 0,
  NonUnique = 1,
  MultipleAdmissionDate = 2
}

class EffectiveDateItem {

  date: Date;
  value: any;
  isValid: boolean;
  invalidReason: EffectiveDateInvalidReason;
  isEditable: boolean;

  constructor(date: Date) {
    this.date = date ? moment(date).toDate() : null;
    this.value = null;
    this.isValid = true;
    this.isEditable = true;
    this.invalidReason = EffectiveDateInvalidReason.None;
  }
}

abstract class EffectiveDatesDialog {
  config: IAddEditFeeEffectiveDatesDialogConfig;
  heading: string;
  label: string;
  dateLabel: string;
  minAmount: number;
  maxPaymentDate: string;
  minPaymentDate: Date;
  type: string;
  items: EffectiveDateItem[];
  admissionDateItem: EffectiveDateItem;
  residentName : string;
  protected constructor(
    config: IAddEditFeeEffectiveDatesDialogConfig, valueType: string   
  ) {
    this.config = config;
    this.type = valueType;

    this.setItems(config.effectiveDates);

    if (this.config.isAdmitted) {
      this.addItem();
    }
  }

  addItem() {
    this.items.unshift(new EffectiveDateItem(null));
  }

  removeItem(index) {
    this.items.splice(index, 1);
  }

  setItems(items: any[]) {
    this.items = [];
    _.each(items, item => {
      let effectiveDate = new EffectiveDateItem(item.effectiveFromDate);
      effectiveDate.value = this.getItemValue(this.type, item);
      this.items.push(effectiveDate);
    }, this);

    this.items = _.sortBy(this.items, item => item.date).reverse();

    // Set the last item as the admissionDate and make it non-editable
    if (this.config.isFirstValueBasedOnAdmissionDate && this.items.length > 0) {
      this.admissionDateItem = this.items[this.items.length - 1];
      this.admissionDateItem.isEditable = false;
    }
  }

  abstract getValue(type, item);

  abstract getItemValue(type, item);


  getValidItems(): ResidentFinanceEffectiveDate[] {
    return this
      .items
      .filter(effectiveDate => {
        return effectiveDate.date !== null && effectiveDate.value !== null;
      })
      .map(item => {
        let effectiveDate = new ResidentFinanceEffectiveDate();
        effectiveDate.effectiveDateType = this.config.effectiveDateType;
        effectiveDate.effectiveFromDate = item.date;
        effectiveDate.value = this.getValue(this.type, item);
        return effectiveDate;
      });
  }

  onDateChange(currentEffectiveDate: EffectiveDateItem): void {
    currentEffectiveDate.invalidReason = EffectiveDateInvalidReason.None;
    currentEffectiveDate.isValid = true;

    if (!currentEffectiveDate.date) {
      return;
    }

    if (this.admissionDateItem != undefined && this.items.indexOf(currentEffectiveDate) != this.items.indexOf(this.admissionDateItem)) {
      let isBeforeAdmissionDate = moment(currentEffectiveDate.date).isAfter(moment(this.admissionDateItem.date));
      if (!isBeforeAdmissionDate) {
        currentEffectiveDate.isValid = false;
        currentEffectiveDate.invalidReason = EffectiveDateInvalidReason.MultipleAdmissionDate;
      }
    }

    if (currentEffectiveDate.isValid) {
      const count = _.filter(this.items,
        item => {
          return moment(item.date).isSame(moment(currentEffectiveDate.date), 'day');
        }).length;

      if (count !== 1) {
        currentEffectiveDate.isValid = false;
        currentEffectiveDate.invalidReason = EffectiveDateInvalidReason.NonUnique;
      }

    }
  }

  hasAtLeastOneValidDate(): boolean {
    const now = moment().startOf('day').utc();
    return this
      .items
      .filter(effectiveDate => {
        return effectiveDate.date !== null && effectiveDate.value !== null && moment(effectiveDate.date).isSameOrBefore(now, 'day');
      })
      .length > 0;
    }

  allDatesAreUnique(): boolean {
    const numberOfDatesEntered = this.items.filter(item => {
      return item.date !== null && item.value !== null;
    }).length;
    const numberOfUniqueDates = this
      .items
      .filter(item => item.date !== null)
      .map(item => moment(item.date).toISOString())
      .filter((date, i, list) => list.indexOf(date) === i).length;
    return numberOfUniqueDates === numberOfDatesEntered;
  }

  hasOnlyOneEffectiveDateAsAdmissionDate(): boolean {
    const lastItemIndex = this.items.length - 1;
    let isValid = true;

    const countOfInvalidDates = this.items.filter((item, index) => {
      if (index == lastItemIndex) {
        return false;
      }
      if (item.date === null) {
        return false;
      }
      if (index < lastItemIndex) {
        return !moment(item.date).isAfter(moment(this.admissionDateItem.date));
      }
    }).length;

    return countOfInvalidDates === 0;
  }


  protected allItemsAreValid(): boolean {

    // if the effective date list is empty and pre condition is the list can be started as empty , still list is valid
    if(this.config.isEmptyCollectionValid && this.items.length == 0) 
    {
      return true;
    }
    
    return this
      .items
      .every(effectiveDate => {
        return (effectiveDate.date === null && effectiveDate.value === null) ||
          (effectiveDate.date !== null && effectiveDate.value !== null);
      }) &&
      this.hasAtLeastOneValidDate() &&
      this.allDatesAreUnique() &&
      (!this.config.isFirstValueBasedOnAdmissionDate || this.hasOnlyOneEffectiveDateAsAdmissionDate());
  }
}

class AmountEffectiveDatesDialog extends EffectiveDatesDialog {
  constructor(config: IAddEditFeeEffectiveDatesDialogConfig, dialogHeading: string, dateLabel: string, maxPaymentDate: string, minAmount: number, minPaymentDate: Date) {
    super(config, 'amount');
    this.dateLabel = dateLabel;
    this.label = 'Amount';
    this.heading = dialogHeading;
    this.maxPaymentDate = maxPaymentDate;
    this.minAmount = minAmount;
    this.minPaymentDate = minPaymentDate;
    this.residentName = config.isIncludeResidentName ? config.residentName : null;
  }

  getValue(type, item): string {
    return type === 'amount' ? String(item.value) : null;
  }

  getItemValue(type, item): number {
    return type === 'amount' ? Number(item.value) : null;
  }
}

class SupportLevelEffectiveDatesDialog extends EffectiveDatesDialog {
  constructor(config: IAddEditFeeEffectiveDatesDialogConfig) {
    super(config, 'support-level');

    this.heading = 'Support status';
    this.label = 'Support Level';
  }

  getValue(type, item): string {
    return type === 'support-level' ? item.value : null;
  }

  getItemValue(type, item): string {
    return type === 'support-level' ? item.value : null;
  }
}

class RADRACEffectiveDatesDialog extends AmountEffectiveDatesDialog {

  constructor(config: IAddEditFeeEffectiveDatesDialogConfig, dialogHeading: string, dateLabel: string, maxPaymentDate: string, minAmount: number, minPaymentDate: Date) {
    super(config, dialogHeading, dateLabel, maxPaymentDate, minAmount, minPaymentDate);
    this.dateLabel = dateLabel;
    this.label = 'Amount';
    this.heading = dialogHeading;
    this.maxPaymentDate = maxPaymentDate;
    this.minAmount = minAmount;
  }

  allItemsAreValid(): boolean {
    return super.allItemsAreValid() && this.isTotalAmountPaidGreaterThanOrEqualToZero()
  }

  isTotalAmountPaidGreaterThanOrEqualToZero(): boolean {
    var total = _.reduce(this.items, (total, item) => {
      return total + item.value;
    }, 0);
    return total >= 0;
  }
}


class AddEditFeeEffectiveDatesDialogController {
  static $inject = ["$state", "modalConfig", "residents.service", "EffectiveDateTypes", "$rootScope"];

  public dialog: EffectiveDatesDialog;
  public $hide: any;
  public isRadRacDialog: boolean;
  public isBillingIntegratedFacility;

  constructor(
    private $state: IStateService,
    private modalConfig: IAddEditFeeEffectiveDatesDialogConfig,
    private residentsService: IResidentsService,
    private EffectiveDateTypes: any,
    private $rootScope: any
  ) {
    this.isBillingIntegratedFacility = $rootScope.isBillingIntegratedFacility;
    if (modalConfig.effectiveDateType === EffectiveDateTypes.SupportLevel) {
      this.dialog = new SupportLevelEffectiveDatesDialog(modalConfig);
    }
    else if (modalConfig.effectiveDateType === EffectiveDateTypes.RadPaid ||
      modalConfig.effectiveDateType === EffectiveDateTypes.RacPaid) {
      let heading = '';
      let dateLabel = 'Payment Date';
      let minAmount = -9999999.99;
      let maxPaymentDate = "today";
      switch (modalConfig.effectiveDateType) {
        case EffectiveDateTypes.RadPaid:
          heading = 'RAD Payment';
          break;
        case EffectiveDateTypes.RacPaid:
          heading = 'RAC Payment';
          break;
        default:
          break;
      }
      this.dialog = new RADRACEffectiveDatesDialog(modalConfig, heading, dateLabel, maxPaymentDate, minAmount, modalConfig.admissionDate);
      this.isRadRacDialog = true;
    }
    else {
      let heading = '';
      let dateLabel = 'Effective Date';
      let minAmount = 0;
      let maxPaymentDate = '';
      switch (modalConfig.effectiveDateType) {
        case EffectiveDateTypes.MeansTestedCareFee:
          heading = 'Means tested care fee';
          break;
        case EffectiveDateTypes.Pre2014IncomeTestedFee:
          heading = 'Income tested care fee';
          break;
        case EffectiveDateTypes.DailyAccommodationContribution:
          heading = 'Daily accommodation contribution';
          break;
        case EffectiveDateTypes.AgreedRoomPrice:
          heading = 'Agreed room price';
          break;
        case EffectiveDateTypes.IngoingContribution:
          heading = 'Ingoing contribution';
          dateLabel = "Payment Date";
          maxPaymentDate = "today";
          break;
        default:
          break;
      }

      this.dialog = new AmountEffectiveDatesDialog(modalConfig, heading, dateLabel, maxPaymentDate, minAmount, null);

    }
  }

  public bindHide = (hide) => {
    this.$hide = hide;
  };

  public done = (isValid: boolean) => {
    if (isValid) {
      this.modalConfig.callBack(this.dialog.config.effectiveDateType, this.dialog.getValidItems());
      this.$hide();
    }
  };

  public removeItem(index) {
    this.dialog.removeItem(index);
  }
}

export = AddEditFeeEffectiveDatesDialogController;