"use strict";
const _ = require("underscore");

module.exports = [
    "roles.service",
    "notification.service",
    function (rolesService, notificationService) {
        var vm = this;
        vm.readOnlyRoles = [];
        vm.editableRoles = [];

        function getRoles() {
            rolesService.getRoles().then(function (result) {
                vm.readOnlyRoles = _.chain(result).where({ isEditable: false }).sortBy(function (x) { return x.name.toLowerCase() }).value();
                vm.editableRoles = _.chain(result).where({ isEditable: true }).sortBy(function (x) { return x.name.toLowerCase() }).value();
            }, function () {
                notificationService.error("Unexpected error occurred while loading roles.");
            });
        };

        getRoles();

    }
];
