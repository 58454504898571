import type { IStateService } from "angular-ui-router";

class ForgotPasswordController {
    static $inject = ["users.service", "$state"];

    constructor(
        private readonly usersService: any,
        private $state: IStateService
    ) {

    }

    private emailValidator = new RegExp(/[\w._%+-]+@[\w.-]+\.[a-zA-Z]+$/);

    success: boolean = false;
    error: any;
    email: string;

    private isValidEmail = () => {
        return this.emailValidator.test(this.email);
    };

    showError = (error: any) => {
        this.error = error;
    };

    showSuccess = () => {
        this.success = true;
        this.error = null;
    };

    cancel = () => {
        this.$state.go("dashboard");
    };

    generateForgotPasswordLink = () => {
        //if email valid
        if (!this.isValidEmail()) {
            return this.showError("Please provide a valid email address.");
        }

        this.usersService.generateForgotPasswordLink(this.email).then((result) => {
            this.showSuccess();

        }, (error: any) => {
            if (error.modelStateSummary.email) {
                this.showError(error.modelStateSummary.email);
            }
        });
    };
}

export = ForgotPasswordController;

