import { FinancialSummaryAndUnitCostsDto, IngoingContributionRate, DeferredManagementFeeDto } from "../Models";
import { IDialogService } from "../../common/services/IDialogService";
import { IIndependentLivingService } from "../services/IIndependentLivingService";

class ContractEditorComponent implements ng.IComponentOptions {

  public bindings: any = {
    contractData: "=",
    dmfData: "=",
    isIngoingContributionChange: "=",
    enquiryId: "=",
    parentForm: "="
  };

  public templateUrl: string = 'app/independentLiving/partials/contractEditor.html';

  public controller: any = class ContractEditorComponent {
    static $inject = ["dialog.service", "EffectiveDateTypes", "independentLiving.service", "$rootScope"];

    public parentForm: any;
    public contractData: FinancialSummaryAndUnitCostsDto;
    public dmfData: DeferredManagementFeeDto;

    public isIngoingContributionChange: boolean;
    public enquiryId: number;
    public ingoingContributionList: IngoingContributionRate[];
    public isBillingIntegratedFacility: boolean;

    constructor(private readonly dialogService: IDialogService,
      public readonly EffectiveDateTypes: any,
      private readonly iluService: IIndependentLivingService, private readonly $rootScope: any) {

      this.isBillingIntegratedFacility = this.$rootScope.isBillingIntegratedFacility;
      if (!this.isBillingIntegratedFacility) {
        this.fillIngoingContributionList();
      }
    }

    public getTotalDMFPercentageForTheTimePeriod = (index) => {
      return this.iluService.getTotalDMFPercentageForTheTimePeriod(index, this.dmfData.deferredManagementFeesSchedule);
    }

    public removeDmfDetail = (index) => {
      if (this.dmfData.deferredManagementFeesSchedule.length === 1) {
        return;
      }
      this.dmfData.deferredManagementFeesSchedule.splice(index, 1);
    }

    public addDmfDetail = () => {
      this.dmfData.deferredManagementFeesSchedule.push({
        effectiveMonth: null,
        value: null,
        independentLivingAllocationId: this.enquiryId,
        total: null,
        feeOrderNumber: 0
      });
    };

    public fillIngoingContributionList = () => {
      this.ingoingContributionList = new Array<IngoingContributionRate>();
      let index = 1;
      var totalValue = 0;
      this.contractData.effectiveDates.forEach(effectiveDate => {
        this.ingoingContributionList.push({ id: index, startDate: effectiveDate.effectiveFromDate, endDate: new Date(), value: parseFloat(effectiveDate.value) });
        ++index;
        totalValue = totalValue + parseFloat(effectiveDate.value);
      });
      this.contractData.ingoingContribution = totalValue;
    }

    public addEditFeeEffectiveDatesDialog = (effectiveDateType: string) => {

      this.dialogService.openDialog("app/residents/partials/addEditFeeEffectiveDatesDialog.html", "addEditFeeEffectiveDatesDialog.controller", {
        effectiveDateType: effectiveDateType,
        effectiveDates: this.contractData.effectiveDates,
        callBack: this.setEffectiveDates,
        isAdmitted: true,
        admissionDate: null,
        isEmptyCollectionValid: true,
        isFirstValueBasedOnAdmissionDate: false
      });
    };


    public setEffectiveDates = (effectiveDateType, effectiveDates) => {
      this.contractData.effectiveDates = [];
      var totalValue = 0;
      effectiveDates.forEach(effectiveDate => {
        this.contractData.effectiveDates.push(effectiveDate);
        totalValue = totalValue + parseFloat(effectiveDate.value);
      });
      this.contractData.ingoingContribution = totalValue;

      if (effectiveDates.length > 0) {
        this.isIngoingContributionChange = true;
      }

    }

  }
}

export = ContractEditorComponent;