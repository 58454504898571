"use strict";
const angular = require("angular");
const _ = require("underscore");

module.exports = [
    "residents.service",
    "notification.service",
    "modalConfig",
    "$state",
    "fundingService",
    "facility.service",
    'authentication.service',
    function (residentsService, notification, modalConfig, $state, anaccService, facilityService, authenticationService) {
        var vm = this;
        vm.resident = {};
        vm.useAnaccCalculator = modalConfig.expectedAnaccClassification?.viaAnaccCalculator ?? false;

        anaccService.getAnaccFixedComponentForCurrentFacility().then(result => {
            vm.fixedComponent = result;
        }, () => {
            vm.$hide();
            notification.error("Unexpected error while loading MMM classification.");
        });

        vm.permanentAnaccClasses = [];
        vm.respiteAnaccClasses = [];
        anaccService.getAnaccVariableComponents(true, true).then(result => {
            vm.permanentAnaccClasses = _.filter(result, (anaccVariableComponent) => anaccVariableComponent.isPermanent);
            vm.respiteAnaccClasses = _.filter(result, (anaccVariableComponent) => anaccVariableComponent.isRespite);

            if (modalConfig.expectedAnaccClassification?.anaccVariableComponent.anaccClass) {
                vm.anaccVariableComponent = _.find(result, { anaccClass: modalConfig.expectedAnaccClassification.anaccVariableComponent.anaccClass }) ?? null;
            } else {
                vm.anaccVariableComponent = null;
            }
            }, () => {
                vm.$hide();
                notification.error("Unexpected error while loading AN-ACC classes.");
            }); 

        vm.classificationDate = modalConfig.expectedAnaccClassification ? modalConfig.expectedAnaccClassification.classificationDate : null;
        angular.extend(vm.resident, modalConfig.resident);
        vm.canEnterExpectedAnacc = false;
        setCanEnterExpectedAnacc();

        vm.bindHide = ($hide) => {
            vm.$hide = $hide;
        };

        vm.saveNewExpectedAnaccClassification = (isNewExpectedAnaccClassification) => {
            if (isNewExpectedAnaccClassification) {
                // No action if the AN-ACC Class and Classification Date matches the provided AN-ACC Classification.   
                if (modalConfig.expectedAnaccClassification && modalConfig.expectedAnaccClassification.anaccVariableComponent.anaccClass === vm.anaccVariableComponent.anaccClass && modalConfig.expectedAnaccClassification.classificationDate === vm.classificationDate)
                {
                    vm.$hide();
                    return;
                }
                
                residentsService.saveExpectedANACC(vm.resident.id, {
                    classificationDate: vm.classificationDate,
                    anaccClass: vm.anaccVariableComponent.anaccClass,
                    viaAnaccCalculator: vm.useAnaccCalculator
                })
                    .then(() => {
                        vm.$hide();
                        $state.reload();
                        notification.success("Expected AN-ACC saved.");
                    },
                        () => {
                            notification.error("Unable to save AN-ACC. Either your computer is offline, or Resident Manager is having problems of its own.");
                        });
            }
        };

        function setCanEnterExpectedAnacc() {
            authenticationService.userHasPermissionDeferred((x) => {
                return x.residentsEnterExpectedAnacc.canAction;
            }).then(() => {
                vm.canEnterExpectedAnacc = true;
            }, () => {
                vm.canEnterExpectedAnacc = false;
            });
        }

        vm.anaccVariableComponentChanged = (anaccVariableComponent) => {
            vm.anaccVariableComponent = anaccVariableComponent;
        };
    }
];