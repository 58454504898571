import * as angular from 'angular';
import * as _ from 'underscore';
import { FacilityDto } from "../../authentication/Models";
import { IFacilityService } from "../../authentication/services/IFacilityService";
import { INotificationService } from "../../common/services/INotificationService";
import { IDateFormattingService } from "../../common/services/IDateFormattingService";
import { IReportingService } from "../services/IReportingService";
import { FinancialDetailsReportFilter, SupportStatusItem, SupportStatusEnum, FacilityType} from "../Models";
import { IDownloadFileService } from '../../common/services/IDownloadFileService';

class FinancialDetailsReportComponent implements ng.IComponentOptions {
    public bindings: any = {};

    public templateUrl: string = 'app/reporting/partials/financialDetailsReport.html';

    public controller: any = class FinancialDetailsReportController {
        static $inject = ["facility.service", "notification.service", "dateFormatting.service", "reporting.service", "downloadFile.service"];

        public facilities: FacilityDto[] = [];
        public selectedFacility: FacilityDto;

        public supportStatusItems: SupportStatusItem[] = [];
        public selectedSupportStatusItems: SupportStatusItem[] = [];
        public filterTypeSettings: any;
        public translationText: any;

        constructor(private readonly facilityService: IFacilityService,
            private readonly notificationService: INotificationService,
            private readonly dateFormatingService: IDateFormattingService,
            private readonly reportingService: IReportingService,
            private readonly downloadFileService : IDownloadFileService){

            this.facilities = facilityService.activeUserFacilities.filter(x => x.facilityType == "AgeCare");
            if (this.facilities.length == 1)
                this.selectedFacility = this.facilities[0];
            this.initialiseSupportStatusFilter();           
        }
        
        private initialiseSupportStatusFilter() {
            _.each(Object.keys(SupportStatusEnum), key => {
                this.supportStatusItems.push({
                    id: SupportStatusEnum[key],
                    label: key
                });
            });
            this.supportStatusItems = this.supportStatusItems.slice(this.supportStatusItems.length / 2);

            angular.copy(this.supportStatusItems, this.selectedSupportStatusItems);

            this.filterTypeSettings = {
                checkBoxes: true,
                smartButtonMaxItems: 3,
                idProperty: 'label',
                smartButtonTextConverter:  (itemText) => { return itemText; }
            };

            this.translationText = {
                checkAll: "All",
                uncheckAll: "None",
                buttonDefaultText: "Select supported status"
            };
        }

        public extract = () : void => {
            let filter = new FinancialDetailsReportFilter();
            filter.facilityId = this.selectedFacility == null ? 0 : this.selectedFacility.id;

            filter.supportStatus = "All";
            if (this.selectedSupportStatusItems.length > 0 && this.selectedSupportStatusItems.length < Object.keys(this.supportStatusItems).length) {
                filter.supportStatus = this.selectedSupportStatusItems.map(function (element) {
                    return element.label;
                }).join(",");
            }

            this.reportingService.getfinancialDetailsReportExcelStream(filter).then((response) => {
                this.downloadFile(response, 'FinancialDetailsReport.xlsx');
            },
            () => {
                this.notificationService.error("Error occurred while exporting financial details");
            });
        }

        private downloadFile = (response: ArrayBuffer, fileName: string) => {
            this.downloadFileService.downloadFile(response, fileName);
        }
    }
}

export = FinancialDetailsReportComponent;