interface IConfig {
    residentName: string;
    meansTestedCareFees: any;
}

class MeansTestedCareFeesChangeHistoryDialogController {
    static $inject = ["modalConfig"];
    public residentName: string;
    public meansTestedCareFees: any;
    public $hide: any;

    constructor(private modalConfig: IConfig) {
       this.residentName = modalConfig.residentName;
       this.meansTestedCareFees = modalConfig.meansTestedCareFees;
    }

    public bindHide = (hide) => {
        this.$hide = hide;
    };
    
    public close = () => {       
        this.$hide();
    }
}
export = MeansTestedCareFeesChangeHistoryDialogController;