import type { IStateParamsService } from "angular-ui-router";

class ViewExpectedStatementResidentsController {
    static $inject = ["$stateParams"];

    public statement: any;

    constructor(
        private readonly stateParams: IStateParamsService,
    ) {
        this.statement = stateParams.statement;
    }
}

export = ViewExpectedStatementResidentsController