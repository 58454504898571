import * as _ from "underscore";
import moment = require('moment');
import { INotificationService } from '../../common/services/INotificationService';
import { IInfiniteLoaderFactory } from '../../common/services/IInfiniteLoaderService';
import { IResidentsService } from '../services/IResidentsService';
import { ResidentFinanceTransaction, ResidentTransactionsFilter } from '../Models';
import type { IStateParamsService, IStateService } from "angular-ui-router";

interface IResidentFinanceTransactionControllerScope extends ng.IScope {
    residentId: number;
}

class TransactionsComponent implements ng.IComponentOptions{
    public bindings: any;
    public templateUrl: string = 'app/residents/partials/transactions.html';

  

    public controller: any = class NotesComponentController {
        static $inject = ["$scope", "$stateParams", "$state", "residents.service", "notification.service", "infiniteLoader.service"];

        private residentId: number;
        private filter: ResidentTransactionsFilter;
        public refundableLabel: string;
        public balanceRoomPriceLabel: string;
        public roomPriceLabel: string;
        public refundable: number;
        public balanceRoomPrice: number;
        public roomPrice: number;
        public balanceProgress: string;
        public showTransactionHistoryHeaderPanel: boolean;
        public infiniteLoader: any;
        public transactions: ResidentFinanceTransaction[];
        public transactionBalance: number; 
        
        constructor( private $scope: IResidentFinanceTransactionControllerScope,
            private $stateParams: IStateParamsService,
            private $state: IStateService,
            private residentsService: IResidentsService,
            private notificationService: INotificationService,
            private infiniteLoaderService: IInfiniteLoaderFactory) {                
                this.residentId = $stateParams.residentId;
                this.getResidentFinanceTransactionsBalance();
        }

        private getResidentFinanceTransactionsBalance = () => {
            this.residentsService.getResidentFinanceTransactionsBalance(this.residentId).then((result) => {
                this.transactionBalance = result;
                this.getResidentFinanceTransactions();
            }, (error) => {
                this.notificationService.error("Unexpected error while loading transaction history balance.");
            });
        }
        
        private getResidentFinanceTransactions = () => {
            this.transactions = new Array<ResidentFinanceTransaction>();
            this.filter = new ResidentTransactionsFilter();
            this.filter.ResidentId = this.residentId;
            this.infiniteLoader = this.infiniteLoaderService.instanceWithFilter(this.residentsService.getResidentFinanceTransactions,
                this.filter,
                (result) => {
                    this.transactions = this.transactions.concat(result);
                    this.markTransactionWithAHeader();
                },
                () => {
                    this.notificationService.error("Unexpected error while loading transaction history.");
                });
        }
    
         private markTransactionWithAHeader = () => {
            _.each(this.transactions, function(transaction, i, transactions) {
                if (i === 0) {
                    transaction.addHeader = true;    
                }
                else {
                    let transactionDate = moment(transaction.transactionDateUtc);
                    let previousTransactionDate = moment(transactions[i - 1].transactionDateUtc);
                    transaction.addHeader = (transactionDate.format('YYYY') !== previousTransactionDate.format('YYYY')) ||
                        (transactionDate.format('M') !== previousTransactionDate.format('M'));
                }
    
            });
        }
   
    }
}

export = TransactionsComponent