"use strict";
const _ = require("underscore");

module.exports =
    [
        "authentication.service", "facility.service", "$log", "$state", "data.service",
        "featureFlags.service",
        "notification.service",
         "$rootScope",
    function (authenticationService, facilityService, $log, $state, dataService, 
             featureFlagsService, notificationService, $rootScope) {      
            var vm = this;

            vm.activeUserFacilities = [];
            vm.selectedFacility = {}; // step 1 - loading - no selected facility
            var previousFacility = JSON.parse(localStorage.getItem("selectedFacility") || "{}");

            vm.switchToFacility = function (facility) {             
                // step 5 - set selected facility
                facilityService.selectedFacility = facility;
                vm.selectedFacility = facilityService.selectedFacility;
                localStorage.setItem("selectedFacility", JSON.stringify(facility));
                // step 6 - set selected featureFlags
                featureFlagsService.getFeatureFlags().then(function (result) {
                    featureFlagsService.featureFlags = result;
                       // step 7 - navigate to dashboard if facility changed
                        $rootScope.facilityValidated = true;  
                        $rootScope.isBillingIntegratedFacility = facilityService.selectedFacility.isBillingIntegratedFacility;
                        $rootScope.facilityType = facilityService.selectedFacility.facilityType;
                        if (previousFacility.id !== facility.id) {
                            previousFacility = facility;
                            var currentStateName = $state.current.name;
                            if(facility.facilityType == 'AgeCare' && (currentStateName.includes("statementNavigator") || currentStateName == "viewMedicareStatements"))
                            {
                                $state.go("viewMedicareStatements", {}, { reload: true });
                            }
                            else $state.go("dashboard", {}, { reload: true });
                        }
                },
                function () {
                    notificationService.error("Unable to get feature flags. Either your computer is offline, or Resident Manager is having problems of its own.");
                });
             
            }

            vm.logout = function () {
                authenticationService.signoutRedirect().then(null, function (e) {
                    $log.error(e);
                });
            };

            vm.accountSettings = function () {
                $state.go("accountSettings");
            };

            authenticationService.getUser()           
                .then(function (user) {
                    vm.profile = user.profile;
                    localStorage.setItem("user", JSON.stringify(vm.profile));
                    // step 2 - getting user facilities - no selected facility
                    return dataService.get("users/facilities", null, null, true);
                })                
                .then(function (activeUserFacilities) {                      
                    vm.activeUserFacilities = activeUserFacilities;
                    facilityService.activeUserFacilities = activeUserFacilities;
                    $rootScope.OrgHasAgeCareFacility = activeUserFacilities.some(function(x) {return x.facilityType == "AgeCare"});
                    // step 3 - check if last selected facility is still available
                    var alreadySelectedFacility = _.find(activeUserFacilities, function (x) { return x.id === previousFacility.id; });

                    // step 4 - set facility that can be selected
                    if (alreadySelectedFacility) {
                        vm.switchToFacility(alreadySelectedFacility);
                    } else {
                        vm.switchToFacility(activeUserFacilities[0]);
                    }
                });

        }
    ];

