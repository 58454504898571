"use strict";
const angular = require("angular");
const _ = require("underscore");

module.exports = [
    "organisation.rooms.service",
    "notification.service",
    "facility.service",
    "dialog.service",
    "$window",
    "$scope",
    "RoomTypePriceSetting",
    function (roomsService, notificationService, facilityService, dialogService, $window, $scope, RoomTypePriceSetting) {
        var vm = this;
        var selectedAccommodationType; // temporary variable to track what was selected last
        vm.mode = "view";
       
        vm.selectedFacilityName = facilityService.selectedFacility.name;
        vm.selectedFacilityType = facilityService.selectedFacility.facilityType;
        vm.accommodationTypeFeatures = [];
        vm.roomPriceRequiredError = false;
        vm.priceSettingList = [];

        (function pupulatePriceSettingList () {
            vm.priceSettingList.push({id: RoomTypePriceSetting.room, text: 'per room'});
            vm.priceSettingList.push({id: RoomTypePriceSetting.bed, text: 'per bed'});
        })();

        function initialisePriceHistoryPopoverOptions(isIluFacility) {
            const priceColumn = {
                headerText: 'Price',
                headerCssClass: 'price-col-th',
                dataFieldName: 'price',
                cellCssClass: 'price-col-td',
                filterName: 'currency',
                currencySymbol: '$',
                currencyDecimalPlace: 2
            };
            const priceSettingColumn = {
                headerText: 'Per bed/room',
                headerCssClass: 'priceSetting-col-th',
                dataFieldName: 'priceSetting',
                cellCssClass: 'priceSetting-col-td',
                onCellCreated: (rowIndex, formattedValue) => { return vm.priceSettingList.find(x => x.id == formattedValue).text; }
            };
            const effectiveDateColumn = {
                headerText: 'Effective date',
                headerCssClass: 'effective-date-col-th',
                dataFieldName: 'effectiveDate',
                cellCssClass: 'effective-date-col-td',
                filterName: 'date',
                format: 'dd-MMM-yyyy'
            };
            const optionsTemplate = {
                placement: 'bottom',
                autoClose: true,
                popoverCssClass: 'price-history-popover',
                columns: [priceColumn, priceSettingColumn, effectiveDateColumn ],
                getRowCssClass: vm.getRowCssClass
            };
            const iluOptionsTemplate = {
                placement: 'bottom',
                autoClose: true,
                popoverCssClass: 'price-history-popover',
                columns: [priceColumn, effectiveDateColumn ],
                getRowCssClass: vm.getRowCssClass
            };

            if (isIluFacility) {
                vm.PriceHistoryPopupOptions = _.extend({}, iluOptionsTemplate, {
                    id: 'priceHistory-link',
                    target: 'div#priceHistory'
                });
            }
            else {
                vm.PriceHistoryPopupOptions = _.extend({}, optionsTemplate, {
                    id: 'priceHistory-link',
                    target: 'div#priceHistory'
                });
            }
        }

        function sortAccommodationTypes() {
            if (vm.accommodationTypes !== null && vm.accommodationTypes.length > 0) {
                vm.accommodationTypes = _.sortBy(vm.accommodationTypes, function (accommodationType) {
                    return accommodationType.name.toLowerCase();
                });
            }
        };

        var selectFirstAccommodationTypeIfAvailable = function () {
            if (vm.accommodationTypes !== null && vm.accommodationTypes.length > 0) {
                vm.selectAccommodationType(vm.accommodationTypes[0]);
            }
        };

        var getAccommodationTypes = function(selectedAccommodationTypeId) {
            roomsService.getAccommodationTypes()
                .then(function (result) {
                    vm.accommodationTypes = result;
                    sortAccommodationTypes();
                    if (selectedAccommodationTypeId == undefined) {
                        selectFirstAccommodationTypeIfAvailable();
                    }
                    else {
                        var selectedAccommodationType = vm.accommodationTypes.find(x => x.id == selectedAccommodationTypeId);
                        vm.selectAccommodationType(selectedAccommodationType);
                    }
                },
                    function () {
                        notificationService.error("Unexpected error occurred while loading room types.");
                    });
        };
        getAccommodationTypes();

        function getAccommodationTypeFeatures() {
            return roomsService.getAccommodationTypeFeatures()
                .then(function (result) {
                    vm.accommodationTypeFeatures = result;
                },
                    function () {
                        notificationService.error("Unexpected error occurred while loading room type features.");
                    });
        }

        var allowClosingForm = function (unsavedChanges) {
            if (unsavedChanges) {
                return $window.confirm("Do you want to leave this page? Changes you made may not be saved.");
            } else {
                return true;
            }
        };

        vm.selectAccommodationType = function (accommodationType) {

            var unsavedChanges = vm.accommodationTypeForm && vm.accommodationTypeForm.$dirty;
            if (vm.mode !== "view" && !allowClosingForm(unsavedChanges)) {
                return;
            }

            vm.selectedAccommodationType = accommodationType; // vm.selectedAccommodationType is currently selected type
            selectedAccommodationType = accommodationType;
            vm.PriceHistoryPopupData = accommodationType.accommodationTypePriceHistories;
            vm.mode = "view";
            initialisePriceHistoryPopoverOptions(vm.selectedAccommodationType.isIluFacility);
        };

        vm.addNewAccommodationType = function () {
            selectedAccommodationType = angular.copy(vm.selectedAccommodationType);
            vm.accommodationType = {};
            vm.accommodationType.features = [];
            vm.accommodationType.price = 0;
            vm.accommodationType.priceSettingDescription = "Room";
            vm.accommodationType.isIluFacility = (vm.selectedFacilityType == 'IndependentLiving') ? true : false;
            vm.accommodationType.accommodationTypePriceHistories = [];
            
            vm.selectedAccommodationType = vm.accommodationType;
            vm.mode = "add";
            vm.roomPriceRequiredError = true;
            getAccommodationTypeFeatures();
        };

        vm.editAccommodationType = function () {
            vm.accommodationType = angular.copy(vm.selectedAccommodationType);
            selectedAccommodationType = vm.selectedAccommodationType;
            vm.mode = "edit";
            getAccommodationTypeFeatures();
        };

        vm.cancel = function () {
            var unsavedChanges = vm.accommodationTypeForm.$dirty;
            if (allowClosingForm(unsavedChanges)) {
                vm.accommodationTypeForm.$setPristine();
                vm.mode = "view";
                vm.selectedAccommodationType = selectedAccommodationType;
            }
        };

        vm.save = function (isFormValid) {
            if (isFormValid && !vm.roomPriceRequiredError) {
                roomsService.saveAccommodationType(vm.accommodationType)
                    .then(function (saveResult) {
                        if (vm.mode === "add") {
                            vm.accommodationTypes.push(saveResult.accommodationType);
                            vm.selectedAccommodationType = saveResult.accommodationType;
                        } else if (vm.mode === "edit") {
                            _.extend(vm.selectedAccommodationType, saveResult.accommodationType);
                        }
                        getAccommodationTypes(vm.selectedAccommodationType.id);
                        vm.accommodationTypeForm.$setPristine();
                        vm.accommodationType = {};
                        vm.mode = "view";
                    },
                        function (error) {
                            if (error.modelStateSummary.numberBeds) {
                                vm.accommodationTypeForm.numberBeds.$setValidity(error.modelStateSummary.numberBeds, false);
                                dialogService.openMessageDialog(
                                    error.modelStateSummary.numberBeds,
                                    "There are currently residents assigned to rooms of this type. All residents must be unassigned from these rooms before the maximum occupancy can be changed."
                                );
                            }
                            else {
                                notificationService.error("Unexpected error occurred while creating room type.");
                            }
                        });
            }
        };

        vm.addFeature = function (text) {
            $scope.$apply(function () {
                var object = { name: text, id: "new_" + text };
                vm.accommodationTypeFeatures.push(object);
                vm.accommodationType.features.push(object);
            });
        };

        vm.onPriceHistorySaveCallBack = function(updateAccommodationTypePriceHistoryDto) {
            vm.roomPriceRequiredError = false;
            vm.accommodationType.accommodationTypePriceHistories = updateAccommodationTypePriceHistoryDto.AccommodationTypePriceHistories;
            var latestPriceHistory = getLatestPriceFromHistory(updateAccommodationTypePriceHistoryDto.AccommodationTypePriceHistories);
            vm.accommodationType.price = latestPriceHistory.price;
            vm.selectedAccommodationType.price = latestPriceHistory.price; // update display
            vm.accommodationType.priceSetting = latestPriceHistory.priceSetting;
            vm.accommodationType.priceSettingDescription = getPriceSettingDescription(latestPriceHistory.priceSetting);
            vm.selectedAccommodationType.priceSettingDescription = getPriceSettingDescription(latestPriceHistory.priceSetting); // update display

            if (Number(vm.accommodationType.price) == 0) {
                vm.roomPriceRequiredError = true;
            }         
            vm.accommodationTypeForm.$setDirty();
            vm.accommodationTypeForm.price.$dirty = true;     
        }

        function getPriceSettingDescription(priceSetting) {
            switch(priceSetting) {
                case RoomTypePriceSetting.room:
                    return 'Room';
                case RoomTypePriceSetting.bed:
                    return 'Bed';
            }
        }

        function getLatestPriceFromHistory(AccommodationTypePriceHistories) {     
            if (AccommodationTypePriceHistories.length > 0) {
                AccommodationTypePriceHistories =
                    AccommodationTypePriceHistories.sort(function(a,b){
                        return new Date(b.effectiveDate) - new Date(a.effectiveDate)
                    });
                    return AccommodationTypePriceHistories[0];
            }
        }

        vm.openPriceHistoryDialog = function () {
            dialogService.openDialog(
                "app/organisation/partials/getAccommodationTypePriceHistoryDialog.html", 
                "getAccommodationTypePriceHistoryDialog.controller", 
                { 
                    id: vm.selectedAccommodationType.id,
                    mode: vm.mode,
                    accommodationType: vm.accommodationType,
                    onSaveEventCallBack: vm.onPriceHistorySaveCallBack
                }
            );
        };
    }
];
