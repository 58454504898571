import type { IStateParamsService } from "angular-ui-router";
import { Constants } from "../../common/Constants";

class ViewExpectedStatementSummaryController {
    static $inject = ["$stateParams", "utilities.service"];

    public statement: any;
    public yearAndMonth: number;
    public july2024: number;

    constructor(
        private readonly stateParams: IStateParamsService,
        private readonly utilitiesService: any,
    ) {
        this.statement = stateParams.statement;
        this.yearAndMonth = +this.utilitiesService.convertMonthYearToYearMonth(stateParams.monthAndYear);
        this.july2024 = Constants.JULY_2024;
    }
}

export = ViewExpectedStatementSummaryController