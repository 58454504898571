import {
    ViewOrEditAccommodationDto, 
    LocationWithAccommodationsDto, 
    AccommodationTypeDto, 
    AccommodationFeatureDto, 
    AccommodationTypeFeatureDto,
    EditAccommodationDto,
    AddAccommodationDto  } from '../Models';

export interface IRoomsService {
    getAccommodationTypes: () => ng.IPromise<AccommodationTypeDto[]>;
    getRooms: () => ng.IPromise<LocationWithAccommodationsDto[]>;
    getRoomFeatures: () => ng.IPromise<AccommodationFeatureDto[]>;
    getAccommodationTypeFeatures: () => ng.IPromise<AccommodationTypeFeatureDto[]>;
    addRoom: (room: AddAccommodationDto) => ng.IPromise<any>;
    saveAccommodationType: (roomType: AccommodationTypeDto) => ng.IPromise<any>;
    getRoom: (roomId: number) => ng.IPromise<ViewOrEditAccommodationDto>;
    editRoom: (room: EditAccommodationDto) => ng.IPromise<any>;   
    deleteRoom: (id: number) => ng.IPromise<any>;
}