/**
 * @description
 *
 * Usage: 
 * 1. permission straight as a text, for example. There can be one or more permissions
 *  requires-permission="leadsDetails.canModify"
 * 2. object describing which permission should be checked for which route, for example:
 *  requires-permission='{"viewLead": "leadsDetails.canView", "viewResident": "residentsDetails.canView"}'
 * 
 */


"use strict";

module.exports = ["authentication.service", "$state", "$rootScope",
    function (authenticationService, $state, $rootScope) {
        return {
            restrict: "A",
            scope: {
                requiresPermission: "@"
            },
            link: function ($scope, element, attrs) {

                var watchState = "requiresPermissionWatchState" in attrs;

                function applyPermission() {
                    var permission;
                    if ($scope.requiresPermission[0] === "{") {
                        var permissionObject = JSON.parse($scope.requiresPermission);
                        permission = permissionObject[$state.current.name];
                    } else {
                        permission = $scope.requiresPermission;
                    }
                    if (permission) {
                        var permissionArray = permission.split(","); // for elements which need one of the permissions to be visible, for example Staff menu
                        var allowed = false;
                        for (var i = 0; i < permissionArray.length; i++) {
                            var check = permissionArray[i].split(".");
                            allowed = allowed || authenticationService.userPermissions[check[0]][check[1]];
                        }
                        if (allowed) {
                            if (watchState) {
                                element.show();
                            }
                            // no rollback option for element.remove()
                        } else {
                            if (watchState) {
                                element.hide();
                            } else {
                                element.remove();
                            }
                        }
                    }
                }

                // register watching state
                var unbindWatchHandler;
                if (watchState) {
                    unbindWatchHandler = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                        applyPermission();
                    });
                }

                // init permission check
                applyPermission();

                // unregister watching state
                $scope.$on('$destroy', function () {
                    if (unbindWatchHandler) {
                        unbindWatchHandler();
                    }
                });

            }
        };
    }
];
