import { IIndependentLivingService } from "../services/IIndependentLivingService";
import { INotificationService } from "../../common/services/INotificationService";
import { SimpleResidentDto } from "../Models";
import { IInfiniteLoaderFactory } from "../../common/services/IInfiniteLoaderService";

class ViewResidentsComponent implements ng.IComponentOptions {
    public bindings: any = {};
    public templateUrl: string = 'app/independentLiving/partials/viewResidents.html';

    public controller: any = class ViewResidentsController {
        static $inject = ["independentLiving.service", "notification.service", "infiniteLoader.service"];

        public residents: Array<SimpleResidentDto>;
        public infiniteLoader: any;

        constructor(private readonly iluService: IIndependentLivingService,
            private readonly notificationService: INotificationService,
            private readonly infinitLoaderService: IInfiniteLoaderFactory) {
            this.getResidents();
        }

        private getResidents = () => {
            this.residents = new Array<SimpleResidentDto>();
            this.infiniteLoader = this.infinitLoaderService.instance(this.iluService.viewResidents,
                (result) => {
                    if (result && result.length > 0) {
                        this.residents = this.residents.concat(result);
                    }
                },
                () => {
                    this.notificationService.error("Unexpected error occurred while loading residents to view.");
                }
            );
        }
    }
}

export = ViewResidentsComponent;