"use strict";

const $ = require("jquery");
const croppie = require("croppie/croppie");

module.exports = [function () {
    return {
        restrict: "E",
        replace: true,
        require: ["^^form", "ngModel"],
        scope: {
            "ngModel": "=",
            "rawFile": "="
        },
        templateUrl: "app/common/directives/partials/photoSelector.html",
        link: function ($scope, element, attrs, ctrls) {
            $scope.form = ctrls[0];
            $scope.fileName = "";
            var croppieObj;
            var zoomLevel = 0;
            var zoomStep = 0.1;
            $scope.ngModel = {
                photo: null,
                photoTakenDateUtc: null
            };

            var croppieMountElement = element.find(".upload-image")[0];

            element.on("$destroy", function () {
                if (croppieObj) {
                    croppieObj.destroy();
                }
            });

            var update = function (croppe) {
                croppieObj.result({
                    type: 'canvas',
                    size: 'viewport'
                })
                    .then(function (resp) {
                        $scope.ngModel.photo = resp;
                        $scope.$applyAsync();
                    });

                zoomLevel = croppe.zoom;
            };

            croppieObj = new croppie(croppieMountElement, {
                viewport: { width: 300, height: 300 },
                boundary: { width: 630, height: 400 },
                showZoomer: true,
                enableOrientation: true,
                update: update
            });
            
            $scope.rotate = function (angle) {
                croppieObj.rotate(angle);
            };

            $scope.zoom = function (isZoomIn) {
                if (isZoomIn) {
                    croppieObj.setZoom(zoomLevel + zoomStep);
                } else {
                    croppieObj.setZoom(zoomLevel - zoomStep);
                }
            };

            (function (file) {
                if (file) {
                    var reader = new FileReader();

                    reader.onload = function (e) {
                        croppieObj.bind({
                            url: e.target.result
                        });

                        $(".upload-image-block").addClass("ready");
                    }

                    reader.readAsDataURL(file);
                }
                else {
                    alert("Sorry - you're browser doesn't support the FileReader API");
                }
            }($scope.rawFile));
        }
    };
}];
