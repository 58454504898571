import * as _ from 'underscore';
import * as moment from 'moment';
import { IReportingService } from '../services/IReportingService';
import { IDateFormattingService } from '../../common/services/IDateFormattingService';
import { EnquirySourceReportFilter } from '../Models';
import { CareType } from '../Models';
import { DateRange } from '../Models';
import { IDownloadFileService } from '../../common/services/IDownloadFileService';
import { FacilityDto } from "../../authentication/Models";
import { IFacilityService } from "../../authentication/services/IFacilityService";
import { IInfiniteLoaderFactory, IInfiniteLoaderService } from '../../common/services/IInfiniteLoaderService';
import { INotificationService } from '../../common/services/INotificationService';

class EnquirySourceReportController {
    static $inject = [
        'reporting.service',
        'notification.service',
        'infiniteLoader.service',
        'dateFormatting.service',
        'downloadFile.service',
        'facility.service',
    ];
    infiniteLoader: IInfiniteLoaderService;
    enquiries: any[] = [];
    reportFilters: EnquirySourceReportFilter;
    totalCount: number;
    public selectedFacility: FacilityDto;
    public facilities: FacilityDto[] = [];
    public IluFacilityCount : number = 0 ;
    public AgeCareFacilityCount : number = 0;
    public hideCareType : boolean;

    constructor(
        private readonly reportingService: IReportingService,
        private readonly notificationService: INotificationService,
        private readonly infiniteLoaderFactory: IInfiniteLoaderFactory,
        private readonly dateFormattingService: IDateFormattingService,
        private readonly downloadFileService : IDownloadFileService,
        private readonly facilityService: IFacilityService
    ) {
        this.fetchAndSetFacilities();
        this.setFilters();
        this.setFacilityDetails();
        this.updateDisplayFilters();
    }

    private setFilters = () => {

        this.reportFilters = new EnquirySourceReportFilter();
        this.reportFilters.dateRange = DateRange.MonthToDate;
        this.reportFilters.careType = CareType.All;
        this.reportFilters.admissionsOnly = false;

        this.updateFilters();
    }

    private loadReportData = () => {
        this.enquiries = [];
        this.totalCount = 0;
        this.infiniteLoader = this.infiniteLoaderFactory.instance(this.loadEnquiries,
            (result, totalCount) => {
                this.enquiries = this.enquiries.concat(result);
                this.totalCount = totalCount;
                this.processEnquiries();
            }, () => {
                this.notificationService.error('Unexpected error occurred while loading report data.');
            });
    }

    private processEnquiries = () => {
        _.each(this.enquiries, (item, index) => {
            var admissionDate = moment(item.admissionDateUtc);
            item.addHeader = index === 0 || admissionDate.format('M') !== moment(this.enquiries[index - 1].admissionDateUtc).format('M');//mark header
        });
    }

    private loadEnquiries = (notIncludingItemsAfter: string, skip: number, take: number) => {
        if (this.reportFilters.fromDate == undefined || this.reportFilters.toDate == undefined) {
            return; //return if user manually fills the datepicker with wrong date format
        }

        return this.reportingService.getEnquirySourceReportData(notIncludingItemsAfter, skip, take, this.reportFilters);
    }

    private fetchAndSetFacilities = () : void => {
        this.facilities = this.facilityService.activeUserFacilities;
        this.AgeCareFacilityCount = this.facilities.filter(x => x.facilityType == "AgeCare").length;
        this.IluFacilityCount = this.facilities.filter(x => x.facilityType == "IndependentLiving").length;
        if(this.facilities.length == 1)
        {
                this.selectedFacility = this.facilities[0];
        }
    }

    private updateDisplayFilters = () : void => {
        if(this.AgeCareFacilityCount == 0 && this.IluFacilityCount >0)
        {
            this.hideCareType = true;
            this.reportFilters.careType = CareType.ILU;
        }
    }


    updateFilters = () => {
        if (this.reportFilters.dateRange === DateRange.MonthToDate) {
            var filterDate = this.dateFormattingService.snapToClosestUTCMidnight(new Date());
            this.reportFilters.toDate = this.dateFormattingService.snapToClosestUTCMidnight(new Date());
            filterDate.setUTCDate(1); //Sets the first day of month
            this.reportFilters.fromDate = filterDate;
        }
        else if (this.reportFilters.dateRange === DateRange.YearToDate) {
            var filterDate = this.dateFormattingService.snapToClosestUTCMidnight(new Date());
            this.reportFilters.toDate = this.dateFormattingService.snapToClosestUTCMidnight(new Date());
            filterDate.setUTCMonth(0, 1); //Sets the first day of year
            this.reportFilters.fromDate = filterDate;
        }

        this.setFacilityDetails();
        this.loadReportData();
    }

    setFacilityDetails = () => {
        if (this.selectedFacility != null) {
            this.reportFilters.facilityId = this.selectedFacility.id;
            this.reportFilters.facilityName = this.selectedFacility.name;
        }
        else {
            this.reportFilters.facilityId = 0;
            this.reportFilters.activeUserFacilitiesId = this.facilities.map(({ id }) => id);
        }
    }

    exportReport = () => {
        this.reportingService.getEnquirySourceReportExcelStream(this.reportFilters).then((response) => {
            this.downloadFileService.downloadFile(response, 'EnquirySourceReport.xlsx');
        });
    }
}
export = EnquirySourceReportController;


