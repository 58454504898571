"use strict";
var angular = require("angular");

var module = angular.module("residents.module", ["common.module", "accr.module"]);
require("./states.js").configure(module);

module.controller("residentFinanceFunding.controller", require("./controllers/residentFinanceFunding.controller"));
module.controller("residentFinanceFees.controller", require("./controllers/residentFinanceFees.controller"));
module.controller("residentDetails.controller", require("./controllers/residentDetails.controller"));
module.controller("uploadPhotoDialog.controller", require("./controllers/uploadPhotoDialog.controller"));
module.controller("residentPhotoWithMenu.controller", require("./controllers/residentPhotoWithMenu.controller"));
module.controller("residentProfilePhoto.controller", require("./controllers/residentProfilePhoto.controller"));
module.controller("viewResident.controller", require("./controllers/viewResident.controller"));
module.controller("editResident.controller", require("./controllers/editResident.controller"));
module.controller("viewResidents.controller", require("./controllers/viewResidents.controller"));
module.controller("sendOnLeaveDialog.controller", require("./controllers/sendOnLeaveDialog.controller"));
module.controller("assignBedDialog.controller", require("./controllers/assignBedDialog.controller"));
module.controller("departureDialog.controller", require("./controllers/departureDialog.controller"));
module.controller("returnFromLeaveDialog.controller", require("./controllers/returnFromLeaveDialog.controller"));
module.controller("viewDepartedResidents.controller", require("./controllers/viewDepartedResidents.controller"));
module.controller("admissionCorrectionDialog.controller", require("./controllers/admissionCorrectionDialog.controller"));
module.controller("getCridDialog.controller", require("./controllers/getCridDialog.controller"));
module.controller("assignExistingACFIDialog.controller", require("./controllers/assignExistingACFIDialog.controller"));
module.controller("addAN-ACCReassessmentRequestDialog.controller", require("./controllers/addAN-ACCReassessmentRequestDialog.controller"));
module.controller("enterExpectedAN-ACCDialog.controller", require("./controllers/enterExpectedAN-ACCDialog.controller"));
module.controller("viewTimeline.controller", require("./controllers/viewTimelineController"));
module.controller("residentSupplementsEnteralFeedingDialog.controller", require("./controllers/residentSupplementsEnteralFeedingDialog.controller"));
module.controller("residentSupplementsOxygenDialog.controller", require("./controllers/residentSupplementsOxygenDialog.controller"));

module.directive("residentFinanceFunding", require("./directives/residentFinanceFunding.directive"));
module.directive("residentFinanceFees", require("./directives/residentFinanceFees.directive"));
module.directive("residentFinanceEditorFees", require("./directives/residentFinanceEditorFees.directive"));
module.directive("residentDetails", require("./directives/residentDetails.directive"));
module.directive("residentDetailsEditor", require("./directives/residentDetailsEditor.directive"));
module.directive("residentProfilePhoto", require("./directives/residentProfilePhoto.directive"));
module.directive("residentPhotoWithMenu", require("./directives/residentPhotoWithMenu.directive"));
module.directive("residentTimeline", require("./directives/residentTimeline.directive"));
module.directive("enquiryFinanceEditor", require("../independentLiving/directives/enquiryFinanceEditor.directive"));
module.directive("enquiryFinanceDetail", require("../independentLiving/directives/enquiryFinanceDetail.directive"));
module.directive("bookTour", require("./directives/bookTour.directive"));

require("./constants.js").configure(module);

module.factory("residents.service", require("./services/residents.service"));

//Converted to TypeScript
module.controller("markDepartedResidentAsEnquiry.controller", require("./controllers/MarkDepartedResidentAsEnquiryController"));
module.component("notes", new (require("./components/notesComponent"))());
module.component("fundingEstimate", new (require("./components/fundingEstimateComponent"))());
module.component("incomeAsset", new (require("./components/incomeAssetComponent"))());
module.component("transactions", new (require("./components/transactionsComponent"))());
module.component("residentEventHistory", new (require("./components/residentEventHistoryComponent"))());
module.component("extraService", new (require("./components/extraServiceComponent"))());
module.controller("enquiryFinanceEditor.controller", require("../independentLiving/controllers/EnquiryFinanceEditorController"));
module.controller("enquiryFinanceDetail.controller", require("../independentLiving/controllers/EnquiryFinanceDetailController"));
module.controller("residentFinanceTransaction.controller", require("./controllers/ResidentFinanceTransactionController"));
module.directive("residentFinanceTransaction", require("./directives/residentFinanceTransactionDirective"));
module.directive("residentLeaveHistory", require("./directives/residentLeaveHistoryDirective"));
module.controller("addEditFeeEffectiveDatesDialog.controller", require("./controllers/AddEditFeeEffectiveDatesDialogController"));
module.controller("legalNoticeReceivedDialog.controller", require("./controllers/legalNoticeReceivedDialogController"));
module.controller("addEditContactDetailDialog.controller", require("./controllers/AddEditContactDetailDialogController"));
module.controller("addContactDialog.controller", require("./controllers/AddContactDialogController"));
module.controller("transferResidentDialog.controller", require("./controllers/TransferResidentDialogController"));
module.controller("meansTestedCareFeesChangeHistoryDialog.controller", require("./controllers/MeansTestedCareFeesChangeHistoryDialogController"));
module.controller("anaccCalculatorDialog.controller", require("./controllers/AnaccCalculatorDialogController"));
