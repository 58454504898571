import * as angular from 'angular';
import { DropdownOption } from "../../common/Models";
import { FacilityDto } from "../../authentication/Models";
import { IFacilityService } from "../../authentication/services/IFacilityService";
import { INotificationService } from "../../common/services/INotificationService";
import { IDateFormattingService } from "../../common/services/IDateFormattingService";
import { IReportingService } from "../services/IReportingService";
import { BillingExtractFilter, FacilityType} from "../Models";
import { IFilterService } from "angular";

class BillingExtractComponent implements ng.IComponentOptions {
    public bindings: any = {};

    public templateUrl: string = 'app/reporting/partials/billingExtract.html';

    public controller: any = class BillingExtractController {
        static $inject = ["facility.service", "notification.service", "dateFormatting.service", "reporting.service", "$filter"];

        public careTypes: DropdownOption[];
        public facilities: FacilityDto[];
        public fromDate: Date;
        public toDate: Date;
        public selectedCareType: DropdownOption;
        public selectedFacility: FacilityDto;

        constructor(private readonly facilityService: IFacilityService,
            private readonly notificationService: INotificationService,
            private readonly dateFormatingService: IDateFormattingService,
            private readonly reportingService: IReportingService,
            private $filter: IFilterService){
            this.setCareTypes();
            this.facilities = facilityService.activeUserFacilities.filter(x => x.facilityType == "AgeCare");
            this.selectedCareType = this.careTypes[0];
            if (this.facilities.length == 1)
                this.selectedFacility = this.facilities[0];
        }

        private setCareTypes = () : void => {
            this.careTypes = [{ value: "0", displayText: "Both" },
                { value: "1", displayText: "Permanent" }, 
                { value: "2", displayText: "Respite" }
            ];
        }      

        public extract = () : void => {
            let filter = new BillingExtractFilter();
            filter.fromDate = this.fromDate;
            filter.toDate = this.toDate;
            filter.careType = this.selectedCareType.displayText;
            filter.facilityId = this.selectedFacility == null ? 0 : this.selectedFacility.id;

            this.reportingService.getBillingExtractCsvStream(filter).then((response) => {
                this.downloadFile(response, 'BillingExtract.csv');
            },
            () => {
                this.notificationService.error("Error occurred while exporting billing extract");
            });
        }

        public exportForAttache = () : void => {
            let filter = new BillingExtractFilter();
            filter.fromDate = this.fromDate;
            filter.toDate = this.toDate;
            filter.careType = this.selectedCareType.displayText;
            filter.facilityId = this.selectedFacility == null ? 0 : this.selectedFacility.id;

            this.reportingService.getExportForAttacheCsvStream(filter).then((response) => {
                var today = new Date();
                var formattedDate = this.$filter('date')(today, 'ddMMyy');
                this.downloadFile(response, 'AttacheExtract' + formattedDate +'.csv');
            },
            () => {
                this.notificationService.error("Error occurred while exporting for attache report");
            });
        }

        private createLink = () => {
            return angular.element('<a></a>');
        }

        private downloadFile = (response: ArrayBuffer, fileName: string) => {
            var blob = new Blob([response], { type: 'application/csv;' });
            if (window.navigator.msSaveOrOpenBlob) { //IE11 only supports download via msSaveOrOpenBlob
                window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
                var downloadLink = this.createLink(); //created method to help with unit test cases
                downloadLink.attr('href', window.URL.createObjectURL(blob));
                downloadLink.attr('download', fileName);
                downloadLink[0].click();
            }
        }
    }
}

export = BillingExtractComponent;