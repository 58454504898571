"use strict";

var angular = require("angular");

module.exports = [
    "residents.service",
    "notification.service",
    "modalConfig",
    "$rootScope",
    function (residentsService, notification, modalConfig, $rootScope) {
        var vm = this;
        angular.extend(vm, modalConfig);
        vm.residentPhoto = null;

        vm.bindHide = function ($hide) {
            vm.$hide = $hide;
        };

        vm.uploadPhoto = function (uploadPhotoForm) {
            if (uploadPhotoForm.$valid && vm.residentPhoto) {
                residentsService.uploadPhoto(vm.residentId, vm.residentPhoto).then(function (result) {
                    $rootScope.$broadcast("residentProfileImageChanged", vm.residentId);
                    vm.$hide();
                }, function () {
                    notification.error("Unable to save photo. Either your computer is offline, or Resident Manager is having problems of its own.");
                });
            }
        };
    }
];
