"use strict";

module.exports = function () {
    return {
        restrict: "AE",
        scope: {
            permissionOption: "@",
            permissionValue: "=",
            permissionToggle: "&",
            permissionReadOnly: "="
        },
        replace: false,
        templateUrl: "app/roles/directives/partials/viewEditPermissionValue.html"
    };
}
