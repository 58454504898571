module.exports = [
    "data.service", function(dataService) {

        function getAccommodationTypes() {
            return dataService.get("accommodations/types");
        }

        function getRooms() {
            return dataService.get("accommodations");
        }

        function addRoom(newRoom) {
            return dataService.post("accommodations/add", newRoom);
        }

        function saveAccommodationType(roomType) {
            return dataService.post("accommodations/types", roomType);
        }

        function getRoom(roomId) {
            return dataService.get("accommodations/" + roomId);
        }

        function getAccommodationTypeFeatures() {
            return dataService.get("accommodations/types/features");
        }

        function getRoomFeatures() {
            return dataService.get("accommodations/features");
        }

        function editRoom(room) {
            return dataService.post("accommodations/edit", room);
        }

        function deleteRoom(id) {
            return dataService.delete("accommodations/" + id);
        }

        return {
            getAccommodationTypes: getAccommodationTypes,
            getRooms: getRooms,
            addRoom: addRoom,
            saveAccommodationType: saveAccommodationType,
            getRoom: getRoom,
            editRoom: editRoom,
            getAccommodationTypeFeatures: getAccommodationTypeFeatures,
            deleteRoom: deleteRoom,
            getRoomFeatures: getRoomFeatures
        }
    }
];