"use strict";

module.exports = function () {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            name: "@",
            form: "="
        },
        templateUrl: "app/common/directives/partials/currencyInputValidation.html"
    };
}
