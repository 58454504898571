export enum DetailedDropdownStyle {
    "Style1" = "Style1",
    "Style2" = "Style2",
    "Style3" = "Style3"
}
export class DetailedDropdownSection {
    heading: string;
    subHeading: string;
    details: DropdownDetail[];
    style: DetailedDropdownStyle;
}

export class DropdownDetail{
    detail: string;
    description: string;
    enquiryId : number;
    selectedResidentId : number;
}