"use strict";
const _ = require("underscore");

module.exports =
    [
        "$scope",
        function ($scope) {
            $scope.selectUnit = function (id) {
                $scope.selectedUnit = _.findWhere($scope.availableUnits, { accommodationId: id });
            };           
        }
    ];

