import { IPromise } from "angular";

export interface IInfiniteLoaderService {
    nextPage(): void;
    showLoadingIndicator(): boolean
}

export interface InfiniteLoaderAction {
    (notIncludingItemsAfter: string, skip: number, take: number): ng.IPromise<any>;
}

export interface InfiniteLoaderFilterAction {
    (filter: any, notIncludingItemsAfter: string, skip: number, take: number): ng.IPromise<any>;
}

export interface IInfiniteLoaderFactory {
    instance(
        action: InfiniteLoaderAction,
        successCallback: (results: any[], totalCount?: number) => void,
        errorCallback: () => void,
        itemsToFetch?: number): IInfiniteLoaderService

    instanceWithFilter(
        action: InfiniteLoaderFilterAction,
        filter: any,
        successCallback: (results: any[]) => void,
        errorCallback: () => void,
        itemsToFetch?: number): IInfiniteLoaderService
}
