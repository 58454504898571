import { SearchResult } from "../residents/Models";
import { SimpleGLAccountMapping } from "../organisation/Models";

export class SundryCharge {
    id: number;
    invoiceDateUtc: Date;
    sundryAccountId: string;
    sundryAccountDescription: string;
    postedSundryAccountDescription: string;
    description: string;
    supplier: string;
    invoiceNumber: string;
    postedDateUtc: Date;
    total: number;
    residents: Resident[];
    draft: boolean;
    gstInclusive: boolean;
    gstRate: number;
    modifiedByName: string;
    modifiedOnUtc: Date;
    splitBillEvenly: boolean;
    isDirty: boolean;
    addHeader: boolean;
}

export class Resident {
    residentId: number;
    residencyId: number;
    firstName: string;
    sundryAccountId: string;
    sundryAccountDescription: string;
    postedSundryAccountDescription: string;
    description: string;
    amount: number;
    gstType: string;
    lastName: string;
    isDeparted: boolean;
    isChecked: boolean;
    initialValue: SearchResult;
}

export class SundryChargeFilter {
    public sundryChargeStatus: string;
}
