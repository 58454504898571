import * as moment from 'moment';
import { IResidentsService } from "../services/IResidentsService";
import { INotificationService } from '../../common/services/INotificationService';
import type { IStateService, IStateParamsService } from 'angular-ui-router';

class MarkDepartedResidentAsEnquiryController {
    static $inject = ["$state", "$stateParams", "residents.service", "notification.service", "facility.service"];
    facilityName: string;
    residentName: string;
    departureSummary: string;
    resident: any;
    residentId: number;
    markAsEnquiryForm: ng.IFormController;

    constructor(
        private readonly $state: IStateService,
        private readonly $stateParams: IStateParamsService,
        private readonly residentsService: IResidentsService,
        private readonly notificationService: INotificationService,
        private readonly facilityService: any
    ) {
        this.facilityName = this.facilityService.selectedFacility.name;
        this.resident = {
            careType: "Permanent",
            contactIsResident: false
        };
        this.residentId = $stateParams.residentId;
        this.loadResident();
    }

    private loadResident() {
        this.residentsService.getResident(this.residentId)
            .then(result => {
                this.resident.firstName = result.firstName;
                this.resident.lastName = result.lastName;
                this.departureSummary = result.departureReasonDescription +
                    " " + moment(result.departureDateUtc).format("DD MMM YYYY");

                if (result.primaryContact) {
                    this.resident.contactFirstName = result.primaryContact.firstName;
                    this.resident.contactLastName = result.primaryContact.lastName;
                    this.resident.contactPhoneNumber = result.primaryContact.phoneNumber;
                }
            });
    }

    cancel() {
        this.$state.go("viewResident", { residentId: this.residentId });
    }

    saveEnquiry() {
        if (this.markAsEnquiryForm.$valid) {
            this.residentsService.markDepartedResidentAsEnquiry(this.residentId, this.resident)
                .then(result => {
                    this.notificationService.success("Resident was marked as an enquiry successfully.");
                    this.$state.go("editLead", { "residentId": this.residentId });
                }
                , (err) => {
                    if(err.data.modelState && err.data.modelState.mpir){
                        this.notificationService.error("Unable to mark as enquiry. " + err.data.modelState.mpir[0]);
                    }
                    else{
                        this.notificationService.error("Unexpected error while marking the resident as an enquiry.");
                    }
                });
        }
    }

    minRequestedDepartureDate() {
        return moment(this.resident.requestedAdmissionDateUtc).add(1, 'd').startOf('day').toDate();
    };

    clearContactFields() {
        if (this.resident.contactIsResident) {
            delete this.resident.contactFirstName;
            delete this.resident.contactLastName;
        }
    }
}
export = MarkDepartedResidentAsEnquiryController;
