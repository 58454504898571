"use strict";

module.exports = [function () {
    return {
        restrict: "E",
        replace: true,
        require: ["^^form", "ngModel"],
        scope: {
            "ngModel": "=",
            "value": "=",
            "required": "=",
            "class": "@",
            "label": "@",
            "name": "@",
            "disabled": "<",
            "onClick": "=?"
        },
        templateUrl: "app/common/directives/partials/toggleButton.html",
        controller: "toggleButton.controller",
        controllerAs: "vm",
        link: function ($scope, element, attrs, ctrls) {
            $scope.form = ctrls[0];
        }
    };
}];
