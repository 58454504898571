import * as _ from "underscore";
import moment = require('moment');
import { INotificationService } from '../../common/services/INotificationService';
import { IDialogService } from '../../common/services/IDialogService';
import { IOrganisationFacilityService } from '../services/IOrganisationFacilityService';
import { IFacilityService } from '../../authentication/services/IFacilityService';
import { OptionalService, OptionalServiceStatus, Period , StopBillingEvent} from '../Models';
import { IDateFormattingService } from '../../common/services/IDateFormattingService';

class ViewAndEditOptionalServicesController {
    static $inject = ["$timeout", "organisation.facility.service", "notification.service", "dialog.service", "facility.service", "dateFormatting.service"];

    private mode: string;
    public optionalServices: Array<OptionalService>;
    public selectedFacility: any;
    public facilityCode: string;
    public isAddingOptionalService: boolean;
    public saveOptionalServicesForm: ng.IFormController;
    public inputData: any;
    public readonlyEffectiveDatePopoverConfigs: any[] = [];
    public formOptionalServices: any;
    public dropdownSettings = {
        singleSelection: true,
        addAllOption: false,
        enableCheckAll: false,
        showClearSelectedItems: false
    };
    public stopBillingEvent = StopBillingEvent;
    public stopBillingEventOptions: Array<any> = [];

    // locally stored enums so we can use them in the templates
    public OptionalServiceStatus;
    public Period;

    constructor(
        private $timeout: ng.ITimeoutService,
        private organisationFacilityService: IOrganisationFacilityService,
        private notificationService: INotificationService,
        private dialogService: IDialogService,
        private facilityService: IFacilityService,
        private dateFormattingService: IDateFormattingService
    ) {
        // init the enums
        this.OptionalServiceStatus = OptionalServiceStatus;
        // filter period on numeric keys
        this.Period = Object.keys(Period).filter(key => !isNaN(parseFloat(key))).reduce((obj, key) => {
            obj[key] = Period[key];
            return obj;
          }, {});

        this.mode = "view";
        this.populateStopBillingEventOptions();
        this.getOptionalServices();
    }

    public getOptionalServiceLength = (status = null) => {
        if (!this.optionalServices || this.optionalServices.length == 0) {
            return 0;
        }
        if (!status) {
            return this.optionalServices.length;
        }
        return this.optionalServices.filter(optionalService => { return optionalService.status == status}).length;
    }

    public showDropDown(optionalService: OptionalService): boolean
    {
        if (this.selectedFacility.facilityType == 'AgeCare') {
            return false;
        }
        if (optionalService.stopBillingEvent == undefined || optionalService.stopBillingEvent == 0) {
            return true;
        }
        if (optionalService.id == 0) {
            return true
        }

        return false;
    }

    public stopBillingEventChanged(formIndex: number, optionalService: OptionalService)
    {
        let ctrl = this.saveOptionalServicesForm['stopBillingEvent' + formIndex];
        if (ctrl.$modelValue) {
            var selectedItemId = ctrl.$modelValue[0].id;
            optionalService.stopBillingEvent = selectedItemId;
            ctrl.$setValidity('required', true);
        }
        else { // highly unlikely but I'd rather be safe
            ctrl.$setValidity('required', false);
        }
    }

    private populateStopBillingEventOptions()
    {
        this.stopBillingEventOptions.push({id: this.stopBillingEvent.Departure, text: this.stopBillingEvent[this.stopBillingEvent.Departure]});
        this.stopBillingEventOptions.push({id: this.stopBillingEvent.Settlement, text: this.stopBillingEvent[this.stopBillingEvent.Settlement]});
    }

    private getOptionalServices = () => {
        this.organisationFacilityService.getOptionalServices().then((result) => {
            this.readonlyEffectiveDatePopoverConfigs = [];
            this.optionalServices = [];
            this.optionalServices = OptionalService.buildArray(result);

            this.initialiseReadonlyEffectiveDatePopoverConfig();
            this.setSelectedFacility();
        },
        () => {
            this.notificationService.error("Unexpected error while loading optional services.");
        });
    }

    // set selected facility 
    private setSelectedFacility = () => {
        this.selectedFacility = this.facilityService.selectedFacility;
    }   

    public isValidDateRange = (startDate, stopDate): boolean => {
        return moment.utc(startDate).format() <= moment.utc(stopDate).format();
    }

    private addOptionalService = () => {
        var stopBillingEvent =  this.selectedFacility.facilityType == 'AgeCare'
                                ? this.stopBillingEvent.Departure
                                : undefined;
        this.optionalServices.push(new OptionalService(0, '', 0, '', [], true, stopBillingEvent));
        this.$timeout(() => {
            this.attachCustomValidators();
        }, 0);
    }

    private attachCustomValidators = () => {        
        // look through the charge type boxes and check to see if they have the notUnique custom validator
        for (var formProperty in this.saveOptionalServicesForm) {
            if (formProperty.indexOf('chargeType') > -1) {
                var controlValidators = this.saveOptionalServicesForm[formProperty].$validators;
                if (!controlValidators.hasOwnProperty('notUnique')) {
                    // found a charge type box, attach the notUnique custom validator
                    controlValidators.notUnique = this.validateOptionalServiceChargeType;
                }
            }
        }
    }

    public setOptionalServiceStartDate = (optionalService, formIndex) => {
        optionalService.setStartDate(optionalService.displayStartDate);
        this.validateOptionalServiceDateRange(optionalService, formIndex);
    }

    public setOptionalServiceStopDate = (optionalService, formIndex) => {
        optionalService.setStopDate(optionalService.displayStopDate);
        this.validateOptionalServiceDateRange(optionalService, formIndex);
    }

    public validateOptionalServiceChargeType = (chargeType) => {
        if (!chargeType)
            return true;

        for (let i = 0; i < this.optionalServices.length; i++) {
            const optionalService = this.optionalServices[i]
            if (optionalService.chargeType && optionalService.status != this.OptionalServiceStatus.Stopped && chargeType.toString().trim().toLowerCase() == optionalService.chargeType.toString().trim().toLowerCase())
                return false;
        };
        return true;
    }

    public validateOptionalServiceDateRange = (optionalService, formIndex) => {
        let ctrl = this.saveOptionalServicesForm['optionalServiceTo' + formIndex];
        let isValid = true;

        let fromDate = optionalService.ratesList[optionalService.displayRateIndex].startDate;
        let toDate = optionalService.displayStopDate;

        // only check date range if we have a todate
        if (toDate)
            isValid = this.isValidDateRange(this.dateFormattingService.snapToClosestUTCMidnight(fromDate), this.dateFormattingService.snapToClosestUTCMidnight(toDate));

        if (!isValid) {
            ctrl.$setValidity('dateRange', false);
        } else {
            ctrl.$setValidity('dateRange', true);
        }
        return isValid;
    };

    private removeOptionalService = (optionalService: OptionalService) => {
        this.optionalServices.splice(this.optionalServices.indexOf(optionalService), 1);
    }    

    public startAddingOptionalService = (isFormValid: boolean) => {
        if (!isFormValid)
            return;

        this.isAddingOptionalService = true;
        this.addOptionalService();
        this.isAddingOptionalService = false;
    }

    public saveOptionalServices = (isFormValid: boolean) => {
        if (!isFormValid) {
            console.log("Cannot save due to form errors");
            return;
        }
        this.save();
    }

    private save = () => {
        this.organisationFacilityService.saveOptionalServices(this.optionalServices).then((result) => {
            this.notificationService.success("Saved successfully.");
            // when saving, re-load the optional services from the server to re-initialise the form
            this.getOptionalServices();
            this.mode = "view";
        },
        () => {
            this.notificationService.error("Error occurred while saving the optional services. Please try again.");
        });
    }

    public cancel = () => {
        // when cancelling, re-load the optional services from the server to remove any user chances
        this.getOptionalServices();

        this.mode = "view";
    }

    public edit = () => {
        this.mode = "edit";
    }

    private initialiseReadonlyEffectiveDatePopoverConfig = () => {
        this.readonlyEffectiveDatePopoverConfigs = [];
        this.optionalServices.forEach(optionalService => {
            this.readonlyEffectiveDatePopoverConfigs[optionalService.id] = {
                id: 'value-link-' + optionalService.id,
                placement: 'bottom',
                target: 'a#value-link-' + optionalService.id,
                autoClose: true,
                popoverCssClass: 'fee-effective-popover',
                columns: [{
                    headerText: 'RATE',
                    headerCssClass: 'support-col',
                    dataFieldName: 'value',
                    cellCssClass: 'support-col',
                    filterName: 'currency'
                }, {
                    headerText: 'EFFECTIVE DATE',
                    headerCssClass: 'effective-date-col',
                    dataFieldName: 'startDate',
                    cellCssClass: 'effective-date-col',
                    filterName: 'date',
                    format: 'dd MMM yyyy',
                    onCellCreated: (rowIndex, formattedValue) => { return formattedValue; }
                }],
                getRowCssClass: (data) => {
                    return optionalService.ratesList.indexOf(data) == optionalService.displayRateIndex ? 'latest' : ''
                }
            };
            
        });
    }

    public showEditableEffectiveDatePopover = (hdr, inputType, optionalService: OptionalService, isMandatory) => {
        
        // work on a copy of the array
        var useRatesList = optionalService.ratesList.slice();

        // make everything editable other than the first rate
        for (let i = 0; i < useRatesList.length - 1; i++) {
            useRatesList[i].isEditable = true;
        }
        useRatesList[useRatesList.length - 1].isEditable = false;

        var modalConfig = {
            header: hdr,
            inputType: inputType,
            data: useRatesList,
            callbackData: optionalService,
            callback: this.valuesWithEffectiveDateCallback,
            isMandatory: isMandatory
        }

        this.dialogService.openDialog("app/common/partials/value-with-effective-date.html", "valueWithEffectiveDateController", modalConfig, true);
    }

    
    public valuesWithEffectiveDateCallback = (newRatesList, optionalService) => {
        optionalService.setRatesList(newRatesList);
    }
}
export = ViewAndEditOptionalServicesController;