"use strict";

module.exports = [
    "footer.service", "notification.service",
    function (footerService, notificationService) {
        var vm = this;

        function openUrl(url) {
            if (url && url.isSuccessful) {
                window.open(url.url, '_blank')?.focus();
            } else if (url && !url.isSuccessful) {
                notificationService.error(url.message);
            }
        }

        vm.openTrainingManualsLink = function () {
            footerService.getTrainingManualUrl().then(function (result) {
                openUrl(result);
            }, function () {
//                notificationService.error("Unexpected error occurred while loading dashboard.");
            });
        };

        vm.openReleaseInformationLink = function () {
            footerService.getReleaseInformationUrl().then(function (result) {
                openUrl(result);
            }, function () {
//                notificationService.error("Unexpected error occurred while loading dashboard.");
            });
        };
    },
];
