"use strict";
const moment = require("moment");
const _ = require("underscore");

module.exports = [
    "facility.service",
    "dashboard.service",
    "notification.service",
    'authentication.service',
    function (facilityService, dashboardService, notificationService, authenticationService) {
        var vm = this;
        vm.selectedFacilityName = facilityService.selectedFacility.name;
        vm.selectedFacilityType = facilityService.selectedFacility.facilityType;
        vm.hasDeviceExpiryNotificationPermission = false;
        setdeviceExpiryNotificationPermission();

        (
            function getDashboardInfo() {
                if (facilityService.selectedFacility.id) {
                    // on first redirect from log in screen on a new browser the selected facility and user facilities may be empty
                    // loggedInUserInfo will select first facility and refresh dashboard
                    dashboardService.getDashboardInfo().then(function (result) {
                        vm.dashboardInfo = result;
                        processResults();
                    }, function () {
                        notificationService.error("Unexpected error occurred while loading dashboard.");
                    });
                };
            }
        )();

        function processResults() {
            vm.residency = {
                registeredBeds: vm.dashboardInfo.facilityResidencyStatistics.residentOccupancyInfo.registeredBeds,
                respiteResidents: vm.dashboardInfo.facilityResidencyStatistics.residentOccupancyInfo.respiteResidentCount,
                permanentResidents: vm.dashboardInfo.facilityResidencyStatistics.residentOccupancyInfo.permanentResidentCount,
                get vacancy() {
                    return this.registeredBeds - (this.permanentResidents + this.respiteResidents);
                },
                get vacancyText() {
                    if (this.vacancy === 1)
                        return "vacancy";
                    else return "vacancies";
                },
                get supportedResidents() {
                    return vm.dashboardInfo.facilityResidencyStatistics.supportedResidentsInfo.supportedRatio;
                },
                get supportedResidentsThreshold() {
                    return vm.dashboardInfo.facilityResidencyStatistics.supportedResidentsInfo.supportThreshold;
                },

                get thresholdText() {
                    if (this.supportedResidentsThresholdStatus > 0)
                        return "below";

                    if (this.supportedResidentsThresholdStatus < 0)
                        return "above";

                    return "same as";
                },
                get supportedResidentsThresholdStatusDisplay() {
                    return Math.abs(this.supportedResidentsThresholdStatus);
                },
                get supportedResidentsThresholdStatus() {
                    return vm.dashboardInfo.facilityResidencyStatistics.supportedResidentsInfo.supportThreshold - this.supportedResidents;
                }
            };

            vm.residentsOnLeaveHospital = _.where(vm.dashboardInfo.residentsOnLeave, { type: "Hospital" });
            vm.residentsOnLeaveHospital = _.sortBy(vm.residentsOnLeaveHospital, "startDateUtc");
            vm.residentsOnLeaveSocial = _.where(vm.dashboardInfo.residentsOnLeave, { type: "Social" });
            vm.residentsOnLeaveEmergency = _.where(vm.dashboardInfo.residentsOnLeave, { type: "Emergency" });
            if (vm.dashboardInfo.numberOfDaysToDeviceExpiry !== null)
            {
                vm.dayOrDays = vm.dashboardInfo.numberOfDaysToDeviceExpiry > 1 ? "days" : "day";
                vm.isDeviceExpired = vm.dashboardInfo.numberOfDaysToDeviceExpiry <= 0;
            }
        }

        vm.residentOnLeavDueInfo = function (residentOnLeave) {
            if (residentOnLeave.type === "Social" || residentOnLeave.type === "Emergency") {
                var days = moment().startOf('day').diff(moment(residentOnLeave.expectedReturnDateUtc).startOf('day'), 'days');
                residentOnLeave.days = days;
                switch (days) {
                    case 0:
                        return "Due back today";
                    case -1:
                        return "Due back tomorrow";
                    case 1:
                        return "Due back yesterday";
                    default:
                        return "Due back " + moment(residentOnLeave.expectedReturnDateUtc).format("D MMM YYYY");
                }
            } else {
                var days = moment().startOf('day').diff(moment(residentOnLeave.startDateUtc).startOf('day'), 'days');
                switch (days) {
                    case 0:
                        return "Today";
                    case 1:
                        return "Yesterday";
                    default:
                        return days + " days ago (" + moment(residentOnLeave.startDateUtc).format("D MMM YYYY") + ")";
                }
            }
        };

        function setdeviceExpiryNotificationPermission()
        {
            authenticationService.userHasPermissionDeferred((x) => {
                return x.deviceExpiryNotification.canAction;
            }).then(() => {
                vm.hasDeviceExpiryNotificationPermission = true;
            }, () => {
                vm.hasDeviceExpiryNotificationPermission = false;
            });            
        }

    }
];
