import { ReconciliationAdjustmentsSummary } from "../Models";
import { IFundingService } from "../Interfaces";
import { INotificationService } from "../../common/services/INotificationService";
import { IReportingService } from "../../reporting/services/IReportingService";
import { IDownloadFileService } from "../../common/services/IDownloadFileService";
import moment = require("moment");

class AdjustmentSummaryController {
    static $inject = ["funding.service", "notification.service", "modalConfig", "reporting.service", "downloadFile.service"];
    public $hide: any;
    public adjustmentSummary: ReconciliationAdjustmentsSummary[];
    public period: String;
    public isReadOnly: boolean;

    constructor(private readonly fundingService: IFundingService,
        private readonly notificationService: INotificationService,
        private readonly modalConfig: any,
        private readonly reportingService: IReportingService,
        private readonly downloadFileService: IDownloadFileService) {
            this.setAdjustmentSummary(modalConfig.monthAndYear);
            this.period = moment(modalConfig.monthAndYear, "MYYYY").format("MMMM YYYY");
            this.isReadOnly = modalConfig.isReadOnly;
    }

    public bindHide = ($hide) => {
        this.$hide = $hide;
    };

    public finalise = () => {
        this.modalConfig.finalise();
        this.$hide();
    }

    private setAdjustmentSummary = (monthAndYear:String) => {
        this.fundingService.getAdjustmentSummary(monthAndYear).then((result) => {
            this.adjustmentSummary = result;
        },
            () => {
                this.notificationService.error("Unexpected error occurred while fetching the variance in payments.");
            }
        );
    }

    public exportAdjustments = () => {
        this.reportingService.getAdjustmentSummaryReportExcelStream(this.modalConfig.monthAndYear).then((response) => {
            this.downloadFileService.downloadFile(response, 'AdjustmentSummaryReport.xlsx');
        });
    }
}

export = AdjustmentSummaryController;