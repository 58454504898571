import { ResidentFinanceEffectiveDate } from "../residents/Models";
import { EnquirySourceDto } from "../common/Models";
import { ReligionDto } from "../common/Models";

export class CreateEnquiryDto {    
    prospect1FirstName: string;
    prospect1LastName: string;
    prospect2FirstName: string;
    prospect2LastName: string;
    contactFirstName: string;
    contactLastName: string;
    contactPhoneNumber: string;
    contactEmail: string;
    primaryContact: string;    
    requestedAdmissionDateUtc: Date; 
    enquirySources: EnquirySourceDto[];  
    prospect1ResidentId?: number;
    prospect2ResidentId?: number;
}

export class SimpleEnquiryDto {
    enquiryId: number;
    prospects: ProspectDto[];
    createdOn: Date;
}

export class SimpleResidentDto {
    allocationId: number;
    residentId: number;
    firstName: string;
    lastName: string;
    dateOfBirth?: Date;
    age?: number;
    unitNumber: string;
}

export enum CareType {
    Permanent = 0,
    Respite = 1,
    ILU = 2
}

export enum DepartureReason {
    Deceased = 0,
    Hospital = 1,
    ReturningFamilyHome = 2,
    Other = 3,
    AnotherAgedCareServiceByProvider = 4,
    AnotherAgedCareServiceByResident = 5,
    AnotherOrganisationFacilityServiceByProvider = 6,
    AnotherOrganisationFacilityServiceByResident = 7
}

export enum TransferType {
    ActiveResidency = 0,
    DepartedResidency = 1,
    Lead = 2
}

export class DepartedResidentDto {
    allocationId: number;
    residentId: number;
    firstName: string;
    lastName: string;
    departureDateUtc: Date;
    departureReason: DepartureReason;
    departureReasonNotes: string;
    isTransferred: boolean;
    transferType?: TransferType;
    transferToFacility: string;
    departureReasonDescription: string;
    settlementStatus: string;
}

export class ProspectDto {
    firstName: string;
    lastName: string; 
    residentId: number;
}

export class ViewEnquiryDto {
    enquiryId: number;
    responsibleResidencyId?: number;
    residents: ResidentDto[];
    assignedUnit: ILUUnitDto;
    occupiedDate?: Date;
    isSettled: boolean;
    accessibilityRequirement: string;
}

export class FacilityAddressDto
{
    street1: string;
    street2: string;
    suburb: string;
    stateDescription: string;
    postCode: string;
    country: string;
}

export class SelectedResidentDto {
    residentId : number;
    residencyId: number;
    fullName: string;
}

export class AccommodationDto {
    accommodationId : number;
    roomNumber : string;
    accommodationName : string;
    location : string;
}

export class UnitDto  extends AccommodationDto {
    price : number;
    iluStatus : string;
    residents : ResidentDto[];
    briefNotes: string;
}

export class UpdateEnquiryDto {
    enquiryId: number;
    resident: ResidentDto;
}

export class FinancialSummaryAndUnitCostsDto {
    independentLivingAllocationId : number;
    publishedPrice?: number;
    agreedPrice?: number;
    responsibleResidencyId?: number;
    responsibleResidentId?: number;
    responsibleResident: string;
    contractSignedDateUtc?: Date;
    noticeToVacateDateUtc?: Date;
    ingoingContribution: number;
    effectiveDates: ResidentFinanceEffectiveDate[];
    depositPaidDateUtc?: Date;
    balancePaidDateUtc?: Date;
    dmfCalculationDateUtc?: Date;
    dmfUnitPrice?: number;
    additionalCosts?: number;
    settlementPrice?: number;
    settlementDateUtc?: Date;
    residentCapitalGainRate: number;
    residentCapitalLossRate: number;
    calculatedIngoingContributionFromTechone: number;
}

export class ResidentDto {
    id: number;
    firstName: string;
    lastName: string; 
    title: string;
    dateOfBirth: string;
    dateOfBirthFormatted: string;
    middleName: string;
    preferredName: string;
    sex: string;
    maritalStatus: string;
    maritalStatusDescription: string;
    indigenousStatus: string;
    indigenousStatusDescription: string;
    countryOfBirth: string;
    countryOfBirthId: number;
    languageSpoken: string;
    medicareNumber: string;
    medicareMemberNumber: string;
    pensionStatus: string;
    pensionStatusDescription: string;
    pensionNumber: string;
    dVACardColour: string;
    dVACardColourDescription: string;
    dVANumber: string;  
    street1: string;
    street2: string;
    suburb: string;
    stateDescription: string;  
    postCode: string;
    countryId?: number;
    phoneNumber: string;
    email: string;
    residencyId: number;
    primaryContact: ResidentContactDto;
    secondaryContact: ResidentContactDto;
    age: number;
    isAdmitted: boolean;   
    requestedAdmissionDateUtc: Date;
    requestedAdmissionDateFormatted: string;  
    admissionTimeLocal:string;
    phoneNumberAlternate: string;
    isLead: boolean;
    careRecipientId: string;
    residentialCareId: string;
    otherContacts : ResidentContactDto[];
    facilityAddress : FacilityAddressDto;
    isDeparted: boolean;
    departDateUtc?: Date;
    departureReasonNotes: string;
    departureReason: DepartureReason;
    departureReasonDescription: string;
    enquirySources: EnquirySourceDto[];
    Religions: ReligionDto[];
    medicareExpiryMonth: string;
    medicareExpiryYear?: number | null;
    pensionExpiryMonth?: string;
    pensionExpiryYear?: number | null;
    dvaExpiryMonth?: string;
    dvaExpiryYear?: number | null;
    numberOfActiveOptionalServices: number;
    isResidentResponsible: boolean;
    accessibilityRequirement: string;
}

export class ILUResidentDto  {
    enquiryId: number;
    resident: ResidentDto;
}

export class ILUUnitDto {
    independentLivingAllocationId: number;
    accommodationId: number;
    unitNumber: string;
    location: string;
    unitType: string;
    status: IluStatus;
    price: number;
}

export class ResidentContactDto {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    street1: string;
    street2: string;
    suburb: string;
    stateDescription: string;
    postCode: string;
    country: string;
    phoneNumber: string;
    faxNumber: string;
    phoneNumberAlternate: string;
    phoneNumberAdditional: string;
    relationships: RelationshipDto[];
    contactNotes: string;
    contactId: string;
}

export class RelationshipDto {
    id: number;
    name: string;
}

export class AssignedAccommodationDto {
    independentLivingAllocationId : number;
    accommodationId : number;
    unitNumber : string;
    location : string;
    unitType : string;
    price : number;
}

export class ResidencyFinanceOptionalServiceDto {
    index: number;
    description?: string;
    optionalServiceId: number;
    startDateUtc: Date;
    endDateUtc: Date | null;
    stopped: boolean;
    new: boolean;
    rate: string;
    applicableRatesList: any;
    facilityOptionalServiceStartDateUtc: Date;
    facilityOptionalServiceEndDateUtc: Date;
}

export class IluResidencyFinanceFeesDto {
    residencyId: number;
    firstName: string;
    lastName: string;
    admissionDateUtc?: Date;
    optionalServices: ResidencyFinanceOptionalServiceDto[];
    isBillPayerDefined: boolean;
    isResponsibleResident: boolean;
    billPayerType: BillPayerType;
    billPayerFullName: string;
}

export class FeesAndChargesDto {
    independentLivingAllocationId : number;
    admissionDateUtc?: Date;
    vacatingDateUtc?: Date;
    assignedAccommodationDto : AssignedAccommodationDto;
    financialSummaryAndUnitCostsDto: FinancialSummaryAndUnitCostsDto;
    deferredManagementFeeDto: DeferredManagementFeeDto;
    iluResidencyFinanceFees: IluResidencyFinanceFeesDto[];
}

export class IluAccommodationFinancialEventsDto {
    accommodationId: number;
    events: IluFinancialEventDto[];
}

export class IluFinancialEventDto {
    iluAllocationId: number;
    contractSignedDateUtc?: Date;
    depositPaidDateUtc?: Date;
    balancePaidDateUtc?: Date;
    noticeToVacateDateUtc?: Date;
    residentId: number;
    residentFirstName: string;
    residentLastName: string;
    isAdmitted: boolean;
}

export enum ResidentFinanceEffectiveDateType {
    MeansTestCareFee = 1,
    Pre2014IncomeTestFee = 2,
    DailyAccommodationContribution = 3,
    SupportLevel = 4,
    TopupDailyAccommodationContribution = 5,
    DailyAccommodationPayment = 6,
    AgreedRoomPrice = 7,
    Hardship = 8,
    Pre2014MonthlyAmountFee = 9,
    RadPaid = 10,
    RacPaid = 11,
    BasicDailyCareFee = 12,
    IngoingContribution = 13,
    PrivateDailyCareFee = 14
}

export class DeferredManagementFeeScheduleDto {
    effectiveMonth : number;
    value: number;
    independentLivingAllocationId: number;
    feeOrderNumber: number;
    total: number;
}

export enum DmfUnitCostCalculationType {
    IngoingUnitCost = 1,
    OutgoingUnitCost = 2
}

export class DeferredManagementFeeDto {
    minimumRate : number;
    independentLivingAllocationId : number;
    dmfUnitCostCalculationType : DmfUnitCostCalculationType;
    dmfUnitCostCalculationTypeDescription : string;
    deferredManagementFeesSchedule : DeferredManagementFeeScheduleDto[];
}

export class IngoingContributionRate {
    public id: number;
    public startDate: Date;
    public endDate?: Date;
    public value: number;

    constructor(id: number, startDate: Date, endDate: Date, value: number) {
        this.id = id;
        this.startDate = startDate;
        this.endDate = endDate;
        this.value = value;
    }
}

export class residentDetailDto {
    residentId: number;
    dateOfBirth: string;
}

export class IluAdmissionDto {
    iluAllocationId: number;
    accommodationId: number;
    admissionDate: Date;
    admissionTimeLocal: string;
    responsibleResidencyId: number;
    price: number;
    residentDetails: residentDetailDto[];
}

export class IluSettleUnitDto {
    iluAllocationId: number;
    settlementDate: Date;
    settlementPrice: number;
}

export enum IluStatus {
    Available = 0,
    Occupied = 1,
    OutOfService = 2,
    Sold = 3
}

export class TransferFacilityToFacilityDto {
     residentIds: number[];
     toFacilityId: number;
     transferNotes: string;
     transferType: TransferType;
}

export enum BillPayerType {
    Resident = "Resident",
    PrimaryContact = "PrimaryContact",
    SecondaryContact = "SecondaryContact"
}
