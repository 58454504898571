import { INotificationService } from '../../common/services/INotificationService';
import { ILeadsService } from '../services/ILeadsService';
import { IDownloadFileService } from '../../common/services/IDownloadFileService';
import { LeadDashboardFilterDto, Gender, LeadCareType } from '../Models';
import _ from 'underscore';

class LeadDashboardFilterComponent implements ng.IComponentOptions {

    public bindings: any = {
        leadDashboardFilter: "=",
        dashboardLoadCallback: "&"
    };
    public templateUrl: string = 'app/leads/partials/leadDashboardFilter.html';

    public controller: any = class LeadDashboardFilterComponentController {
        static $inject = ["leads.service", "notification.service", "downloadFile.service"];

        leadDashboardFilter: LeadDashboardFilterDto;
        leadGender: any[] = [];
        leadLocation: any[] = [];
        private anaccVariableComponents: any[] = [];
        displayAnaccVariableComponents: any[] = [];
        selectedLocation: any;
        selectedGender: any;
        showFilter: boolean = false;
        dashboardLoadCallback: () => any;

        public sexList = [
            { value: Gender.Male, displayText: "Male" },
            { value: Gender.Female, displayText: "Female" },
            { value: Gender.IntersexOrIndeterminate, displayText: "Intersex/Indeterminate" },
            { value: Gender.Unknown, displayText: "Unknown" }];

        constructor(private readonly leadsService: ILeadsService,
            private readonly notificationService: INotificationService,
            private readonly downloadFileService: IDownloadFileService) {
            this.loadLeadFiltersData();
            this.setFilterDefaults();
        }

        private setFilterDefaults = () => {
            this.leadDashboardFilter.leadCareType = LeadCareType.All;
            this.leadDashboardFilter.locationId = 0;
            this.leadDashboardFilter.gender = null;
            this.leadDashboardFilter.anaccClass = null;
            this.selectedLocation = null;
            this.selectedGender = null;
        }

        public applyFilters() {
            this.leadDashboardFilter.locationId = this.selectedLocation != null ? this.selectedLocation.id : null;
            this.leadDashboardFilter.gender = this.selectedGender != null ? this.selectedGender.value : null;
            this.dashboardLoadCallback();
            this.updateAnaccVariableComponentFilter();
        }

        private loadLeadFiltersData = () => {
            this.leadsService.getLeadFiltersData().then(result => {
                this.leadLocation = result.leadLocations;
                this.setGenderDisplayText(result.genders);
                this.anaccVariableComponents = result.anaccVariableComponents;
                this.updateAnaccVariableComponentFilter();
            }, () => {
                this.notificationService.error("Unexpected error while loading lead filters.");
            });
        }

        public setGenderDisplayText = (genders: any[]) => {
            genders.forEach(gender => {

                this.leadGender.push({
                    value: this.sexList.filter(x => x.value == gender)[0].value,
                    displayText: this.sexList.filter(x => x.value == gender)[0].displayText
                });
            });
        }

        public showFilters(showFlag) {
            this.showFilter = showFlag;
            if (!showFlag) {
                this.setFilterDefaults();
                this.dashboardLoadCallback();
            }
        };

        public exportLeadsReport = () => {
            this.leadsService.getLeadsReportExcelStream().then((response) => {
                this.downloadFile(response, 'ResidentManagerLeadsExport.xlsx');
            },
                () => {
                    this.notificationService.error("Error occurred while exporting leads report.");
                });
        }

        private downloadFile = (response: ArrayBuffer, fileName: string) => {
            this.downloadFileService.downloadFile(response, fileName);
        }

        private updateAnaccVariableComponentFilter = () => {
            if (this.leadDashboardFilter.leadCareType === LeadCareType.Permanent) {
                this.displayAnaccVariableComponents = (_.filter(this.anaccVariableComponents, (anaccVariableComponent) => anaccVariableComponent.isPermanent)).slice(0);
            } else if (this.leadDashboardFilter.leadCareType === LeadCareType.Respite) {
                this.displayAnaccVariableComponents = (_.filter(this.anaccVariableComponents, (anaccVariableComponent) => anaccVariableComponent.isRespite)).slice(0);
            } else {
                this.displayAnaccVariableComponents = this.anaccVariableComponents.slice(0);
            }

            this.displayAnaccVariableComponents.unshift({
                anaccClass: "Unknown",
                anaccClassText: "Unknown",
                nwau: 0,
                rate: 0,
                totalCareMinutes: null,
                registeredNurseCareMinutes: null,
                isPermanent: false,
                isRespite: false,
                isDefault: false
            });
        }
    }
}
export = LeadDashboardFilterComponent;