class TemplateRequestInterceptor implements ng.IHttpInterceptor {
    static gitCommitHash: string;
    static factory(): TemplateRequestInterceptor {
        return new TemplateRequestInterceptor();
    }

    request = (config: ng.IRequestConfig): ng.IRequestConfig => {
        if (config.method.toLowerCase() === 'get' && config.url.indexOf('partials') !== -1) {
            config.url = config.url + '?hash=' + TemplateRequestInterceptor.gitCommitHash;
        }
        return config;
    }
}

export = TemplateRequestInterceptor;
