import DataService = require('../../common/services/DataService');
import { SundryCharge, SundryChargeFilter, Resident } from '../Models';

class BillingService {
    static $inject = [
        "data.service"
    ];

    constructor(
        private readonly dataService: DataService,
    ) {
    }

    getSundryCharges = (filter: SundryChargeFilter, notIncludingItemsAfter: string, skip: Number, take: Number): ng.IPromise<SundryCharge[]> => {
        return this.dataService.get("billing/sundry", true, {
            sundryChargeStatus: filter.sundryChargeStatus,
            notIncludingItemsAfter: notIncludingItemsAfter,
            skip: skip,
            take: take
        }, false);
    };

    saveSundryCharge = (sundryCharge) => {
        return this.dataService.post("billing/sundry", sundryCharge);
    };
    
    getSundryCharge = (sundryChargeId : number) : ng.IPromise<SundryCharge> => {
        return this.dataService.get(`billing/sundry/${sundryChargeId}`, false, null, false);
    };

    getSundryResidents = (): ng.IPromise<Resident[]> => {
        return this.dataService.get("residents/sundryresidents", true, null, false);
    };

    postSundryCharge = (sundryCharge : SundryCharge) : ng.IPromise<SundryCharge> => {
        return this.dataService.post(`billing/sundry/post`, sundryCharge);
    };

    deleteSundryCharge = (sundryChargeId: number) : ng.IPromise<SundryCharge> => {
        return this.dataService.delete(`billing/sundry/${sundryChargeId}`);
    }
}

export = BillingService