export class Constants {
    static readonly CARDS_NUMBER_TAKE_EACH_TIME: number = 10;
}

export class Resident {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    street1: string;
    street2: string;
    suburb: string;
    stateDescription: string;
    postCode: string;
    countryId?: number;
    primaryContact: ResidentContact;
    secondaryContact: ResidentContact;
}

export class ResidentContact {
    firstName: string;
    lastName: string;
    email: string;
    street1: string;
    street2: string;
    suburb: string;
    stateDescription: string;
    postCode: string;
    country: string;
}

export class ResidentFinanceFees {
    supportLevel: string;
    refundableAccommodationDepositPaid: number;
    refundableAccommodationDepositRemaining: number;
    agreedRoomPrice: number;
    unsupportedBalanceProgressPercentage: number;
    refundableAccommodationContributionPaid: number;
    refundableAccommodationContributionRemaining: number;
    refundableAccommodationContributionCalculated: number;
    supportedBalanceProgressPercentage: number;
    pre2014CareLevel: string;
    pre2014Care: boolean;
    pre2014ContinueCare?: boolean;
    pre2014BondBalanceProgressPercentage: number;
    pre2014BondOrRoomPrice: number;
    pre2014RefundableBondPaid: number;
    pre2014RefundableBondDepositRemaining: number;
    drawdowns: number;
    expectedLumpSumAmount  : number;
    expectedLumpSumPaymentDateUtc : Date;
}

export class UpdateResident {
    resident: Resident;
}

export class TourFacilitatorUser {
    id: number;
    displayName: string;
}

export class TourDto {
    id: number;
    tourDateTime: Date;
    tourFacilitatorUserId: number;
    tourFacilitatorUserName: string;
    attendees: string;
    residencyId: number;
    tourFacilitatorUsers: TourFacilitatorUser[];
    isCompleted: boolean;
}

export class IluEnquiryInfoDto
{
    enquiriesInLeadCount  : number;
    enquiriesWithDepositPaidCount: number
    enquiriesOnATourCount : number

    depositPaidAndUnitInfo : IluDepositPaidAndUnitInfoDto[];
    iluTourCompletedList : IluTourCompletedDto[];
    enquiryDetails : IluEnquiryDto[];
}

export class IluDepositPaidAndUnitInfoDto
{
    depositPaidDateUtc : Date;
    accommodationId : number;
    roomNumber: string;
    firstResidentId : number;
    frstResidentFullName : string;
    secondResidentFullName : string;
    independentLivingAllocationId : number;
}

export class IluTourCompletedDto
{
    rourDateTime : Date;
    firstResidentId : number;
    attendees : string;
    firstResidencyId : number;
    tourFacilitatorUserName : string;
    firstResidentFullName : string;
    secondResidentFullName : string;
    independentLivingAllocationId : number;

}

export class IluTourDto
{
    iluTourCompletedList : IluTourCompletedDto[];
}
    
 export class IluEnquiryDto
{
    createdOnUtc : Date;
    independentLivingAllocationId : number;
    firstResidentId : number;
    firstResidentFullName : string;
    secondResidentFullName : string;
}

export enum EnquiryStatus   {
    Enquiries = "Enquiries",
    TourBooked = "TourBooked",
    TourCompleted = "TourCompleted",
    Waiting = "Waiting",
    Confirmed = "Confirmed"
}

export enum Gender {
    Unknown = "Unknown",
    Female = "Female",
    Male = "Male",
    IntersexOrIndeterminate = "IntersexOrIndeterminate"
}

export enum CareType {
    Permanent = 0,
    Respite = 1,
    ILU = 2
}

export enum LeadCareType {
    All = "All",
    Permanent = "Permanent",
    Respite = "Respite"
}

export enum RespiteCareLevel {
    Unknown = 0,
    Low = 1,
    High = 2
}

export enum AcfiLevel {
    Default = 0,
    Nil = 1,
    Low = 2,
    Medium = 3,
    High = 4
}

export enum IncomeAssetStatus {
    Unknown = "Unknown",
    Completed = "Completed",
}

export class LeadCardDto {
    residentId: number;
    residencyId: number;
    firstName: string;
    lastName: string;
    sex?: Gender;
    careType: CareType;
    respiteCareLevel? : RespiteCareLevel;
    adl: AcfiLevel;
    beh: AcfiLevel;
    chc: AcfiLevel;
    permanentCareLevel : string;
    createdOnUtc: Date;
    supportStatus: string;
    location: string;
    incomeAssetStatus? : IncomeAssetStatus;
    hasPreviousResidency: boolean;
    orderNumber: string;
    isDeceased: boolean;
    isArchived: boolean = false;
    tourInfo: TourDto;
    archiveCallback: (residenId: number) => any;
    sendToLeadsCallback: (residenId: number) => any;
    removeFromLeadsCallback: (residentId: number) => any;
    markAsDeceasedCallback: (residentId: number) => any;
    cardMoved: () => any;
    isPrivateResidency: boolean;
    expectedAnaccVariableComponent: any;
}

export class BatchResult<T> {
    notIncludingItemsAfter: Date;
    totalCount: number;
    result: T[];
}

export class LeadDashboardDto {
    enquiries: BatchResult<LeadCardDto>;
    tourBooked: BatchResult<LeadCardDto>;
    tourCompleted: BatchResult<LeadCardDto>;
    waiting: BatchResult<LeadCardDto>;
    confirmed: BatchResult<LeadCardDto>;
}
export class ResidentFundingEstimateDto {
    residencyId: number;
    aDLScore: AcfiLevel;
    bEHScore: AcfiLevel;
    cHCScore: AcfiLevel;
    dailyAmount: number;
    respiteCareLevel: RespiteCareLevel;
}

export class StatusPanel {
    public status: EnquiryStatus;           
    public isContractSigned: boolean;
    public isDeceased: boolean;
}

export class PreAdmissionLocation {
    public id: number;
    public name: string;
}

export class AccommodationPanel {
    public preferredFacilityLocationId?: number;
    public availableLocations: PreAdmissionLocation[];
}

export class IncomeAssetPanel {
    public status: IncomeAssetStatus;           
}
export class LeadDashboardFilterDto {
    gender: Gender | null;
    locationId: number;
    leadCareType: LeadCareType;
    anaccClass: string | null;
}