"use strict";

module.exports = function () {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            residentId: "=",
            isPrivateResidency: "="
        },
        controller: "residentFinanceFees.controller",
        controllerAs: "vm",
        templateUrl: "app/residents/directives/partials/residentFinanceEditorFees.html"
    };
}
