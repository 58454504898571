"use strict";

module.exports = function () {
    return {
        restrict: "AE",
        scope: {},
        controller: "residentSearch.controller",
        controllerAs: "vm",
        replace: false,
        templateUrl: "app/common/directives/partials/residentSearch.html"
    };
}
