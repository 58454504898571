/// <reference path="../../common/constants.ts" />
"use strict";
const angular = require("angular");
const moment = require("moment");
const _ = require("underscore");

module.exports = [
    "residents.service",
    "notification.service",
    "modalConfig",
    "$state",
    "dialog.service",
    "DefaultTime",
    "featureFlags.service",
    function (residentsService, notification, modalConfig, $state, dialogService, DefaultTime, featureFlagsService) {
        var vm = this;
        angular.extend(vm, modalConfig);

        vm.isReadOnly = modalConfig.isReadOnly;
        vm.bindHide = function ($hide) {
            vm.$hide = $hide;
        };

        vm.residentialCareIdFieldMaxLength = 10

        residentsService.getResidentAdmissionInformation(vm.residentId)
            .then(function (result) {
                vm.residentAdmissionInfo = result;
                vm.residentAdmissionInfo.admissionDateUtc = moment(vm.residentAdmissionInfo.admissionDateUtc);
                if (!vm.residentAdmissionInfo.admissionTimeLocal) {
                    vm.residentAdmissionInfo.admissionTimeLocal = DefaultTime;
                }
                if (vm.residentAdmissionInfo.expectedDepartureDateUtc) {
                    vm.residentAdmissionInfo.expectedDepartureDateUtc =
                        moment(vm.residentAdmissionInfo.expectedDepartureDateUtc);
                }

                if (vm.residentAdmissionInfo.careType === "Permanent" && vm.residentAdmissionInfo.preEntryLeaveDateUtc) {
                    vm.hasPreEntryLeaveDate = true;
                    var preEntryLeaveDateUtc = moment(vm.residentAdmissionInfo.preEntryLeaveDateUtc);
                    vm.preEntryDays = vm.residentAdmissionInfo.admissionDateUtc.diff(preEntryLeaveDateUtc, "days");
                }

                if (vm.residentAdmissionInfo.conflictEffectiveDates) {
                    vm.residentAdmissionInfo.conflictEffectiveDates = _.map(vm.residentAdmissionInfo.conflictEffectiveDates,
                        function (effectiveDate) {
                            effectiveDate.date = moment(effectiveDate.date);
                            return effectiveDate;
                        });
                }

            },
                function () {
                    notification.error("Unable to display admission details. Either your computer is offline, or Resident Manager is having problems of its own.");
                });

        vm.minDepartureDate = function () {
            return moment(vm.residentAdmissionInfo.admissionDateUtc).add(1, 'd').startOf('day').toDate();
        }

        vm.preEntryDate = function () {
            if (vm.preEntryDays && vm.hasPreEntryLeaveDate && vm.residentAdmissionInfo.admissionDateUtc) {
                return moment(vm.residentAdmissionInfo.admissionDateUtc)
                    .subtract(vm.preEntryDays, "d")
                    .zone("Z")
                    .startOf("day")
                    .toDate();
            }
            return null;
        };

        vm.isValidDateBasedOnEffectiveDates = function (value) {

            var isValid = true;
            _.each(vm.residentAdmissionInfo.conflictEffectiveDates,
                function (effectiveDate) {
                    // value < 0 , means the new value is greater than effective date
                    var diff = effectiveDate.date.diff(value, 'days');
                    if (diff <= 0) {
                        isValid = false;
                    }
                });
            return isValid;
        };

        vm.submitResidentAdmission = function (residentAdmissionForm) {
            if (residentAdmissionForm.$valid) {
                vm.residentAdmissionInfo.preEntryLeaveDateUtc = vm.preEntryDate();

                residentsService.correctResidentAdmission(vm.residentAdmissionInfo, vm.uniqueEventId, vm.residentAdmissionInfo.isPrivateResidency)
                    .then(function () {
                        vm.$hide();
                        //this dialog can be called from multiple screens. Reload the page if no onModalCloseCallback has been passed.
                        if (modalConfig.onModalCloseCallback != undefined) {
                            if (vm.fromEventHistory) {
                                modalConfig.onModalCloseCallback('admissionCorrection');
                            }
                            else {
                                modalConfig.onModalCloseCallback();
                            }
                        }
                        else {
                            $state.reload();
                        }

                        notification.success("Admission corrected.");
                    },
                        function (error) {
                            if (!error.modelStateSummary) {
                                notification
                                    .error("Unexpected error occurred while submitting correction for resident admission.");
                            } else {
                                dialogService.openMessageDialog(
                                    "Correct admission",
                                    "Admission cannot be corrected.",
                                    "residentEntry-error",
                                    _.values(error.modelStateSummary));
                            }
                        });
            }
        };

        vm.reverseEvent = function () {
            if (modalConfig.isDeparted != undefined && modalConfig.isDeparted === true) {
                dialogService.openMessageDialog("Unable to reverse", "This event cannot be reversed.", "reversal-error");
                return;
            }

            dialogService.openDialog("app/funding/partials/reverseEvent.html",
                "reverseEvent.controller",
                {
                    width: "100px",
                    selectedEvent: {
                        uniqueEventId: vm.uniqueEventId,
                        residentId: vm.residentId,
                        requestType: vm.requestType
                    },
                    onModalCloseCallback: vm.reverseEventCallBack
                });
        };

        vm.reverseEventCallBack = function reverseEventCallBack() {
            if (modalConfig.onModalCloseCallback != undefined) {
                modalConfig.onModalCloseCallback('admissionReversed');
            }
            vm.$hide();
        }

        vm.expectedDurationOfStayForRespiteCare = function () {
            if (vm.residentAdmissionInfo.admissionDateUtc && vm.residentAdmissionInfo.expectedDepartureDateUtc) {
                var expectedDepartureMoment = moment(vm.residentAdmissionInfo.expectedDepartureDateUtc);
                var admissionDateMoment = moment(vm.residentAdmissionInfo.admissionDateUtc);
                var dateDiffInDays = expectedDepartureMoment.diff(admissionDateMoment, "days");
                if (dateDiffInDays > 0) {
                    return dateDiffInDays === 1 ? dateDiffInDays + " day" : dateDiffInDays + " days";
                }
            }
            return "-";
        }
    }
];
