import * as _ from "underscore";
import { ILeadsService } from "../services/ILeadsService";
import { INotificationService } from '../../common/services/INotificationService';
import {EnquiryStatus, AccommodationPanel, PreAdmissionLocation} from '../Models';

class preferredLocationComponent implements ng.IComponentOptions {
    public bindings: any = {
        residencyId: "=",
        isResident: "=",
        accommodationInfo: "="
    };

    public templateUrl: string = 'app/leads/partials/preferredLocation.html';

    public controller: any = class preferredLocationComponentController {
        static $inject = ["leads.service", "notification.service"];

        public residencyId: number;
        public isResident: boolean;
        public accommodationInfo: AccommodationPanel;           
        public isEditMode: boolean;
      
        public availableLocations: PreAdmissionLocation[] = [];
        public viewModeLocation: PreAdmissionLocation; 
        public preferredLocation: PreAdmissionLocation;

        public noLocation: PreAdmissionLocation = {id: 0, name: ""};

        constructor(private readonly leadsService: ILeadsService,    
            private readonly notificationService: INotificationService) {    
                this.availableLocations.push(this.noLocation);   //Add no location for dropdown list
                this.availableLocations = this.availableLocations.concat(this.accommodationInfo.availableLocations);               
              
                if (this.accommodationInfo.preferredFacilityLocationId != null) {                   
                    this.preferredLocation = _.findWhere(this.accommodationInfo.availableLocations, {id: this.accommodationInfo.preferredFacilityLocationId});
                    this.viewModeLocation = this.preferredLocation;
                }
                else {                    
                    this.preferredLocation = this.noLocation;
                    this.viewModeLocation = this.noLocation;
                }

                this.isEditMode = false; 
        }

        public showEditMode = () => {
            this.isEditMode = true;
            this.preferredLocation = this.viewModeLocation;
        }

        public hideEditMode = () => {
            this.isEditMode = false;
        }

        public save = () => {            
            if (!this.isResident) {
                let locationId: any =  this.preferredLocation.id;
                if (locationId == 0) 
                    locationId = null;

                this.leadsService.updatePreferredFacilityLocation(this.residencyId, locationId).then(response => {
                    this.isEditMode = false;
                    this.viewModeLocation = this.preferredLocation;
                    this.accommodationInfo.preferredFacilityLocationId = this.preferredLocation.id;
                    this.notificationService.success("Lead accommodation updated successfully!");
                },
                () => {
                    this.notificationService.error("Unexpected error occurred while updating lead accommodation.");
                });
            }
        }
    }
}
export = preferredLocationComponent;