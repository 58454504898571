import { INotificationService } from '../../common/services/INotificationService';
import { IluSettleUnitDto } from '../Models';
import { IIndependentLivingService } from '../services/IIndependentLivingService';
import _ = require('underscore');
import type { IStateService } from 'angular-ui-router';

class SettleUnitDialogController {
    static $inject = ["independentLiving.service", "notification.service", "$state", "modalConfig"];
    public $hide: any;
    public title: string;

    public enquiryId : number;
    public unitNumber: string;
    public settlementPrice: number;
    public settlementDate: Date;
    public vacatingDate: Date;

    constructor(
        private readonly independentLivingService: IIndependentLivingService,
        private readonly notificationService: INotificationService,
        private $state: IStateService,
        private readonly modalConfig: any) {

        this.enquiryId = modalConfig.enquiryId;
        this.unitNumber = modalConfig.unitNumber;
        this.settlementPrice = modalConfig.price;
        this.settlementDate = modalConfig.date;
        this.vacatingDate = modalConfig.vacatingDate;
        this.title = "Finalise settlement";
    }
    public bindHide = (hide) => {
        this.$hide = hide;
    };

    public settle = (isValid) => {
        if (isValid) {            
            let iluSettleUnitDto: IluSettleUnitDto = {
                iluAllocationId: this.enquiryId,
                settlementDate: this.settlementDate,
                settlementPrice: this.settlementPrice
            };
            this.independentLivingService.settleUnit(iluSettleUnitDto).then(result => {
                    this.$hide();
                    this.notificationService.success("Settlement finalised");
                    this.$state.reload();
            },
            () => {
                this.notificationService.error("Unexpected error occurred while finalising settlement");
            });
        }
    }
}

export = SettleUnitDialogController;
