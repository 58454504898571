"use strict";

module.exports = function () {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            subsidy: "=",
            description: "@",
            col1: "@",
            col2: "@",
            col3: "@",
            desc1: "@",
            desc2: "@",
            desc3: "@"
        },
        templateUrl: "app/funding/directives/partials/statementDailySubsidyAcfi.html"
    };
}
