﻿import { MovementsReportFilter, BillingExtractFilter, FinancialDetailsReportFilter, EnquirySourceReportFilter, UnitsReportFilter, RoomsReportFilter, SettlementsReportFilter, ClassificationHistoryReportFilter, PalliativeAssessmentsReportFilter, ReassessmentRequestsReportFilter, PotentialReassessmentsReportFilter } from '../Models';

export interface IReportingService {
    getMovementsReportData(notIncludingItemsAfter: string, skip: number, take: number, reportFilters: MovementsReportFilter): ng.IPromise<any>;
    getMovementsReportExcelStream(reportFilters: MovementsReportFilter): ng.IPromise<any>;
    getUnitsReportData(notIncludingItemsAfter: string, skip: number, take: number, reportFilters: UnitsReportFilter): ng.IPromise<any>;
    getSettlementsReportData(notIncludingItemsAfter: string, skip: number, take: number, reportFilters: SettlementsReportFilter): ng.IPromise<any>;
    getRoomsReportExcelStream(reportFilters: RoomsReportFilter): ng.IPromise<any>;
    getBillingExtractCsvStream(billingExtractFilter: BillingExtractFilter): ng.IPromise<any>;
    getExportForAttacheCsvStream(billingExtractFilter: BillingExtractFilter): ng.IPromise<any>;
    getfinancialDetailsReportExcelStream(financialDetailsReportFilter: FinancialDetailsReportFilter): ng.IPromise<any>;
    getpalliativeAssessmentsReportExcelStream(palliativeAssessmentsReportFilter: PalliativeAssessmentsReportFilter): ng.IPromise<any>;
    getFacilityLocationsWithAccommodationTypes();
    getExpectedPaymentsReportExcelStream(monthAndYear: string): ng.IPromise<any>;
    getActualPaymentsReportExcelStream(monthAndYear: string): ng.IPromise<any>;
    getAdjustmentSummaryReportExcelStream(monthAndYear: string): ng.IPromise<any>;
    getVarianceStatementExcelStream(monthAndYear: string, selectedFilter: string);
    getEnquirySourceReportData(notIncludingItemsAfter: string, skip: number, take: number, reportFilters: EnquirySourceReportFilter): ng.IPromise<any>;
    getEnquirySourceReportExcelStream(reportFilters: EnquirySourceReportFilter): ng.IPromise<any>;
    getUnitReportExcelStream(reportFilters: UnitsReportFilter): ng.IPromise<any>;
    getSettlementsReportExcelStream(reportFilters: SettlementsReportFilter): ng.IPromise<any>;
    getClassificationHistoryReportCsvStream(reportFilters: ClassificationHistoryReportFilter): ng.IPromise<any>;
    getReassessmentRequestsReportExcelStream(reportFilters: ReassessmentRequestsReportFilter): ng.IPromise<any>;
    getPotentialReassessmentsReportExcelStream(potentialReassessmentsReportFilter: PotentialReassessmentsReportFilter): ng.IPromise<any>;
}