"use strict";
const _ = require("underscore");

module.exports =
    ["data.service",
        function (dataService) {
            var vm = this;

            vm.searchResidents = function (searchString, httpCancelerPromise) {
                return dataService.get("residents/search?searchStr=" + searchString, true, { timeout: httpCancelerPromise }).then(function (response) {
                    return { results: _.first(response, 10) };
                });
            };

            vm.busy = false;
            vm.searchResidentsForInputTags = function (searchString) {
                vm.busy = true;
                return dataService.get("residents/search?searchStr=" + searchString, true)
                    .then(function (response) {
                        vm.busy = false;
                        return response;
                    });
            };
        }
    ];
