import * as angular from 'angular';
import * as _ from "underscore";
import { IFilterService } from "angular";

interface ITablePopoverControllerScope extends ng.IScope {
    options: TablePopoverOptions;
    data: any;
}

class TablePopoverOptions {
    public id: string;
    public placement: string;
    public target: string;
    public trigger: string;
    public autoClose: boolean;
    public popoverCssClass: string;
    public columns: Array<TablePopoverColumn>;
    public getRowCssClass: (row: any) => string;
}

class TablePopoverColumn {
    public headerText: string;
    public headerCssClass: string;
    public dataFieldName: string;
    public cellCssClass: string;
    public filterName: string;
    public format: string;
    public currencySymbol: string;
    public currencyDecimalPlace: number;
    public onCellCreated: (rowIndex: number, formattedValue: string) => string;
}

class TablePopoverController {
    static $inject = ["$scope", "$popover", "$timeout", "$filter"];

    private filterService: IFilterService;    
    public templateUrl: string;
    popoverOption: any = {};
    popover: any = null;

    constructor(
        private $scope: ITablePopoverControllerScope,
        private $popover: mgcrea.ngStrap.popover.IPopoverService,
        private $timeout: ng.ITimeoutService,
        private $filter: IFilterService
    ) {
        this.ShowContent();
    }

    public updateData(data:any) {
        this.$scope.data = data;
        this.ShowContent();
    }

    private ShowContent() {
        this.filterService = this.$filter;
        if (typeof this.$scope.options !== 'undefined') {
            if (this.$scope.options.id == 'radpaid-link' || this.$scope.options.id == 'racpaid-link' ||
                this.$scope.options.id == 'dap-link' || this.$scope.options.id == 'topupdac-link') {
                this.templateUrl = "app/common/directives/partials/tablePopoverForceSize.html";
            }
            else {
                this.templateUrl = "app/common/directives/partials/tablePopover.html";
            }

            this.popoverOption.templateUrl = this.templateUrl;
            this.popoverOption.placement = this.$scope.options.placement ? this.$scope.options.placement : "bottom";
            if (this.$scope.options.target) {
                this.popoverOption.target = this.$scope.options.target;
            }
            this.popoverOption.autoClose = this.$scope.options.autoClose ? this.$scope.options.autoClose : false;

            this.popoverOption.html = true;
            this.popoverOption.content = this.createContent();

            // this delay allows for instances where the IDs contained on the parent template are calculated dynamically
            this.$timeout(() => { 
                if (this.popover) {
                    this.popover.destroy();
                }
                this.popover = this.$popover(<any>angular.element(document.querySelector('#' + this.$scope.options.id)), this.popoverOption) 
            }, 500);
        }
    }

    private createContent(): string {
        let content: string = `<div class='${this.$scope.options.popoverCssClass}'>`;
        content += this.createTableHeader();
        content += "<div class='rows'>"
        _.each(this.$scope.data, (d, i) => {
            content += this.createTableRow(d, i);
        });
        content += "</div></div>";
        return content;
    }

    private createTableHeader(): string {
        let headerRow: string = "<div class='row header-row'>";
        _.each(this.$scope.options.columns, (col) => {
            headerRow += `<div class='${col.headerCssClass}'>${col.headerText}</div>`;
        });
        headerRow += "</div>";

        return headerRow;
    }

    private createTableRow(row: any, index: number): string {
        let css = this.$scope.options.getRowCssClass ? this.$scope.options.getRowCssClass(row) : '';
        let tableRow: string = `<div class='row ${css}'>`;
        _.each(this.$scope.options.columns, (col) => {
            var formattedValue;
            switch (col.filterName) {
                case 'currency':
                    formattedValue = this.filterService(col.filterName)(row[col.dataFieldName],
                        col.currencySymbol, col.currencyDecimalPlace);
                    break;
                case 'date':
                    formattedValue = this.filterService(col.filterName)(row[col.dataFieldName],
                        col.format);
                    break;
                case 'dateInUtcTimezone':
                    formattedValue = this.filterService('date')(row[col.dataFieldName],
                        col.format, 'UTC');
                    break;
                    
                default:
                    formattedValue = row[col.dataFieldName];
                    break;
            }

            if (col.onCellCreated) {
                formattedValue = col.onCellCreated(index, formattedValue);
            }
            tableRow += `<div class='${col.cellCssClass}'>${formattedValue}</div>`;
        });
        tableRow += "</div>";

        return tableRow;
    }
}

export = TablePopoverController;