import { MedicareMappingDto } from "../../common/Models";
import { IReferenceDataService } from "../../common/services/IReferenceDataService"

class ExtraServiceComponent implements ng.IComponentOptions {
    public templateUrl: string = 'app/residents/partials/extraService.html';
    public bindings: any = {
        extraServiceCode: "=",
        isEditable: "<"
      };

    public controller: any = class ExtraServiceComponentController {
        static $inject = ["referenceData.service"];
        public extraServices : MedicareMappingDto[] = [];
        public selectedExtraServiceId : number;
        public extraServiceCode: string;
        public isEditable: boolean;
        public selectedExtraService : MedicareMappingDto;
        constructor(private readonly referenceDataService : IReferenceDataService)
        {
            this.getExtraServies();
        }

        public getExtraServies = () => {
            this.referenceDataService.getExtraServices().then((resp) => {
                this.extraServices = resp;
                if(this.extraServiceCode!=null)
                {
                    this.selectedExtraService = this.extraServices.filter(x=>x.code == this.extraServiceCode)[0];
                    this.selectedExtraServiceId = this.selectedExtraService.id;
                }
            });
        }

        public onExtraServiceSelectCallback = ($item) => {
            this.selectedExtraServiceId = $item.id;
            this.extraServiceCode = $item.code;
        }
    }
}
export = ExtraServiceComponent