import * as _ from 'underscore';
import * as moment from 'moment';
import { INotificationService } from '../../common/services/INotificationService';
import { IInfiniteLoaderFactory } from '../../common/services/IInfiniteLoaderService';
import BillingService = require('../services/BillingService');
import { Constants } from '../../common/Constants';
import { SundryCharge, SundryChargeFilter } from '../Models';
import type { IStateService } from 'angular-ui-router';

class ViewSundryChargesController {
    static $inject = ["billingService", "notification.service", "infiniteLoader.service", "$state"];
    public infiniteLoader: any;
    public charges: SundryCharge[];
    public noPhotoImage: string;
    public sundryChargeStatusFilter: string;

    constructor(
        private billingService: BillingService,
        private notificationService: INotificationService,
        private infiniteLoaderService: IInfiniteLoaderFactory,
        private $state: IStateService
    ) {

        this.noPhotoImage = Constants.NO_PHOTO_IMAGE;
        this.getSundryCharges('All');
    }

    private markChargesWithAHeader = () => {
        _.each(this.charges, function (charge, i, charges) {
            if (i === 0) {
                charge.addHeader = charge.invoiceDateUtc !== null ? true : false;
            }
            else {
                let invoiceDate = moment(charge.invoiceDateUtc);
                let previousInvoiceDate = moment(charges[i - 1].invoiceDateUtc);

                charge.addHeader = (invoiceDate.format('YYYY') !== previousInvoiceDate.format('YYYY')) ||
                    (invoiceDate.format('M') !== previousInvoiceDate.format('M'));
            }

        });
    }

    public getSundryCharges = (sundryChargeStatus: string) => {
        this.charges = new Array<SundryCharge>();
        this.sundryChargeStatusFilter = sundryChargeStatus;

        this.infiniteLoader = this.infiniteLoaderService.instanceWithFilter(this.billingService.getSundryCharges,
            this.getSundryChargeFilter(),
            (result) => {
                this.charges = this.charges.concat(result);
                this.markChargesWithAHeader();
            },
            () => {
                this.notificationService.error("Unexpected error while loading sundry charges.");
            });
    }

    private getSundryChargeFilter = () => {
        let filter = new SundryChargeFilter();
        filter.sundryChargeStatus = this.sundryChargeStatusFilter;
        return filter;
    }
    public navigateToAdd() {
        this.$state.go("addOrEditSundryCharge", { sundryChargeId: 0 });
    }

    public navigateToView(charge: SundryCharge) {
        this.$state.go("viewSundryCharge", { sundryChargeId: charge.id });
    }
}

export = ViewSundryChargesController;
