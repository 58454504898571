import moment = require("moment");
import type { IStateService } from "angular-ui-router";

class VarianceStatementComponent implements ng.IComponentOptions {
    public bindings: any;
    public templateUrl: string = 'app/funding/partials/varianceStatement.html';

    constructor() {
        this.bindings = {
            monthAndYear: '='
        };
    }

    public controller: any = class VarianceStatementComponentController {
        static $inject = ["$state"];

        public monthAndYear:string;
        public period:string
        constructor(
            public readonly state: IStateService) {
                this.period = moment(this.monthAndYear, "MYYYY").format("MMMM YYYY");
        }
    }
}
export = VarianceStatementComponent;