"use strict";

module.exports = [
    "funding.service",
    "$stateParams",
    "notification.service",
    "$state",
    "July2024",
    "utilities.service",
    function (fundingService, $stateParams, notification, $state, July2024, utilitiesService) {
        var vm = this;
        vm.yearAndMonth = +utilitiesService.convertMonthYearToYearMonth($stateParams.monthAndYear);
        vm.statement = $stateParams.statement;
        vm.july2024 = July2024;
    }
];
