"use strict";
var angular = require("angular");

var module = angular.module("leads.module", ["common.module", "accr.module", "residents.module"]);
require("./states.js").configure(module);

module.controller("createLead.controller", require("./controllers/createLead.controller"));
module.controller("editLead.controller", require("./controllers/editLead.controller"));
module.controller("viewLead.controller", require("./controllers/viewLead.controller"));
module.controller("admitResidentDialog.controller", require("./controllers/admitResidentDialog.controller"));
module.controller("markAsProspectDialog.controller", require("./controllers/markAsProspectDialog.controller"));
module.factory("leads.service", require("./services/leads.service"));

//written in TypeScript
module.controller("bookTour.controller", require("./controllers/BookTourController"));
module.controller("tourBookForLeadsDashboardDialog.controller", require("./controllers/TourBookForLeadsDashboardController"));

//components
module.component("leadsDashboard", new (require("./components/leadsDashboardComponent"))());
module.component("leadsDashboardColumn", new (require("./components/leadsDashboardColumnComponent"))());
module.component("leadCard", new (require("./components/leadCardComponent"))());
module.component("leadStatus", new (require("./components/leadStatusComponent"))());
module.component("preferredLocation", new (require("./components/preferredLocationComponent"))());
module.component("archivedLeads", new (require("./components/archivedLeadsComponent"))());
module.component("leadDashboardFilter", new (require("./components/leadDashboardFilterComponent"))());