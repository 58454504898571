const moment = require("moment");
const _ = require("underscore");

module.exports = [
    "data.service",
    function (dataService) {
        function getMedicareEvents(notIncludingItemsAfter, skip, take, medicareEventsFilter) {
            return dataService.get("funding/medicare-events", true, {
                eventTypes: medicareEventsFilter.eventTypes,
                responseTypes: medicareEventsFilter.responseTypes,
                selectedResidentIds: medicareEventsFilter.selectedResidentIds,
                skip: skip,
                take: take,
                notIncludingItemsAfter: notIncludingItemsAfter,
                toDate: medicareEventsFilter.toDate,
                fromDate: medicareEventsFilter.fromDate
            });
        }

        function fetchPaymentStatement(claimMonth) {
            return dataService.get("funding/payment-statement", false, {
                claimMonth: claimMonth
            });
        }

        function finaliseClaim(claimMonth) {
            return dataService.get("funding/finalise-claim", false, {
                claimMonth: claimMonth
            });
        }

        function fetchStatus() {
            return dataService.get("funding/fetch-medicare-event-status");
        }

        function lastStatusFetchInformation() {
            return dataService.get("funding/last-medicare-status-fetch-date", true);
        }

        function getMedicareStatements() {
            return dataService.get("funding/medicare-statements");
        }

        function getMedicareStatement(monthAndYear) {
            return dataService.get("funding/medicare-statement", false, {
                monthAndYear: monthAndYear
            });
        }

        function getExpectedMedicareStatement(monthAndYear) {
            return dataService.get("funding/expected-statement", false, {
                monthAndYear: monthAndYear
            });
        }
        function getFinalisedMonths(facilityId) {
            return dataService.get("funding/finalised-months", false, {
                facilityId: facilityId
            });
        }

        function downloadStatement(statementId) {
            return dataService.getArrayBuffer("funding/download-statement", false, {
                statementId: statementId
            });
        }

        function getAllCareFundingRates() {
            return dataService.get("funding/care-funding-rates");
        }

        function getAllAccommodationFundingRates() {
            return dataService.get("funding/accommodation-funding-rates");
        }

        function getAllFeesAndCharges() {
            return dataService.get("funding/fees-and-charges");
        }

        function getAcfiLevelMetadata() {
            return [
                {
                    name: "Nil",
                    description: "Nil"
                },
                {
                    name: "Low",
                    description: "Low"
                },
                {
                    name: "Medium",
                    description: "Medium"
                },
                {
                    name: "High",
                    description: "High"
                }
            ];
        }

        function getAcfiDomainMetadata() {
            return [
                {
                    name: "ADL",
                    description: "ADL"
                },
                {
                    name: "BEH",
                    description: "BEH"
                },
                {
                    name: "CHC",
                    description: "CHC"
                }
            ];
        }

        function getSupplementsMetadata() {
            return [
                {
                    name: "Oxygen",
                    description: "Oxygen"
                },
                {
                    name: "EnteralFeedingBolus",
                    description: "Enteral feeding - bolus"
                },
                {
                    name: "EnteralFeedingNonBolus",
                    description: "Enteral feeding - non bolus"
                },
                {
                    name: "AdjustedSubsidyReduction",
                    description: "Adjusted subsidy reduction"
                },
                {
                    name: "Veterans",
                    description: "Veterans'"
                },
                {
                    name: "Homeless",
                    description: "Homeless"
                },
                {
                    name: "TwentyTwentyOneBasicDailyFeeSupplement",
                    description: "2021 Basic daily fee supplement"
                },
                {
                    name: "OutbreakManagementSupport",
                    description: "Outbreak management support"
                }
            ];
        }

        function saveFundingRate(fundingRate) {
            return dataService.post("funding/care-funding-rates/save", fundingRate);
        }

        function saveAccommodationFundingRate(accFundingRate) {
            return dataService.post("funding/accommodation-funding-rates/save", accFundingRate);
        }

        function saveFeesAndCharges(feeAndCharge) {
            return dataService.post("funding/fees-and-charges", feeAndCharge);
        }

        function getEventDetails(eventId, eventType) {
            return dataService.get("funding/medicare-event-info", true, { id: eventId, requestType: eventType }, false);
        }

        getVarianceSummary = monthAndYear => {
            return dataService.get("funding/variance-statement-summary", false, {
                monthAndYear: monthAndYear
            });
        };

        getVariancePayment = monthAndYear => {
            return dataService.get("funding/variance-statement-payment", false, {
                monthAndYear: monthAndYear
            });
        };

        actionAdjustments = (monthAndYear, action, adjustments) => {
            return dataService.post("funding/statement/" + monthAndYear + "/reconciliation-adjustment", {
                action: action,
                adjustments: adjustments
            });
        };

        getAdjustmentSummary = monthAndYear => {
            return dataService.get("funding/adjustment-summary", false, {
                monthAndYear: monthAndYear
            });
        };

        finaliseReconciliation = (monthAndYear) => {
            return dataService.post("funding/statement/" + monthAndYear + "/reconciliation-adjustment/finalise", {});
        };

        addVariancePaymentNote = (monthAndYear, note, lineItem, isCarringOver) => {
            return dataService.post("funding/statement/" + monthAndYear + "/variance-payment-note", {
                note: note,
                lineItem: lineItem,
                isCarringOver: isCarringOver
            });
        }

        return {
            getMedicareEvents: getMedicareEvents,
            fetchPaymentStatement: fetchPaymentStatement,
            fetchStatus: fetchStatus,
            lastStatusFetchInformation: lastStatusFetchInformation,
            getMedicareStatements: getMedicareStatements,
            getMedicareStatement: getMedicareStatement,
            getExpectedMedicareStatement: getExpectedMedicareStatement,
            getFinalisedMonths: getFinalisedMonths,
            downloadStatement: downloadStatement,
            finaliseClaim: finaliseClaim,
            getAllCareFundingRates: getAllCareFundingRates,
            getAllAccommodationFundingRates: getAllAccommodationFundingRates,
            getAllFeesAndCharges: getAllFeesAndCharges,
            getAcfiLevelMetadata: getAcfiLevelMetadata,
            getAcfiDomainMetadata: getAcfiDomainMetadata,
            getSupplementsMetadata: getSupplementsMetadata,
            saveFundingRate: saveFundingRate,
            saveAccommodationFundingRate: saveAccommodationFundingRate,
            saveFeesAndCharges: saveFeesAndCharges,
            getEventDetails: getEventDetails,
            getVarianceSummary: getVarianceSummary,
            getVariancePayment: getVariancePayment,
            actionAdjustments: actionAdjustments,
            getAdjustmentSummary: getAdjustmentSummary,
            finaliseReconciliation: finaliseReconciliation,
            addVariancePaymentNote: addVariancePaymentNote
        };
    }
];
