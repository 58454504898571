import { Relationship, Country, EnquirySourceDto, ReligionDto } from '../Models';

export interface IReferenceDataService {
    getRelationships: () => ng.IPromise<Array<Relationship>>;
    getGstRate: () => ng.IPromise<number>;
    getCountries: ()=> ng.IPromise<Array<Country>>;
    getExtraServices: ()=> ng.IPromise<Array<MedicareMapping>>;
    getEnquirySources: () => ng.IPromise<Array<EnquirySourceDto>>;
    getReligions: () => ng.IPromise<Array<ReligionDto>>;
    getAcfiRates : () => ng.IPromise<any>;
}