class RedirectToLoginController {
    static $inject = ["authentication.service"];

    constructor(
        private readonly authenticationService: any
    ) {
        authenticationService.redirectToLoginIfNotLoggedIn();
    }
}

export = RedirectToLoginController;