"use strict";

import '@uirouter/angularjs/release/stateEvents';

var angular = require("angular");
//Explicitly importing the dependancies for ui.sortable
//as web pack doesn't import them automatically.
require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/disable-selection');

// Code from bower with no NPM package
require("./directives/ng-scroll-to-error/ng-scroll-to-error");
require("./services/displayMask/display-mask");

var module = angular.module("common.module", ["ui.router", 
        "ui.router.upgrade",
        "ui.router.state.events",
        "ngMessages", 
        "mgcrea.ngStrap", 
        "ui.mask", 
        "ui.sortable",
        "ngScrollToError", 
        "localytics.directives", 
        "authentication.module", 
        "infinite-scroll", 
        "angucomplete-alt", 
        "ngFileUpload", 
        "angularMoment", 
        "ui.select", 
        "ngSanitize", 
        "displayMask", 
        "LocalStorageModule", 
        "angularjs-dropdown-multiselect",
        'ngTagsInput']);

module.config(require("./runModules/toStateTracker"));

module.run(require("./runModules/templatesLoader"));
module.run(require("./runModules/ngStrapModalTracker"));
module.run(require("./signalr/underMaintenance"));


module.value("appSettings", require("./configs/config"));
module.factory("notification.service", require("./services/notification.service"));
module.factory("dialog.service", require("./services/dialog.service"));
module.factory("referenceData.service", require("./services/referenceData.service"));
module.directive("loadingIndicator", require("./directives/loadingIndicator.directive"));
module.directive("currencyInput", require("./directives/currencyInput.directive"));
module.directive("currencyInputValidation", require("./directives/currencyInputValidation.directive"));
module.factory("fileReader.service", require("./services/fileReader.service"));
module.factory("utilities.service", require("./services/utilities.service"));
module.directive("stickySidebar", require("./directives/stickySidebar.directive"));
module.factory("dateFormatting.service", require("./services/dateFormatting.service"));
module.directive("inlineAddress", require("./directives/inlineAddress.directive"));
module.directive("photoSelector", require("./directives/photoSelector.directive"));
module.directive("stickyActionbar", require("./directives/stickyActionbar.directive"));
module.directive("unsavedChangesWarning", require("./directives/unsavedChangesWarning.directive"));
module.directive("toggleButton", require("./directives/toggleButton.directive"));
module.directive("fileUploadButton", require("./directives/fileUploadButton.directive"));
module.directive("infiniteScrollLoadingIndicator", require("./directives/infiniteScrollLoadingIndicator.directive"));
module.directive("showHidePassword", require("./directives/showHidePassword.directive"));
module.controller("residentSearch.controller", require("./controllers/residentSearch.controller"));
module.directive("residentSearch", require("./directives/residentSearch.directive"));
module.directive("residentFilterSearch", require("./directives/ResidentFilterSearchDirective"));
module.directive("residentSearchSundry", require("./directives/ResidentSearchSundryDirective"));
module.controller("residentSearchSundry.controller", require("./controllers/ResidentSearchSundryController"));
module.directive("residentRoomAssignment", require("./directives/residentRoomAssignment.directive"));
module.controller("residentRoomAssignment.controller", require("./controllers/residentRoomAssignment.controller"));
module.directive("unitAssignment", require("./directives/unitAssignment.directive"));
module.controller("unitAssignment.controller", require("./controllers/UnitAssignmentController"));
module.filter("yesNo", require("./filters/yesNo.filter"));
module.filter("emptyValue", require("./filters/emptyValue.filter"));
module.directive("scriptUrl", require("./directives/scriptUrl.directive"));
module.directive("rmAutomation", require("./directives/rmAutomation.directive"));
module.directive("documentUpload", require("./directives/documentUpload.directive"));
module.controller("documentUpload.controller", require("./controllers/documentUpload.controller"));
module.directive("navPublisher", require("./directives/navPublisher.directive"));
module.directive("navSubscriber", require("./directives/navSubscriber.directive"));
module.directive("navSubscriber", require("./directives/navSubscriber.directive"));
module.filter("dateInUtcTimezone", require("./filters/dateInUtcTimezone.filter"));
module.filter("percent", require("./filters/percent.filter"));

// Converted to TypeScript
module.controller("daysInPastComponentController", require("./controllers/daysInPastComponentController"));
module.component("daysInPast", new (require("./components/daysInPastComponent"))());
module.service("data.service", require("./services/DataService")); 
module.service("promise.service", require("./services/PromiseService")); 
module.service("downloadFile.service", require("./services/DownloadFileService"));
module.controller("residentSearchRow.controller", require("./controllers/ResidentSearchRowController"));
module.directive("residentSearchRow", require("./directives/ResidentSearchRowDirective"));
module.directive("residentFilterSearchRow", require("./directives/ResidentFilterSearchRowDirective"));
module.controller("residentSearchSundryRow.controller", require("./controllers/ResidentSearchSundryRowController"));
module.directive("residentSearchSundryRow", require("./directives/ResidentSearchSundryRowDirective"));
module.directive("closeOnClick", require("./directives/CloseOnClickDirective"));
module.directive("calenderValidation", require("./directives/CalenderValidationDirective"));
module.service("infiniteLoader.service", require("./services/InfiniteLoaderService"));
module.directive("autoResizeTextarea", require("./directives/auto-resize-textarea/AutoResizeTextareaDirective").factory());
module.controller("valueWithEffectiveDateController", require("./controllers/ValueWithEffectiveDateController"));
module.controller("checkbox.controller", require("./controllers/CheckboxController"));
module.directive("checkbox", require("./directives/checkboxDirective"));
module.directive("focusOnLoad", require("./directives/focusOnLoadDirective"));
module.controller("tablePopover.controller", require("./controllers/TablePopoverController"));
module.directive("tablePopover", require("./directives/TablePopoverDirective"));
module.directive("autoCompleteFreeText", require("./directives/autoCompleteFreeTextDirective"));
module.controller("autoCompleteFreeText.controller", require("./controllers/AutoCompleteFreeTextController"));
module.controller("toggleButton.controller", require("./controllers/ToggleButtonController"));
module.controller("datepicker.controller", require("./controllers/DatepickerController"));
module.directive("datepicker", require("./directives/datepickerDirective"));
module.directive("backToTop", require("./directives/BackToTopDirective"));
module.controller("footer.controller", require("./controllers/FooterController"));
module.directive("footer", require("./directives/footerDirective"));
module.factory("footer.service", require("./services/FooterService"));
