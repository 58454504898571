"use strict";
module.exports = {

    configure: function (module) {

        module.config(["$stateProvider", function ($stateProvider) {

            $stateProvider.state("editResident", {
                url: "/editResident/{residentId:int}",
                templateUrl: "app/residents/partials/editResident.html",
                controller: "editResident.controller as vm",
                resolve: {
                    authorized: ["authentication.service", "$state", function (auth, $state) {
                        if ($state.next.name === "editResident.financialsFunding") {
                            return auth.userHasPermissionDeferred(function (x) { return x.residentsFinancials.canModify; });
                        } else {
                            // both details and history are controlled by the same permission
                            return auth.userHasPermissionDeferred(function (x) { return x.residentsDetails.canModify; });
                        }
                    }]
                }
            });

            $stateProvider.state("editResident.financialsFunding", {
                url: "/financials/funding",
                template: "<resident-finance-editor-funding resident-id='residentId' />",
                controller: ["$stateParams", "$scope", function ($stateParams, $scope) {
                    $scope.residentId = $stateParams.residentId;
                }],
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.residentsFinancials.canModify; });
                    }]
                }
            });

            $stateProvider.state("editResident.financialsFees", {
                url: "/financials/{isPrivateResidency:bool}/fees",
                template: "<resident-finance-editor-fees resident-id='residentId' is-private-residency='isPrivateResidency'/>",
                controller: ["$stateParams", "$scope", function ($stateParams, $scope) {
                    $scope.residentId = $stateParams.residentId;
                }],
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.residentsFinancials.canModify; });
                    }]
                }
            });

            $stateProvider.state("viewResident", {
                url: "/viewResident/{residentId:int}",
                templateUrl: "app/residents/partials/viewResident.html",
                controller: "viewResident.controller as vm",
                resolve: {
                    authorized: ["authentication.service", "$state", function (auth, $state) {
                        if ($state.next.name === "viewResident.financialsFunding") {
                            return auth.userHasPermissionDeferred(function (x) { return x.residentsFinancials.canView; });
                        } else {
                            // both details and history are controlled by the same permission
                            return auth.userHasPermissionDeferred(function (x) { return x.residentsDetails.canView; });
                        }
                    }]
                }
            });

            $stateProvider.state("viewResidents", {
                url: "/viewResidents",
                templateUrl: "app/residents/partials/viewResidents.html",
                controller: "viewResidents.controller as vm",
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.residentsDetails.canView; });
                    }]
                },
                onEnter: ["$rootScope", function($rootScope) {
                    $rootScope.hideFooter = true;
                }]
            });

            $stateProvider.state("viewDepartedResidents", {
                url: "/viewDepartedResidents",
                templateUrl: "app/residents/partials/viewDepartedResidents.html",
                controller: "viewDepartedResidents.controller as vm",
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.residentsDetails.canView; });
                    }]
                },
                onEnter: ["$rootScope", function($rootScope) {
                    $rootScope.hideFooter = true;
                }]
            });

            $stateProvider.state("viewResident.financialsFunding", {
                url: "/financials/funding",
                template: "<resident-finance-funding resident-id='residentId' />",
                controller: ["$stateParams", "$scope", function ($stateParams, $scope) {
                    $scope.residentId = $stateParams.residentId;
                }],
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.residentsFinancials.canView; });
                    }]
                }
            });

            $stateProvider.state("viewResident.financialsFees", {
                url: "/financials/{isPrivateResidency:bool}/fees",
                template: "<resident-finance-fees resident-id='residentId' is-private-residency='isPrivateResidency'/>",
                controller: ["$stateParams", "$scope", function ($stateParams, $scope) {
                    $scope.residentId = $stateParams.residentId;
                }],
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.residentsFinancials.canView; });
                    }]
                }
            });

            $stateProvider.state("viewResident.financialsTransaction", {
                url: "/financials/{isPrivateResidency:bool}/transaction",
                template: "<resident-finance-transaction resident-id='residentId' is-private-residency='isPrivateResidency'/>",
                controller: ["$stateParams", "$scope", function ($stateParams, $scope) {
                    $scope.residentId = $stateParams.residentId;
                }],
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.residentsFinancials.canView; });
                    }]
                }
            });

            $stateProvider.state("viewResident.events", {
                url: "/events",
                template: "<resident-event-history resident-id='residentId'></resident-event-history>",
                controller: [
                    "$stateParams", "$scope", function ($stateParams, $scope) {
                        $scope.residentId = $stateParams.residentId;
                    }
                ],
                resolve: {
                    authorized: [
                        "authentication.service", function (auth) {
                            return auth.userHasPermissionDeferred(function (x) { return x.residentsDetails.canView; });
                        }
                    ]
                }
            })

            $stateProvider.state("viewResident.timeline",
                {
                    url: "/timeline",
                    template: "<resident-timeline resident-id='residentId' />",
                    controller: [
                        "$stateParams", "$scope", function ($stateParams, $scope) {
                            $scope.residentId = $stateParams.residentId;
                        }
                    ],
                    resolve: {
                        authorized: [
                            "authentication.service", function (auth) {
                                return auth.userHasPermissionDeferred(function (x) { return x.residentsDetails.canView; });
                            }
                        ]
                    }
                });

            $stateProvider.state("viewResident.notes", {
                url: "/notes",
                template: "<notes resident-id='residentId' />",
                controller: [
                    "$stateParams", "$scope", function ($stateParams, $scope) {
                        $scope.residentId = $stateParams.residentId;
                    }
                ]
            });

            $stateProvider.state("markDepartedResidentAsEnquiry", {
                url: "/markAsEnquiry/{residentId:int}",
                templateUrl: "app/residents/partials/markDepartedResidentAsEnquiry.html",
                controller: "markDepartedResidentAsEnquiry.controller as vm"
            });
        }]);
    }

};
