import { ILeadsService } from "../services/ILeadsService";
import { IResidentsService } from "../../residents/services/IResidentsService";
import { INotificationService } from '../../common/services/INotificationService';
import {EnquiryStatus, StatusPanel} from '../Models';

class LeadStatusComponent implements ng.IComponentOptions {
    public bindings: any = {
        residencyId: "=",
        isResident: "=",
        statusInfo: "="
    };

    public templateUrl: string = 'app/leads/partials/leadStatus.html';

    public controller: any = class LeadStatusComponentController {
        static $inject = ["leads.service", "residents.service","notification.service"];

        public residencyId: number;
        public isResident: boolean;
        public statusInfo: StatusPanel;           
        public isEditMode: boolean;
      
        public viewModeStatus: string;
        public viewModeContractSigned: boolean;
        public viewModeDeceased: boolean;
        public toggleButtonStatus: string;
        public isContractSigned: boolean;
        public isDeceased: boolean;
        public statusDict: {[status: string]: string} = {
            Enquiries: "Lead",
            TourBooked: "Lead",
            TourCompleted: "Lead",
            Waiting: "Waitlist",
            Confirmed: "Confirmed" 
        }

        constructor(private readonly leadsService: ILeadsService,
            private readonly residentsService: IResidentsService,
            private readonly notificationService: INotificationService) {     
            this.isEditMode = false;            
            this.viewModeStatus = this.statusDict[this.statusInfo.status];
            this.viewModeContractSigned = this.statusInfo.isContractSigned;
            this.viewModeDeceased = this.statusInfo.isDeceased;

            this.toggleButtonStatus = this.statusDict[this.statusInfo.status];
            this.isContractSigned = this.statusInfo.isContractSigned;
            this.isDeceased = this.statusInfo.isDeceased;

        }

        public showEditMode = () => {
            this.isEditMode = true;
            this.toggleButtonStatus = this.viewModeStatus;
            this.isContractSigned = this.viewModeContractSigned;
            this.isDeceased = this.viewModeDeceased;
        }

        public hideEditMode = () => {
            this.isEditMode = false;
        }

        public updateStatus = () => {            
            if (!this.isResident) {
                let enquiryStatus = this.getStatusKeyByValue(this.toggleButtonStatus);
                this.leadsService.updatePreAdmissionStatus(this.residencyId, this.isContractSigned, enquiryStatus, this.isDeceased).then(response => {
                    this.isEditMode = false;
                    this.viewModeStatus = this.toggleButtonStatus;
                    this.viewModeContractSigned = this.isContractSigned;
                    this.viewModeDeceased = this.isDeceased;
                    this.statusInfo.isDeceased = this.isDeceased; //Refresh parent view (resident details)
                    this.statusInfo.isContractSigned = this.isContractSigned;
                    this.statusInfo.status = enquiryStatus;
                    this.notificationService.success("Lead status updated successfully!");
                },
                () => {
                    this.notificationService.error("Unexpected error occurred while updating lead status.");
                });
            }
            else {
                this.residentsService.updateContractSigned(this.residencyId, this.isContractSigned).then(result => {
                    this.isEditMode = false;                
                    this.viewModeContractSigned = this.isContractSigned;
                    this.statusInfo.isContractSigned = this.isContractSigned;
                    this.notificationService.success("contract signed status updated successfully!");
                },
                () => {
                    this.notificationService.error("Unexpected error occurred while updating contract signed status.");
                });
            }
        }

        private getStatusKeyByValue = (value: string): EnquiryStatus => { 
            if (value == "Lead")
                return EnquiryStatus.Enquiries;
            else if (value == "Waitlist")
                return EnquiryStatus.Waiting;
            else if (value == "Confirmed")
                return EnquiryStatus.Confirmed;            
        } 
    }
}
export = LeadStatusComponent;