import * as _ from 'underscore';
import { IIndependentLivingService } from '../services/IIndependentLivingService';
import { FeesAndChargesDto, SelectedResidentDto, ResidencyFinanceOptionalServiceDto, BillPayerType} from '../Models';
import { INotificationService } from '../../common/services/INotificationService';
import { OptionalService } from '../../organisation/Models';
import { IOrganisationFacilityService } from '../../organisation/services/IOrganisationFacilityService';
import type { IStateService } from 'angular-ui-router';

class EnquiryFinanceDetailController {
    static $inject = ["independentLiving.service", "organisation.facility.service", "$state", "$stateParams",
        "notification.service", "dialog.service"];

    public feesAndCharges: FeesAndChargesDto;
    public selectedResidents: Array<SelectedResidentDto> = new Array<SelectedResidentDto>();
    public enquiryId: number;
    public selectedResponsibleResidency: string;
    public selectedResponsibleResidentId: number;
    public selectedBillPayerFullName: string;
    public selectedBillPayerType : BillPayerType;
    public contractSignedDate?: Date

    public facilityOptionalServices: OptionalService[];
    public facilityOptionalServicesLookupById: { [optionalServiceId: number]: OptionalService } = {};
    public popoverConfigsForOneResidency: any;
    public readonlyEffectiveDatePopoverConfigs: { [residencyId: number]: any[] } = {};
    public viewDepartedMode: boolean;
    public isSelectedResidentResponsible: boolean;

    constructor(private readonly iluService: IIndependentLivingService,
        private readonly organisationFacilityService: IOrganisationFacilityService,
        private $state: IStateService,
        private readonly notificationService: INotificationService) {

        this.enquiryId = $state.params.enquiryId;
        this.viewDepartedMode = $state.params.viewDepartedMode;
        if ($state.params.selectedResidents != null) {
            this.selectedResidents = $state.params.selectedResidents;
        }
        this.getFeesAndCharges();
    }

    private getFeesAndCharges = () => {
        let residentId = this.viewDepartedMode ? this.$state.params.residentId : null;
        this.iluService.getFeesAndCharges(this.enquiryId, this.viewDepartedMode, residentId).then(result => {
            this.feesAndCharges = result;
            this.isSelectedResidentResponsible = this.feesAndCharges.financialSummaryAndUnitCostsDto.responsibleResidentId != undefined;
            if(this.isSelectedResidentResponsible)
            {
                var selectedResponsibleFullName = this.feesAndCharges.financialSummaryAndUnitCostsDto.responsibleResident;
                if (selectedResponsibleFullName)
                    this.selectedResponsibleResidency = selectedResponsibleFullName;
                this.selectedResponsibleResidentId = this.feesAndCharges.financialSummaryAndUnitCostsDto.responsibleResidentId;
                var selectedResidencyFinanceFee = this.feesAndCharges.iluResidencyFinanceFees.filter(x=>x.residencyId == this.feesAndCharges.financialSummaryAndUnitCostsDto.responsibleResidencyId)[0];
                this.selectedBillPayerType = selectedResidencyFinanceFee.billPayerType;
                this.selectedBillPayerFullName = selectedResidencyFinanceFee.billPayerFullName;
            }
            this.organisationFacilityService.getOptionalServices()
                .then(result => {
                    this.facilityOptionalServices = result;
                    this.initializeOptionalServiceInformation();
                }, () => {
                    this.notificationService.error("Unexpected error occurred while getting facility optional services.");
                });
        }, () => {
            this.notificationService.error("Unexpected error occurred while loading fee and charges");
        });

    }

    public getTotalDMFPercentageForTheTimePeriod(index) {
        return this.iluService.getTotalDMFPercentageForTheTimePeriod(index, this.feesAndCharges.deferredManagementFeeDto.deferredManagementFeesSchedule);
    }

    public initializeReadonlyEffectiveDatePopoverConfig = (optionalServices: ResidencyFinanceOptionalServiceDto[], residencyId: number, index = null) => {
        if (this.facilityOptionalServices == null || this.facilityOptionalServices.length === 0 || optionalServices == null)
            return;

        if (index === null) {
            this.popoverConfigsForOneResidency = [];
            for (let i = 0; i < optionalServices.length; i++) {
                this.initializeReadonlyEffectiveDatePopoverConfig(optionalServices, residencyId, i);
            }
        }
        else {
            this.popoverConfigsForOneResidency[index] = {
                id: 'value-link-' + residencyId + '-' + index,
                placement: 'bottom',
                target: 'a#value-link-' + residencyId + '-' + index,
                autoClose: true,
                popoverCssClass: 'fee-effective-popover',
                columns: [{
                    headerText: 'AMOUNT',
                    headerCssClass: 'support-col',
                    dataFieldName: 'value',
                    cellCssClass: 'support-col',
                    filterName: 'currency'
                }, {
                    headerText: 'EFFECTIVE DATE',
                    headerCssClass: 'effective-date-col',
                    dataFieldName: 'startDate',
                    cellCssClass: 'effective-date-col',
                    filterName: 'date',
                    format: 'dd MMM yyyy',
                    onCellCreated: (rowIndex, formattedValue) => { return formattedValue; }
                }]
            };
        }
    }

    private initializeOptionalServiceInformation = () => {
        if (this.facilityOptionalServices == null || this.facilityOptionalServices.length == 0) {
            return;
        }

        // start with initialising the facility-level optional services
        
        this.facilityOptionalServices.forEach(facilityOptionalService => {
            // populate the lookup
            this.facilityOptionalServicesLookupById[facilityOptionalService.id] = facilityOptionalService;
            this.iluService.initialiseFacilityOptionalServices(facilityOptionalService);
        });

        this.feesAndCharges.iluResidencyFinanceFees.forEach(residencyFinanceFees => {
            this.popoverConfigsForOneResidency = [];
            this.initializeReadonlyEffectiveDatePopoverConfig(residencyFinanceFees.optionalServices, residencyFinanceFees.residencyId);
            this.readonlyEffectiveDatePopoverConfigs[residencyFinanceFees.residencyId] = this.popoverConfigsForOneResidency;

            // now do the resident-level optional services
            residencyFinanceFees.optionalServices.forEach(optionalService => {
                this.iluService.initialiseResidencyOptionalServices(optionalService, residencyFinanceFees.optionalServices, this.facilityOptionalServicesLookupById);
            }); 
        });
    }

    public redirectToContactDetailBillPayer = () => {
        var goToState = ((this.$state.current.name === 'viewIndependentLivingAllocation.finance') ||
          (this.$state.current.name === 'editIndependentLivingAllocation.finance'))
          ? 'viewIndependentLivingAllocation'
          : 'viewEnquiry';
         this.$state.go(goToState, { 'enquiryId': this.enquiryId, 'residentId': this.selectedResponsibleResidentId, '#': this.selectedBillPayerType.substring(0, 1).toLowerCase() + this.selectedBillPayerType.substring(1)}, { reload: true });
      };
}
export = EnquiryFinanceDetailController;
