import * as moment from 'moment';
import { IDateFormattingService } from '../../common/services/IDateFormattingService';

class DatepickerController {

    static $inject = ["$scope", "dateFormatting.service"];

    private scope: any;
    public internalDate: Date = null;
    public ngModel: any;
    public changeTriggered: Boolean;
    
    constructor(
        private $scope: any,
        private dateFormattingService: IDateFormattingService
    ) {
        this.scope = $scope;
    }

    public updateFromExternal(model) {
        if(!model.$viewValue) {
            // if we have an empty string as the view value, then it seems we're trying to clear the date
            this.internalDate = null;
            return;
        }

        var newDate = this.parseDate(model.$viewValue);
        // recursion guard
        if (this.dateEqual(newDate, this.internalDate)) {
            return;
        }

        // set internal date
        if (newDate)
            this.internalDate = model.$viewValue;

        // note we reset the external date to ensure that it is the utc midnight version of whatever it was previously
        model.$setViewValue(model.$viewValue);
    }


    public datePicked = () => {        
        let newDate = this.parseDate(this.internalDate)
        // make the required adjustments
        // don't set dates that have been zeroed to 1970 by the date picker
        if ((this.parseDate(newDate) && newDate.getTime() > 0) || this.internalDate === null) {
            this.internalDate = this.generateUTCMidnightDate(newDate)
            // only cascade externally if the date has changed
            if (!this.dateEqual(this.ngModel, this.internalDate)) {
                // update model
                this.ngModel = this.internalDate;
                // fire change event
                if (this.scope.vm.onChange) {
                    this.changeTriggered = true;
                }
            }
        }
    };

    // private utility functions

    private dateEqual(newValue, oldValue) {
        return (newValue instanceof Date && oldValue instanceof Date && newValue.getTime() == oldValue.getTime())
    }

    private parseDate(date) {
        // we don't want to use the moment parser here, because it tends to succeed and return today
        // when we try and parse an empty string or malformed dates
        if (moment.isMoment(date)) {
            return date.toDate();
        } else if (date instanceof Date) {
            return date;
        } else if (typeof date == "string" && isNaN(Date.parse(date.replace(/"/g,""))) == false) { // if we can parse a string as a date
            return new Date(Date.parse(date.replace(/"/g,"")));
        } else {
            return null;
        }
    }

    public getDateForDateRangeDelimiter(date) {
        // allow 'today' to generate today's date
        let newDate = (date == "today" || date == "todayMidnight") ? (new Date(new Date().setHours(0, 0, 0, 0))) : this.parseDate(date);
        if (!newDate)
            return null;

        // snap us to utc midnight so we can reliably determine the year month and day regardless of whether input date was local or GMT time
        newDate = this.generateUTCMidnightDate(newDate);
        // datepicker date range delimeters work properly when passed a date generated like this
        let returnDate = new Date(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate());
        return (date == "today") ? new Date(returnDate.setHours(23, 59, 59, 999)) : returnDate;
    }

    private generateUTCMidnightDate(date) {
        // first we try and see if we can establish a valid Date object based on the input
        let useDate = this.parseDate(date);

        // initialisation corrections
        // if we have a valid Date object, then we may have to adjust it
        // if the date is not set to midnight UTC, then we assume that the date is midnight local time, and adjust it to UTC for the date picker

        if (useDate instanceof Date) {
            useDate = this.epochShiftToUTCMidnight(useDate);
        }

        return useDate;
    }
    
    private epochShiftToUTCMidnight = (date: Date) => {
        // assuming midnight local time, snaps to midnight UTC
        return this.dateFormattingService.snapToClosestUTCMidnight(new Date(date.getTime()));
    }
}

export = DatepickerController;