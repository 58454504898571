import * as _ from "underscore";
import moment = require('moment');
import { INotificationService } from '../../common/services/INotificationService';
import { IInfiniteLoaderFactory } from '../../common/services/IInfiniteLoaderService';
import { IResidentsService } from '../services/IResidentsService';
import { ResidentFinanceFees, ResidentFinanceTransaction, ResidentTransactionsFilter } from '../Models';
import type { IStateParamsService, IStateService } from "angular-ui-router";

interface IResidentFinanceTransactionControllerScope extends ng.IScope {
    residentId: number;
}

class ResidentFinanceTransactionController {
    static $inject = ["$scope", "$stateParams", "$state", "residents.service", "notification.service", "infiniteLoader.service"];

    private residentId: number;
    private finance: ResidentFinanceFees;
    private filter: ResidentTransactionsFilter;
    public refundableLabel: string;
    public balanceRoomPriceLabel: string;
    public roomPriceLabel: string;
    public refundable: number;
    public balanceRoomPrice: number;
    public roomPrice: number;
    public balanceProgress: string;
    public showTransactionHistoryHeaderPanel: boolean;
    public infiniteLoader: any;
    public transactions: ResidentFinanceTransaction[];
    public transactionBalance: number;  
    public isPrivateResidency: boolean;

    constructor(
        private $scope: IResidentFinanceTransactionControllerScope,
        private $stateParams: IStateParamsService,
        private $state: IStateService,
        private residentsService: IResidentsService,
        private notificationService: INotificationService,
        private infiniteLoaderService: IInfiniteLoaderFactory
    ) {
        this.residentId = $scope.residentId;
        this.isPrivateResidency = $stateParams.isPrivateResidency;
        this.getResidentFinanceFees();
        this.getResidentFinanceTransactionsBalance();
    }
    
    private getResidentFinanceTransactionsBalance = () => {
        this.residentsService.getResidentFinanceTransactionsBalance(this.residentId).then((result) => {
            this.transactionBalance = result;
            this.getResidentFinanceTransactions();
        }, (error) => {
            this.notificationService.error("Unexpected error while loading transaction history balance.");
        });
    }
    
    private getResidentFinanceTransactions = () => {
        this.transactions = new Array<ResidentFinanceTransaction>();
        this.filter = new ResidentTransactionsFilter();
        this.filter.ResidentId = this.residentId;
        this.infiniteLoader = this.infiniteLoaderService.instanceWithFilter(this.residentsService.getResidentFinanceTransactions,
            this.filter,
            (result) => {
                this.transactions = this.transactions.concat(result);
                this.markTransactionWithAHeader();
            },
            () => {
                this.notificationService.error("Unexpected error while loading transaction history.");
            });
    }

    private getResidentFinanceFees = () => {
        this.residentsService.getResidentFinanceFees(this.residentId).then((result) => {
            this.finance = result.financeFees;
            this.initializeFinanceInformation();
        }, (error) => {
            this.notificationService.error("Unexpected error while loading transaction history.");
        });
    }

    private initializeFinanceInformation = () => {

        this.showTransactionHistoryHeaderPanel = false;

        if (this.isPreCare2014()) {

            this.initializePreCare2014FinanceInformation();
            return;
        }

        this.initializeCareFinanceInformation();
    }

    private initializeCareFinanceInformation = () => {
        if (!this.finance.supportLevel) {
            this.finance.supportLevel = "Unknown";
        }

        if (this.isUnsupported()) {
            this.refundableLabel = "Refundable RAD";
            this.balanceRoomPriceLabel = "Balance of room price";
            this.roomPriceLabel = "Agreed room price";
            this.refundable = this.finance.refundableAccommodationDepositPaid - (this.finance.drawdowns || 0) - this.finance.refundedAmountFromLumpSumDeposit;
            this.balanceRoomPrice = this.finance.refundableAccommodationDepositRemaining;
            this.roomPrice = parseFloat(this.finance.latestAgreedRoomPrice.value);
            this.balanceProgress = this.finance.unsupportedBalanceProgressPercentage + '%';
            this.showTransactionHistoryHeaderPanel = true;
        }
        else if (this.isSupported()) {
            this.refundableLabel = "Refundable RAC";
            this.balanceRoomPriceLabel = "Balance of calculated room price";
            this.roomPriceLabel = "Calculated room price";
            this.refundable = this.finance.refundableAccommodationContributionPaid - (this.finance.drawdowns || 0) - this.finance.refundedAmountFromLumpSumDeposit;
            this.balanceRoomPrice = this.finance.refundableAccommodationContributionRemaining;
            this.roomPrice = this.finance.refundableAccommodationContributionCalculated;
            this.balanceProgress = this.finance.supportedBalanceProgressPercentage + '%';
            this.showTransactionHistoryHeaderPanel = true;
        }
    }

    private initializePreCare2014FinanceInformation() {

        this.showTransactionHistoryHeaderPanel = true;
        this.refundableLabel = "Refundable bond";
        this.balanceRoomPriceLabel = "Balance of bond / room price";
        this.roomPriceLabel = "Orignal bond / room price";
        this.refundable = this.finance.pre2014RefundableBondPaid || 0;
        this.balanceRoomPrice = this.finance.pre2014RefundableBondDepositRemaining;
        this.roomPrice = this.finance.pre2014BondOrRoomPrice;
        this.balanceProgress = this.finance.pre2014BondBalanceProgressPercentage + '%';
    }

    private isPreCare2014 = () => {
        return this.finance.pre2014Care && this.finance.pre2014CareLevel === "Low" && this.finance.pre2014ContinueCare;
    }

    private isUnknown = () => {
        return this.finance.latestSupportLevel.value === "Unknown";
    }

    private isSupported = () => {
        return this.finance.latestSupportLevel.value === "Supported";
    }

    private isUnsupported = () => {
        return this.finance.latestSupportLevel.value === "Unsupported";
    }

    public showFundingTab = () => {      
        switch (this.$state.current.name) {
            case "viewResident.financialsTransaction":
                this.$state.go("viewResident.financialsFunding", { "residentId": this.residentId });
                break;
            case "viewLead.financialsTransaction":
                this.$state.go("viewLead.financialsFunding", { "residentId": this.residentId });
                break;
        }
    }

    public showFeesTab = () => {      
        switch (this.$state.current.name) {
            case "viewResident.financialsTransaction":
                this.$state.go("viewResident.financialsFees", { "residentId": this.residentId, "isPrivateResidency":  this.isPrivateResidency});
                break;
            case "viewLead.financialsTransaction":
                this.$state.go("viewLead.financialsFees", { "residentId": this.residentId, "isPrivateResidency":  this.isPrivateResidency });
                break;
        }
    }

    private markTransactionWithAHeader = () => {
        let previousTransaction;
        _.each(this.transactions, function(transaction, i, transactions) {
            if (i === 0) {
                transaction.addHeader = true;    
            }
            else {
                let transactionDate = moment(transaction.transactionDateUtc);
                let previousTransactionDate = moment(transactions[i - 1].transactionDateUtc);
                transaction.addHeader = (transactionDate.format('YYYY') !== previousTransactionDate.format('YYYY')) ||
                    (transactionDate.format('M') !== previousTransactionDate.format('M'));
            }

        });
    }
}

export = ResidentFinanceTransactionController;