import * as _ from 'underscore';
import * as moment from 'moment';
import { IResidentsService } from "../services/IResidentsService";
import { INotificationService } from '../../common/services/INotificationService';
import type { IStateService } from 'angular-ui-router';

class LegalNoticeReceivedDialogController {
    static $inject = ["$state", "residents.service", "notification.service", "modalConfig"];
    private maximumPenaltyInterestRates: any;

    residencyId: number;
    noticeDateUtc: string;
    $hide: any;
    baseInterestRate: number;
    maximumPenaltyInterestRate: number;
    maximumPenaltyInterestRateAppliedDate: string;
    departureDateUtc: string;
    admissionDateUtc: string;

    constructor(
        private readonly $state: IStateService,
        private readonly residentsService: IResidentsService,
        private readonly notificationService: INotificationService,
        private readonly modalConfig: any,
    ) {
        this.residencyId = modalConfig.residencyId;
        this.departureDateUtc = modalConfig.departureDateUtc;
        this.loadRates();
    }

    private loadRates() {
        this.residentsService.getLegalNoticeDateInformation(this.residencyId).then(
            data => {
                this.baseInterestRate = data.baseInterestRate;
                this.maximumPenaltyInterestRates = data.maximumPenaltyInterestRates;
                this.noticeDateUtc = data.noticeDateUtc;
                this.admissionDateUtc = data.admissionDateUtc;
                this.setMaximumPenaltyInterestRate();
            },
            () => {
                this.notificationService.error("Unexpected error while saving notice date.");
            });
    }

    getNoticeDateCalMin(){
        let today = moment().startOf('day');
        let adminDate = moment(this.admissionDateUtc).startOf('day');
        if(today.isSame(adminDate)) {
            return moment().startOf('day').add(moment().utcOffset() * -1, 'm').subtract(1, 'd').toISOString();
        }

        return this.admissionDateUtc;
    }

    bindHide(hide) {
        this.$hide = hide;
    };

    setMaximumPenaltyInterestRate() {
        var filterByDate = moment.utc(new Date()); //today
        if (this.noticeDateUtc) {
            filterByDate = moment.utc(this.noticeDateUtc);
        }

        filterByDate = filterByDate.add('days', 14); //add 14 days to the notice date or today
        this.maximumPenaltyInterestRateAppliedDate = moment(filterByDate).format("DD MMM YYYY");

        var filteredRate = _.chain(this.maximumPenaltyInterestRates)
            .find((item) => {
                var startDate = moment(item.startDateUtc);
                var endDate = moment(item.endDateUtc);
                return filterByDate.isBetween(startDate, endDate) || filterByDate.isSame(startDate) || filterByDate.isSame(endDate)
            }).value();

        if (filteredRate) { //the date range will always exist in ideal scenario. Keeping this check for testing environments.
            this.maximumPenaltyInterestRate = filteredRate.rate;
        }
    }

    save(isvalid) {
        if (isvalid) {
            this.residentsService.saveNoticeDate(this.residencyId, moment(this.noticeDateUtc).toDate()).then(
                data => {
                    this.notificationService.success("Notice date saved successfully.");
                    this.$hide();
                    this.$state.reload();
                },
                () => {
                    this.notificationService.error("Unexpected error while saving notice date.");
                });
        }
    };
}

export = LegalNoticeReceivedDialogController;
