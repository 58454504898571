"use strict";

const angular = require("angular");
const $ = require("jquery");

module.exports = [
    "$affix", "$window", function ($affix, $window) {
        var offsetTop = 94; //offset from top, relative to window, i.e. height of header section
        return {
            restrict: "E",
            replace: true,
            scope: {
                "class": "@", //css classes to add to container div, comma seperated
                "parentContainerForWidth": "@", // used to set right width for the action bar
                "offsetTop": "@", // when using different offset than default
                "adjustWidth": "@" // used to adjust width for the action bar
            },
            transclude: true,
            template: "<div id=\"stickyActionbar{{::$id}}\" " +
                "ng-transclude>" +
                "</div>",
            link: function (scope, element) {

                var componentId = "stickyActionbar" + scope.$id;
                var currentWindow = angular.element($window);
                if (scope.offsetTop) {
                    offsetTop = parseInt(scope.offsetTop);
                }


                function maintainActionBarWidth() {
                    var width = $("#" + scope.parentContainerForWidth).width();

                    if (width === 0) {
                        return;
                    }

                    var adjustWidth = 25;
                    if (scope.adjustWidth) {
                        adjustWidth = parseInt(scope.adjustWidth);
                    }

                    $("#" + componentId).width(width + adjustWidth);
                    $("#" + componentId).addClass("position-changed");
                }

                $("#" + componentId).addClass(scope.class);

                $affix(element.eq(0),
                    {
                        offsetTop: offsetTop.toString(),
                        target: currentWindow
                    });

                currentWindow.bind("scroll", maintainActionBarWidth);
                currentWindow.bind("resize", maintainActionBarWidth);

                element.on("$destroy",
                    function () {
                        currentWindow.unbind("scroll", maintainActionBarWidth);
                        currentWindow.unbind("resize", maintainActionBarWidth);
                    });
            }
        };
    }
];
