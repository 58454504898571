function residentSearchSundryDirective(): ng.IDirective {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            remoteApiHandler: "&",
            remoteUrlDataField: "=",
            initialValue: "=",
            required: "=",
            inputName: "@",
            index: "=",
            onSelected: "=",
        },
        controller: "residentSearchSundry.controller",
        controllerAs: "vm",
        templateUrl: "app/common/directives/partials/residentSearchSundry.html"
    };
}

export = residentSearchSundryDirective;