module.exports = [
    "$modal",
    "$rootScope",
    function ($modal, $rootScope) {
        function openDialog(templateUrl, controller, modalConfig, backdrop) {
            if (!backdrop) {
                backdrop = 'static';
            }

            $modal({
                templateUrl: templateUrl,
                show: true,
                placement: "top",
                controller: controller,
                controllerAs: "vm",
                prefixEvent: "resman.modal",
                backdrop: backdrop,
                keyboard: false,
                resolve: {
                    modalConfig: function () {
                        return modalConfig;
                    }
                }
            });
        };

        function openStaticDialog(templateUrl, controller, modalConfig) {
            openDialog(templateUrl, controller, modalConfig, "static");
        };

        function openMessageDialog(title, message, tag, innerMessages) { 
            var scopeForDialog = $rootScope.$new();
            scopeForDialog.innerMessages = innerMessages;
            scopeForDialog.modalClass = tag.includes("error") ? "modal-body modal-body-error" : "modal-body";
            $modal({
                templateUrl: "confirmation-modal",
                tag: tag,
                title: title,
                content: message,
                show: true,
                placement: "center",
                scope: scopeForDialog,
                backdrop: 'static'
            });
        };

        function openActionDialog(title, message, tag, buttonText, action, buttonClass) {
            var scopeForDialog = $rootScope.$new();
            scopeForDialog.buttonText = buttonText;
            if (buttonClass == null) {
                buttonClass = "btn-primary btn-inline-block-xsm";
            }
            scopeForDialog.buttonClass = buttonClass;

            scopeForDialog.submit = function () {
                action();
                scopeForDialog.$hide();
            };

            scopeForDialog.bindHide = function ($hide) {
                scopeForDialog.$hide = $hide;
            };

            $modal({
                templateUrl: "action-modal",
                tag: tag,
                title: title,
                content: message,
                show: true,
                placement: "center",
                scope: scopeForDialog,
                backdrop: 'static'
            });
        };

        function openTemplateActionDialog(templateUrl, action) {
            var scopeForDialog = $rootScope.$new();
            scopeForDialog.submit = function () {
                action();
                scopeForDialog.$hide();
            };

            scopeForDialog.bindHide = function ($hide) {
                scopeForDialog.$hide = $hide;
            };

            $modal({
                templateUrl: templateUrl,
                show: true,
                placement: "center",
                scope: scopeForDialog,
                backdrop: 'static'
            });
        }

        function openInputDialog(title, label, initialValue, maxLength, tag, buttonText, action, buttonClass) {
          var scopeForDialog = $rootScope.$new();

            scopeForDialog.buttonText = buttonText;
            scopeForDialog.label = label;
            scopeForDialog.value = initialValue;
            scopeForDialog.maxLength = maxLength;
            scopeForDialog.innerMessages = [];
          
            if (buttonClass == null) {
                buttonClass = "btn-primary btn-inline-block-xsm";
            }
            scopeForDialog.buttonClass = buttonClass;

            scopeForDialog.submit = function (value, innerMessages) {
              if (action(value, innerMessages)) {;
                scopeForDialog.$hide();
              }
            };

            scopeForDialog.bindHide = function ($hide) {
                scopeForDialog.$hide = $hide;
            };

            $modal({
                templateUrl: "input-modal",
                tag: tag,
                title: title,
                show: true,
                placement: "center",
                scope: scopeForDialog,
                backdrop: 'static'
            });
        };

        return {
            openDialog: openDialog,
            openMessageDialog: openMessageDialog,
            openStaticDialog: openStaticDialog,
            openActionDialog: openActionDialog,
            openInputDialog: openInputDialog,
            openTemplateActionDialog: openTemplateActionDialog
        }
    }
];