"use strict";
const _ = require("underscore");

module.exports = [
    "organisation.users.service",
    "notification.service",
    "organisation.facility.service",
    "facility.service",
    "$window",
    "authentication.service",
    "$anchorScroll",
    "dialog.service",
    function (usersService, notificationService, orgfacilityService, facilityService, $window, authenticationService, $anchorScroll, dialogService) {
        var vm = this;
        vm.mode = "view";
        vm.loggedInUserFacilities = [];
        vm.allowedAndExistingFacilities = [];
        vm.isShowDeactivated = false;
        vm.facilitiesList = null;
        vm.addUserPromise = null;
        vm.editUserPromise = null;
        var upperCharValidator = new RegExp(/^(?=.*[A-Z]).+$/);
        var lowerCharValidator = new RegExp(/^(?=.*[a-z]).+$/);
        var numberValidator = new RegExp(/^(?=.*\d).+$/);

        var selectFirstUserIfAvailable = function () {
            if (vm.users !== null && vm.users.length > 0) {
                vm.selectUser(vm.users[0]);
            }
        };

        var allowClosingForm = function (unsavedChanges) {
            if (unsavedChanges) {
                return $window.confirm("Do you want to leave this page? Changes you made may not be saved.");
            } else {
                return true;
            }
        };

        var sortUsers = function (userList) {
            if (userList !== null && userList.length > 0) {
               return _.sortBy(_.sortBy(userList, 'firstName'), 'lastName');
            }
        };

        (function getUsersAndFacilities() {
            usersService.getRoles()
                .then(function (roles) {
                    vm.rolesForViewMode = roles;
                    vm.rolesForEditMode = roles;
                    var orgManagerRole = _.findWhere(vm.rolesForViewMode, { isOrganisationManager: true });
                    vm.orgManagerRoleId = orgManagerRole.id;
                    authenticationService.getUser()
                        .then(function (loggedInUser) {
                            vm.loggedInUserFacilities = _.map(loggedInUser.profile.resman_fac_ids, 'id');
                            var isSystemUser = loggedInUser.profile.is_system_user == 'true' ? true : false;
                            var loggedInUserId = parseInt(loggedInUser.profile.resman_user_id);
                            usersService.getAllUsers()
                                .then(function (result) {
                                    vm.allUsers = sortUsers(result);
                                    vm.users = _.filter(vm.allUsers, function(user) {
                                        return user.isActive;
                                    });
                                    if (vm.users && vm.users.length > 0) {
                                        var loggedInUserRoleId;
                                        if(isSystemUser == false)
                                        {
                                        loggedInUserRoleId =_.findWhere(vm.users, { id: loggedInUserId }).roleId;
                                        }
                                        else
                                        {
                                            loggedInUserRoleId = orgManagerRole.id;
                                        }
                                        if (orgManagerRole.id !== loggedInUserRoleId) {
                                            vm.rolesForEditMode =
                                                _.without(vm.rolesForViewMode, orgManagerRole);
                                        } else {
                                            vm.isLoggedInUserOrgManager = true;
                                        }
                                    }
                                    selectFirstUserIfAvailable();
                                },
                                    function () {
                                        notificationService
                                            .error("Unexpected error occurred while loading users.");
                                    });
                        },
                            function () {
                                notificationService.error("Unexpected error occurred while loading logged in user.");
                            });
                },
                    function () {
                        notificationService.error("Unexpected error occurred while loading roles.");
                    });

            vm.facilitiesList = orgfacilityService.getFacilitiesForList()
                .then(function (result) {
                    vm.availableFacilities = _.sortBy(result, 'name');
                },
                    function () {
                        notificationService.error("Unexpected error occurred while loading facilities.");
                    });
        })();

        vm.atLeast8Chars = function () {
            return vm.editedUser.password.length >= 8;
        };

        vm.newPasswordUpperAndLower = function () {
            return lowerCharValidator.test(vm.editedUser.password) && upperCharValidator.test(vm.editedUser.password);
        };

        vm.newPasswordNumbersValid = function () {
            return numberValidator.test(vm.editedUser.password);
        };

        vm.selectUser = function (user) {
            var unsavedChanges = vm.editUserForm && vm.editUserForm.$dirty;
            if (vm.mode !== "view" && !allowClosingForm(unsavedChanges)) {
                return;
            }

            user.roleName = _.findWhere(vm.rolesForViewMode, { id: user.roleId }).name;
            vm.selectedUser = user;
            vm.mode = "view";
        };

        function allowedAndExistingFacilities(existingFacilities) {
            return _.filter(vm.availableFacilities, function (facility) {
                return _.contains(vm.loggedInUserFacilities, facility.id) || !!_.findWhere(existingFacilities, { id: facility.id });
            });
        }

        vm.addNewUser = function () {
            vm.addUserPromise = Promise.all([vm.facilitiesList])
                .then(function () {
                    vm.selectedUser = null;
                    vm.mode = "add";
                    vm.editedUser = { id: 0 };
                    vm.allowedAndExistingFacilities = allowedAndExistingFacilities([]);
                },
                    function (error) {
                        notificationService.error("Unexpected error occurred while loading facilities. " + error);
                    });
        };

        vm.editUser = function () {
            if (vm.selectedUser) {
                vm.editUserPromise = Promise.all([vm.facilitiesList])
                    .then(function () {
                        vm.editedUser = JSON.parse(JSON.stringify(vm.selectedUser));
                        vm.mode = "edit";
                        vm.allowedAndExistingFacilities = allowedAndExistingFacilities(vm.editedUser.userFacilities);
                    },
                        function (error) {
                            notificationService.error("Unexpected error occurred while loading facilities. " + error);
                        });
            }
        };

        vm.allowDeleteOption = function (option) {
            return _.contains(vm.loggedInUserFacilities, parseInt(option.value));
        };

        vm.cancel = function () {
            var unsavedChanges = vm.editUserForm.$dirty;
            if (allowClosingForm(unsavedChanges)) {
                vm.editUserForm.$setPristine();
                selectFirstUserIfAvailable();
                vm.mode = "view";
            }
        };

        vm.deactivate = function() {
            dialogService.openActionDialog("Warning",
                "You are about to deactivate this user. They will no longer be able to access the system.  \n  \nDo you want to continue?",
                "Deactivate",
                "Yes",
                () => {
                    vm.selectedUser.isActive = false;
                    usersService.deactivateUser(vm.selectedUser.id)
                        .then(function() {
                            notificationService.success("User Deactivated successfully.");
                            //refresh the user list after Deactivate
                            if (vm.isShowDeactivated) {
                                vm.users = vm.allUsers;
                            }
                            else
                            {
                                vm.users = _.filter(vm.allUsers,
                                    function(user) {
                                        return user.isActive;
                                    });
                            }
                            vm.mode = "view";
                        });
                });
        };

        vm.activate = function () {
            dialogService.openActionDialog("Warning",
                "You are about to activate this user for access to Resident Manager. \n  \nDo you want to continue?",
                "Activate",
                "Yes",
                () => {
                    vm.selectedUser.isActive = true;
                    usersService.activateUser(vm.selectedUser.id)
                        .then(function () {
                            notificationService.success("User Activated successfully.");
                            vm.mode = "view";
                        });
                  
                });
        };

        vm.showDeactivated = function (isShowDeactivated) {
            if (isShowDeactivated) {
                vm.users = vm.allUsers;
            } else {
                vm.users = _.filter(vm.allUsers,
                    function(user) {
                        return user.isActive;
                    });

            }
        }

        vm.save = function (allowDuplicateUserName) {
            allowDuplicateUserName = !allowDuplicateUserName ? false : allowDuplicateUserName;
            vm.editedUser.allowDuplicateUserName = allowDuplicateUserName;
            var isFormValid = vm.editUserForm.$valid;
            if (isFormValid) {
                usersService.saveUser(vm.editedUser)
                    .then(function (saveResults) {
                        notificationService.success("Saved successfully.");
                        vm.editedUser.password = null;
                        vm.editUserForm.$setPristine();

                        if (vm.mode === "add") {
                            if (_.findWhere(vm.editedUser.userFacilities,
                                { id: facilityService.selectedFacility.id })) {
                                vm.editedUser.id = saveResults.result;
                                vm.editedUser.isActive = true;
                                vm.allUsers.push(vm.editedUser);
                                vm.allUsers = sortUsers(vm.allUsers);
                                vm.users.push(vm.editedUser);
                                vm.users = sortUsers(vm.users);
                                vm.selectUser(vm.editedUser);
                            } else {
                                selectFirstUserIfAvailable();
                            };
                        } else {
                            if (_.findWhere(vm.editedUser.userFacilities,
                                { id: facilityService.selectedFacility.id })) {
                                _.extend(vm.selectedUser, vm.editedUser);
                                vm.selectedUser.roleName = _
                                    .findWhere(vm.rolesForViewMode, { id: vm.selectedUser.roleId })
                                    .name;
                                vm.users = sortUsers(vm.users);
                            } else {
                                vm.users = _.without(vm.users, vm.selectedUser);
                                selectFirstUserIfAvailable();
                            };
                        }
                        vm.mode = "view";
                    },
                    function (error) {
                            if (error.modelStateSummary && error.modelStateSummary.email) {
                                vm.errorMessage = error.modelStateSummary.email;
                                vm.editUserForm.email.$setValidity("emailAlreadyExists", false);
                                $anchorScroll.yOffset = 300;
                                $anchorScroll("email");
                            }
                            else if (error.modelStateSummary && error.modelStateSummary.firstName) {
                                dialogService.openActionDialog("Warning",
                                    error.modelStateSummary.firstName,
                                    "name",
                                    "Yes",
                                    function () {
                                        vm.save(true);
                                    },
                                    "btn-primary btn-inline-block-xsm");
                            }
                            else {
                                notificationService.error("Unexpected error occurred while creating user.");
                            }
                        });
            }
        };

        vm.onEmailChange = function () {
            vm.editUserForm.email.$setValidity("emailAlreadyExists", true);
        }
    }
];
