import * as _ from 'underscore';
import * as moment from 'moment';
import {extendMoment} from 'moment-range';
import { IIndependentLivingService } from '../services/IIndependentLivingService';
import { UnitDto, FeesAndChargesDto, FinancialSummaryAndUnitCostsDto, SelectedResidentDto,
         IluResidencyFinanceFeesDto, ResidencyFinanceOptionalServiceDto, DeferredManagementFeeDto, DeferredManagementFeeScheduleDto, IluStatus, BillPayerType, AssignedAccommodationDto} from '../Models';


import { INotificationService } from '../../common/services/INotificationService';
import {IOrganisationFacilityService} from '../../organisation/services/IOrganisationFacilityService';
import {OptionalService} from '../../organisation/Models';
import type { IStateService } from 'angular-ui-router';

const { range } = extendMoment(moment);

class EnquiryFinanceEditorController {
  static $inject = ["independentLiving.service", "organisation.facility.service", "$state", "notification.service", "$q", "dialog.service","EffectiveDateTypes"];

     public feesAndCharges: FeesAndChargesDto;        
     public availableUnits: Array<UnitDto> = new Array<UnitDto>();
     public selectedUnit : UnitDto = new UnitDto();
     public residents : Array<SelectedResidentDto> = new Array<SelectedResidentDto>();
     public residentId : number;
     public enquiryId : number;  
     public saveFinanceForm: ng.IFormController;
     public admissionDateUtc : Date;

     // this class being used for populate and display financial summary and unit costs fields
     public financialSummaryAndUnitCosts : FinancialSummaryAndUnitCostsDto;

     public facilityOptionalServices: OptionalService[];
     public facilityServicesDropDownOptions = [];
     public dropdownSettings = {
        singleSelection: true,
        addAllOption: false,
        enableCheckAll: false,
        showClearSelectedItems: false
    };
     public facilityOptionalServicesLookupById: {[optionalServiceId: number]: OptionalService} = {};
     public popoverConfigsForOneResidency: any;
     public readonlyEffectiveDatePopoverConfigs: { [residencyId: number]: any[] } = {};

     // this class being used for populate and display deffered management fee fields
     public deferredManagementFee: DeferredManagementFeeDto;
     public isIngoingContributionChange : boolean;
     public viewDepartedMode: boolean;
     public isSelectedResidentResponsible: boolean;
     public selectedResponsibleResidency: string;
     public selectedResponsibleResidentId: number;
     public selectedBillPayerFullName: string;
     public selectedBillPayerType : BillPayerType;
    
    constructor(
        private readonly iluService: IIndependentLivingService, 
        private readonly organisationFacilityService: IOrganisationFacilityService, 
        private $state: IStateService, 
        private readonly notificationService: INotificationService,
        private readonly $q: any) {

        this.enquiryId = this.$state.params.enquiryId;
        this.viewDepartedMode = this.$state.params.viewDepartedMode;
         
        // if direct to this partial view from assign unit popup, need to use selected unit
        if(this.$state.params.selectedUnit)  {
            this.selectedUnit = this.$state.params.selectedUnit;
        }
        
        if(this.$state.params.selectedResidents)  {
            this.residents = this.$state.params.selectedResidents;
        }
        
        this.getAvailableUnitsAndSetEvents();
        this.getFeesAndCharges();       
    }  

    public serviceOptionalServiceIdChanged = function (optionalService, ctrl) {
        if (ctrl.$modelValue) {
            var selectedItemId = ctrl.$modelValue[0].id;
            optionalService.optionalServiceId = selectedItemId;
            var facilityOptionalService = this.facilityOptionalServices.find(x => x.id == selectedItemId); 
            optionalService.facilityOptionalServiceStartDateUtc = facilityOptionalService.startDateUtc;
            optionalService.facilityOptionalServiceEndDateUtc = facilityOptionalService.endDateUtc;
            ctrl.$setValidity('required', true);
        }
        else {
            ctrl.$setValidity('required', false);
        }
    };

    private getAvailableUnitsAndSetEvents = () => {
        let unitsPromise;
        if (this.$state.current.name == "editIndependentLivingAllocation.finance") {
            unitsPromise = this.iluService.getUnitsByStatus(IluStatus.Available).then(result => {
                return result;
            }, () => {
                this.notificationService.error("Unexpected error while loading units.");
            });
        }
        else {
            unitsPromise = this.iluService.getAvailableUnits()
            .then(result => {
                return result;
            }, () => {
                this.notificationService.error("Unexpected error while loading unit events.");
            });
        }

        let unitNotesPromise = this.iluService.getAccommodationFinancialEvents().then(events => {
            return events;
          },() => {
            this.notificationService.error("Unexpected error while loading units.");
        });
        
        this.$q.all([unitsPromise, unitNotesPromise]).then(result => {
            this.availableUnits = result[0];
            this.iluService.getUnitNotes(this.availableUnits, result[1]);
          });
    }
    
    public initDMFDetails = () => {
        this.deferredManagementFee.minimumRate = this.feesAndCharges.deferredManagementFeeDto.minimumRate;
        this.deferredManagementFee.deferredManagementFeesSchedule = new Array<DeferredManagementFeeScheduleDto>();
        var detail = new DeferredManagementFeeScheduleDto();
        this.deferredManagementFee.deferredManagementFeesSchedule.push(detail);

        return this.deferredManagementFee;
    }
    
    private getFeesAndCharges = () => {  
        let residentId = this.viewDepartedMode ? this.$state.params.selectedResidentId : null;
        this.iluService.getFeesAndCharges(this.enquiryId, this.viewDepartedMode, residentId).then(result => {
            this.feesAndCharges = result; 

            if (this.feesAndCharges != null && this.feesAndCharges.assignedAccommodationDto != null) {
                this.selectedUnit.accommodationId = this.feesAndCharges.assignedAccommodationDto.accommodationId;
                this.selectedUnit.roomNumber = this.feesAndCharges.assignedAccommodationDto.unitNumber;
                this.selectedUnit.accommodationName = this.feesAndCharges.assignedAccommodationDto.unitType;
                this.selectedUnit.location = this.feesAndCharges.assignedAccommodationDto.location;
            }

            this.financialSummaryAndUnitCosts = this.feesAndCharges.financialSummaryAndUnitCostsDto;     
            if(this.feesAndCharges.financialSummaryAndUnitCostsDto.publishedPrice!=null)           
            {
                this.selectedUnit.price = this.feesAndCharges.financialSummaryAndUnitCostsDto.publishedPrice;
            }

            this.isSelectedResidentResponsible = this.$state.params.selectedResidentId == this.feesAndCharges.financialSummaryAndUnitCostsDto.responsibleResidentId;
            if(this.isSelectedResidentResponsible)
            {
                var selectedResponsibleFullName = this.feesAndCharges.financialSummaryAndUnitCostsDto.responsibleResident;
                if (selectedResponsibleFullName)
                    this.selectedResponsibleResidency = selectedResponsibleFullName;
                this.selectedResponsibleResidentId = this.feesAndCharges.financialSummaryAndUnitCostsDto.responsibleResidentId;
                var selectedResidencyFinanceFee = this.feesAndCharges.iluResidencyFinanceFees.filter(x=>x.residencyId == this.feesAndCharges.financialSummaryAndUnitCostsDto.responsibleResidencyId)[0];
                this.selectedBillPayerType = selectedResidencyFinanceFee.billPayerType;
                this.selectedBillPayerFullName = selectedResidencyFinanceFee.billPayerFullName;
            }
            // load dmf accordion
            this.deferredManagementFee = this.feesAndCharges.deferredManagementFeeDto;
            if (this.deferredManagementFee.deferredManagementFeesSchedule.length == 0) {
              // no any record before so need to fill in with some initial data
              this.deferredManagementFee = this.initDMFDetails();
            }

            this.organisationFacilityService.getOptionalServices()
            .then(result => {
                this.facilityOptionalServices = result;
                this.initializeOptionalServiceInformation(); 
            }, () => {
                this.notificationService.error("Unexpected error occurred while getting facility optional services.");
            });
        }, () => {
            this.notificationService.error("Unexpected error occurred while loading fee and charges");
        });
    }

    public markFormAsDirtyIfLabelFieldsChanged()
    {
        // if assign unit already exist and now change from dropdown list, form should consider as dirty 
        if(this.feesAndCharges && this.feesAndCharges.assignedAccommodationDto) 
        {
           if(this.feesAndCharges.assignedAccommodationDto.unitNumber && 
            this.feesAndCharges.assignedAccommodationDto.unitNumber!=this.selectedUnit.roomNumber)
           {
                this.saveFinanceForm.$setDirty();
           }
        }

        if(this.isIngoingContributionChange)
        {
            this.saveFinanceForm.$setDirty();
        }
    } 

    public addDmfDetail = (form) => {
        this.feesAndCharges.deferredManagementFeeDto.deferredManagementFeesSchedule.push({
            effectiveMonth : null,
            value : null,
            independentLivingAllocationId : this.enquiryId,
            total : null,
            feeOrderNumber : 0
        });
    };

    public removeDmfDetail = (index) => {
        if (this.deferredManagementFee.deferredManagementFeesSchedule.length === 1) {
            return;
        }
        this.deferredManagementFee.deferredManagementFeesSchedule.splice(index, 1);
    }

    public getTotalDMFPercentageForTheTimePeriod = (index) => {
        return this.iluService.getTotalDMFPercentageForTheTimePeriod(index, this.deferredManagementFee.deferredManagementFeesSchedule);
    }

   public save(form: ng.IFormController)
   {
        this.validateContractSignedDate(form);

        if(form.$valid)
        {
            if (this.feesAndCharges.assignedAccommodationDto === null) {
                //This can happen if a unit was unassigned to the enquiry (e.g. the unit was deleted,
                //so create a new dto object here to receive the assigned unit details)
                this.feesAndCharges.assignedAccommodationDto = new AssignedAccommodationDto();
            }
            this.feesAndCharges.assignedAccommodationDto.accommodationId = this.selectedUnit.accommodationId;
            this.feesAndCharges.assignedAccommodationDto.independentLivingAllocationId = this.enquiryId;

            this.feesAndCharges.financialSummaryAndUnitCostsDto = this.financialSummaryAndUnitCosts;
            this.feesAndCharges.financialSummaryAndUnitCostsDto.publishedPrice = this.selectedUnit.price;

            // save deferred management fee, must use deep clone here
            this.feesAndCharges.deferredManagementFeeDto = new DeferredManagementFeeDto();
            this.feesAndCharges.deferredManagementFeeDto.minimumRate = this.deferredManagementFee.minimumRate;
            this.feesAndCharges.deferredManagementFeeDto.dmfUnitCostCalculationType = this.deferredManagementFee.dmfUnitCostCalculationType;
            this.feesAndCharges.deferredManagementFeeDto.dmfUnitCostCalculationTypeDescription = this.deferredManagementFee.dmfUnitCostCalculationTypeDescription;
            this.feesAndCharges.deferredManagementFeeDto.independentLivingAllocationId = this.deferredManagementFee.independentLivingAllocationId;
            
            let feeOrderNumber : number = 1;
            this.feesAndCharges.deferredManagementFeeDto.deferredManagementFeesSchedule = new Array<DeferredManagementFeeScheduleDto>();
            this.deferredManagementFee.deferredManagementFeesSchedule.forEach(scheduleDetail => {
                if(scheduleDetail.effectiveMonth>0 && scheduleDetail.value>0)
                {
                    
                    this.feesAndCharges.deferredManagementFeeDto.deferredManagementFeesSchedule.push({effectiveMonth : scheduleDetail.effectiveMonth,
                        value : scheduleDetail.value,
                        independentLivingAllocationId : scheduleDetail.independentLivingAllocationId,
                        total : scheduleDetail.total,
                        feeOrderNumber : feeOrderNumber++});        
                }
            });

            this.iluService.saveFeesAndCharges(this.feesAndCharges).then(result => {
            this.notificationService.success("fees and charges saved successfully!");
            this.goToView();
            }, () => {
                this.notificationService.error("Unexpected error occurred while saving fees and charges");
            });

        }
   }
   
    public goToView = ():void => {
        if (this.$state.current.name == "editIndependentLivingAllocation.finance") {
            this.$state.go("viewIndependentLivingAllocation.finance", {'enquiryId': this.enquiryId, 'viewDepartedMode': this.viewDepartedMode,  
                'residentId': this.residents[0].residentId,'selectedResidents' : this.residents, 'admissionDate' : this.admissionDateUtc});
        }
        else{
            this.$state.go("viewEnquiry.finance", {'enquiryId': this.enquiryId, "residentId": this.residents[0].residentId, 'selectedResidents' : this.residents});
        }
    }

    public addOptionalService = (form: ng.IFormController, residencyId: number) => {
        let iluResidencyFinanceFees: IluResidencyFinanceFeesDto = _.findWhere(this.feesAndCharges.iluResidencyFinanceFees, {residencyId: residencyId});
        let optionalServices = iluResidencyFinanceFees.optionalServices;
        var serviceWithValidationErrors = _.find(optionalServices, function (service) {
            if (!service.optionalServiceId || !service.startDateUtc) {
                return true;
            }
        });

        if (serviceWithValidationErrors) {
            for (var i = 0; i < optionalServices.length; i++) {
                if (form.hasOwnProperty('serviceOptionalServiceId.'+ residencyId +'.'+ i))
                    form['serviceOptionalServiceId.'+ residencyId +'.'+ i].$setTouched();
                if (form.hasOwnProperty('serviceStartDate.'+ residencyId +'.'+ i))
                    form['serviceStartDate.'+ residencyId +'.'+ i].$setTouched();
                if (form.hasOwnProperty('serviceEndDate.'+ residencyId +'.'+ i))
                    form['serviceEndDate.'+ residencyId +'.'+ i].$setTouched();                    
            }
            return;
        }

        if (this.validateOptionalServiceOverlap(form, residencyId, optionalServices)) {
            return;
        }

        optionalServices.push({
            index: optionalServices.length,
            optionalServiceId: null,
            startDateUtc: null,
            endDateUtc: null,
            stopped: false,
            new: true,
            rate: "",
            applicableRatesList: null
        });

        this.popoverConfigsForOneResidency = [];
        this.initializeReadonlyEffectiveDatePopoverConfig(optionalServices, residencyId, optionalServices.length - 1);
        this.readonlyEffectiveDatePopoverConfigs[residencyId].push(this.popoverConfigsForOneResidency[optionalServices.length - 1]);  
    };

    public validateContractSignedDate = (form: ng.IFormController) => {
        let contractSignedDateCtrl = form['contractSignedDate'];

        if (form.hasOwnProperty('contractSignedDate')) {
            if (this.financialSummaryAndUnitCosts.contractSignedDateUtc && contractSignedDateCtrl.$modelValue == null)
                contractSignedDateCtrl.$setValidity('emptyValue', false);
            else
                contractSignedDateCtrl.$setValidity('emptyValue', true);
        }
        
    }

    public validateOptionalService = (form: ng.IFormController, 
        optionalServices: ResidencyFinanceOptionalServiceDto[],
        service: ResidencyFinanceOptionalServiceDto, 
        residencyId: number,
        index: number
        ) => {
        var valid = true;
        let startDateCtrl = form['serviceStartDate.' + residencyId + '.' + index];
        let endDateCtrl = form['serviceEndDate.' + residencyId + '.' + index];

        if (moment.utc(service.startDateUtc).isBefore(moment.utc(service.facilityOptionalServiceStartDateUtc))) {
            startDateCtrl.$setValidity('startDateBeforeServiceStartDate', false);
            valid = false;
        }
        else {
            startDateCtrl.$setValidity('startDateBeforeServiceStartDate', true);
        }

        if (service.facilityOptionalServiceEndDateUtc && moment.utc(service.startDateUtc).isAfter(moment.utc(service.facilityOptionalServiceEndDateUtc))) {
            startDateCtrl.$setValidity('startDateAfterServiceEndDate', false);
            valid = false;
        }
        else {
            startDateCtrl.$setValidity('startDateAfterServiceEndDate', true);
        }

        if (service.endDateUtc && moment.utc(service.endDateUtc).isBefore(service.startDateUtc)) {
        endDateCtrl.$setValidity('invalidDate', false);
            valid = false;
        }
        else {
            endDateCtrl.$setValidity('invalidDate', true);
        }

        if (service.endDateUtc && service.facilityOptionalServiceEndDateUtc && moment.utc(service.endDateUtc).isAfter(moment.utc(service.facilityOptionalServiceEndDateUtc))) {
            endDateCtrl.$setValidity('endDateAfterServiceEndDate', false);
            valid = false;
        }
        else {
            endDateCtrl.$setValidity('endDateAfterServiceEndDate', true);
        }

        if (this.validateOptionalServiceOverlap(form, residencyId, optionalServices)) {
            valid = false;
        }

        return valid;
    };

    private  validateOptionalServiceOverlap = (form: ng.IFormController, residencyId: number, optionalServices: ResidencyFinanceOptionalServiceDto[]) => {
        let hasError = false;

        for (let i = 0; i < optionalServices.length; i++) {
            if (form.hasOwnProperty('serviceEndDate.' + residencyId + '.' + i))
                form['serviceEndDate.' + residencyId + '.' + i].$setValidity('overlapDate', true);
        }

        // find the maximum date used, it will be used as the range delimiter for open date ranges (ie no end date)
        let maxDate = new Date();
        optionalServices.forEach(optionalService => {
            if (optionalService.startDateUtc > maxDate)
                maxDate = new Date(optionalService.startDateUtc.getTime());
            if (optionalService.endDateUtc > maxDate)
                maxDate = new Date(optionalService.endDateUtc.getTime());
        });

        // add a month onto the max date to give some time for the overlap to apply
        maxDate.setUTCMonth(maxDate.getUTCMonth() + 1);

        for (let i = 0; i < optionalServices.length; i++) {                

            if (!optionalServices[i].startDateUtc) {
                continue;
            }

            // if no end date, use the max date
            let range1 = range(optionalServices[i].startDateUtc, optionalServices[i].endDateUtc ? optionalServices[i].endDateUtc : maxDate);

            for (let j = i + 1; j < optionalServices.length; j++) {

                // only check overlaps for matching ids as different optional services can overlap
                if (optionalServices[i].optionalServiceId != optionalServices[j].optionalServiceId)
                    continue;

                if (!optionalServices[j].startDateUtc) {
                    continue;
                }

                // don't check if both are stopped
                if (optionalServices[i].stopped || optionalServices[j].stopped)
                    continue;

                // if no end date, use the max date
                let range2 = range(optionalServices[j].startDateUtc, optionalServices[j].endDateUtc ? optionalServices[j].endDateUtc : maxDate);

                if (range1.overlaps(range2, { adjacent: true })) {
                    let formIndexI = this.lookupOptionalServiceFormRowIndex(form, residencyId, i);
                    let formIndexJ = this.lookupOptionalServiceFormRowIndex(form, residencyId, j);

                    if (form.hasOwnProperty('serviceEndDate.' + formIndexI))
                        form['serviceEndDate.' + formIndexI].$setValidity('overlapDate', false);

                    if (form.hasOwnProperty('serviceEndDate.' + formIndexJ))
                        form['serviceEndDate.' + formIndexJ].$setValidity('overlapDate', false);
                    hasError = true;
                }
            }
        }

        return hasError;
    }

    public lookupOptionalServiceFormRowIndex = (form: ng.IFormController, residencyId: number, optionalServiceIndex: number) => {
        let rowName = Object.keys(form)
            .filter(key => {
                return key.indexOf('index.') == 0 
                    && form[key].$name.substring(form[key].$name.indexOf('.')+ 1, form[key].$name.lastIndexOf('.')) == residencyId.toString()
                    && form[key].$modelValue == optionalServiceIndex
            })[0];

        return rowName.substring(rowName.indexOf('.') + 1);
    } 

    public initializeReadonlyEffectiveDatePopoverConfig = (optionalServices: ResidencyFinanceOptionalServiceDto[], residencyId: number, index = null) => {
        if (this.facilityOptionalServices == null || this.facilityOptionalServices.length === 0 || optionalServices == null)
            return;

        if (index === null) {
            this.popoverConfigsForOneResidency = [];
            for (let i = 0; i < optionalServices.length; i++) {
                this.initializeReadonlyEffectiveDatePopoverConfig(optionalServices, residencyId, i);
            }
        } 
        else {
            this.popoverConfigsForOneResidency[index] = {
                id: 'value-link-' + residencyId + '-' + index,
                placement: 'bottom',
                target: 'a#value-link-' + residencyId + '-' + index,
                autoClose: true,
                popoverCssClass: 'fee-effective-popover',
                columns: [{
                    headerText: 'AMOUNT',
                    headerCssClass: 'support-col',
                    dataFieldName: 'value',
                    cellCssClass: 'support-col',
                    filterName: 'currency'
                }, {
                    headerText: 'EFFECTIVE DATE',
                    headerCssClass: 'effective-date-col',
                    dataFieldName: 'startDate',
                    cellCssClass: 'effective-date-col',
                    filterName: 'date',
                    format: 'dd MMM yyyy',
                    onCellCreated: (rowIndex, formattedValue) => { return formattedValue; }
                }],
                getRowCssClass: (data) => {
                    for (let i = 0; i < this.facilityOptionalServices.length; i++)
                        if (this.facilityOptionalServices[i].ratesList.indexOf(data) == this.facilityOptionalServices[i].todayRateIndex)
                            return 'latest';
                    return '';
                }
            };
        }
    }

    private initializeOptionalServiceInformation = () => {            
        if (this.facilityOptionalServices == null || this.facilityOptionalServices.length == 0) {
            return;
        }

        // start with initialising the facility-level optional services

        // sort by name so the dropdown is sorted alphabetically
        this.facilityOptionalServices = _.sortBy(this.facilityOptionalServices, facilityOptionalService => facilityOptionalService.typeDescription.toLowerCase());

        this.facilityOptionalServices.forEach(facilityOptionalService => {
            if (!facilityOptionalService.stopped) {
                this.facilityServicesDropDownOptions.push({id: facilityOptionalService.id, text:facilityOptionalService.typeDescription + ` - ${facilityOptionalService.billingPeriod.toLowerCase()} until ${facilityOptionalService.stopBillingEvent.toLowerCase()}` });
            }

            // populate the lookup
            this.facilityOptionalServicesLookupById[facilityOptionalService.id] = facilityOptionalService;                

            this.iluService.initialiseFacilityOptionalServices(facilityOptionalService);            
        });

        this.feesAndCharges.iluResidencyFinanceFees.forEach(residencyFinanceFees => {
            this.popoverConfigsForOneResidency = [];
            this.initializeReadonlyEffectiveDatePopoverConfig(residencyFinanceFees.optionalServices, residencyFinanceFees.residencyId);
            this.readonlyEffectiveDatePopoverConfigs[residencyFinanceFees.residencyId] = this.popoverConfigsForOneResidency;

            // now do the resident-level optional services
            residencyFinanceFees.optionalServices.forEach(optionalService => {
                this.iluService.initialiseResidencyOptionalServices(optionalService, residencyFinanceFees.optionalServices, this.facilityOptionalServicesLookupById);
            });
        });        
    }  

    public removeOptionalService = (form: ng.IFormController, residencyId: number, optionalService: ResidencyFinanceOptionalServiceDto, optionalServices: ResidencyFinanceOptionalServiceDto[]) => {
        for (let i = 0; i < optionalServices.length; i++) {
            if (form.hasOwnProperty('serviceEndDate.' + residencyId + '.' + i))
                form['serviceEndDate.' + residencyId + '.' + i].$setValidity('overlapDate', true);
        }
        
        optionalServices.splice(optionalServices.indexOf(optionalService), 1);

        for (let i = 0; i < optionalServices.length; i++) {
            optionalServices[i].index = i;
        }

        if (optionalServices.length > 1)
            this.validateOptionalServiceOverlap(form, residencyId, optionalServices);
    } 

    public redirectToContactDetailBillPayer = () => {
        var goToState = ((this.$state.current.name === 'viewIndependentLivingAllocation.finance') ||
          (this.$state.current.name === 'editIndependentLivingAllocation.finance'))
          ? 'viewIndependentLivingAllocation'
          : 'viewEnquiry';
         this.$state.go(goToState, { 'enquiryId': this.enquiryId, 'residentId': this.selectedResponsibleResidentId, '#': this.selectedBillPayerType.substring(0, 1).toLowerCase() + this.selectedBillPayerType.substring(1)}, { reload: true });
      };

      public updateBillPayerLink = (residencyId : number) => {
        var selectedResidencyFinanceFee = this.feesAndCharges.iluResidencyFinanceFees.filter(x=>x.residencyId == residencyId)[0];
        this.selectedBillPayerType = selectedResidencyFinanceFee.billPayerType;
        this.selectedBillPayerFullName = selectedResidencyFinanceFee.billPayerFullName;
      }

}
export = EnquiryFinanceEditorController;
