"use strict";

module.exports = function () {
    return {
        restrict: "E",
        scope: {
            residentId: "=",
            expectedDepartureDate: "=",
            availableRooms: "=",
            selectedRoom: "=",
            selectedBedId: "=",
            loadingRoomsAndBeds: "=",
            internalDisabled: "<",
            residentAdmissionInfo: "="
        },
        controller: "residentRoomAssignment.controller",
        controllerAs: "vm",
        replace: false,
        templateUrl: "app/common/directives/partials/residentRoomAssignment.html"
    };
}