function datepickerDirective() {
    return {
        restrict: "E",
        replace: true,
        scope: {
            internalRequired: "@internalRequired",
            internalDisabled: "<",
            ngModel: '=',
            internalId: "@internalId",
            internalName: "@internalName",
            internalClass: "@internalClass",
            internalDataMinDate: "@internalDataMinDate",
            internalDataMaxDate: "@internalDataMaxDate",
            internalContainer: "@internalContainer",
            internalDataAutomation: "@internalDataAutomation",
            onChange: "&",
            calendarClass: "@calendarClass",
            doNotDisplayPlaceholder: "@doNotDisplayPlaceholder"
        },
        require: 'ngModel',
        link: function(scope, element, attrs, ngModel) {
           ngModel.$render = function () {
                scope.vm.updateFromExternal(ngModel);
                if(scope.vm.changeTriggered){
                    scope.vm.changeTriggered = false;
                    scope.vm.onChange();
                }
           };
        },
        controller: "datepicker.controller",
        controllerAs: "vm",
        bindToController: true,
        templateUrl: "app/common/directives/partials/datepicker.html"
    };
}

export = datepickerDirective;


