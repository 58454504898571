"use strict";

module.exports = [
    "$scope",
    "dialog.service",
    "notification.service",
    "residents.service",
    "$rootScope",
    "$window",
    function ($scope, dialogService, notification, residentsService, $rootScope, $window) {
	$scope.uploadPhoto = function () {
		dialogService.openStaticDialog("app/residents/partials/uploadPhotoDialog.html", "uploadPhotoDialog.controller", {
			residentId: $scope.residentId
		});
	}

	$scope.selectFile = function (files) {
	    if (files.length === 0)
	        return;

	    dialogService.openStaticDialog("app/residents/partials/uploadPhotoDialog.html", "uploadPhotoDialog.controller", {
	        residentId: $scope.residentId,
	        rawFile: files[0]
	    });
	};

	$scope.deletePhoto = function () {
	    if ($window.confirm("Do you really want to remove the profile picture.")) {
			residentsService.deletePhoto($scope.residentId).then(function (result) {
				$rootScope.$broadcast("residentProfileImageChanged", $scope.residentId);
			},
			function () {
                notification.error("Unable to remove photo. Either your computer is offline, or Resident Manager is having problems of its own.");
			});
		}
	}
}];