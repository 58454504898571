"use strict";

module.exports = function () {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            "name": "@",
            "class": "@",
            "label": "@",
            "accept": "@",
            "onChangeCallback": "="
        },
        templateUrl: "app/common/directives/partials/fileUploadButton.html",
        link: function ($scope, element) {
            var fileUploadInput = element.find("input[type='file']");
            fileUploadInput.bind("change", function () {
                var files = fileUploadInput[0].files;
                //send files to onChangeCallback method
                $scope.onChangeCallback(files);
                //clear input file to allow reselecting the same file
                fileUploadInput.val(null);
            });
        }
    };
}
