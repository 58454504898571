"use strict";

module.exports = {
    
    configure : function(module) {

        module.config(["$stateProvider", "$urlRouterProvider",
            function ($stateProvider, $urlRouterProvider) {
            $urlRouterProvider.otherwise("/dashboard");
            $stateProvider
              // .state("accountSettings", {
              //   url: "/accountSettings",
              //   templateUrl: "app/authentication/partials/accountSettings.html",
              //   controller: "accountSettings.controller as vm"
              // })
              .state("forgotPassword", {
                url: "/forgotPassword",
                templateUrl: "app/authentication/partials/forgotPassword.html",
                controller: "forgotPassword.controller as vm"
              }).state("resetPassword", {
                url: "/resetPassword?salt",
                templateUrl: "app/authentication/partials/resetPassword.html",
                controller: "resetPassword.controller as vm"
              }).state("redirectToLogin", {
                url: "/redirectToLogin",
                controller: "redirectToLogin.controller as vm"
              });
        }]);
    }

};