import * as angular from 'angular';
import * as _ from 'underscore';
import { FacilityDto } from "../../authentication/Models";
import { IFacilityService } from "../../authentication/services/IFacilityService";
import { INotificationService } from "../../common/services/INotificationService";
import { IDateFormattingService } from "../../common/services/IDateFormattingService";
import { IReportingService } from "../services/IReportingService";
import { IDownloadFileService } from '../../common/services/IDownloadFileService';
import { PalliativeAssessmentsReportFilter } from '../Models';

class PalliativeAssessmentsReportComponent implements ng.IComponentOptions {
    public bindings: any = {};

    public templateUrl: string = 'app/reporting/partials/palliativeAssessmentsReport.html';

    public controller: any = class FinancialDetailsReportController {
        static $inject = ["facility.service", "notification.service", "dateFormatting.service", "reporting.service", "downloadFile.service"];

        public facilities: FacilityDto[] = [];
        public selectedFacility: FacilityDto;

        public filterTypeSettings: any;
        public translationText: any;

        constructor(private readonly facilityService: IFacilityService,
            private readonly notificationService: INotificationService,
            private readonly dateFormatingService: IDateFormattingService,
            private readonly reportingService: IReportingService,
            private readonly downloadFileService: IDownloadFileService) {

            this.facilities = facilityService.activeUserFacilities.filter(x => x.facilityType == "AgeCare");
            if (this.facilities.length == 1)
                this.selectedFacility = this.facilities[0];
        }

        public extract = (): void => {
            let filter = new PalliativeAssessmentsReportFilter();
            filter.facilityId = this.selectedFacility == null ? 0 : this.selectedFacility.id;

            this.reportingService.getpalliativeAssessmentsReportExcelStream(filter).then((response) => {
                this.downloadFile(response, 'PalliativeAssessments.xlsx');
            },
                () => {
                    this.notificationService.error("Error occurred while exporting palliative assessments report");
                });
        }

        private downloadFile = (response: ArrayBuffer, fileName: string) => {
            this.downloadFileService.downloadFile(response, fileName);
        }
    }
}

export = PalliativeAssessmentsReportComponent;