import { INotificationService } from '../../common/services/INotificationService';
import { IOrganisationFacilityService } from '../../organisation/services/IOrganisationFacilityService';
import {TransferFacilityToFacilityDto, ProspectDto, TransferType } from '../Models';
import { IIndependentLivingService } from '../services/IIndependentLivingService';
import _ = require('underscore');
import type { IStateService } from 'angular-ui-router';

class TransferEnquiryDialogController {
    static $inject = ["independentLiving.service", "notification.service", "organisation.facility.service", "$state", "modalConfig"];

    public allOrganisationFacilities: any[];
    public transferFacilityToFacilityDto: TransferFacilityToFacilityDto = new TransferFacilityToFacilityDto();
    public residentIds : number[];
    public enquiry : any;
    public $hide: any;
    public title: any;
    public fromILU: boolean;

    constructor(
        private readonly independentLivingService: IIndependentLivingService,
        private readonly notificationService: INotificationService,
        private readonly organisationFacilityService: IOrganisationFacilityService,
        private $state: IStateService,
        private readonly modalConfig: any) {

        this.enquiry = modalConfig.enquiry;

        this.title = "Transfer";

        this.organisationFacilityService.getAllOrganisationFacilities(true).then(
            response => {
                this.allOrganisationFacilities = response;
            });
    }
    public bindHide = (hide) => {
        this.$hide = hide;
    };

    public transfer = (isValid) => {
        if (isValid) {
            this.transferFacilityToFacilityDto.transferType = TransferType.Lead;
            this.transferFacilityToFacilityDto.residentIds = [];
    
            _.forEach(this.enquiry.prospects, (prospect: ProspectDto) => {
                this.transferFacilityToFacilityDto.residentIds.push(prospect.residentId);
            }); 
                 
            this.independentLivingService.transferEnquiry(this.transferFacilityToFacilityDto).then(result => {
                    this.$hide();
                    this.notificationService.success("Enquiry transferred");
                    this.$state.reload();
            },
            () => {
                this.notificationService.error("Unexpected error occurred while saving");
            });
        }
    }
}

export = TransferEnquiryDialogController;
