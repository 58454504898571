import { IIndependentLivingService } from "../services/IIndependentLivingService";
import { INotificationService } from "../../common/services/INotificationService";
import { DepartedResidentDto } from "../Models";
import { IInfiniteLoaderFactory } from "../../common/services/IInfiniteLoaderService";

class ViewDepartedResidentsComponent implements ng.IComponentOptions {
    public bindings: any = {};
    public templateUrl: string = 'app/independentLiving/partials/viewDepartedResidents.html';

    public controller: any = class ViewDepartedResidentsComponent {
        static $inject = ["independentLiving.service", "notification.service", "infiniteLoader.service"];

        public departedResidents: Array<DepartedResidentDto>;
        public infiniteLoader: any;

        constructor(private readonly iluService: IIndependentLivingService,
            private readonly notificationService: INotificationService,
            private readonly infinitLoaderService: IInfiniteLoaderFactory) {
            this.getDepartedResidents();
        }

        private getDepartedResidents = () => {
            this.departedResidents = new Array<DepartedResidentDto>();
            this.infiniteLoader = this.infinitLoaderService.instance(this.iluService.viewDepartedResidents,
                (result) => {
                    if (result && result.length > 0) {
                        this.departedResidents = this.departedResidents.concat(result);
                    }
                },
                () => {
                    this.notificationService.error("Unexpected error occurred while loading departed residents to view.");
                }
            );
        }

        public isSettledText = (text: string) => {
            return text.lastIndexOf("Settled", 0) === 0;
        }
    }
}

export = ViewDepartedResidentsComponent;