interface IResidentSearchSundryControllerScope extends ng.IScope {
    remoteApiHandler: (searchString, httpCancelerPromise) => ng.IPromise<any>;
    remoteUrlDataField: string;
    selectedResidentId: any;
    initialValue: string;
    selectedObj: any;
    required:boolean;
    inputName: string;
    index: number;
    onSelected: (index: number, item: any) => void;
}

class ResidentSearchSundryController {
    static $inject = ["$scope"];
    public dataField: string;
    public selectedResident: any;
    public initialValue: string;
    public required: boolean;
    public inputName: string;

    constructor(private $scope: IResidentSearchSundryControllerScope) {
        this.dataField = $scope.remoteUrlDataField;
        this.initialValue = $scope.initialValue;
        this.required = $scope.required;
        this.inputName = $scope.inputName;

        $scope.$watch("selectedObj", (newValue, oldValue, scope) => {
            this.selectedResident = newValue;
            this.$scope.onSelected(this.$scope.index, (newValue === undefined) ? null : (<any>newValue).originalObject)
        });
    }

    public search = (searchString, httpCancelerPromise) => {
        return (<any>this.$scope).remoteApiHandler({searchString: searchString, httpCancelerPromise: httpCancelerPromise}).then(response => {
            return response;
        });
    }
}

export = ResidentSearchSundryController;