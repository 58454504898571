"use strict";
const _ = require("underscore");
const IluStatus = require("../Models").IluStatus;

module.exports = [
    "organisation.rooms.service",
    "independentLiving.service",
    "dialog.service",
    "notification.service",
    "facility.service",
    "authentication.service",
    "$state",
    "$timeout",
    function (roomsService, iluService, dialogService, notificationService, facilityService, auth, $state, $timeout) {
        var vm = this;
        vm.iLuStatusDict = {};
        vm.mode = "view";
        vm.selectedFacilityName = facilityService.selectedFacility.name;
        vm.selectedFacilityType = facilityService.selectedFacility.facilityType;
        vm.rooms = [];
        vm.roomTypes = [];
        vm.accommodationFinancialEvents = [];


        (function getLocationsWithRoomsAndRoomTypes() {
            roomsService.getRooms()
                .then(function (result) {
                        vm.rooms = result;
                    },
                    function() {
                        notificationService.error("Unexpected error occurred while loading rooms.");
                    });
            roomsService.getAccommodationTypes()
                .then(function (result) {
                    vm.roomTypes = result;
                    },
                    function() {
                        notificationService.error("Unexpected error occurred while loading room types.");
                    });
            iluService.getAccommodationFinancialEvents().then(events => {
                    vm.accommodationFinancialEvents = events;
                    },
                    function() {
                        notificationService.error("Unexpected error occurred while loading accommodation financial events.");
                    }); 

            Object.keys(IluStatus).map(key => {
                vm.iLuStatusDict[key] = IluStatus[key];
            });
        })();

        vm.startAddingRoom = function (locationWithRoom) {
            locationWithRoom.isAddingRoom = true;
            locationWithRoom.focusTrigger = new Date();
            $timeout(function () {
                locationWithRoom.addRoomForm.newRoomNumber.$validators.uniqueRoomNumber = function(roomNumber) {
                    return vm.checkNewRoomNumber(locationWithRoom, roomNumber);
                };
            }, 0);
        };

        vm.cancelAddingRoom = function (locationWithRoom) {
            locationWithRoom.isAddingRoom = false;
            locationWithRoom.newRoomNumber = null;
            locationWithRoom.newRoomType = null;
        };

        vm.clearFormState = function(form) {
            form.$setPristine();
            form.$setUntouched();
        };

        vm.addRoom = function (locationWithRoom) {
            var isFormValid = locationWithRoom.addRoomForm.$valid;
            if (isFormValid) {
                var newRoom = {
                    roomNumber: locationWithRoom.newRoomNumber,
                    accommodationTypeId: locationWithRoom.newRoomType.id,
                    facilityLocationId: locationWithRoom.id
                };
                roomsService.addRoom(newRoom).then(function (saveResults) {
                    locationWithRoom.accommodations.push({
                        accommodationId: saveResults.result,
                        roomNumber: locationWithRoom.newRoomNumber,
                        accommodationName: locationWithRoom.newRoomType.name,
                        beds: (function() {
                            var result = [];
                            _.each(_.range(locationWithRoom.newRoomType.numberBeds),
                                function(index) {
                                    result.push({
                                        id: 0,
                                        number: locationWithRoom.newRoomNumber + "." + index,
                                        occupant: null,
                                        isOutOfService: false
                                    });
                                });
                            return result;
                        })(),
                        justAdded: true
                    });
                if (parseInt(locationWithRoom.newRoomNumber).toString() === locationWithRoom.newRoomNumber) {
                    locationWithRoom.newRoomNumber = (parseInt(locationWithRoom.newRoomNumber) + 1).toString();
                } else {
                    locationWithRoom.newRoomNumber = null;
                };
                locationWithRoom.focusTrigger = new Date();
                }, function () {
                    notificationService.error("Unexpected error occurred while creating room.");
                });
            }
        };

        vm.addUnit = function (locationWithRoom) {
            var isFormValid = locationWithRoom.addRoomForm.$valid;
            if (isFormValid) {
                var newRoom = {
                    roomNumber: locationWithRoom.newRoomNumber,
                    accommodationTypeId: locationWithRoom.newRoomType.id,
                    facilityLocationId: locationWithRoom.id
                };
                roomsService.addRoom(newRoom).then(function (saveResults) {
                    locationWithRoom.accommodations.push({
                        accommodationId: saveResults.result,
                        roomNumber: locationWithRoom.newRoomNumber,
                        accommodationName: locationWithRoom.newRoomType.name,
                        price: locationWithRoom.newRoomType.price,
                        iluStatus: "Available",
                        justAdded: true
                    });
                if (parseInt(locationWithRoom.newRoomNumber).toString() === locationWithRoom.newRoomNumber) {
                    locationWithRoom.newRoomNumber = (parseInt(locationWithRoom.newRoomNumber) + 1).toString();
                } else {
                    locationWithRoom.newRoomNumber = null;
                };
                locationWithRoom.focusTrigger = new Date();
                }, function () {
                    notificationService.error("Unexpected error occurred while creating unit.");
                });
            }
        };

        vm.viewRoom = function (roomId) {
            $state.go("viewRoom", { "roomId": roomId, "locationWithRooms": vm.rooms, "roomTypes": vm.roomTypes });
        };

        vm.checkNewRoomNumber = function(locationWithRoom, roomNumber) {
            var numberAlreadyExists = !!_.findWhere(locationWithRoom.accommodations, { roomNumber: roomNumber });
            return !numberAlreadyExists;
        };

        vm.getUnitEvents = function(unitId) {
            var unitEvents = _.findWhere(vm.accommodationFinancialEvents, {accommodationId: unitId});
            var result = [];
            if (unitEvents && unitEvents.events.length > 0) {
                unitEvents.events.forEach(x => {
                    let event = {};
                    if (x.noticeToVacateDateUtc) {
                        event = {
                            date: x.noticeToVacateDateUtc, 
                            code: "Notice given to vacate on", 
                            resident: x.residentFirstName + " "+ x.residentLastName.toUpperCase(),
                            enquiryId: x.iluAllocationId,
                            residentId: x.residentId,
                            isAdmitted: x.isAdmitted
                        };
                        result.push(event);
                    }
                    else if (x.balancePaidDateUtc) {
                        event = {
                            date: x.balancePaidDateUtc, 
                            code: "Balance paid", 
                            resident: x.residentFirstName + " " + x.residentLastName.toUpperCase(),
                            enquiryId: x.iluAllocationId,
                            residentId: x.residentId,
                            isAdmitted: x.isAdmitted
                        };
                        result.push(event);
                    }
                    else if (x.depositPaidDateUtc) {
                        event = {
                            date: x.depositPaidDateUtc, 
                            code: "Deposit paid", 
                            resident: x.residentFirstName + " " + x.residentLastName.toUpperCase(),
                            enquiryId: x.iluAllocationId,
                            residentId: x.residentId,
                            isAdmitted: x.isAdmitted
                        };
                        result.push(event);
                    }
                    else if (x.contractSignedDateUtc) {
                        event = {
                            date: x.contractSignedDateUtc, 
                            code: "Contract signed", 
                            resident: x.residentFirstName + " " + x.residentLastName.toUpperCase(),
                            enquiryId: x.iluAllocationId,
                            residentId: x.residentId,
                            isAdmitted: x.isAdmitted
                        };
                        result.push(event);
                    };  
                });
            }
            return result;
        }

        vm.saveUnitDialogCallback = function() {
            roomsService.getRooms()
            .then(function (result) {
                    vm.rooms = result;
                },
                function() {
                    notificationService.error("Unexpected error occurred while loading rooms.");
                });
        }

        vm.openViewOrEditUnitDialog = function (unitId) {
            if (auth.userPermissions.facilityRooms.canModify) {                
                dialogService.openDialog("app/organisation/partials/editUnitDialog.html",
                    "viewAndEditUnitDialog.controller",
                    {
                        unitId,
                        units: vm.rooms,
                        unitTypes: vm.roomTypes,
                        callBack: vm.saveUnitDialogCallback
                    });
            }
            else {
                dialogService.openDialog("app/organisation/partials/viewUnitDialog.html",
                    "viewAndEditUnitDialog.controller",
                    {
                        unitId,
                        units: vm.rooms,
                        unitTypes: vm.roomTypes
                    });
            }
        };

        vm.navigateToResident = function(event, enquiryId, residentId, isAdmitted) {
            event.stopPropagation();
            if (isAdmitted) {
                $state.go("viewIndependentLivingAllocation", {'enquiryId': enquiryId, 'residentId': residentId});
            }
            else {
                $state.go("viewEnquiry", {'enquiryId': enquiryId, 'residentId': residentId});
            }
        }
    }
];
