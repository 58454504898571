module.exports = [
    "data.service", function (dataService) {
        
        function getRoles() {
            return dataService.get("roles");
        }

        function getRole(roleId) {
            return dataService.get("roles/" + roleId);
        }

        function getPermissions() {
            return dataService.get("roles/permissions");
        }

        function saveRole(role) {
            return dataService.post("roles", role);
        }

        return {
            getRoles: getRoles,
            getRole: getRole,
            getPermissions: getPermissions,
            saveRole: saveRole
        }
    }
];