"use strict";
module.exports = {
    configure: function(module) {

        module.config([
            "$stateProvider",
            function($stateProvider) {
                
                $stateProvider.state("viewSundryCharges", {
                    url: "/viewSundryCharges",
                    templateUrl: "app/billing/partials/viewSundryCharges.html",
                    controller: "viewSundryChargesController as vm",
                    resolve: {
                        authorized: ["authentication.service", function (auth) {
                            return auth.userHasPermissionDeferred(function (x) { return x.billingSundryCharges.canView; });
                        }]
                    }
                });

                $stateProvider.state("viewSundryCharge", {
                    url: "/billing/viewSundryCharge/{sundryChargeId:int}",
                    templateUrl: "app/billing/partials/viewSundryCharge.html",
                    controller: "viewSundryChargeController as vm",
                    resolve: {
                        authorized: ["authentication.service", function (auth) {
                            return auth.userHasPermissionDeferred(function (x) { return x.billingSundryCharges.canView; });
                        }]
                    }
                });

                $stateProvider.state("addOrEditSundryCharge", {
                    url: "/billing/addOrEditSundryCharge/{sundryChargeId:int}",
                    templateUrl: "app/billing/partials/addOrEditSundryCharge.html",
                    controller: "addOrEditSundryChargeController as vm",
                    resolve: {
                        authorized: ["authentication.service", function (auth) {
                            return auth.userHasPermissionDeferred(function (x) { return x.billingSundryCharges.canModify; });
                        }]
                    },
                    params: {
                        sundryCharge: null
                    }
                });

                $stateProvider.state("reviewSundryCharge", {
                    url: "/billing/reviewSundryCharge/{sundryChargeId:int}",
                    templateUrl: "app/billing/partials/viewSundryCharge.html",
                    controller: "viewSundryChargeController as vm",
                    resolve: {
                        authorized: ["authentication.service", function (auth) {
                            return auth.userHasPermissionDeferred(function (x) { return x.billingSundryCharges.canModify; });
                        }]
                    },
                    params: {
                        sundryCharge: null
                    }
                });
            }
        ]);
    }
};