module.exports = [
    "data.service", function(dataService) {
        var featureFlags = {
            systemConfigurationFlags : {},
            currentFacilityFeatureFlags : {},
        };
        function getFeatureFlags() {
            return dataService.get("featureFlags");
        }
        return {
            getFeatureFlags: getFeatureFlags,
            featureFlags : featureFlags
        }
    }
];  




