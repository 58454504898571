import * as _ from "underscore";
import * as moment from 'moment';
import {INotificationService} from '../../common/services/INotificationService';
import { IFundingService, IFeesAndCharges, IRateForPeriod} from '../Interfaces';

class FeesAndChargesController {
    static $inject = ["funding.service", "notification.service", "$window"];

    constructor(
        private readonly fundingService: IFundingService,
        private readonly notificationService: INotificationService,
        private readonly $window: ng.IWindowService
    ) {
        this.getFeesAndCharges();
        this.mode = "view";
    }

    private selectedFeeAndChargePlaceHolder: IFeesAndCharges;
    public feesAndCharges: IFeesAndCharges[];
    public selectedFeeAndCharge: IFeesAndCharges;
    public mode: string;
    public saveFeesAndChargesForm: ng.IFormController;
    public hideEndDate: boolean;

    private getFeesAndCharges = () => {
        this.fundingService.getAllFeesAndCharges()
            .then(result => {
                    if (result && result.length > 0) {
                        this.feesAndCharges = result;
                        this.selectedFeeAndCharge = _.first(this.feesAndCharges);
                    }
                },
                () => {
                    this.notificationService.error("Error occurred while fetching fees and charges");
                });
    }

    public selectFeeAndCharge = (feeAndCharge: IFeesAndCharges) => {
        this.selectedFeeAndCharge = feeAndCharge;
        this.mode = "view";
    }

    public editFeesAndCharges = () => {
        this.cloneSelectedFeeAndChargeToPlaceHolder();
        this.mode = "edit";
    }

    public enterNewFeeAndCharge = () => {
        this.cloneSelectedFeeAndChargeToPlaceHolder();
        this.selectedFeeAndCharge = <IFeesAndCharges>{
            id: 0
        };
        this.addMpirRate();
        this.addMaxPenaltyInterestRate();
        this.mode = "add";
    }

    public isValidDateRange = (rate: IRateForPeriod): boolean => {
        if (!rate.startDateUtc || !rate.endDateUtc)
            return true;

        return moment.utc(rate.startDateUtc).format() <= moment.utc(rate.endDateUtc).format();
    }

    public endDateChanged = (rates: IRateForPeriod[], index: number) => {
        if (index === rates.length - 1)
            return;

        let currentRate = rates[index];
        let nextRate = rates[index + 1];

        nextRate.startDateUtc = moment.utc(currentRate.endDateUtc).add(1, "day").toDate();
    }

    public isInvalidEndDate = (rates: IRateForPeriod[], index: number): boolean => {
        if (index >= rates.length - 1)
            return false;

        let currentRate = rates[index];
        let nextRate = rates[index + 1];

        return !nextRate.endDateUtc
            ? false
            : moment.utc(currentRate.endDateUtc).format() >=
            moment.utc(nextRate.endDateUtc).format();
    }

    private cloneSelectedFeeAndChargeToPlaceHolder = () => {

        if (this.selectedFeeAndCharge === null || this.selectedFeeAndCharge === undefined)
            return;

        this.selectedFeeAndChargePlaceHolder = _.extend({}, this.selectedFeeAndCharge);
        this.selectedFeeAndChargePlaceHolder.mpirRates = [];
        this.selectedFeeAndChargePlaceHolder.maximumPenaltyInterestRates = [];

        _.forEach(this.selectedFeeAndCharge.mpirRates,
            (value) => {
                this.selectedFeeAndChargePlaceHolder.mpirRates.push(_.extend({}, value));
            });

        _.forEach(this.selectedFeeAndCharge.maximumPenaltyInterestRates,
            (value) => {
                this.selectedFeeAndChargePlaceHolder.maximumPenaltyInterestRates.push(_.extend({}, value));
            });

    }

    public saveFeesAndCharges = (isFormValid: boolean) => {
        const computedEndDate = this.computeEndDate(this.selectedFeeAndCharge.startDateUtc,
            this.selectedFeeAndCharge.id);
        const ratesValidated = this.validateRates(this.selectedFeeAndCharge.mpirRates) &&
            this.validateRates(this.selectedFeeAndCharge.maximumPenaltyInterestRates);
        if (isFormValid && computedEndDate !== undefined && ratesValidated) {
            this.selectedFeeAndCharge.endDateUtc = computedEndDate;
            this.fundingService.saveFeesAndCharges(this.selectedFeeAndCharge).then(
                response => {
                    this.fundingService.getAllFeesAndCharges()
                        .then(result => {
                                if (result && result.length > 0) {
                                    this.feesAndCharges = result;
                                    if (this.mode === "add") {
                                        this.selectedFeeAndCharge = _.first(this.feesAndCharges);
                                    } else if (this.mode === "edit") {
                                        const index = _.findIndex(this.feesAndCharges,
                                            fundingRate => fundingRate.id === this.selectedFeeAndCharge.id);
                                        this.selectedFeeAndCharge = this.feesAndCharges[index];
                                    }
                                }
                                this.selectedFeeAndChargePlaceHolder = _.extend({}, this.selectedFeeAndCharge);
                                this.saveFeesAndChargesForm.$setPristine();
                                this.mode = "view";
                            },
                            () => {
                                this.notificationService.error(
                                    "Error occurred while fetching fees and charges");
                            });
                },
                e => {
                    this.notificationService.error("Error occurred while saving the fee and charge. please try again");
                });
        }
    }

    public cancel = () => {
        const unsavedChanges = this.saveFeesAndChargesForm.$dirty;
        if (this.allowClosingForm(unsavedChanges)) {
            this.saveFeesAndChargesForm.$setPristine();

            if (this.selectedFeeAndChargePlaceHolder) {
                this.selectedFeeAndCharge = _.extend({}, this.selectedFeeAndChargePlaceHolder);
                this.selectedFeeAndCharge.mpirRates = [];
                this.selectedFeeAndCharge.maximumPenaltyInterestRates = [];

                _.forEach(this.selectedFeeAndChargePlaceHolder.mpirRates,
                    (value) => {
                        this.selectedFeeAndCharge.mpirRates.push(_.extend({}, value));
                    });

                _.forEach(this.selectedFeeAndChargePlaceHolder.maximumPenaltyInterestRates,
                    (value) => {
                        this.selectedFeeAndCharge.maximumPenaltyInterestRates.push(_.extend({}, value));
                    });

                if (this.mode === 'edit') {
                    let leftMenuSelFeeAndCharge = _.find(this.feesAndCharges,
                        (feeAndCharge) => {
                            return feeAndCharge.id === this.selectedFeeAndCharge.id;
                        });

                    leftMenuSelFeeAndCharge.startDateUtc = this.selectedFeeAndCharge.startDateUtc;
                }
            }

            this.mode = "view";
        }
    }

    public getEndDate = (startDateUtc: Date, id: number): Date => {
        const endDate = this.computeEndDate(startDateUtc, id);
        if (!endDate) {
            this.hideEndDate = true;
        } else {
            this.hideEndDate = false;
        }
        return endDate;
    }

    public addMpirRate = () => {
        if (!this.selectedFeeAndCharge.mpirRates || this.selectedFeeAndCharge.mpirRates.length === 0) {
            this.selectedFeeAndCharge.mpirRates = [];
            this.selectedFeeAndCharge.mpirRates.push(<IRateForPeriod>{
                id: 0
            });
            return;
        }

        let mpirControls = _.filter(<any>this.saveFeesAndChargesForm,
            (item) => {
                if (item && typeof item == "object" && (<any>item).$name) {
                    let name = (<string>(<any>item).$name);
                    if (name.substr(0, 8) === 'mpirFrom' ||
                        name.substr(0, 6) === 'mpirTo' ||
                        name.substr(0, 8) === 'mpirRate') {
                        return true;
                    }
                }
                return false;
            });

        _.forEach(mpirControls,
            (value) => {
                (<any>value).$setTouched();
            });

        if (!this.validateRates(this.selectedFeeAndCharge.mpirRates))
            return;

        let sortedMpir = _.sortBy(this.selectedFeeAndCharge.mpirRates, "startDateUtc");
        let lastItem = _.last(sortedMpir);

        this.selectedFeeAndCharge.mpirRates.push(<IRateForPeriod>{
            id: 0,
            startDateUtc: moment.utc(lastItem.endDateUtc).add(1, "day").toDate()
        });
    }

    private validateRates = (rates: IRateForPeriod[]) => {
        return _.every(rates,
            (value, key) => {
                if (!this.isValidDateRange(value))
                    return false;

                if (this.isInvalidEndDate(rates, key))
                    return false;

                if (!value.startDateUtc || !value.endDateUtc || !value.rate)
                    return false;

                return true;
            });
    }

    public removeMpir = (index: number) => {
        if (this.selectedFeeAndCharge.mpirRates.length === 1 || index === 0)
            return;

        this.selectedFeeAndCharge.mpirRates.splice(index, 1);
        let sortedMpirRates = _.sortBy(this.selectedFeeAndCharge.mpirRates, "startDateUtc");
        let previous = sortedMpirRates[0];

        for (let i = 1; i < sortedMpirRates.length; i++) {
            let current = sortedMpirRates[i];
            current.startDateUtc = moment.utc(previous.endDateUtc).add(1, "day").toDate();
            previous = current;
        }
    }

    public addMaxPenaltyInterestRate = () => {
        if (!this.selectedFeeAndCharge.maximumPenaltyInterestRates ||
            this.selectedFeeAndCharge.maximumPenaltyInterestRates.length === 0) {
            this.selectedFeeAndCharge.maximumPenaltyInterestRates = [];
            this.selectedFeeAndCharge.maximumPenaltyInterestRates.push(<IRateForPeriod>{
                id: 0
            });
            return;
        }

        let maxPanIntRateControls = _.filter(<any>this.saveFeesAndChargesForm,
            (item) => {
                if (item && typeof item == "object" && (<any>item).$name) {
                    let name = (<string>(<any>item).$name);
                    if (name.substr(0, 30) === 'maximumPenaltyInterestRatefrom' ||
                        name.substr(0, 28) === 'maximumPenaltyInterestRateTo' ||
                        name.substr(0, 30) === 'maximumPenaltyInterestRateRate') {
                        return true;
                    }
                }
                return false;
            });

        _.forEach(maxPanIntRateControls,
            (value) => {
                (<any>value).$setTouched();
            });

        if (!this.validateRates(this.selectedFeeAndCharge.maximumPenaltyInterestRates))
            return;

        let sortedMaxPenaltyInterestRates = _.sortBy(this.selectedFeeAndCharge.maximumPenaltyInterestRates,
            "startDateUtc");
        let lastItem = _.last(sortedMaxPenaltyInterestRates);

        this.selectedFeeAndCharge.maximumPenaltyInterestRates.push(<IRateForPeriod>{
            id: 0,
            startDateUtc: moment.utc(lastItem.endDateUtc).add(1, "day").toDate()
        });
    }

    public removeMaximumPenaltyInterestRate = (index: number) => {
        if (this.selectedFeeAndCharge.maximumPenaltyInterestRates.length === 1 || index === 0)
            return;

        this.selectedFeeAndCharge.maximumPenaltyInterestRates.splice(index, 1);
        let sortedMaxPenaltyInterestRates = _.sortBy(this.selectedFeeAndCharge.maximumPenaltyInterestRates,
            "startDateUtc");
        let previous = sortedMaxPenaltyInterestRates[0];

        for (let i = 1; i < sortedMaxPenaltyInterestRates.length; i++) {
            let current = sortedMaxPenaltyInterestRates[i];
            current.startDateUtc = moment.utc(previous.endDateUtc).add(1, "day").toDate();
            previous = current;
        }
    }

    private allowClosingForm = (unsavedChanges: boolean) => {
        if (unsavedChanges) {
            return this.$window.confirm("Do you want to leave this page? Changes you made may not be saved.");
        } else {
            return true;
        }
    }

    private computeEndDate = (startDateUtc: Date, id: number): Date => {
        if (!this.feesAndCharges || this.feesAndCharges.length === 0 || !startDateUtc) {
            return null;
        }

        let sortedAccFundingRates = _.reject(this.feesAndCharges,
            fundingRate => fundingRate.id === id);

        if (sortedAccFundingRates.length === 0) {
            return null;
        }

        let overlappingRateIndex = _.findIndex(sortedAccFundingRates,
            fundingRate => moment(fundingRate.startDateUtc).isSame(moment(startDateUtc)));

        if (overlappingRateIndex !== -1) {
            return undefined;
        }

        let immediateSuccessorIndex = _.findLastIndex(sortedAccFundingRates,
            fundingRate => moment(fundingRate.startDateUtc).isAfter(moment(startDateUtc)));

        if (immediateSuccessorIndex === -1) {
            return null;
        }

        let endDateUtc = sortedAccFundingRates[immediateSuccessorIndex].startDateUtc;
        return moment(endDateUtc).subtract(1, "second").toDate();
    }
}

export = FeesAndChargesController;
