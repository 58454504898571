class ToggleButtonController {
    static $inject = ["$scope"];

    constructor(
        private $scope: any
    ) {
    }

    public toggleClick(name: string, value: string) {
        var isNewValue = this.$scope.ngModel !== value;
        this.$scope.ngModel = value;
        this.$scope.form[name].$setDirty();
        if (isNewValue && this.$scope.onClick != undefined) {
            this.$scope.onClick(value);
        }
    }
}

export = ToggleButtonController;