"use strict";

module.exports = ["appSettings", function (appSettings) {
    return {
        link: function (scope, element, attrs) {
            if (appSettings.AutomationEnabled) {
                element.attr('data-automation', attrs.rmAutomation);
            }
            element.removeAttr('rm-automation');
        }
    };
}
];
