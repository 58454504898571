"use strict";

module.exports = function () {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            residencyId: "=",
            isResident: "=",
            tourInfo: "=",
            isCareTypeIlu: "="
        },
        controller: "bookTour.controller",
        controllerAs: "vm",
        templateUrl: "app/residents/directives/partials/bookTour.html"
    };
}
