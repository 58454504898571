import {FeesAndChargesDto } from "../Models";
import * as moment from 'moment';

class settlementCostsEditorComponent implements ng.IComponentOptions {
    
    public bindings: any = {
        feesAndCharges: "=",
        saveFinanceForm: "=",
    };

    public templateUrl: string = 'app/independentLiving/partials/settlementCostsEditor.html';

    public controller: any = class settlementCostsEditorComponent {
        static $inject = ["$scope", "$rootScope"];
        public feesAndCharges: FeesAndChargesDto;
        public accruedDMF: number;
        public additionalCosts: number;
        public capitalGain: number;
        public estimatedRefund: number;
        public ingoingContribution: number;
        public minSettlementDateUtc: Date;
        public saveFinanceForm: ng.IFormController;

        constructor(private readonly $scope: ng.IScope, 
            private readonly $rootScope: ng.IRootScopeService) {
            
            this.minSettlementDateUtc = this.feesAndCharges.vacatingDateUtc ? this.feesAndCharges.vacatingDateUtc : this.feesAndCharges.admissionDateUtc;
            this.calculateAccruedDMF();
            this.calculateCapitalGain();

            this.additionalCosts = this.feesAndCharges.financialSummaryAndUnitCostsDto.additionalCosts ? 
                                    this.feesAndCharges.financialSummaryAndUnitCostsDto.additionalCosts : 0;

            if (this.$rootScope.isBillingIntegratedFacility)
                this.ingoingContribution = this.feesAndCharges.financialSummaryAndUnitCostsDto.calculatedIngoingContributionFromTechone ?
                                    this.feesAndCharges.financialSummaryAndUnitCostsDto.calculatedIngoingContributionFromTechone : 0;
            else
                this.ingoingContribution = this.feesAndCharges.financialSummaryAndUnitCostsDto.ingoingContribution ?
                                    this.feesAndCharges.financialSummaryAndUnitCostsDto.ingoingContribution : 0;

            this.calculateEstimatedRefund();
        }     
        
        public calculateAccruedDMF = () => {
            if ( !this.feesAndCharges.financialSummaryAndUnitCostsDto.dmfCalculationDateUtc 
                 || !this.feesAndCharges.financialSummaryAndUnitCostsDto.dmfUnitPrice) {
                     this.accruedDMF = 0;
                     return;
            }

            let dmfCalculationDate = moment(this.feesAndCharges.financialSummaryAndUnitCostsDto.dmfCalculationDateUtc);

            let calculatedAccruedDMF: number = 0;      
            let minimumPayment: number = 0;  
            
            if (this.feesAndCharges.deferredManagementFeeDto.deferredManagementFeesSchedule.length > 0
                && this.feesAndCharges.deferredManagementFeeDto.deferredManagementFeesSchedule[0].total)   {

                let dmfFeeSchedule = this.feesAndCharges.deferredManagementFeeDto.deferredManagementFeesSchedule;

                let startDateOfCurrentPeriod = moment(this.feesAndCharges.admissionDateUtc);
                let startDateOfNextPeriod = startDateOfCurrentPeriod.clone();
                for (let i=0; i<dmfFeeSchedule.length; i++) {

                    startDateOfNextPeriod.add(dmfFeeSchedule[i].effectiveMonth, 'months'); //Get start date of next period

                    let daysInCurrentPeriod: number = 0;
                    if (dmfCalculationDate < startDateOfNextPeriod) {   //the last period to calculate
                        daysInCurrentPeriod = dmfCalculationDate.diff(startDateOfCurrentPeriod, 'days');
                        calculatedAccruedDMF +=  this.feesAndCharges.financialSummaryAndUnitCostsDto.dmfUnitPrice  
                                                * daysInCurrentPeriod * dmfFeeSchedule[i].value / 36500.0;
                        break;
                    }
                    else {
                        daysInCurrentPeriod = startDateOfNextPeriod.diff(startDateOfCurrentPeriod, 'days');
                        calculatedAccruedDMF +=  this.feesAndCharges.financialSummaryAndUnitCostsDto.dmfUnitPrice 
                                                * daysInCurrentPeriod * dmfFeeSchedule[i].value / 36500.0;
                    }
                    
                    startDateOfCurrentPeriod = startDateOfNextPeriod.clone();
                } 
            }

            minimumPayment = this.feesAndCharges.deferredManagementFeeDto.minimumRate * this.feesAndCharges.financialSummaryAndUnitCostsDto.dmfUnitPrice / 100.0;
            this.accruedDMF = Math.max(calculatedAccruedDMF, minimumPayment);
        }

        public calculateCapitalGain = () => {
            if (!this.feesAndCharges.financialSummaryAndUnitCostsDto.agreedPrice
                || !this.feesAndCharges.financialSummaryAndUnitCostsDto.settlementPrice) {
                    this.capitalGain = 0;
                    return;
            }

            this.capitalGain = this.feesAndCharges.financialSummaryAndUnitCostsDto.settlementPrice - this.feesAndCharges.financialSummaryAndUnitCostsDto.agreedPrice;
            if (this.capitalGain > 0) {
                this.capitalGain *= this.feesAndCharges.financialSummaryAndUnitCostsDto.residentCapitalGainRate / 100.0;
            }
            else {
                this.capitalGain *= this.feesAndCharges.financialSummaryAndUnitCostsDto.residentCapitalLossRate / 100.0;
            }
        }

        public calculateEstimatedRefund = () => {
            this.estimatedRefund = this.ingoingContribution
                                - this.accruedDMF
                                - this.additionalCosts
                                + this.capitalGain;
        }

        public onBindingValueChange = () => {     
            //To make sure the change event won't get lost and be handled correctly, we must use applyAsync here
            this.$scope.$applyAsync(() => {
                this.calculateAccruedDMF();
                this.calculateCapitalGain();
    
                this.additionalCosts = this.feesAndCharges.financialSummaryAndUnitCostsDto.additionalCosts ? 
                                        this.feesAndCharges.financialSummaryAndUnitCostsDto.additionalCosts : 0 
    
                this.calculateEstimatedRefund();
            });       
        }
    }
}

export = settlementCostsEditorComponent;