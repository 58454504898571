"use strict";
module.exports = [
    "$q",
    function ($q) {

        function readAsText(uploadedFile) {
            var deferred = $q.defer();
            var reader = new FileReader();

            reader.onload = function (event) {
                deferred.resolve(event.target.result);
            }
            reader.onerror = function () {
                deferred.reject();
            }

            reader.onabort = function () {
                deferred.reject();
            }

            reader.readAsText(uploadedFile, "UTF-8");
            return deferred.promise;
        }

        return {
            readAsText: readAsText
        };
    }
];
