import * as angular from 'angular';
import * as _ from 'underscore';
import { FacilityDto } from "../../authentication/Models";
import { IFacilityService } from "../../authentication/services/IFacilityService";
import { INotificationService } from "../../common/services/INotificationService";
import { IReportingService } from "../services/IReportingService";
import { IDownloadFileService } from '../../common/services/IDownloadFileService';
import { PotentialReassessmentsReportFilter } from '../Models';
import { PotentialReclassificationReasons } from '../enums/potentialReclassificationReasons';
import { IDateFormattingService } from '../../common/services/IDateFormattingService';

class PotentialReassessmentsReportComponent implements ng.IComponentOptions {
    public bindings: any = {};

    public templateUrl: string = 'app/reporting/partials/PotentialReassessmentsReport.html';

    public controller: any = class FinancialDetailsReportController {
        static $inject = ["facility.service", "notification.service", "reporting.service", "downloadFile.service"];

        public facilities: FacilityDto[] = [];
        public selectedFacility: FacilityDto;
        public reasons = [ 
            {text: 'All', value: PotentialReclassificationReasons.All},
            {text: 'Hospitalization event(s)', value: PotentialReclassificationReasons.Hospitalisation},
            {text: 'Time', value: PotentialReclassificationReasons.Time}
        ];
        public selectedReason = this.reasons[0];

        constructor(private readonly facilityService: IFacilityService,
            private readonly notificationService: INotificationService,
            private readonly reportingService: IReportingService,
            private readonly downloadFileService: IDownloadFileService) {

            this.facilities = facilityService.activeUserFacilities.filter(x => x.facilityType == "AgeCare");
            if (this.facilities.length == 1)
                this.selectedFacility = this.facilities[0];
        }

        public extract = (): void => {
            let filter = new PotentialReassessmentsReportFilter();
            filter.facilityId = this.selectedFacility == null ? 0 : this.selectedFacility.id;
            filter.reasonId = this.selectedReason.value;

            this.reportingService.getPotentialReassessmentsReportExcelStream(filter).then((response) => {
                this.downloadFile(response, 'PotentialReassessments.xlsx');
            },
                () => {
                    this.notificationService.error("Error occurred while exporting potential reassessments report");
                });
        }

        private downloadFile = (response: ArrayBuffer, fileName: string) => {
            this.downloadFileService.downloadFile(response, fileName);
        }
    }
}

export = PotentialReassessmentsReportComponent;