"use strict";
const angular = require("angular");
const _ = require("underscore");

module.exports = [
    "organisation.rooms.service",
    "notification.service",
    "$stateParams",
    "$state",
    "$window",
    "$scope",
    "dialog.service",
    "organisationService",
    function (roomsService, notificationService, $stateParams, $state, $window, $scope, dialogService, organisationService) {
        var vm = this;
        var bedIndex = undefined;
        var selectedRoomType;
        vm.roomFeatures = [];
        vm.facilityLocations = $stateParams.locationWithRooms;
        vm.roomTypes = $stateParams.roomTypes;
        vm.isSaveChanges = false;
        //array of latest out of service start date of each out of service bed in a room:
        vm.outOfServiceStartDate = [];
        //array of latest out of service end date of each out of service bed in a room:
        vm.outOfServiceEndDate = [];
        var emptyFacilityLocation = _.findWhere(vm.facilityLocations, { id: null });

        //this is the array of bed out of service bed information to be saved to the db: 
        vm.bedsOutOfServiceData = [];

        if (emptyFacilityLocation) {
            emptyFacilityLocation.id = "";
        }

        vm.showFacilityLocations = !(emptyFacilityLocation && vm.facilityLocations.length === 1);


        (function getRoom() {
            roomsService.getRoom($stateParams.roomId)
                .then(function (result) {
                    vm.room = result;
                    _.each(vm.room.beds,
                        function (bed, ind) {
                            bed.softDeleted = false;
                            if (bed.isOutOfService) {
                                organisationService.getOutOfServiceBeds(bed.id).then((result) => {
                                    vm.outOfServiceStartDate[ind] = result[0]?.startDate; //dates are ordered newest to oldest, so get the first result
                                    vm.outOfServiceEndDate[ind] = result[0]?.endDate;
                                });
                            }
                        });
                    vm.selectedRoomType = _.findWhere(vm.roomTypes, { id: vm.room.accommodationTypeId });
                    var facilityLocationId = vm.room.facilityLocationId ? vm.room.facilityLocationId : "";
                    vm.room.facilityLocation = _.findWhere(vm.facilityLocations, { id: facilityLocationId });
                },
                    function () {
                        notificationService.error("Unexpected error occurred while loading room.");
                    });
        })();

        vm.mode = "view";
        vm.editRoom = function () {
            vm.roomEdit = angular.copy(vm.room);
            selectedRoomType = vm.selectedRoomType;
            vm.selectedRoomType = angular.copy(selectedRoomType);
            vm.mode = "edit";
            getRoomFeatures();
        };

        function getRoomFeatures() {
            return roomsService.getRoomFeatures()
                .then(function (result) {
                    vm.roomFeatures = result;
                },
                    function () {
                        notificationService.error("Unexpected error occurred while loading room features.");
                    });
        }

        vm.addFeature = function (text) {
            $scope.$apply(function () {
                var object = { name: text, id: "new_" + text };
                vm.roomFeatures.push(object);
                vm.roomEdit.features.push(object);
            });
        };

        vm.isRoomNumberDuplicate = function (roomNumber) {
            if (roomNumber) {
                var existingRoomObj = _.find(vm.roomEdit.facilityLocation.accommodations,
                    function (room) {
                        return room.accommodationId !== vm.roomEdit.id && room.roomNumber === vm.roomEdit.number;
                    });

                if (existingRoomObj) {
                    return true;
                }
            }
            return false;
        }

        vm.saveRoom = function (isFormValid) {
            if (isFormValid && !vm.isRoomNumberDuplicate(vm.roomEdit.number)) {
                var roomEditToPost = {
                    id: vm.roomEdit.id,
                    number: vm.roomEdit.number,
                    facilityLocationId: vm.roomEdit.facilityLocation.id,
                    accommodationTypeId: vm.selectedRoomType.id,
                    features: vm.roomEdit.features,
                    beds: _.map(vm.roomEdit.beds,
                        function (bed) { return { id: bed.id, isOutOfService: bed.isOutOfService } }),
                    outOfServiceDates: vm.bedsOutOfServiceData
                };

                roomsService.editRoom(roomEditToPost)
                    .then(function () {
                        $state.reload();
                    },
                        function () {
                            notificationService.error("Unexpected error occurred while editing room.");
                        });
            }
        };

        var allowClosingForm = function (unsavedChanges) {
            if (unsavedChanges) {
                return $window.confirm("Do you want to leave this page? Changes you made may not be saved.");
            } else {
                return true;
            }
        };

        vm.navigateToResident = function (residentId) {
            var unsavedChanges = vm.editRoomForm.$dirty;
            if (allowClosingForm(unsavedChanges)) {
                $state.go("viewResident", { residentId: residentId });
            }
        };

        vm.cancel = function () {
            var unsavedChanges = vm.editRoomForm.$dirty;
            if (allowClosingForm(unsavedChanges)) {
                vm.selectedRoomType = selectedRoomType;
                vm.mode = "view";
            }
        };

        vm.openBedStatusDialog = function (bedNumber, bedId) {
            vm.bedIndex = bedNumber - 1;
            dialogService.openDialog("app/organisation/partials/editBedStatusDialog.html", "editBedStatusDialog.controller", {
                roomNumber: vm.roomEdit.number,
                bedNumber: bedNumber,
                bedId: bedId,
                items: vm.bedsOutOfServiceData.filter(e => e.bedId === bedId && e.isDeleted === false)
            },
            'static');
        };

        $scope.$on('update_bed_statuses',
            function (event, outOfServiceBedStatusInfo) {
                let currentDate = new Date(new Date().setHours(0, 0, 0, 0));
                vm.roomEdit.beds[vm.bedIndex].isOutOfService = false;

                //out of service data from other beds and previously deleted entries for this bed:
                let deletedAndOtherBedsStatusData = vm.bedsOutOfServiceData.filter(f => f.bedId !== outOfServiceBedStatusInfo[0].bedId || f.isDeleted);

                //update list of out of service of all beds with most current info for this bed:
                vm.bedsOutOfServiceData = deletedAndOtherBedsStatusData.concat(outOfServiceBedStatusInfo);
                let nonDeletedBedsOutOfServiceData = outOfServiceBedStatusInfo.filter(e => e.isDeleted === false);

                for (var item of nonDeletedBedsOutOfServiceData) {
                    var startDate = new Date(new Date(item.startDate).setHours(0, 0, 0, 0));
                    var endDate = new Date(new Date(item.endDate).setHours(0, 0, 0, 0));
                    if (item.endDate == null) { //need this otherwise endDate would be begging of time instead of null
                        endDate = null;
                    }

                    if (startDate <= currentDate && (endDate == null || endDate >= currentDate)) {
                        vm.roomEdit.beds[vm.bedIndex].isOutOfService = true;
                    }
                }

                vm.outOfServiceStartDate[vm.bedIndex] = nonDeletedBedsOutOfServiceData[0]?.startDate; //dates are ordered newest to oldest, so get the first result
                vm.outOfServiceEndDate[vm.bedIndex] = nonDeletedBedsOutOfServiceData[0]?.endDate;
            })

        vm.areResidentsAssigned = function () {
            var isAssigned = false;
            _.each(vm.room.beds,
                function (bed) {
                    if (bed.occupant !== null) {
                        isAssigned = true;
                    }
                });
            return isAssigned;
        }

        vm.delete = function (id) {
            if (!vm.areResidentsAssigned()) {
                roomsService.deleteRoom(id)
                    .then(function () {
                        $state.go("viewAndEditRooms");
                    },
                        function () {
                            notificationService.error("Error occurred while deleting room");
                        });
            } else {
                notificationService.error("Rooms with residents assigned to them can't be deleted.");
            }
        }

        vm.recalculateBeds = function () {
            var notDeletedBedsCount = _.where(vm.roomEdit.beds, { softDeleted: false }).length;
            if (notDeletedBedsCount > vm.selectedRoomType.numberBeds) {
                //soft delete the real beds
                for (var i = notDeletedBedsCount; i > vm.selectedRoomType.numberBeds; i--) {
                    if (vm.roomEdit.beds[i - 1].id !== 0) {
                        vm.roomEdit.beds[i - 1].softDeleted = true;
                    } else {
                        vm.roomEdit.beds.pop();
                    }
                }
            }
            if (notDeletedBedsCount < vm.selectedRoomType.numberBeds) {
                //either reenable soft deleted bed or add a new bed
                for (var j = notDeletedBedsCount; j < vm.selectedRoomType.numberBeds; j++) {
                    if (vm.roomEdit.beds[j]) {
                        vm.roomEdit.beds[j].softDeleted = false;
                    } else {
                        vm.roomEdit.beds.push({
                            id: 0,
                            occupant: null,
                            isOutOfService: false,
                            softDeleted: false
                        });
                    }
                }
            }
        }
    }
];
