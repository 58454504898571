"use strict";

module.exports = function () {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            address: "="
        },
        templateUrl: "app/common/directives/partials/inlineAddress.html"
    };
}