"use strict";

module.exports = [
    "$scope",
    "residents.service",
    "notification.service",
    function ($scope, residentsService, notification) {
    $scope.image = null;


        function loadProfileImage() {
            if ($scope.residentId) {
                residentsService.getPhoto($scope.residentId).then(function(result) {
                        $scope.image = result.photo;
                        $scope.photoTakenDate = result.photoTakenDateUtc;
                    },
                    function() {
                        $scope.image = "../../../images/unknown-user.jpg";
                        notification.error("Unable to display photo. Either your computer is offline, or Resident Manager is having problems of its own.");
                    });
            } else {
                if ($scope.financialTabSelected) {
                    $scope.image = "../../../images/financials-placeholder.png";
                } else {
                    $scope.image = "../../../images/unknown-user.jpg";
                }
                    
            }
        }

        $scope.$on("residentProfileImageChanged", function (event, residentId) {
        if (residentId === $scope.residentId) {
            loadProfileImage();
        }
    });

    $scope.$watch('residentId', function () {
        loadProfileImage();
    });
}]