"use strict";
module.exports = {
    
    configure : function(module) {

        module.config(["$stateProvider",
            function ($stateProvider) {
            $stateProvider.state("facilityList", {
                url: "/facilities",
                templateUrl: "app/organisation/partials/viewFacilities.html",
                controller: "viewFacilities.controller as vm",
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.facilityDetails.canAction; });
                    }]
                }
            });

            $stateProvider
               .state("viewAndEditUsers", {
                   url: "/viewAndEditUsers",
                   templateUrl: "app/organisation/partials/viewAndEditUsers.html",
                   controller: "viewAndEditUsers.controller as vm",
                   resolve: {
                       authorized: ["authentication.service", function (auth) {
                           return auth.userHasPermissionDeferred(function (x) { return x.manageUsers.canView; });
                       }]
                   }
               });

           $stateProvider
               .state("viewAndEditAccommodationTypes", {
                   url: "/viewAndEditAccommodationTypes",
                   templateUrl: "app/organisation/partials/viewAndEditAccommodationTypes.html",
                   controller: "viewAndEditAccommodationTypes.controller as vm",
                   resolve: {
                       authorized: ["authentication.service", function (auth) {
                           return auth.userHasPermissionDeferred(function (x) { return x.facilityRoomTypes.canView; });
                       }]
                   }
               });

           $stateProvider
               .state("viewAndEditRooms", {
                   url: "/viewAndEditRooms",
                   templateUrl: "app/organisation/partials/viewAndEditRooms.html",
                   controller: "viewAndEditAccommodations.controller as vm",
                   resolve: {
                       authorized: ["authentication.service", function (auth) {
                           return auth.userHasPermissionDeferred(function (x) { return x.facilityRooms.canView; });
                       }]
                   }
               });

            $stateProvider
               .state("viewAndEditUnits", {
                   url: "/viewAndEditUnits",
                   templateUrl: "app/organisation/partials/viewAndEditUnits.html",
                   controller: "viewAndEditAccommodations.controller as vm",
                   resolve: {
                       authorized: ["authentication.service", function (auth) {
                           return auth.userHasPermissionDeferred(function (x) { return x.facilityRooms.canView; });
                       }]
                   }
               });

           $stateProvider.state("viewRoom", {
               url: "/viewRoom/{roomId:int}",
               templateUrl: "app/organisation/partials/viewAndEditRoomDetails.html",
               controller: "viewAndEditRoomDetails.controller as vm",
               params: {
                   locationWithRooms: null,
                   roomTypes: null
               },
               resolve: {
                   authorized: ["authentication.service", function (auth) {
                       return auth.userHasPermissionDeferred(function (x) { return x.facilityRooms.canView; });
                   }]
               }
            });
            
            $stateProvider.state("viewAndEditGLAccountMapping", {
                url: "/viewAndEditAccountMapping",
                templateUrl: "app/organisation/partials/viewAndEditGLAccountMapping.html",
                controller: "viewAndEditGLAccountMapping.controller as vm",
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) {                            
                            return x.facilityAccountMapping.canView;
                        });
                    }]
                }
            });

            $stateProvider.state("viewAndEditOptionalServices", {
                url: "/viewAndEditOptionalServices",
                templateUrl: "app/organisation/partials/viewAndEditOptionalServices.html",
                controller: "viewAndEditOptionalServices.controller as vm",
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.facilityOptionalServices.canView; });
                    }]
                }
            });
        }]);
    }
};