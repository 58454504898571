"use strict";
const angular = require("angular");

module.exports = [
    "modalConfig",
    "$rootScope",

    function (modalConfig, $rootScope) {
        var vm = this;
        angular.extend(vm, modalConfig);
        
        vm.doneButtonPressed = (outOfServiceBedStatusInfo) => {
            $rootScope.$broadcast('update_bed_statuses', outOfServiceBedStatusInfo);
        }
    },
];