export class ClinicalIntegrationDto {
    facilityId: number;
    facilityName: string;
    isStarted: boolean;
    isCompleted: boolean;
    startedTime: Date;
    completedTime: Date;
    residents: ResidentIntegrationDto[]
}

export class ResidentIntegrationDto {
    firstName: string;
    lastName: string;
    careReceipientId: string;
    clinicalId: string;
    sentToIntegrationPlatform: boolean;
    createdInClinical: boolean;
}

export enum OperationType {
    OnboardResidents = "OnboardResidents",
    UpdateResidents = "UpdateResidents"
}