import * as angular from 'angular';
import * as _ from 'underscore';
import { IIndependentLivingService } from "../services/IIndependentLivingService";
import { INotificationService } from '../../common/services/INotificationService';
import { IReferenceDataService } from "../../common/services/IReferenceDataService";
import {ResidentDto, CreateEnquiryDto} from '../Models';
import { EnquirySourceDto } from "../../common/Models";
import type { IStateService, IStateParamsService } from "angular-ui-router";

class MarkDepartedResidentAsIluEnquiryController {
    static $inject = ["$state", "$stateParams", "independentLiving.service", "notification.service", "facility.service", "referenceData.service"];
    
    prospect1: ResidentDto;
    anotherResident: ResidentDto;
    facilityName: string;
    isUnitSettled: boolean;
    responsibleResidencyId: number;
    markAsEnquiryForm: ng.IFormController;

    enquiry: CreateEnquiryDto;
    enquirySources :Array<EnquirySourceDto> = new Array<EnquirySourceDto>();  
    includingAnotherResident: boolean;

    constructor(
        private readonly $state: IStateService,
        private readonly $stateParams: IStateParamsService,
        private readonly independentLivingService: IIndependentLivingService,
        private readonly notificationService: INotificationService,
        private readonly facilityService: any,
        private readonly referenceDataService: IReferenceDataService
    ) {
        this.enquiry = new CreateEnquiryDto();        
        this.enquiry.enquirySources = new Array<EnquirySourceDto>(); 
        this.getEnquirySources();

        this.facilityName = this.facilityService.selectedFacility.name;
        this.loadResident();
    }

    private loadResident = () => {
        this.independentLivingService.getEnquiry(this.$stateParams.enquiryId, true)
            .then(result => {      
                this.isUnitSettled = result.isSettled;   
                this.responsibleResidencyId = result.responsibleResidencyId;         
                this.prospect1 = _.find(result.residents, (x) => { return x.id == this.$stateParams.residentId; });
                this.enquiry.primaryContact = "prospect1";
                this.enquiry.prospect1ResidentId = this.prospect1.id;
                this.enquiry.prospect1FirstName = this.prospect1.firstName;
                this.enquiry.prospect1LastName = this.prospect1.lastName;
                this.enquiry.contactEmail = this.prospect1.email;
                this.enquiry.contactPhoneNumber = this.prospect1.phoneNumber;  
                this.enquiry.enquirySources = angular.copy(this.prospect1.enquirySources);    

                if (result.residents.length == 2) {
                    this.anotherResident = _.find(result.residents, (x) => { return x.id != this.$stateParams.residentId; });
                }
            });
    }

    public cancel = () => {
        this.$state.go("viewIndependentLivingAllocation", {
            enquiryId: this.$stateParams.enquiryId,
            residentId: this.$stateParams.residentId,
            viewDepartedMode: true
        })
    }

    public addOrRemoveProspect2FromFoundRelation = () => {
        if (!this.includingAnotherResident)  {    
            this.includingAnotherResident = true;      
            this.enquiry.prospect2ResidentId = this.anotherResident.id;
            this.enquiry.prospect2FirstName = this.anotherResident.firstName;
            this.enquiry.prospect2LastName = this.anotherResident.lastName; 
        }
        else {
            this.includingAnotherResident = false;
            this.enquiry.prospect2ResidentId = null;
            this.enquiry.prospect2FirstName = null;
            this.enquiry.prospect2LastName = null;             
        }
    }

    public saveEnquiry = (isValid) => {
        if (isValid) {
            this.independentLivingService.saveEnquiry(this.enquiry).then(response => {
                this.notificationService.success("Enquiry was created successfully."); 
                 this.$state.go("editEnquiry", { 'enquiryId': response.enquiryId, 'selectedResidentId': response.selectedResidentId, 
                'selectedUnit' : null, 'selectedResidents' :  null, 'doesFeeAndChargeExist' : false, 'financialTabSelected' : false,
                'enableAddingNewResident': false});
            }, () => {
                this.notificationService.error("Unexpected error occurred while saving enquiry");
            });
        }
    }

    public setContactFields = () => {        
        if (this.enquiry.primaryContact == 'prospect1') {
            this.enquiry.contactEmail = this.prospect1.email;
            this.enquiry.contactPhoneNumber = this.prospect1.phoneNumber;  
            this.enquiry.enquirySources = angular.copy(this.prospect1.enquirySources);
        }
        else if (this.includingAnotherResident && this.enquiry.primaryContact == 'prospect2' ) {
            this.enquiry.contactEmail = this.anotherResident.email;
            this.enquiry.contactPhoneNumber = this.anotherResident.phoneNumber; 
            this.enquiry.enquirySources = angular.copy(this.anotherResident.enquirySources);  
        }

        if (this.enquiry.primaryContact != 'other') {
            delete this.enquiry.contactFirstName;
            delete this.enquiry.contactLastName;
        }
    }

    public inputChangeForProspect2 = () => {
        if(this.enquiry.primaryContact == 'prospect2') // primary contact selected as prospect 2
        {
            // if clear first name and last name for prospect2, need to clear selected radio for not saving wrong primary contact person
            if(!this.enquiry.prospect2FirstName && !this.enquiry.prospect2LastName)
            {
                this.enquiry.primaryContact='';
            }
        }
    }


    public addEnquirySource = (text)  => {
        var object = { name: text, id: "new_" + text };
            this.enquirySources.push(object);
            this.enquiry.enquirySources.push(object);
       
    }

    public getEnquirySources = () => {
        this.referenceDataService.getEnquirySources()
        .then(data => {
            this.enquirySources = data;
       }, () => {
            this.notificationService.error("Unable to display enquiry sources. Either your computer is offline, or Resident Manager is having problems of its own.");
       });
    }
}
export = MarkDepartedResidentAsIluEnquiryController;
