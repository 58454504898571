module.exports = [
    "data.service", function(dataService) {

        function getFacilitiesForList(type) {
            if(!type) type = "";
            return dataService.get("facilities", true, { 'facilityType': type});
        }

        function getRegions() {
            return dataService.get("facilities/regions");
        }

        function getGLAccountMappings() {
            return dataService.get("finance/gl-accounts");
        }

        function saveGLAccountMappings(glAccountMappings) {
            return dataService.put("finance/gl-accounts", glAccountMappings);
        }

        function getOptionalServices() {
            return dataService.get("facilities/optional-services");
        }

        function saveOptionalServices(optionalServices) {
            return dataService.put("facilities/optional-services", optionalServices);
        }

        function getSundryChargesGLAccountMappings() {
            return dataService.get("finance/gl-accounts/sundrycharges");
        }

        function getAllOrganisationFacilities(excludeCurrentFacility) {
            return dataService.get("facilities/all-organisation-facilities", true, { "excludeCurrentlyLoggedInFacility": excludeCurrentFacility})
        }

        return {
            getFacilitiesForList: getFacilitiesForList,
            getRegions: getRegions,
            getGLAccountMappings: getGLAccountMappings,
            saveGLAccountMappings: saveGLAccountMappings,
            getOptionalServices: getOptionalServices,
            saveOptionalServices: saveOptionalServices,
            getSundryChargesGLAccountMappings: getSundryChargesGLAccountMappings,
            getAllOrganisationFacilities: getAllOrganisationFacilities
        }
    }
];