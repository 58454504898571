import { IIndependentLivingService } from "../services/IIndependentLivingService";
import { INotificationService } from '../../common/services/INotificationService';
import { IFacilityService } from '../../authentication/services/IFacilityService';
import { CreateEnquiryDto, SelectedResidentDto } from '../../independentLiving/Models';
import { IReferenceDataService } from "../../common/services/IReferenceDataService";
import { EnquirySourceDto } from "../../common/Models";
import type { IStateService } from "angular-ui-router";

class CreateEnquiryController {
    static $inject = ["independentLiving.service", "notification.service", "facility.service", "$state", "referenceData.service"];
    public enquiry: CreateEnquiryDto;
    public selectedFacility: any;  
    public enquirySources :Array<EnquirySourceDto> = new Array<EnquirySourceDto>();  

    constructor(private readonly independentLivingService: IIndependentLivingService,
        private readonly notificationService: INotificationService,
        private facilityService: IFacilityService,
        private $state: IStateService,
        private readonly referenceDataService: IReferenceDataService) {           
        this.selectedFacility = facilityService.selectedFacility.name;
        this.enquiry = new CreateEnquiryDto();
        this.enquiry.enquirySources = new Array<EnquirySourceDto>(); 
        this.enquirySources = [];
        this.getEnquirySources();
      
    }

    public saveEnquiry = (isValid) => {
        if (isValid) {
            this.independentLivingService.saveEnquiry(this.enquiry).then(response => {
                this.notificationService.success("Enquiry was created successfully."); 
                 this.$state.go("editEnquiry", { 'enquiryId': response.enquiryId, 'selectedResidentId': response.selectedResidentId, 
                'selectedUnit' : null, 'selectedResidents' :  null, 'doesFeeAndChargeExist' : false, 'financialTabSelected' : false,
                'enableAddingNewResident': false});
            }, () => {
                this.notificationService.error("Unexpected error occurred while saving enquiry");
            });
        }
    }

    public clearContactFields = () => {        
        if (this.enquiry.primaryContact != 'other') {
            delete this.enquiry.contactFirstName;
            delete this.enquiry.contactLastName;
        }
    }

    public inputChangeForProspect2 = () => {
        if(this.enquiry.primaryContact == 'prospect2') // primary contact selected as prospect 2
        {
            // if clear first name and last name for prospect2, need to clear selected radio for not saving wrong primary contact person
            if(!this.enquiry.prospect2FirstName && !this.enquiry.prospect2LastName)
            {
                this.enquiry.primaryContact='';
            }
        }
    }


    public addEnquirySource = (text)  => {
        var object = { name: text, id: "new_" + text };
            this.enquirySources.push(object);
            this.enquiry.enquirySources.push(object);
       
    }

    public getEnquirySources = () => {
        this.referenceDataService.getEnquirySources()
        .then(data => {
            this.enquirySources = data;
       }, () => {
            this.notificationService.error("Unable to display enquiry sources. Either your computer is offline, or Resident Manager is having problems of its own.");
       });
    }
}

export = CreateEnquiryController;
