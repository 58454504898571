"use strict";
const _ = require("underscore");

module.exports = [
    "organisation.facility.service",
    "notification.service",
    function (orgfacilityService, notificationService) {
        var vm = this;

        (function getFacilities() {
            orgfacilityService.getFacilitiesForList()
                .then(function (result) {
                    if (result.length > 0) {
                        vm.regions = sortAndGroupFacilities(result);
                    } else {
                        vm.noFacilities = true;
                    }
                },
                    function () {
                        notificationService.error("Unexpected error occurred while loading facilities.");
                    });
        })();

        function sortAndGroupFacilities(facilities) {
            var regions = _.chain(facilities)
                .pluck("region")
                .uniq()
                .sortBy(function (region) { return region; })
                .value();
            var regionsCollection = [];
            _.each(regions,
                function (region) {
                    var regionObj = {
                        name: region,
                        facilities: _.chain(facilities)
                            .where({ region: region })
                            .sortBy("name")
                            .value()
                    }
                    regionsCollection.push(regionObj);
                });
            return regionsCollection;
        }
    }
];
