import { StatementVarianceRespitePayment, StatementVariancePermanentPayment } from "../Models";

class MatchValuesDialogController {
    static $inject = ["modalConfig"];
    public $hide: any;
    public respite: StatementVarianceRespitePayment;
    public permanent: StatementVariancePermanentPayment;

    constructor(private readonly modalConfig: any) {
        this.respite = modalConfig.respite;
        this.permanent = modalConfig.permanent;
    }

    public bindHide = ($hide) => {
        this.$hide = $hide;
    };

    public acceptActual = () => {
        this.modalConfig.acceptActual();
        this.$hide();
    }
}

export = MatchValuesDialogController;