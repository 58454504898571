module.exports = [
    "data.service", function(dataService) {

        function getAllUsers() {
            return dataService.get("users");
        }

        function saveUser(userDto) {
            return dataService.post("users/save", userDto);
        }

        function activateUser(userId) {
            return dataService.post("users/" + userId + "/activate");
        }

        function deactivateUser(userId) {
            return dataService.post("users/" + userId + "/deactivate");
        }

        function getRoles() {
            return dataService.get("users/roles");
        }

        return {
            getAllUsers: getAllUsers,
            saveUser: saveUser,
            getRoles: getRoles,
            activateUser: activateUser,
            deactivateUser: deactivateUser
        }
    }
];