"use strict";

var moment = require("moment");

module.exports = [
    "leads.service",
    "$stateParams",
    "notification.service",
    "$state",
    "dialog.service",
    "$scope",
    "$rootScope",
    "$anchorScroll",
    "$location",
    function (leadsService, $stateParams, notification, $state, dialogService, $scope, $rootScope,
        $anchorScroll, $location) {
        var vm = this;        
        var residentId = $stateParams.residentId;

        vm.currentStateName = $state.current.name;

        // register watching state
        var unbindWatchHandler = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            vm.currentStateName = toState.name;
            if (toParams["#"]) {
                $location.hash(toParams["#"]);
                $anchorScroll();
            }
        });

        // unregister watching state
        $scope.$on('$destroy', function () {
            if (unbindWatchHandler) {
                unbindWatchHandler();
            }
        });

        vm.financialsTabIsActive = function () {
            switch (vm.currentStateName) {
                case "editLead.financialsFunding":
                case "editLead.financialsFees":
                case "viewLead.financialsFees":
                case "viewLead.financialsFunding":
                    return true;
                default:
                    return false;
            }
        };

        function getLead(residentId) {
            leadsService.getLead(residentId).then(function (result) {
                vm.resident = result;
                vm.resident.isLead = true;

                if (vm.resident.dateOfBirth) {
                    vm.resident.age = moment().diff(moment(vm.resident.dateOfBirth, "DD/MM/YYYY"), 'years');
                    vm.resident.dateofBirthFormatted = moment(vm.resident.dateOfBirth, "DD/MM/YYYY").format("DD MMM YYYY");
                }
              
                if (vm.resident.requestedAdmissionDateUtc) {
                    vm.resident.requestedAdmissionDateFormatted = "Requested admission " + moment(vm.resident.requestedAdmissionDateUtc).local().format("DD MMM YYYY");
                } else {
                    vm.resident.requestedAdmissionDateFormatted = "";
                }
                if (vm.resident.requestedDepartureDateUtc) {
                    vm.resident.secondLineDateNoteFormatted = "Requested departure " + moment(vm.resident.requestedDepartureDateUtc).local().format("DD MMM YYYY");
                } else if (vm.resident.preEntryLeaveDateUtc && vm.resident.careType === 'Permanent') {
                    vm.resident.secondLineDateNoteFormatted = "Pre-entry leave start " + moment(vm.resident.preEntryLeaveDateUtc).local().format("DD MMM YYYY");
                } else {
                    vm.resident.secondLineDateNoteFormatted = "";
                }
            }, function () {
                notification.error("Unable to display resident record. Either your computer is offline, or Resident Manager is having problems of its own.");
            });
        }

        vm.navigateToEdit = function () {
            $state.go("editLead", { residentId: residentId });
        }

        getLead(residentId);

        vm.openAdmitResidentDialog = function () {
            dialogService.openDialog("app/residents/partials/residentAdmissionDialog.html", "admitResidentDialog.controller", {
                residentId: residentId
            });
        }

        vm.openMarkAsProspectDialog = function () {
            dialogService.openDialog("app/leads/partials/markAsProspectDialog.html", "markAsProspectDialog.controller", {
                resident: {
                    id: vm.resident.id,
                    firstName: vm.resident.firstName,
                    lastName: vm.resident.lastName,
                    careType: vm.resident.careType,
                    title: vm.resident.title,
                    sex: vm.resident.sex,
                    dateOfBirth: vm.resident.dateOfBirth,
                    indigenousStatus: vm.resident.indigenousStatus
                }
            });
        }

        vm.openTransferResidentDialog = function () {
            dialogService.openDialog("app/residents/partials/transferResidentDialog.html",
                "transferResidentDialog.controller",
                {
                    resident: vm.resident
                });
        }
    }
];
