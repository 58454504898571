import { DetailedDropdownSection } from "../Models";

class ILuDashboardListDetailsComponent implements ng.IComponentOptions {
    public bindings: any;
    public templateUrl: string = 'app/dashboard/partials/iluDashboardListDetails.html';

    constructor() {
        this.bindings = {
            sections: "="
        };
    }

    public controller: any = class ILuDashboardListDetailsComponent {
        static $inject = ["utilities.service"];
        public sections: DetailedDropdownSection[];
        public internalName;
        public collapsed: boolean = false;

        constructor(private readonly utilitiesService : any){
            this.internalName = this.utilitiesService.newGuid();
        }

        public toggle = () => {
            this.collapsed = !this.collapsed;
        }
    }
}
export = ILuDashboardListDetailsComponent;