import { NoteInfo, StatementAdjustment, PaymentLine, ReconciliationAdjustmentAction } from "../Models";
import { IFundingService } from "../Interfaces";
import { INotificationService } from "../../common/services/INotificationService";
import { IAuthenticationService } from "../../authentication/services/IAuthenticationService";
import moment = require("moment");

class ViewAndAddVarianceNoteController {
    static $inject = ["funding.service", "notification.service", "modalConfig", "authentication.service"];
    public $hide: any;
    public lineItem: StatementAdjustment;
    public payment: PaymentLine;
    public period:string
    public note: string;
    public userFirstName: string;
    public userLastName: string;
    public noteStrErrMsg: string;

    constructor(private readonly fundingService: IFundingService,
        private readonly notificationService: INotificationService,
        private readonly modalConfig: any,
        private readonly authenticationService: IAuthenticationService) {
            this.period = moment(modalConfig.monthAndYear, "MYYYY").format("MMMM YYYY");
            this.lineItem = modalConfig.lineItem;    
            this.payment = modalConfig.payment;      
            this.loadUser();
    }

    private loadUser = ():void => {
        this.authenticationService.getUser()
            .then((user) => {
                this.userFirstName = user.profile.given_name;
                this.userLastName = user.profile.family_name;
            });
    }

    public bindHide = ($hide) => {
        this.$hide = $hide;
    };

    public addNote = (isValid) => {
        if (isValid) {
            let isCarringOver: boolean = this.payment.action == ReconciliationAdjustmentAction.CarryOver;    
            this.fundingService.addVariancePaymentNote(this.modalConfig.monthAndYear, this.note, this.lineItem, isCarringOver).then(() => {
                let noteInfo: NoteInfo = {
                    note: this.note,
                    entryDateTime: new Date(),
                    userFirstName: this.userFirstName,
                    userLastName: this.userLastName
                }
                this.modalConfig.successfulCallback(noteInfo, this.payment);
                this.$hide();
            },
            () => {
                this.notificationService.error("Unexpected error occurred while adding note.");
            });   
        }           
    }

    public getInitial = (firstName, lastName) => {
        return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
    }
}

export = ViewAndAddVarianceNoteController;