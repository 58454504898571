"use strict";

module.exports = [
    "residents.service",
    "$stateParams",
    "notification.service",
    "$state",
    "$scope",
    "$rootScope",
    function (residentsService, $stateParams, notification, $state, $scope, $rootScope) {

        var vm = this;
        var residentId = $stateParams.residentId;
        vm.currentStateName = $state.current.name;

        // register watching state
        var unbindWatchHandler = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            vm.currentStateName = toState.name;
        });

        // unregister watching state
        $scope.$on('$destroy', function () {
            if (unbindWatchHandler) {
                unbindWatchHandler();
            }
        });

        vm.financialsTabIsActive = function () {
            switch (vm.currentStateName) {
                case "editResident.financialsFunding":
                case "editResident.financialsFees":
                case "viewResident.financialsFees":
                case "viewResident.financialsFunding":
                    return true;
                default:
                    return false;
            }
        };

        function getResident(residentId) {
            residentsService.getResident(residentId).then(function (resident) {
                vm.resident = resident;               
            }, function () {
                notification.error("Unable to edit record. Either your computer is offline, or Resident Manager is having problems of its own.");
            });
        }


        vm.cancel = function () {
            $state.go("viewResident", { "residentId": vm.resident.id });
        };

        vm.save = function (isvalid) {
            if (isvalid) {
                vm.resident.medicareExpiryYear = parseInt(vm.resident.medicareExpiryYear);
                residentsService.updateResident({ resident: vm.resident }).then(function () {
                    notification.success("Saved successfully.");
                    vm.cancel();
                }, function(errors) {
                    if (errors && errors.modelStateSummary && errors.modelStateSummary.debtor) {
                        notification.error("Debtor ID already exists.");
                    } else {
                        notification.error("Unable to save changes. Either your computer is offline, or Resident Manager is having problems of its own.");
                    }                   
                });
            }
        };

        getResident(residentId);

        vm.refreshCallback = function () { getResident(residentId); };
    }
];
