"use strict";

module.exports = ["$rootScope", "$compile", function($rootScope, $compile) {
        return {
            restrict: "A",
            link: function (scope, element, attrs) {
                var subscribingTo = attrs['navSubscriber'];
                $rootScope.$on('highlight_navigator',
                    function (event, args) {
                        if (args === subscribingTo) {
                            element.addClass('active');
                        } else {
                            element.removeClass('active');
                        }
                    });
            }
        };
    }
]