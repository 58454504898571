import { ICustomerCareService } from "./ICustomerCareService";
import { ClinicalIntegrationDto, OperationType } from "../Models";

class CustomerCareService implements ICustomerCareService{
    static $inject = ["data.service"];

    constructor(private readonly dataService: any) {
    }

    public processClinicalIntegration = (facilityId: number, operationType: OperationType) => {
        return this.dataService.post("customerCare/integrate-residents-into-clinical/" + facilityId, JSON.stringify(operationType));
    }

    public fetchClinicalIntegrationStatus = (facilityId: number, operationType: OperationType) : ng.IPromise<ClinicalIntegrationDto[]> =>  {
        return this.dataService.get("customerCare/integrate-residents-into-clinical/" + facilityId, false, 
        {
            operationType: operationType
        });
    }
}

export = CustomerCareService;