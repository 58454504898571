class PromiseService {
    requests: any[];

    constructor() {
        this.requests = [];
    }

    register = (promise: any): any => {
        this.requests.push(promise);
    }

    unRegister = (promise: any): any => {
        const idx = this.requests.indexOf(promise);
        if (idx >= 0) {
            this.requests.splice(idx, 1);
        }
    }

    cancelPromise = (promise:any): any => {
        for (let i = 0; i < this.requests.length; i++) {
            const rootPromise = this.requests[i];
            let p = rootPromise;
            // Traverse the promise chain to see 
            // if given promise exists in the chain
            while (p !== promise && p.$$state.pending &&
                p.$$state.pending.length > 0) {
                  p = p.$$state.pending[0][0].promise;
            }
            // If this chain contains given promise, then call the
            // cancel method to cancel the http request
            if (p === promise && typeof p.cancel === 'function') {
                p.cancel();
            }
        }
    }

}

export = PromiseService;