export class Relationship {
    id: number;
    name: string;
}

export class Country {
    id: number;
    displayName: string;
}

export class DropdownOption {
    value: string;
    displayText: string;
}

export class EnquirySourceDto {
    id: string;
    name: string;
}

export class ReligionDto {
    id: string;
    name: string;
}

export class MedicareMappingDto {
    id: number;
    code: string;
    value: string;
    mappingType : MedicareMappingType;
}

export enum MedicareMappingType {
    ExtraService = 1
}