import * as _ from "underscore";

interface IAnaccCalculatorDialogConfig {
    residentId: number;
    residentFirstName: string;
    residentLastName: string;
    residentCareType: string;
    palliativeStatus: string;
    anaccFixedComponent: any | null;
    expectedAnaccVariableComponent: any | null;
    callback: (expectedAnaccVariableComponent: string | null) => void;
}

class AnaccCalculatorDialogController {
    static $inject = ["modalConfig", "funding.service", "facility.service"];

    public $hide: any;
    public residentId: number;
    public firstName: string;
    public lastName: string;
    public careType: string;
    public palliativeStatus: string;
    public anaccFixedComponent: any;
    public expectedAnaccVariableComponent: any;
    public callback: (expectedAnaccVariableComponent: string | null) => void;

    constructor(
        private modalConfig: IAnaccCalculatorDialogConfig
    ) {
        this.residentId = modalConfig.residentId;
        this.firstName = modalConfig.residentFirstName;
        this.lastName = modalConfig.residentLastName;
        this.careType = modalConfig.residentCareType;
        this.expectedAnaccVariableComponent = modalConfig.expectedAnaccVariableComponent;
        this.palliativeStatus = modalConfig.palliativeStatus;
        this.anaccFixedComponent = modalConfig.anaccFixedComponent;
    }

    public bindHide = (hide) => {
        this.$hide = hide;
    };

    public save = () => {
        if (this.modalConfig.callback) {
            this.modalConfig.callback(this.expectedAnaccVariableComponent);
        }
        this.$hide();
    }

    public cancel = () => {
        this.$hide();
    }

    public anaccVariableComponentChanged = (anaccVariableComponent: any | null) => {
        this.expectedAnaccVariableComponent = anaccVariableComponent;
    };
}

export = AnaccCalculatorDialogController;