import * as moment from 'moment';
import { ILeadsService } from "../services/ILeadsService";
import { INotificationService } from '../../common/services/INotificationService';
import { TourFacilitatorUser, TourDto } from '../Models';
import { IDialogService } from '../../common/services/IDialogService';
import * as _ from "underscore";
import type { IStateService } from 'angular-ui-router';
import { IAuthenticationService } from '../../authentication/services/IAuthenticationService';

interface IBookTourControllerScope extends ng.IScope {
    residencyId : number;
    isResident : boolean;
    tourInfo: TourDto;
    isCareTypeIlu: boolean;
}

class BookTourController {
    static $inject = ["$scope", "leads.service", "notification.service", "dialog.service", "$state", "authentication.service"];
    readonly defaultTimeValue: Date = moment("1970-01-01 12:30:00 PM").toDate(); 
    public $hide: any;
    public scope: ng.IScope;

    public residencyId: number;
    public isResident: boolean;
    public tourInfo: TourDto;
    public isCareTypeIlu: boolean;
    public tourInfoCopy: TourDto;
    public tourFacilitators: Array<TourFacilitatorUser> = new Array<TourFacilitatorUser>();
    public showPopup: boolean;
    public tourDate: string = null; //default value for date picker
    public tourTime: Date = this.defaultTimeValue; // default value for time picker

    public isPastTime: boolean;
    public isReadOnly: boolean;

    constructor(
        private $scope: IBookTourControllerScope,
        private readonly leadsService: ILeadsService,
        private readonly notificationService: INotificationService,
        private dialogService: IDialogService,
        private readonly $state: IStateService,
        private readonly authenticationService: IAuthenticationService,
    ) {
        if ($scope.residencyId != undefined) {
            this.residencyId = $scope.residencyId;
            this.isResident = $scope.isResident;
            this.tourInfo = $scope.tourInfo;
            this.isCareTypeIlu = $scope.isCareTypeIlu;
            this.setIsReadOnly();
            this.showPopup = false;
            this.processTourInfo();
        }
    }

    setIsReadOnly = () => {
        this.authenticationService.userHasPermissionDeferred((x) => {
            return x.leadsDetails.canModify;
        }).then(() => {
            this.isReadOnly = this.isResident ? true : false;
        }, () => {
            this.isReadOnly = this.isCareTypeIlu ? true : false;
        });
    }
   
    public saveTour = (bookTourForm) => {
        if (bookTourForm.$valid) {
            let tourToSave = this.prepareDataBeforeSave();

            this.leadsService.saveTour(tourToSave).then(result => {
                this.showPopup = false;
                this.notificationService.success("Tour Saved successfully!");
                    // to refresh the Tour Booked section and recent notes, refreshing the parent page
                    bookTourForm.$setUntouched();
                     this.$state.reload();
                },
                () => {
                    this.notificationService.error("Unexpected error occurred while saving");
                });
        }
    };

    private processTourInfo = () => {         
             if (this.tourInfo.id == 0)
                this.tourInfo.tourFacilitatorUserId = undefined;
            
            this.tourFacilitators =  this.tourInfo.tourFacilitatorUsers;

            if ( this.tourInfo.id != 0) {
                this.setTourDateTimeValue();
            }

            this.setIsPastTime(this.tourInfo.tourDateTime);           
    }

    private setIsPastTime(tourDateTime){
        if (this.IsFutureDateTime(tourDateTime)) {
            this.isPastTime = false;
        }
        else {
            this.isPastTime = true;
        }
    }

    public setTourDateTimeValue = () => {
        this.tourDate = moment(this.tourInfo.tourDateTime).format('YYYY-MM-DD');
        var time = moment(this.tourInfo.tourDateTime).format("hh:mm a");
        this.tourTime = moment("1970-01-01" + ' ' + time).toDate();
    }

    public showTourPopup() {   
        if(this.tourInfo) {
            this.tourInfoCopy = this.tourInfo;
        }        
        this.showPopup = true;
    }

    public hideTourPopup() {
        if(this.tourInfoCopy) {
            this.tourInfo = this.tourInfoCopy;
            if (this.tourInfo.id != 0) {
                this.setTourDateTimeValue();
            }
            this.setIsPastTime(this.tourInfo.tourDateTime);
        }                
        this.showPopup = false;
    }

    public deleteTour = bookTourForm =>{
        this.dialogService.openActionDialog("Delete tour booking",
            "Delete this tour booking?",
            "tour-delete",
            "Delete",
            () => {
                this.leadsService.deleteTour(this.tourInfo.id)
                    .then(
                        () => {
                            this.showPopup = false;
                            this.notificationService.success("Tour Deleted successfully.");
                            this.tourDate = null;
                            this.tourTime = this.defaultTimeValue;
                            // to refresh the Tour Booked section and recent notes, refreshing the parent page
                            bookTourForm.$setUntouched();
                            this.$state.reload();
                        },
                        () => {
                            this.notificationService.error("Unexpected error while saving the tour.");
                        });
            },
            "btn-danger btn-inline-block-xsm");
    }

    public markTourCompleted = bookTourForm => {
        this.dialogService.openActionDialog("Mark as completed",
        "Mark this tour as completed? This action cannot be undone.",
        "mark-tour-completed",
        "Mark tour completed",
        () => {
            if (bookTourForm && bookTourForm.$valid) {
                let tourToSave = this.prepareDataBeforeSave();
                tourToSave.isCompleted = true;
    
                this.leadsService.saveTour(tourToSave).then(result => {
                    this.showPopup = false;
                    this.notificationService.success("Tour marked as complete successfully!");
                    // to refresh the Tour Booked section and recent notes, refreshing the parent page
                    bookTourForm.$setUntouched();
                    this.$state.reload();
                    },
                    () => {
                        this.notificationService.error("Unexpected error occurred while marking tour complete.");
                    });
            }
            else {
                this.tourInfo.isCompleted = true;
                this.leadsService.saveTour(this.tourInfo).then(result => {
                    this.showPopup = false;
                    this.notificationService.success("Tour marked as complete successfully!");
                    this.$state.reload();
                    },
                    () => {
                        this.notificationService.error("Unexpected error occurred while marking tour complete.");
                    });
            }
        },
        "btn-primary");       
    }

    public onTourDateTimeChange = () => {
        var date = moment(this.tourDate).format("LL");
        var time = moment(this.tourTime).format("hh:mm a");
        
        var tourDateTime = moment(date + ' ' + time).toDate();
        this.setIsPastTime(tourDateTime);        
    }

    private prepareDataBeforeSave = () => {
        let tour = new TourDto();
        tour.id = this.tourInfo.id == undefined ? 0 : this.tourInfo.id;
        tour.tourFacilitatorUserId = this.tourInfo.tourFacilitatorUserId;
        tour.attendees = this.tourInfo.attendees;
        tour.residencyId = this.residencyId;
        tour.isCompleted = false;

        let facilitator = _.find(this.tourFacilitators,
            (facilitator) => {
                return facilitator.id == this.tourInfo.tourFacilitatorUserId;
            });
      
        tour.tourFacilitatorUserName = facilitator.displayName;

        var date = moment(this.tourDate).format("LL");
        var time = moment(this.tourTime).format("hh:mm a");
        
        tour.tourDateTime = <any>moment(date + ' ' + time).format('YYYY-MM-DD HH:mm:ss');
        return tour;
    }

    private IsFutureDateTime = ( dateTime: Date ) => {
        var now = new Date();
        var other = new Date(dateTime.toString())
        if( now < other)  {
            return true;
        }
        else {
            return false;
        }
    }
}

export = BookTourController;