import { IIndependentLivingService } from "../services/IIndependentLivingService";
import { INotificationService } from '../../common/services/INotificationService';
import { ViewEnquiryDto, ResidentDto, UnitDto, SelectedResidentDto } from '../../independentLiving/Models';
import {IDialogService} from '../../common/services/IDialogService'
import * as _ from 'underscore';
import * as moment from 'moment';
import type { IStateService, IStateParamsService } from "angular-ui-router";


class ViewEnquiryController {
    static $inject = ["independentLiving.service", "notification.service", "$state", "$stateParams", "dialog.service"
    ,"$scope", "$rootScope"];

    public enquiry: ViewEnquiryDto;
    public enquiryId: number;
    public selectedResident: ResidentDto;
    public financialTabSelected: boolean;
    public doesFeeAndChargeExist: boolean;
    public isUnitAssigned: boolean;
    public selectedUnit: UnitDto = new UnitDto();
    public selectedResidents: Array<SelectedResidentDto> = new Array<SelectedResidentDto>();
    public currentStateName : string ="viewEnquiry";    
    public unbindWatchHandler: any;
    public viewDepartedMode: boolean;
    public isSelectedResidentResponsible: boolean;
    public isBillingIntegratedFacility : boolean;
    public settlementPrice: number;
    public settlementDate: Date; 
    public vacatingDate: Date;
  
    constructor(private readonly iluService: IIndependentLivingService,
        private readonly notificationService: INotificationService, 
        private readonly $state: IStateService,
        private readonly $stateParams: IStateParamsService,
        private readonly dialogService: IDialogService,
        private readonly $scope: any, 
        private readonly $rootScope: any
      ) {   

        this.currentStateName = this.$state.current.name;
        this.registerOrUnregisterStateWatching();
        this.isBillingIntegratedFacility = $rootScope.isBillingIntegratedFacility;

        if(this.$state.current.name == "viewEnquiry.finance" || this.$state.current.name == "viewIndependentLivingAllocation.finance")
        {
            this.financialTabSelected = true;
        }
        this.enquiryId = $stateParams.enquiryId;
        this.viewDepartedMode = $stateParams.viewDepartedMode;
        
        this.getEnquiry();
    }

    private registerOrUnregisterStateWatching = () => {
        // register watching state
        this.unbindWatchHandler = this.$rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
            this.currentStateName = toState.name;
            if(this.financialTabSelected && (this.currentStateName == "viewEnquiry" || this.currentStateName == "viewIndependentLivingAllocation"))
            {
                // if click on detail menu by default should select resident tab
                this.financialTabSelected = false;
                this.selectedResident.id = fromParams.residentId;
            }
        });

        // unregister watching state
        this.$scope.$on('$destroy', () => {
            if (this.unbindWatchHandler) {
                this.unbindWatchHandler();
            }
        });
    }
    
    private getEnquiry = () => {
        let residentId = this.viewDepartedMode ? this.$stateParams.residentId : null;
        this.iluService.getEnquiry(this.enquiryId, this.viewDepartedMode, residentId).then(result => {
            this.enquiry = result; 
            this.iluService.getFeesAndCharges(this.enquiry.enquiryId, this.viewDepartedMode, residentId).then(feeAndChargesResult => {
                if(feeAndChargesResult!=null)
                {
                    if (feeAndChargesResult.assignedAccommodationDto != null) {
                        this.selectedUnit.roomNumber = feeAndChargesResult.assignedAccommodationDto.unitNumber;
                        this.selectedUnit.location = feeAndChargesResult.assignedAccommodationDto.location;
                        this.selectedUnit.accommodationName = feeAndChargesResult.assignedAccommodationDto.unitType;

                        this.isUnitAssigned = true;
                    }
                    else {
                        this.isUnitAssigned = false;
                    }
 
                    this.doesFeeAndChargeExist = true;

                    if (feeAndChargesResult.financialSummaryAndUnitCostsDto) {
                        this.isSelectedResidentResponsible = this.$state.params.residentId == feeAndChargesResult.financialSummaryAndUnitCostsDto.responsibleResidentId;
                        this.settlementPrice = feeAndChargesResult.financialSummaryAndUnitCostsDto.settlementPrice;
                        this.settlementDate = feeAndChargesResult.financialSummaryAndUnitCostsDto.settlementDateUtc;
                        this.vacatingDate = feeAndChargesResult.vacatingDateUtc;
                    }
                }
                else
                {
                    this.doesFeeAndChargeExist = false;
                    this.isUnitAssigned = false;
                }
            });
          
            let preSelectedResident:ResidentDto;
            if(this.$stateParams.residentId) {
                preSelectedResident = _.find(this.enquiry.residents, (x) => { return x.id === this.$stateParams.residentId; });
            }
            this.selectedResident = !preSelectedResident ? this.enquiry.residents[0] : preSelectedResident;          
            this.selectedResidents = [];
             
            _.forEach(this.enquiry.residents,
                (prospect: ResidentDto) => {
                    if(this.enquiry.occupiedDate){
                        prospect.isLead = false;
                    }
                    else {
                        prospect.isLead = true;
                    }
                    
                    if (prospect.dateOfBirth) {
                        prospect.age = moment().diff(moment(prospect.dateOfBirth, "DD/MM/YYYY"), 'years');
                        prospect.dateOfBirthFormatted = moment(prospect.dateOfBirth, "DD/MM/YYYY").format("DD MMM YYYY");
                    }
                    if (prospect.requestedAdmissionDateUtc) {
                        prospect.requestedAdmissionDateFormatted = "Requested admission " + moment(prospect.requestedAdmissionDateUtc).local().format("DD MMM YYYY");
                    }

                    // Need to bring the enquiry's accessibilityRequirement down into the resident level to be displayed;
                    prospect.accessibilityRequirement = this.enquiry.accessibilityRequirement;

                    this.selectedResidents.push({residentId : prospect.id, fullName : prospect.firstName + " " + prospect.lastName, residencyId: prospect.residencyId});                            
                });
        }, () => {
            this.notificationService.error("Unexpected error occurred while viewing enquiry");
        });
    }

    public viewSelectedResident = (residentId: number) => {  
        this.selectedResident = _.find(this.enquiry.residents , function (resident) {
            return resident.id === residentId;
        });

        if (this.$state.current.name == "viewEnquiry.notes") {
            this.$state.go("viewEnquiry.notes", { 'residentId': residentId });
        }

        if (this.$state.current.name == "viewIndependentLivingAllocation.notes") {
            this.$state.go("viewIndependentLivingAllocation.notes", { 'residentId': residentId });
        }

        if(this.$state.current.name == "viewEnquiry.history") {
            this.$state.go("viewEnquiry.history", { 'residentId': residentId});
        }

        if(this.$state.current.name == "viewIndependentLivingAllocation.history") {
            this.$state.go("viewIndependentLivingAllocation.history", { 'residentId': residentId});
        }

        if (this.$state.current.name == "viewEnquiry.timeline") {
            this.$state.go("viewEnquiry.timeline", { 'residentId': residentId });
        }

        if (this.$state.current.name == "viewIndependentLivingAllocation.timeline") {
            this.$state.go("viewIndependentLivingAllocation.timeline", { 'residentId': residentId });
        }

        if (this.$state.current.name == "viewEnquiry.events") {
            this.$state.go("viewEnquiry.events", { 'residentId': residentId });
        }

        if (this.$state.current.name == "viewIndependentLivingAllocation.events") {
            this.$state.go("viewIndependentLivingAllocation.events", { 'residentId': residentId });
        }

        // if finance section was previously selected, when click on resident(s) tab, should return state to default
        if (this.$state.current.name == "viewEnquiry.finance") 
        {
            this.financialTabSelected = false;
            this.$state.go("viewEnquiry", { 'residentId': residentId });
        }

        if (this.$state.current.name == "viewIndependentLivingAllocation.finance") 
        {
            this.financialTabSelected = false;
            this.$state.go("viewIndependentLivingAllocation", { 'residentId': residentId });
        }

        if(this.$state.current.name == "viewEnquiry") {
            this.financialTabSelected = false;
            this.$state.go("viewEnquiry", {  'residentId': residentId });
        }

        if(this.$state.current.name == "viewIndependentLivingAllocation") {
            this.financialTabSelected = false;
            this.$state.go("viewIndependentLivingAllocation", { 'residentId': residentId});
        }

        if (this.$state.current.name == "viewEnquiry.transactions") {
            this.$state.go("viewEnquiry.transactions", { 'residentId': residentId });
        }

        if (this.$state.current.name == "viewIndependentLivingAllocation.transactions") {
            this.$state.go("viewIndependentLivingAllocation.transactions", { 'residentId': residentId, 'allocationId' : this.enquiryId });
        }
    }

    public isActive(residentId: number) {
        
        if (this.selectedResident.id == residentId && !this.financialTabSelected)
            return true;
        return false;
    }

    public selectFinancialTab()
    {
        this.financialTabSelected = true;
        if(this.enquiry.occupiedDate) {          
            this.$state.go("viewIndependentLivingAllocation.finance", {'enquiryId': this.enquiryId, 'selectedResidents' : this.selectedResidents});
        }
        else {
            this.$state.go("viewEnquiry.finance", {'enquiryId': this.enquiryId, 'selectedResidents' : this.selectedResidents});
        }
    }

    public navigateToEdit = () => {    
        if(this.enquiry.occupiedDate){
            if(this.financialTabSelected){
                this.$state.go("editIndependentLivingAllocation.finance", { 'enquiryId': this.enquiryId, 'selectedResidentId': this.selectedResident.id,  'viewDepartedMode': this.viewDepartedMode,
                'selectedUnit' : this.selectedUnit, 'selectedResidents' :  this.selectedResidents, 'doesFeeAndChargeExist' : this.doesFeeAndChargeExist, 'financialTabSelected' : true});
            }
            else {
                this.$state.go("editIndependentLivingAllocation", { 'enquiryId': this.enquiryId, 'selectedResidentId': this.selectedResident.id, 'viewDepartedMode': this.viewDepartedMode,
                'selectedUnit' : this.selectedUnit, 'selectedResidents' :  this.selectedResidents, 'doesFeeAndChargeExist' : this.doesFeeAndChargeExist, 'financialTabSelected' : false});
            }
            
        }
        else {
            this.$state.go("editEnquiry", { 'enquiryId': this.enquiryId, 'selectedResidentId': this.selectedResident.id, 
            'selectedUnit' : this.selectedUnit, 'selectedResidents' :  this.selectedResidents, 'doesFeeAndChargeExist' : this.doesFeeAndChargeExist, 
            'financialTabSelected' : this.financialTabSelected, 'enableAddingNewResident': false});
        }
    }

    public addResident()
    {
        this.$state.go("editEnquiry", { 'enquiryId': this.enquiryId, 'selectedResidentId': this.selectedResident.id, 
        'selectedUnit' : this.selectedUnit, 'selectedResidents' :  this.selectedResidents, 'doesFeeAndChargeExist' : this.doesFeeAndChargeExist, 
       'financialTabSelected' : false, 'enableAddingNewResident': true});
    }

    public openAssignUnitDialog(selectedResidents : Array<string>)
    {
        var modalConfig = {
            enquiryId: this.enquiryId,
            residentId : this.selectedResident.id,
            selectedResidents : selectedResidents
        }
        this.dialogService.openDialog("app/independentLiving/partials/assignUnitDialog.html", "assignUnitDialog.controller", modalConfig);
    }

    public openAdmitDialog = () => {
        let modalConfig = {
            enquiry: this.enquiry
        };
        
        this.dialogService.openDialog("app/independentLiving/partials/admitToUnitDialog.html", "admitToUnitDialogController.controller", modalConfig);
    }

    public openTransferResidentDialog = () => {
        this.dialogService.openDialog("app/residents/partials/transferResidentDialog.html",
            "transferResidentDialog.controller",
            {
                resident: this.selectedResident,
                fromILU: true
            });
    }

    public openSettleUnitDialog = () => {
        this.dialogService.openDialog("app/independentLiving/partials/settleUnitDialog.html",
            "settleUnitDialog.controller",
            {
                enquiryId: this.enquiryId,
                unitNumber: this.selectedUnit.roomNumber,
                price: this.settlementPrice,
                date: this.settlementDate,
                vacatingDate: this.vacatingDate
            });
    }

    public openDepartureDialog =  (isVacating: boolean) => {
        this.dialogService.openDialog("app/residents/partials/departureDialog.html",
            "departureDialog.controller",
            {
                resident: this.selectedResident,
                fromILU: true,
                enquiryId: this.enquiryId,
                isResponsibleResident: this.enquiry.responsibleResidencyId == this.selectedResident.residencyId,
                anotherResident: (this.enquiry.residents.length > 1) ? (this.enquiry.residents.filter(x => x != this.selectedResident))[0]: null,
                isVacating: isVacating
            });
    }

    public getNavPublisher = (): string => {
        return this.enquiry.occupiedDate ? 'residents' : 'leads'
    }

    public openSendOnLeaveDialog = () => {
        this.dialogService.openDialog("app/residents/partials/sendOnLeaveDialog.html",
            "sendOnLeaveDialog.controller",
            {
                resident: this.selectedResident,
                fromILU: true
            });
    }

    public openReturnFromLeaveDialog = () => {
        this.dialogService.openDialog("app/residents/partials/returnFromLeaveDialog.html",
            "returnFromLeaveDialog.controller",
            {
                resident: this.selectedResident,
                fromILU: true
            });
    }

    public navigateToMarkDepartedResidentAsIluEnquiry = () => {
        this.$state.go("markDepartedResidentAsIluEnquiry", 
        { 
            enquiryId: this.enquiryId,
            residentId: this.selectedResident.id 
        });
    }
}

export = ViewEnquiryController;