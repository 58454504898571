"use strict";

module.exports = function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "residentId": "=",
            "photoTakenDate": "=?",
            "financialTabSelected": "="
        },
        controller: "residentProfilePhoto.controller",
        template: "<img data-ng-src=\"{{image}}\" data-err-src=\"../../../images/unknown-user.jpg\"/>"
    };
}
