"use strict";
module.exports = {
    
    configure : function(module) {

        module.config(["$stateProvider", "$urlRouterProvider",
            function ($stateProvider, $urlRouterProvider) {

            $urlRouterProvider.otherwise("/dashboard");
            $stateProvider
                .state("dashboard", {
                    url: "/dashboard",
                    templateUrl: "app/dashboard/partials/dashboard.html",
                    controller: ["$state", "facility.service", function (state, facilityService) {
                        if(facilityService.selectedFacility.facilityType == 'AgeCare')
                        {
                             state.go("dashboard.residential"); 
                        }
                        else if(facilityService.selectedFacility.facilityType == 'IndependentLiving')
                        {
                               state.go("dashboard.ilu"); 
                        }
                    }],
                });

                $stateProvider.state("dashboard.residential", {
                    templateUrl: "app/dashboard/partials/residentialDashboard.html",
                    controller: "residentialDashboard.controller as vm"
                });

                $stateProvider.state("dashboard.ilu", {
                    template: "<ilu-dashboard></ilu-dashboard>"
                });
        }]);
    }
};