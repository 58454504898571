const _ = require("underscore");
const moment = require("moment");

module.exports = [
    "$q", "data.service", "dialog.service", "fileReader.service", "EffectiveDateTypes",
    function ($q, dataService, dialogService, fileReaderService, EffectiveDateTypes) {

        function isNumber(property) {
            return typeof property === "number";
        }

        function getLatestEffectiveDate(finance, effectiveDateType) {
            var now = moment().startOf('day').utc();
            var item = _
                .chain(finance.effectiveDates)
                .filter(function (item) {
                    var effectiveFromDate = moment(item.effectiveFromDate);
                    return item.effectiveDateType === effectiveDateType && effectiveFromDate.isSameOrBefore(now, 'day');
                })
                .sortBy('effectiveFromDate')
                .last()
                .value();

            return item;
        }

        function getLatestEffectiveDateIncludingFutureDates(finance, effectiveDateType) {
            var item = _
                .chain(finance.effectiveDates)
                .filter(function (item) {
                    return item.effectiveDateType === effectiveDateType;
                })
                .sortBy('effectiveFromDate')
                .last()
                .value();

            return item;
        }

        function attachFinanceComputedProperties(finance) {
            Object.defineProperty(finance,
                "refundableAccommodationContributionCalculated",
                {
                    get: function () {
                        var value = null;
                        if (finance.latestDailyAccommodationContribution &&
                            isNumber(finance.maximumPermissibleInterestRate)) {
                            var latestDailyAccommodationContributionValue = Number(finance.latestDailyAccommodationContribution.value);
                            value = latestDailyAccommodationContributionValue * 365 /
                                (finance.maximumPermissibleInterestRate / 100);
                        }
                        return value;
                    }
                });
            Object.defineProperty(finance,
                "refundableAccommodationContributionRemaining",
                {
                    get: function () {
                        var value = null;
                        if (isNumber(finance.refundableAccommodationContributionCalculated) &&
                            isNumber(finance.refundableAccommodationContributionPaid)) {
                            value = finance.refundableAccommodationContributionCalculated -
                                finance.refundableAccommodationContributionPaid + (finance.drawdowns || 0) + finance.refundedAmountFromLumpSumDeposit;
                        }
                        return value;
                    }
                });
            Object.defineProperty(finance,
                "dailyAccommodationContributionTopUp",
                {
                    get: function () {
                        var value = null;
                        if (finance.latestDailyAccommodationContribution &&
                            isNumber(finance.refundableRAC) &&
                            isNumber(finance.maximumPermissibleInterestRate)) {
                            var latestDailyAccommodationContributionValue = Number(finance.latestDailyAccommodationContribution.value);
                            value = latestDailyAccommodationContributionValue -
                                (((finance.refundableRAC) *
                                    (finance.maximumPermissibleInterestRate / 100)) /
                                    365);
                        }
                        return (value > 0) ? value : 0;
                    }
                });
            Object.defineProperty(finance,
                "refundableAccommodationDepositRemaining",
                {
                    get: function () {
                        var value = null;
                        if (isNumber(finance.refundableAccommodationDepositPaid)) {
                            var latestAgreedRoomPriceValue = Number(finance.latestAgreedRoomPrice.value);
                            value = latestAgreedRoomPriceValue + (finance.drawdowns || 0) + finance.refundedAmountFromLumpSumDeposit - finance.refundableAccommodationDepositPaid;
                        }
                        return value;
                    }
                });
            Object.defineProperty(finance,
                "dailyAccommodationPayment",
                {
                    get: function () {
                        var value = null;
                        if (finance.isDeparted) {
                            value = 0;
                        } else {
                            if (isNumber(finance.refundableAccommodationDepositRemaining) &&
                                isNumber(finance.maximumPermissibleInterestRate)) {
                                value = finance.refundableAccommodationDepositRemaining *
                                    (finance.maximumPermissibleInterestRate / 100) /
                                    365;
                            }
                        }
                        return value < 0 ? 0 : value;
                    }
                });
            Object.defineProperty(finance,
                "unsupportedBalanceProgressPercentage",
                {
                    get: function () {
                        var value = 0;
                        var latestAgreedRoomPriceValue = Number(finance.latestAgreedRoomPrice.value);
                        if (isNumber(finance.refundableAccommodationDepositPaid) && latestAgreedRoomPriceValue > 0) {
                            value = Math.round((finance.refundableAccommodationDepositPaid
                                / latestAgreedRoomPriceValue) * 100);
                            value = (value > 100) ? 100 : value;
                        }
                        return value;
                    }
                });
            Object.defineProperty(finance,
                "supportedBalanceProgressPercentage",
                {
                    get: function () {
                        var value = 0;
                        if (isNumber(finance.refundableAccommodationContributionPaid)) {
                            value = Math.round((finance.refundableAccommodationContributionPaid
                                / finance.refundableAccommodationContributionCalculated) * 100);
                            value = (value > 100) ? 100 : value;
                        }
                        return value;
                    }
                });
            Object.defineProperty(finance,
                "pre2014RefundableBondDepositRemaining",
                {
                    get: function () {
                        var value = null;
                        var deposit = finance.pre2014RefundableBondPaid || 0;
                        if (isNumber(finance.pre2014BondOrRoomPrice) && isNumber(deposit)) {
                            value = finance.pre2014BondOrRoomPrice - deposit;
                        }
                        return value;
                    }
                });
            Object.defineProperty(finance,
                "pre2014BondBalanceProgressPercentage",
                {
                    get: function () {
                        var value = 0;
                        if (isNumber(finance.pre2014RefundableBondPaid)) {
                            value = Math.round((finance.pre2014RefundableBondPaid
                                / finance.pre2014RefundableBondDepositRemaining) * 100);
                            value = (value > 100) ? 100 : value;
                        }
                        return value;
                    }
                });
            Object.defineProperty(finance,
                "refundableRAD",
                {
                    get: function () {
                        var value = null;
                        if (isNumber(finance.drawdowns) && isNumber(finance.refundableAccommodationDepositPaid)) {
                            value = finance.refundableAccommodationDepositPaid - finance.drawdowns - finance.refundedAmountFromLumpSumDeposit;
                        }
                        return value;
                    }
                });
            Object.defineProperty(finance,
                "refundableRAC",
                {
                    get: function () {
                        var value = null;
                        if (isNumber(finance.drawdowns) && isNumber(finance.refundableAccommodationContributionPaid)) {
                            value = finance.refundableAccommodationContributionPaid - finance.drawdowns - finance.refundedAmountFromLumpSumDeposit;
                        }
                        return value;
                    }
                });
            Object.defineProperty(finance,
                "latestMeansTestedCareFee",
                {
                    get: function () {
                        return getLatestEffectiveDate(finance, EffectiveDateTypes.MeansTestedCareFee);
                    }
                });
            Object.defineProperty(finance,
                    "latestPrivateDailyCareFee",
                {
                        get: function () {
                            return getLatestEffectiveDate(finance, EffectiveDateTypes.PrivateDailyCareFee);
                        }
                });
            Object.defineProperty(finance,
                "latestBasicDailyCareFee",
                {
                    get: function () {
                        return getLatestEffectiveDate(finance, EffectiveDateTypes.BasicDailyCareFee);
                    }
                });
            Object.defineProperty(finance,
                "latestPre2014IncomeTestFee",
                {
                    get: function () {
                        return getLatestEffectiveDate(finance, EffectiveDateTypes.Pre2014IncomeTestedFee);
                    }
                });
            Object.defineProperty(finance,
                "latestSupportLevel",
                {
                    get: function () {
                        return getLatestEffectiveDate(finance, EffectiveDateTypes.SupportLevel);
                    }
                });
            Object.defineProperty(finance,
                "latestDailyAccommodationContribution",
                {
                    get: function () {
                        return getLatestEffectiveDate(finance, EffectiveDateTypes.DailyAccommodationContribution);
                    }
                });
            Object.defineProperty(finance,
                "latestAgreedRoomPrice",
                {
                    get: function () {
                        return getLatestEffectiveDate(finance, EffectiveDateTypes.AgreedRoomPrice);
                    }
                });
            Object.defineProperty(finance,
                "latestDAP",
                {
                    get: function () {
                        return getLatestEffectiveDateIncludingFutureDates(finance, EffectiveDateTypes.DailyAccommodationPayment);
                    }
                });
            Object.defineProperty(finance,
                "latestTopupDAC",
                {
                    get: function () {
                        return getLatestEffectiveDateIncludingFutureDates(finance, EffectiveDateTypes.TopupDailyAccommodationContribution);
                    }
                });
            Object.defineProperty(finance,
                "pre2014BondDailyAmount",
                {
                    get: function () {
                        var value = null;
                        if (isNumber(finance.pre2014BondOrRoomPrice) && isNumber(finance.pre2014InterestRate)) {
                            value = (finance.pre2014BondOrRoomPrice * finance.pre2014InterestRate / 100 /
                                moment(moment().year().toString() + "-12-31").dayOfYear()).toFixed(2);
                        }
                        return value;
                    }
                });
            Object.defineProperty(finance,
                "pre2014PaymentsRemaining",
                {
                    get: function () {
                        var value = null;
                        if (isNumber(finance.pre2014PaymentsPrior)) {
                            value = 60 - (finance.pre2014PaymentsPrior + finance.pre2014PaymentsAfter);
                        }
                        return value;
                    }
                });
            Object.defineProperty(finance,
                "pre2014LastPayment",
                {
                    get: function () {
                        var value = null;
                        if (isNumber(finance.pre2014PaymentsPrior)) {
                            var paymentDate = finance.admissionDateUtc ? finance.admissionDateUtc : finance.requestedAdmissionDateUtc;
                            var lastPayment = moment(paymentDate).add(60 - finance.pre2014PaymentsPrior, "month").format("MMM YYYY");
                            value = "last payment " + lastPayment;
                        }
                        return value;
                    }
                });
            Object.defineProperty(finance,
                "refundableBond",
                {
                    get: function () {
                        var value = null;
                        if (isNumber(finance.pre2014LumpSum) && isNumber(finance.totalBondRetentionPayments)) {
                            value = finance.pre2014LumpSum - finance.totalBondRetentionPayments;
                        }
                        return value;
                    }
                });
        }

        function getResidentFinanceFunding(residentId) {
            return dataService.get("residents/" + residentId + "/finance/funding")
                .then(function (finance) {
                    return finance;
                });
        };

        function getResidentFinanceFees(residentId) {
            return dataService.get("residents/" + residentId + "/finance/fees")
                .then(function (finance) {
                    attachFinanceComputedProperties(finance.financeFees);
                    return finance;
                });
        };

        function getResidentFinanceTransactions(filter, notIncludingItemsAfter, skip, take) {
            return dataService.get("residents/" + filter.ResidentId + "/finance/transactions",
                true,
                {
                    notIncludingItemsAfter: notIncludingItemsAfter,
                    skip: skip,
                    take: take
                });
        };

        function getResidentFinanceTransactionsBalance(residentId) {
            return dataService.get("residents/" + residentId + "/finance/transactionsbalance")
                .then(function (result) {
                    return result.balance;
                });
        };

        function saveResidentFinanceFees(residentId, finance) {
            return dataService.put("residents/" + residentId + "/finance/fees", finance);
        };

        function saveResidentFinanceFunding(residentId, finance) {
            return dataService.put("residents/" + residentId + "/finance/funding", finance);
        };

        function saveResidentFinanceFundingSupplementStatus(residentId, pensionerSupplement, homelessSupplement, veteranSupplement) {
            return dataService.put("residents/" + residentId + "/finance/funding/supplement-status", {
                'pensionerSupplement': pensionerSupplement,
                'homelessSupplement': homelessSupplement,
                'veteranSupplement': veteranSupplement
            });
        };

        function updateResident(resident) {
            return dataService.put("residents", resident);
        };

        function uploadPhoto(residentId, residentPhotoDto) {
            return dataService.put("residents/" + residentId + "/photo", residentPhotoDto);
        };

        function deletePhoto(residentId) {
            return dataService.delete("residents/" + residentId + "/photo");
        };

        function submitAcfiForResident(residentId, acfiFile) {
            return fileReaderService.readAsText(acfiFile)
                .then(function (acfiFileAsXml) {
                    var acfiPayload = {
                        residentId: residentId,
                        acfiXml: acfiFileAsXml
                    };
                    return dataService.post("residents/" + residentId + "/acfi", acfiPayload);
                },
                    function (error) {
                        return $q.reject(error);
                    });
        }

        function getResident(residentId) {
            return dataService.get("residents/" + residentId);
        };

        function getResidentSimple(residentId) {
            return dataService.get("residents/" + residentId + "/simple");
        };

        function getResidents(notIncludingItemsAfter, skip, take) {
            return dataService.get("residents",
                skip == 0 ? false : true,
                {
                    notIncludingItemsAfter: notIncludingItemsAfter,
                    skip: skip,
                    take: take
                });
        };

        function getDepartedResidents(notIncludingItemsAfter, skip, take) {
            return dataService.get("residents/departed",
                skip == 0 ? false : true,
                {
                    notIncludingItemsAfter: notIncludingItemsAfter,
                    skip: skip,
                    take: take
                });
        }

        function getPhoto(residentId) {
            return dataService.get("residents/" + residentId + "/photo", true);
        }

        function sendOnLeave(residentId, leave) {
            return dataService.post("residents/" + residentId + "/leave", leave);
        }

        function updateLeave(leave) {
            return dataService.put("residents/leave", leave);
        }

        function departResident(residentId, departure, fromILU = false, isVacatingUnit = false) {
            if (fromILU) {
                return dataService.post("residents/" + residentId + "/depart-ilu/" + isVacatingUnit, departure);
            }
            else {
                return dataService.post("residents/" + residentId + "/depart", departure);
            }
        }

        function transferResident(residentId, transferDto) {
            return dataService.post("residents/" + residentId + "/transfer", transferDto);
        }

        function returnFromLeave(residentId, leave) {
            return dataService.post("residents/" + residentId + "/leave/return", leave);
        }

        function getEventHistory(residentId) {
            return dataService.get("residents/" + residentId + "/event-history");
        }

        function reverseLeave(residentId, leaveId) {
            return dataService.put("residents/" + residentId + "/leave/reverse", leaveId);
        }

        function saveSupplementEnteralFeeding(residentId, supplement) {
            return dataService.put("residents/" + residentId + "/finance/supplements/enteral-feeding", supplement);
        }

        function endSupplementEnteralFeeding(residentId, supplement) {
            return dataService.put("residents/" + residentId + "/finance/supplements/enteral-feeding-end", supplement);
        }

        function saveSupplementOxygen(residentId, supplement) {
            return dataService.put("residents/" + residentId + "/finance/supplements/oxygen", supplement);
        }

        function endSupplementOxygen(residentId, supplement) {
            return dataService.put("residents/" + residentId + "/finance/supplements/oxygen-end", supplement);
        }

        function reverseAdmission(residentId, uniqueEventId) {
            let url = "residents/" + residentId + "/admission/reverse";
            if(uniqueEventId !== null || uniqueEventId !== '' || uniqueEventId !== undefined){
                url = url + "/" + uniqueEventId;
            }

            return dataService.post(url);
        }

        function reverseDeparture(residentId, uniqueEventId) {
            let url = "residents/" + residentId + "/depart/reversal";
            if(uniqueEventId !== null || uniqueEventId !== '' || uniqueEventId !== undefined){
                url = url + "/" + uniqueEventId;
            }
            
            return dataService.post(url);
        }

        function updateContractSigned(residencyId, isContractSigned) {
            return dataService.put("residents/" + residencyId + "/contract-signed/" + isContractSigned);
        }

        function updateResidentDeceased(residentId, isDeceased) {
            return dataService.put("residents/" + residentId + "/deceased/" + isDeceased);
        }

        function getResidentAdmissionInformation(residentId) {
            return dataService.get("residents/" + residentId + "/admission");
        }

        function correctResidentAdmission(residentAdmissionDetails, uniqueEventId, isPrivateResident) {
            let url = "residents/" + residentAdmissionDetails.residentId + "/admission/correction";
            if(!isPrivateResident){
                url = url + "/" + uniqueEventId;
            }

            return dataService.post(url, residentAdmissionDetails);
        }

        function getResidentDepartureInformation(residentId) {
            return dataService.get("residents/" + residentId + "/departure");
        }

        function getIluResidentDepartureInformation(residencyId) {
            return dataService.get("residents/departure-ilu/" + residencyId);
        }

        function correctResidentDeparture(residentId, uniqueEventId, departureInformation) {
            return dataService.post("/residents/" + residentId + "/depart/correction/" + uniqueEventId,
                departureInformation);
        }

        function correctIluResidentDeparture(departureInformation) {
            return dataService.post("/residents/departure-ilu/correction/", departureInformation);
        }

        function fetchCareRecipientId(residentId, fetchCridInfo) {
            return dataService.post("residents/" + residentId + "/fetch-crid", fetchCridInfo);
        }

        function saveExistingAcfi(residentId, existingAcfi) {
            return dataService.post("residents/" + residentId + "/assign-existing-acfi", existingAcfi);
        };

        function addANACCReassessmentRequest( residencyId, anaccReassessmentRequest) {
            return dataService.post("residents/" + residencyId + "/anacc-reassessment-request", anaccReassessmentRequest);
        }

        function updateANACCReassessmentRequest(residencyId, anaccReassessmentRequest) {
            return dataService.put("residents/" + residencyId + "/anacc-reassessment-request", anaccReassessmentRequest);
        }

        function saveExpectedANACC(residentId, expectedAnacc) {
            return dataService.post("residents/" + residentId + "/enter-expected-anacc", expectedAnacc);
        }

        function getTimelineForResident(residentId) {
            return dataService.get("residents/" + residentId + "/timeline");
        }

        function searchCurrentAndDepartedResidents(searchString, httpCancelerPromise) {
            return dataService.get("residents/search-current-and-departed?searchStr=" + searchString, true, { timeout: httpCancelerPromise });
        }
        function markDepartedResidentAsEnquiry(residentId, markDepartedResidentEnquiry) {
            return dataService.post("residents/" + residentId + "/mark-departed-resident-as-enquiry", markDepartedResidentEnquiry);
        }

        function getResidentNotes(residentId, notIncludingItemsAfter, skip, take) {
            return dataService.get("residents/" + residentId + "/resident-notes", true, {
                skip: skip,
                take: take,
                notIncludingItemsAfter: notIncludingItemsAfter
            });
        }

        function toggleResidentNotesPinStatus(residentNoteId, isNotePinned) {
            return dataService.post("residents/note-pin-status/" + residentNoteId, isNotePinned);
        }

        function addResidentNote(residentId, note) {
            return dataService.post("residents/" + residentId + "/resident-notes", JSON.stringify(note));
        }

        function getLegalNoticeDateInformation(residencyId) {
            return dataService.get("funding/" + residencyId + "/legal-notice-date-information")
                .then(function (data) {
                    return data;
                },
                    function (error) {
                        return $q.reject(error);
                    });
        }

        function saveNoticeDate(residencyId, noticeDateUtc) {
            return dataService.post("residents/" + residencyId + "/save-notice-date", noticeDateUtc);
        }

        function getIfResidentHasOpenOptionalServices(residentId) {
            return dataService.get("residents/" + residentId + "/open-optional-services");
        }

        function saveLeadAnaccClassification(residencyId, anaccClass) {
            return dataService.put("leads/" + residencyId + "/expectedAnacc", {
                anaccClass
            });
        }

        function updateIncomeAssetStatus(residencyId, status) {
            return dataService.put("residents/" + residencyId + "/income-asset-status/" + status);            
        }

        function makeResidentFunded(residentToAdmit) {
            return dataService.put("residents/make-resident-funded/", residentToAdmit);
        }

        return {
            getResidentFinanceFunding: getResidentFinanceFunding,
            getResidentFinanceFees: getResidentFinanceFees,
            getResidentFinanceTransactionsBalance: getResidentFinanceTransactionsBalance,
            getResidentFinanceTransactions: getResidentFinanceTransactions,
            saveResidentFinanceFees: saveResidentFinanceFees,
            saveResidentFinanceFunding: saveResidentFinanceFunding,
            updateResident: updateResident,
            getResident: getResident,
            getResidentSimple: getResidentSimple,
            submitAcfiForResident: submitAcfiForResident,
            getResidents: getResidents,
            uploadPhoto: uploadPhoto,
            getPhoto: getPhoto,
            deletePhoto: deletePhoto,
            sendOnLeave: sendOnLeave,
            departResident: departResident,
            returnFromLeave: returnFromLeave,
            getDepartedResidents: getDepartedResidents,
            updateLeave: updateLeave,
            reverseAdmission: reverseAdmission,
            reverseDeparture: reverseDeparture,
            reverseLeave: reverseLeave,
            getResidentAdmissionInformation: getResidentAdmissionInformation,
            correctResidentAdmission: correctResidentAdmission,
            getResidentDepartureInformation: getResidentDepartureInformation,
            getIluResidentDepartureInformation: getIluResidentDepartureInformation,
            correctResidentDeparture: correctResidentDeparture,
            correctIluResidentDeparture: correctIluResidentDeparture,
            fetchCareRecipientId: fetchCareRecipientId,
            saveExistingAcfi: saveExistingAcfi,
            addANACCReassessmentRequest: addANACCReassessmentRequest,
            updateANACCReassessmentRequest: updateANACCReassessmentRequest,
            saveExpectedANACC: saveExpectedANACC,
            getTimelineForResident: getTimelineForResident,
            saveSupplementOxygen: saveSupplementOxygen,
            endSupplementOxygen: endSupplementOxygen,
            saveSupplementEnteralFeeding: saveSupplementEnteralFeeding,
            endSupplementEnteralFeeding: endSupplementEnteralFeeding,
            markDepartedResidentAsEnquiry: markDepartedResidentAsEnquiry,
            searchCurrentAndDepartedResidents: searchCurrentAndDepartedResidents,
            getResidentNotes: getResidentNotes,
            toggleResidentNotesPinStatus: toggleResidentNotesPinStatus,
            addResidentNote: addResidentNote,
            getLegalNoticeDateInformation: getLegalNoticeDateInformation,
            saveNoticeDate: saveNoticeDate,
            transferResident: transferResident,
            getIfResidentHasOpenOptionalServices: getIfResidentHasOpenOptionalServices,
            saveResidentFinanceFundingSupplementStatus: saveResidentFinanceFundingSupplementStatus,
            saveLeadAnaccClassification: saveLeadAnaccClassification,
            updateContractSigned: updateContractSigned,
            updateResidentDeceased: updateResidentDeceased,
            updateIncomeAssetStatus : updateIncomeAssetStatus,
            makeResidentFunded: makeResidentFunded,
            getEventHistory : getEventHistory
        }
    }
];
