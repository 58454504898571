import {LeadCardDto, IncomeAssetStatus, EnquiryStatus} from '../Models';
import { CareType } from '../../independentLiving/Models';

class LeadCardComponent implements ng.IComponentOptions {
    public bindings: any = {
        leadCard: "<",
        archiveCallback: "&",
        removeFromLeadsCallback: "&",
        markAsDeceasedCallback: "&",
        sendToLeadsCallback: "&",
        deleteCallback: "&"
    };

    public templateUrl: string = 'app/leads/partials/leadCard.html';

    public controller: any = class LeadCardComponentController {
        static $inject = ["residents.service", "notification.service"];

        public leadCard: LeadCardDto;
        public name: string;
        public createDate: Date;
        public tourDateTimeUtc: Date;
        public modifiedOnUtc: Date;
        public sex: string;
        public supportStatus: string;
        public careType: CareType;
        public respiteCareLevel : string | null;
        public permanentCareLevel : string | null;
        public permanentCareLevelPopover: string | null;
        public orderNumber : string;
        public location: string;
        public incomeAssetStatus : IncomeAssetStatus | undefined;
        public sexStrDict: {[sex: string]: string} = {
            Male: "M",
            Female: "F",
            IntersexOrIndeterminate: "I",
            Unknown: "U",
            null: "U"
        }; 
        public permanentCareLevelStrDict: {[permanentCareLevel: string]: string} = {
            Default: "D",
            Nil: "N",
            Low: "L",
            Medium: "M",
            High: "H"
        }; 
        public permanentCareLevelPopoverDict: {[permanentCareLevel: string]: string} = {
            Default: "Default",
            Nil: "None",
            Low: "Low",
            Medium: "Medium",
            High: "High"
        }; 
        public respiteCareLevelStrDict: {[respiteCareLevelLevel: string]: string} = {
            Unknown : "Unknown",
            Low : "Low care",
            High : "High care"
        }; 
        public hasPreviousResidency: boolean;
        public isArchived: boolean;
        public isPrivateResidency: boolean;
        public moreMenuTemplate: string = 'moreMenuTemplate.html';
        public moreMenuOpen: boolean;
        public isGrabbing: boolean = false;
        public popoverFlags = {
            name: false,
            careType: false,
            careLevel: false,
            incomeAssets: false,
            supportStatus: false,
            location: false
        };
        public expectedAnaccVariableComponentAnaccClassText: string;
        public expectedAnaccVariableComponentRate: number | null;

        archiveCallback: () => any;
        sendToLeadsCallback: () => any;
        removeFromLeadsCallback: () => any;
        markAsDeceasedCallback: () => any;    
        deleteCallback: () => any;  

        constructor() {     
            this.name = this.leadCard.firstName + " " + this.leadCard.lastName;
            this.createDate = this.leadCard.createdOnUtc;
            this.sex = this.sexStrDict[this.leadCard.sex];
            this.supportStatus = this.leadCard.supportStatus;
            this.careType = this.leadCard.careType;
            this.respiteCareLevel = this.leadCard.respiteCareLevel != null ? this.respiteCareLevelStrDict[this.leadCard.respiteCareLevel] : null;          
            this.location = this.leadCard.location;
            this.permanentCareLevel = this.permanentCareLevelStrDict[this.leadCard.adl] == "D" ? null 
                                        : this.permanentCareLevelStrDict[this.leadCard.adl]+this.permanentCareLevelStrDict[this.leadCard.beh]+this.permanentCareLevelStrDict[this.leadCard.chc];
            this.permanentCareLevelPopover = this.permanentCareLevelPopoverDict[this.leadCard.adl] == "Default" ? null 
                                        : this.permanentCareLevelPopoverDict[this.leadCard.adl] + " " +
                                          this.permanentCareLevelPopoverDict[this.leadCard.beh] + " " +
                                          this.permanentCareLevelPopoverDict[this.leadCard.chc];
            this.incomeAssetStatus = this.leadCard.incomeAssetStatus;
            this.orderNumber = this.leadCard.orderNumber;
            this.hasPreviousResidency = this.leadCard.hasPreviousResidency;
            this.isArchived = this.leadCard.isArchived;
            this.isPrivateResidency = this.leadCard.isPrivateResidency;
            this.leadCard.cardMoved = this.cardMoved;
            this.expectedAnaccVariableComponentAnaccClassText = this.leadCard.expectedAnaccVariableComponent?.anaccClassText;
            this.expectedAnaccVariableComponentRate = this.leadCard.expectedAnaccVariableComponent?.rate;
        }

        private cardMoved = () => {
            this.popoverFlags.name = false;
            this.popoverFlags.careType = false;
            this.popoverFlags.careLevel = false;
            this.popoverFlags.incomeAssets = false;
            this.popoverFlags.supportStatus = false;
            this.popoverFlags.location = false;           
        }

        public archive = () => {
            this.moreMenuOpen = false;
            this.archiveCallback();
        }

        public sendToLeads = () => {
            this.moreMenuOpen = false;
            this.sendToLeadsCallback();
        }

        public removeFromLeads = () => {
            this.moreMenuOpen = false;
            this.removeFromLeadsCallback();
        }

        public markAsDeceased = () => {
            this.moreMenuOpen = false;
            this.markAsDeceasedCallback();
        }

        public delete = () => {
            this.moreMenuOpen = false;
            this.deleteCallback();
        }
    }
}
export = LeadCardComponent;