import * as moment from 'moment';
import { ILeadsService } from "../services/ILeadsService";
import { INotificationService } from '../../common/services/INotificationService';
import { TourFacilitatorUser, TourDto, EnquiryStatus } from '../Models';
import * as _ from "underscore";


class TourBookForLeadsDashboardController {
    static $inject = ["leads.service", "notification.service", "modalConfig"];
    readonly defaultTimeValue: Date = moment("1970-01-01 12:30:00 PM").toDate(); 
    public $hide: any;

    public residencyId: number;
    public residentName: string;
    public tourInfo: TourDto;
    public targetStatus: EnquiryStatus;

    public tourFacilitators: Array<TourFacilitatorUser> = new Array<TourFacilitatorUser>();
    public tourDate: Date = null; //default value for date picker
    public tourTime: Date = this.defaultTimeValue; // default value for time picker

    public isPastTime: boolean;

    constructor(
        private readonly leadsService: ILeadsService,
        private readonly notificationService: INotificationService,
        private readonly modalConfig: any
    ) {        
            this.residencyId = modalConfig.residencyId;
            this.residentName = modalConfig.residentName;
            this.tourInfo = modalConfig.tourInfo;
            this.targetStatus = modalConfig.targetStatus;
            this.processTourInfo();   
    }
    
    public bindHide = (hide) => {
        this.$hide = hide;
    }; 

    public cancel = () => {
        if(this.modalConfig.cancelCallback){
            this.modalConfig.cancelCallback(this.residencyId, this.modalConfig.sourceStatus, this.modalConfig.originalOrderNumber);
        }
        this.$hide();       
    }
    public submit = (bookTourForm) => {
        if (this.targetStatus == EnquiryStatus.TourBooked)
            this.saveTour(bookTourForm);
        else if (this.targetStatus == EnquiryStatus.TourCompleted)
            this.markTourCompleted(bookTourForm);
    }
   
    public saveTour = (bookTourForm) => {
        if (bookTourForm.$valid) {
            let tourToSave = this.prepareDataBeforeSave();

            this.leadsService.saveTour(tourToSave).then(result => {
                this.modalConfig.successfulCallback();
                this.notificationService.success("Tour saved successfully!");
                this.$hide();
            },
            () => {
                this.notificationService.error("Unexpected error occurred while saving tour.");
            });
        }
    };

    private processTourInfo = () => {         
             if (this.tourInfo.id == 0)
                this.tourInfo.tourFacilitatorUserId = undefined;
            
            this.tourFacilitators =  this.tourInfo.tourFacilitatorUsers;

            if ( this.tourInfo.id != 0) {
                this.setTourDateTimeValue();
            }

            if (this.IsFutureDateTime(this.tourInfo.tourDateTime)) {
                this.isPastTime = false;
            }
            else {
                this.isPastTime = true;
            }
    }

    public setTourDateTimeValue = () => {
        this.tourDate = moment(this.tourInfo.tourDateTime).toDate();
        var time = moment(this.tourInfo.tourDateTime).format("hh:mm a");
        this.tourTime = moment("1970-01-01" + ' ' + time).toDate();
    }

    public markTourCompleted = bookTourForm => {
        if (bookTourForm.$valid) {
            let tourToSave = this.prepareDataBeforeSave();
            tourToSave.isCompleted = true;

            this.leadsService.saveTour(tourToSave).then(result => {
                this.modalConfig.successfulCallback();
                this.notificationService.success("Tour marked as complete successfully!");
                this.$hide();
            },
            () => {
                this.notificationService.error("Unexpected error occurred while marking tour complete.");
            });
        }
    }

    public onTourDateTimeChange = () => {
        var date = moment(this.tourDate).format("LL");
        var time = moment(this.tourTime).format("hh:mm a");
        
        var tourDateTime = moment(date + ' ' + time).toDate();
        if (this.IsFutureDateTime(tourDateTime)) {
            this.isPastTime = false;            
        }
        else {
            this.isPastTime = true;
        }
    }

    private prepareDataBeforeSave = () => {
        let tour = new TourDto();
        tour.id = this.tourInfo.id == undefined ? 0 : this.tourInfo.id;
        tour.tourFacilitatorUserId = this.tourInfo.tourFacilitatorUserId;
        tour.attendees = this.tourInfo.attendees;
        tour.residencyId = this.residencyId;
        tour.isCompleted = false;

        let facilitator = _.find(this.tourFacilitators,
            (facilitator) => {
                return facilitator.id == this.tourInfo.tourFacilitatorUserId;
            });
      
        tour.tourFacilitatorUserName = facilitator.displayName;

        var date = moment(this.tourDate).format("LL");
        var time = moment(this.tourTime).format("hh:mm a");
        
        tour.tourDateTime = <any>moment(date + ' ' + time).format('YYYY-MM-DD HH:mm:ss');
        return tour;
    }

    private IsFutureDateTime = ( dateTime: Date ) => {
        var now = new Date();
        var other = new Date(dateTime.toString())
        if( now < other)  {
            return true;
        }
        else {
            return false;
        }
    }
}

export = TourBookForLeadsDashboardController;