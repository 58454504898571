import { IFacilityService } from "../../organisation/services/IFacilityService";
import { INotificationService } from "../../common/services/INotificationService";
import { FacilityDto } from "../../organisation/Models";
import { ICustomerCareService } from "../services/ICustomerCareService";
import { ClinicalIntegrationDto, OperationType } from "../Models";
import _ = require("underscore");
import moment = require("moment");

class ClinicalIntegrationComponent implements ng.IComponentOptions {
    public bindings: any = {
        operationType: "="
    };

    public templateUrl: string = 'app/customerCare/partials/clinicalIntegration.html';

    public controller: any = class ClinicalIntegrationComponentController {
        static $inject = ["organisation.facility.service", "notification.service", 'customerCare.service', '$timeout'];

        public facilities: FacilityDto[];
        public selectedFacility: FacilityDto;
        public clinicalIntegrationItems: ClinicalIntegrationDto[];
        public operationType: OperationType;
        public operationTypeMsg: string;

        constructor(private readonly facilityService: IFacilityService,
            private readonly notificationService: INotificationService,
            private readonly customerCareService: ICustomerCareService,
            private readonly $timeout: ng.ITimeoutService) {
            this.fetchAndSetFacilities();
            this.operationTypeMsg = this.getEventTypeMessage(this.operationType);
        }

        public process = (): void => {
            const facilityId = this.selectedFacility != null ? this.selectedFacility.id : 0;
            this.customerCareService.processClinicalIntegration(facilityId, this.operationType).then(result => {
                if (!result.success) {
                    _.each(result.validationResults, (validationError: any) => {
                        this.notificationService.error(validationError.errorMessage);
                    });
                    return;
                }                
                this.notificationService.success("Request to " + this.operationTypeMsg + " the residents to clinical has been submitted.");
                this.checkStatus();
            },
                (error) => {
                    this.notificationService.error("Error occurred while requesting to " + this.operationTypeMsg + " the residents into clinical");
                });
        }

        public formatDateTime = (dateTime: Date): string => {
            return moment(dateTime).format("DD-MM-YYYY HH:mm:ss");
        }

        private checkStatus = () => {
            _.each(this.facilities, (facility) => {
                this.customerCareService.fetchClinicalIntegrationStatus(facility.id, this.operationType).then((result) => {
                    if (result == null || result.length == 0)
                        return;

                    this.updateStatus(facility.id, result[0]);

                    if (!result[0].isCompleted) {
                        this.pollTillCompleted(facility.id);
                    }
                });
            });
        }

        private updateStatus = (facilityId: number, clinicalIntegrationItem: ClinicalIntegrationDto) => {
            if (this.clinicalIntegrationItems == null)
                this.clinicalIntegrationItems = [];

            const pos = _.findIndex(this.clinicalIntegrationItems, (item) => {
                return item.facilityId == facilityId;
            });

            if (pos == -1)
                this.clinicalIntegrationItems.push(clinicalIntegrationItem);
            else
                this.clinicalIntegrationItems[pos] = clinicalIntegrationItem;
        }

        private pollTillCompleted = (facilityId: number) => {
            this.$timeout(() => {
                this.customerCareService.fetchClinicalIntegrationStatus(facilityId, this.operationType).then((result) => {
                    this.updateStatus(facilityId, result[0]);

                    if (!result[0].isCompleted)
                        this.pollTillCompleted(facilityId);
                });
            }, 5000)
        }

        private fetchAndSetFacilities = (): void => {
            this.facilityService.getFacilitiesForList().then(result => {
                this.facilities = result;
                if (this.facilities.length == 1) {
                    this.selectedFacility = this.facilities[0];
                }
                this.checkStatus();
            },
                () => {
                    this.notificationService.error("Error occurred while fetching facilities");
                });
        }

        private getEventTypeMessage = (operationType: OperationType) => {
            let msg="";
            switch(operationType) {
                case OperationType.OnboardResidents:
                    msg = "on-board";
                    break;
                case OperationType.UpdateResidents:
                    msg = "update";
                    break;
            }
            return msg;
        }
    }
}

export = ClinicalIntegrationComponent 