"use strict";
var moment = require("moment");

module.exports = [
    "$scope", "leads.service", "$state", "notification.service", "accr.service", "facility.service",
    "referenceData.service", "featureFlags.service", 
    function ($scope, leadsService, $state, notification, accrService, facilityService, 
        referenceDataService, featureFlagsService) {
        var vm = this;
        vm.lead = {
            careType: "Permanent",
            requestACCR: false,
            entitlementType: 'MCA',
            enquirySources : [],
            religions: [],
            isPrivateResidency : false
        };
        vm.selectedFacility = facilityService.selectedFacility.name;
        vm.hasPreEntryLeaveDate = false;
        vm.enquirySources = [];
        vm.religions = [];
        vm.enablePrivateResidents = featureFlagsService.featureFlags.systemConfigurationFlags.enablePrivateResidents;
        vm.saveLead = function () {
            if ($scope.saveLeadForm.$valid) {
                leadsService.saveLead(vm.lead).then(function (result) {
                    notification.success("Lead was created successfully.");
                    vm.residentId = result.lead.residentId;
                    if (vm.lead.requestACCR) {
                        if (result.getACCRCommandResult.accrLoaded) {
                            $state.go("viewAccr", { "residentId": vm.residentId });
                        } else {
                            accrService.showNoAccrWarning(result.getACCRCommandResult.errorMessage, function () {
                                $state.go("editLead", { "residentId": vm.residentId });
                            });
                        }
                    } else {
                        $state.go("editLead", { "residentId": result.lead.residentId });
                    }

                }, function (err) {
                    if(err.data.modelState && err.data.modelState.mpir){
                        notification.error("Unable to create lead record. " + err.data.modelState.mpir[0]);
                    }
                    else if(err.data.exceptionMessage)
                    {
                        notification.error("Unable to create lead record. " + err.data.exceptionMessage); 
                    }
                    else{
                        notification.error("Unable to create lead record. Either your computer is offline, or Resident Manager is having problems of its own.");
                    }
                });
            }
        };

        vm.clearContactFields = function () {
            if (vm.lead.contactIsResident) {
                delete vm.lead.contactFirstName;
                delete vm.lead.contactLastName;
            }
        };

        vm.minRequestedDepartureDate = function () {
            return moment(vm.lead.requestedAdmissionDateUtc).add(1, 'd').startOf('day').toDate();
        };

        vm.preEntryDate = function () {
            if (vm.preEntryDays && vm.lead.careType === 'Permanent' && vm.lead.requestedAdmissionDateUtc) {
                vm.lead.preEntryLeaveDateUtc = moment(vm.lead.requestedAdmissionDateUtc).subtract(vm.preEntryDays, 'd').zone('Z').startOf('day').toDate();
                return vm.lead.preEntryLeaveDateUtc;
            }

            return null;
        };

        vm.clearPreEntryLeaveDate = function () {
            if (!vm.hasPreEntryLeaveDate) {
                delete vm.lead.preEntryLeaveDateUtc;
                delete vm.preEntryDays;
            }
        };

        referenceDataService.getEnquirySources()
        .then(function (data) {
            vm.enquirySources = data;
        },
          function () {
            notification.error("Unable to display enquiry sources. Either your computer is offline, or Resident Manager is having problems of its own.");
          });

        vm.addEnquirySource = function (text) {
            $scope.$apply(function () {
                var object = { name: text, id: "new_" + text };
                vm.enquirySources.push(object);
                vm.lead.enquirySources.push(object);
            });
        };

        referenceDataService.getReligions()
            .then(function (data) {
                    vm.religions = data;
                },
                function () {
                    notification.error("Unable to display religion. Either your computer is offline, or Resident Manager is having problems of its own.");
                });

        vm.addReligion = function (text) {
            $scope.$apply(function () {
                var object = { name: text, id: "new_" + text };
                vm.religions.push(object);
                vm.lead.religions.push(object);
            });
        };
      }

      
];
