"use strict";

const angular = require("angular");
const $ = require("jquery");

module.exports = ["$affix", "$window", function ($affix, $window) {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "class": "@", //css classes to add to container div, comma seperated
            "offsetTop": "=", //offset from top, relative to view port (window), i.e. height of header section
            "offsetBottom": "=" //offset from bottom, relative to view port (window), i.e. height of footer section
        },
        transclude: true,
        template: "<div id=\"stickySidebar{{::$id}}\" " +
            "ng-transclude>" +
            "</div>",
        link: function (scope, element) {

            var offsetTop = scope.offsetTop;
            var offsetBottom = scope.offsetBottom;

            if (!offsetTop) {
                offsetTop = 96; // default for the container-header in the site template, which contains the header and main menu.
            }
            if (!offsetBottom) {
                offsetBottom = 0; 
            }

            var componentId = "stickySidebar" + scope.$id;

            var currentWindow = angular.element($window);

            function resizeSidebar() {
                $("#" + componentId).height(currentWindow.height() - (offsetTop - currentWindow.scrollTop()));
            }

            resizeSidebar();

            $("#" + componentId).addClass(scope.class);

            $affix(element.eq(0), {
                offsetTop: offsetTop.toString(),
                offsetBottom: offsetBottom.toString(),
                target: currentWindow
            });

            function maintainSidebarHeight() {
                if (currentWindow.scrollTop() <= offsetTop) {
                    //sidebar is not pinned yet, so keep resizing
                    resizeSidebar();
                } else {
                    //sidebar is now pinned, so stop resizing
                    $("#" + componentId).height(currentWindow.height());
                }
            }

            currentWindow.bind("scroll", maintainSidebarHeight);
            currentWindow.bind("resize", maintainSidebarHeight);

            element.on("$destroy", function () {
                currentWindow.unbind("scroll", maintainSidebarHeight);
                currentWindow.unbind("resize", maintainSidebarHeight);
            });

        }
    };
}];
