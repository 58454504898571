import { IAuthenticationService } from "../../authentication/services/IAuthenticationService";
import { IResidentsService } from "../../residents/services/IResidentsService";
import { IInfiniteLoaderService, IInfiniteLoaderFactory } from "../../common/services/IInfiniteLoaderService";
import { IAutoResizeTextareaController } from "../../common/directives/auto-resize-textarea/IAutoResizeTextareaController";
import { INotificationService } from "../../common/services/INotificationService";
import { IIndependentLivingService } from "../../independentLiving/services/IIndependentLivingService";

import * as _ from 'underscore';
import * as moment from 'moment';
import type { IStateParamsService } from "angular-ui-router";

class NotesComponent implements ng.IComponentOptions{
    public bindings: any;
    public templateUrl: string = 'app/residents/partials/notes.html';

    constructor() {
        this.bindings = {
            residentId: "=",
            allocationId: "="
        };
    }

    public controller: any = class NotesComponentController {
        static $inject = ['$stateParams', 'authentication.service', 'residents.service', 'independentLiving.service', 'notification.service', 'infiniteLoader.service', '$location', '$anchorScroll', '$timeout'];

        infiniteLoader: IInfiniteLoaderService;
        public addNoteForm: ng.IFormController;
        public resizeControl: IAutoResizeTextareaController = {};
        public residentId: number;
        public allocationId: number;
        newNote: string;
        noteEntryUserInitials: string;
        notes: any[] = [];
        pinnedNotesCount: number = 0;

        constructor(private readonly $stateParams: IStateParamsService,
            private readonly authenticationService: IAuthenticationService,
            private readonly residentsService: IResidentsService,
            private readonly iluService: IIndependentLivingService,
            private readonly notificationService: INotificationService,
            private readonly infiniteLoaderFactory: IInfiniteLoaderFactory,
            private readonly $location: ng.ILocationService,
            private readonly $anchorScroll: ng.IAnchorScrollService,
            private readonly $timeout: ng.ITimeoutService) {
                this.loadUser();
                this.loadNotes();
        }

        private loadNotes = (): void => {
            this.pinnedNotesCount = 0;
            this.infiniteLoader = this.infiniteLoaderFactory.instance(this.loadResidents,
                (results) => {
                    this.formatDates(results);
                    this.createUserInitials(results);
                    this.notes = this.notes.concat(results);
                    this.pinnedNotesCount = this.pinnedNotesCount + results.filter(x => x.isNotePinned == true).length;
                    this.setFocusOnSelectedNote();
                }, () => {
                    this.notificationService.error('Unexpected error occurred while loading notes.');
                },
                10);
        }
    
        private loadUser = ():void => {
            this.authenticationService.getUser()
                .then((user) => {
                    this.noteEntryUserInitials = this.getInitials(user.profile.given_name, user.profile.family_name);
                });
        }
    
        private loadResidents = (notIncludingItemsAfter: string, skip: number, take: number) => {
            return this.residentsService.getResidentNotes(this.residentId, notIncludingItemsAfter, skip, take);
        }
    
        private formatDates = (results: any[]):void => {
            _.each(results, (item) => {
                item.modifiedOn = moment(item.modifiedOn).format('MMM D YYYY - h:mmA');
            });
        }
    
        private createUserInitials(results: any[]) {
            _.each(results, (item) => {
                item.userInitials = this.getInitials(item.userFirstName, item.userLastName);
            });
        }
    
        private getInitials = (firstName: string, lastName: string):string => {
            return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
        }
    
        private setFocusOnSelectedNote = () => {
            var noteToBeHighlighted = _.find(this.notes, (note) => {
                return note.id == this.$location.hash();
            });
    
            noteToBeHighlighted.isHighlighted = true;
    
            this.$timeout(() => { //provide time for page rendering in order for anchor scroll to work on loaded DOM element.
                this.$anchorScroll.yOffset = 50;
                this.$anchorScroll();
            }, 100);
        }

        private finalizeAfterSuccessfulSave = () => {
            // clear the form input/state and shrink the input box manually using the directive.
            this.addNoteForm.$setPristine();
            this.addNoteForm.$setUntouched();
            this.newNote = '';
            this.resizeControl.resize();
            // reload notes
            this.notes = [];
            this.loadNotes();
            this.notificationService.success('Note added successfully.');
        }
    
        public submit = () => {
            if (this.addNoteForm.$valid) {
                if(this.allocationId && this.allocationId != 0){
                    this.iluService.addNote(this.allocationId, this.newNote).then(() => {
                       this.finalizeAfterSuccessfulSave();
                    }, () => {
                        // reset the form submission state to trigger the
                        // unsaved changes warning if the user leaves this form.
                        this.addNoteForm.$setPristine();
                        this.notificationService.error('Unexpected error while adding the note.');
                    });
                }
                else {
                    this.residentsService.addResidentNote(this.residentId, this.newNote)
                    .then(() => {
                        this.finalizeAfterSuccessfulSave();
                    }, () => {
                        // reset the form submission state to trigger the
                        // unsaved changes warning if the user leaves this form.
                        this.addNoteForm.$setPristine();
                        this.notificationService.error('Unexpected error while adding the note.');
                    });
                }
            }
        }

        public togglePinStatus = (item) => {
            item.isNotePinned = !item.isNotePinned;

            this.residentsService.toggleResidentNotesPinStatus(item.id, item.isNotePinned)
                .then(() => {
                    this.notes = [];
                    this.loadNotes();
                    if (item.isNotePinned) {
                        this.notificationService.success('Note pinned');
                    }
                    else {
                        this.notificationService.success('Note unpinned');
                    }
                    
                }, () => {
                    if (item.isNotePinned) {
                        this.notificationService.error('Unexpected error while pinning the note.');
                    }
                    else {
                        this.notificationService.error('Unexpected error while unpinning the note.');
                    }
                });
        }
    }
}

export = NotesComponent