import moment = require("moment");
import * as _ from "underscore";
import { StatementFilterDataDto, MedicareStatementSimple } from "../Models";
import { DropdownOption } from "../../common/Models";
import { IFundingService, IDialogService} from "../Interfaces";
import { INotificationService } from "../../common/services/INotificationService";
import type { IStateService } from "angular-ui-router";

class StatementNavigatorComponent implements ng.IComponentOptions {
    public bindings: any;
    public templateUrl: string = 'app/funding/partials/statementNavigator.html';

    constructor() {
        this.bindings = {
            monthAndYear: '='
         };
    }

    public controller: any = class StatementNavigatorComponentController {
        static $inject = ["funding.service", "notification.service", "dialog.service", "$state"];

        public monthAndYear:string;
        public period:string;
        public monthYearFilters: DropdownOption[] = [];
        public selectedMonthYearFilter: DropdownOption;
        public statementFilterData : StatementFilterDataDto = new StatementFilterDataDto();
        medicareStatements: MedicareStatementSimple[];
        
         
        constructor(
            private readonly fundingService: IFundingService,
                    private readonly notificationService: INotificationService,
                    private readonly dialogService: IDialogService,
                    public readonly state: IStateService) {
                        
                this.monthAndYear = state.params.monthAndYear;         
                this.getMedicareStatementsMonthAndYear(); 
                          
        }

        getMedicareStatementsMonthAndYear() {
            this.fundingService.getMedicareStatements()
                .then(result => {
 
                    let statements = result.reduce(function (acc, current) {
                        return acc.concat(current.medicareStatements);
                    }, [] as MedicareStatementSimple[]);
                    this.setMonthYearFilters(statements);   
                    this.medicareStatements = statements.map(function (statement) {
                        statement.statementVariance = null;
                        if (statement.expectedStatementTotal != null && statement.statementTotal != null) {
                            statement.statementVariance = statement.expectedStatementTotal - statement.statementTotal;
                        }
                        return statement;
                    }); 
                    this.getMedicareStatementTotal(this.monthAndYear);                 
                 }, () => {
                    this.notificationService.error("Unable to display statements month and year. Either your computer is offline, or Resident Manager is having problems of its own.");
                });
        } 

        private getMedicareStatementTotal = (monthAndYear: string) => {
            var selectedMonth = Number(monthAndYear.substring(0,2));
            var selectedyear = Number(monthAndYear.substring(2));
            var selectedMedicareStatement = this.medicareStatements.filter(x=>x.statementMonth == selectedMonth && x.statementYear == selectedyear)[0]
            this.statementFilterData.statementTotal = selectedMedicareStatement.statementTotal;
            this.statementFilterData.expectedStatementTotal = selectedMedicareStatement.expectedStatementTotal;
            this.statementFilterData.varianceStatementTotal = selectedMedicareStatement.statementVariance;
        
        };
        public finaliseClaim = () => {
            this.fundingService.finaliseClaim(this.selectedMonthYearFilter.value)
                .then(() => {
                        this.state.reload();
                        this.notificationService.success("Statement finalised.");
                    },
                    (e) => {
                        if (!e.modelStateSummary) {
                            this.notificationService.error("Unable to finalise claim. Either your computer is offline, or Resident Manager is having problems of its own.");
                        } else {
                            this.dialogService.openMessageDialog(
                                "Error",
                                "The following errors occurred when finalising the claim:",
                                "claimFinalise-error",
                                _.values(e.modelStateSummary));
                        }
                    });
        }

        private setMonthYearFilters = (statements): void => {
            statements.forEach(statement => {
                this.monthYearFilters.push({ value: this.getFullMonthAndYear(statement.statementMonth, statement.statementYear) , 
                    displayText:  this.getMonthName(statement.statementMonth)+ " " +statement.statementYear}); 
            });
            this.selectedMonthYearFilter = this.monthYearFilters.filter(x=>x.value == this.monthAndYear)[0];
        }

        public changeFilter = () => {
            this.getMedicareStatementTotal(this.selectedMonthYearFilter.value);
            this.state.go("statementNavigator.viewExpectedStatement.summary", 
            {monthAndYear: this.selectedMonthYearFilter.value , statementLoaded: false});
        }

        getMonthName(month: number) {
            return moment().month(month - 1).format('MMMM');
        }

        getFullMonthAndYear(month: number, year: number) {
            var fullMonth = month.toString().length === 1 ? '0' + month : month;
            return fullMonth + '' + year;
        }

        public setExpectedTotalCallback = (value: any) => {
                this.statementFilterData.isClaimFinalised = value.isClaimFinalised;
                if(!value.isClaimFinalised)
                {
                    this.statementFilterData.expectedStatementTotal = value.totalAmountPaid;
                }
            }        
        }
    
        
    
}
export = StatementNavigatorComponent;