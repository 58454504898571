"use strict";

var toastr = require("toastr");

module.exports = ["$rootScope",
function ($rootScope) {

    toastr.options.closeButton = true;
    toastr.options.preventDuplicates = true;
    toastr.options.timeOut = 6000;
    toastr.options.extendedTimeOut = 9000;
    toastr.options.positionClass = "toast-bottom-right";
    toastr.options.showMethod = "slideDown";
    toastr.options.hideMethod = "fadeOut";
    toastr.options.closeMethod = "fadeOut";
    toastr.options.hideDuration = 3000;
    toastr.options.showDuration = 500;
    toastr.options.closeDuration = 0;

    var loadingMsgs = 0;

    function success(message, title) {
        toastr.success(message, title);
    }

    function error(message, title) {
        toastr.error(message, title);
    }

    function warning(message, title) {
        toastr.warning(message, title);
    }

    function startLoading() {
        loadingMsgs++;
        $rootScope.$broadcast("loadingStarts", {});
    }

    function stopLoading() {
        loadingMsgs--;
        if (loadingMsgs <= 0) {
            $rootScope.$broadcast("loadingStops", {});
        }
    }


    return {
        success: success,
        error: error,
        warning: warning,
        startLoading: startLoading,
        stopLoading: stopLoading
    };

}];