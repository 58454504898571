"use strict";
const _ = require("underscore");

module.exports = [
    "roles.service",
    "notification.service",
    "$stateParams",
    "$state",
    function (rolesService, notificationService, $stateParams, $state) {
        var vm = this;

        vm.readOnly = $state.current.name === "viewRole";
        vm.roleId = $stateParams.roleId;
        vm.title = vm.readOnly ? "View role" : vm.roleId ? "Edit role" : "New role";
        vm.roleDetails = null;

        function validatePermissionGroupState(permissionGroup) {
            _.each(["canView", "canModify", "canRemove"], function (permissionType) {
                permissionGroup[permissionType] = _.every(vm.getEditPermissions(permissionGroup.permissionDefinitions), function (permission) {
                    return permission[permissionType];
                });
            });
        }

        function getPermissions() {
            rolesService.getPermissions().then(function (result) {
                vm.roleDetails = result;
                setPermissionSelectorAvailability();
                if (vm.roleId) {
                    getRole(vm.roleId);
                }
            }, function () {
                notificationService.error("Unexpected error occurred while loading permission definitions.");
            });
        };

        function getRole(roleId) {
            rolesService.getRole(roleId).then(function (result) {
                vm.roleDetails.name = result.name;
                vm.roleDetails.isEditable = result.isEditable;
                _.each(vm.roleDetails.permissionGroupDefinitions, function (pgd) {
                    _.each(pgd.permissionDefinitions, function (pd) {
                        var permission = _.findWhere(result.activePermissions, { permissionCode: pd.permissionCode }) || {};

                        pd.canView = permission.canView;
                        pd.canModify = permission.canModify;
                        pd.canRemove = permission.canRemove;
                        pd.canAction = permission.canAction;
                    });
                    validatePermissionGroupState(pgd);
                });
            }, function () {
                notificationService.error("Unexpected error occurred while loading role.");
            });
        };

        function setPermissionSelectorAvailability(){
            _.each(vm.roleDetails.permissionGroupDefinitions, function (pgd) {
                // only show the header permission selectors if there are children that have the same selectors available
                pgd.canViewAll = _.where(pgd.permissionDefinitions, { view: "Editable" }).length > 0;
                pgd.canModifyAll = _.where(pgd.permissionDefinitions, { modify: "Editable" }).length > 0;
                pgd.canRemoveAll = _.where(pgd.permissionDefinitions, { remove: "Editable" }).length > 0;
                pgd.selectAllVisible = pgd.canViewAll || pgd.canModifyAll || pgd.canRemoveAll;
            });
        }

        vm.cancel = function () {
            $state.go("viewRoles");
        };

        vm.getEditPermissions = function (permissionDefinitions) {
            return _.where(permissionDefinitions, { action: "NotAvailable" });
        };

        vm.getActionPermissions = function (permissionDefinitions) {
            return _.where(permissionDefinitions, { action: "Editable" });
        };

        function propagateState(parent, propertyName, newState) {
            parent[propertyName] = newState;
            if (newState) {
                if (parent.canRemove) {
                    parent.canModify = true;
                    parent.canView = true;
                }
                if (parent.canModify) {
                    parent.canView = true;
                }
            } else {
                if (!parent.canView) {
                    parent.canModify = false;
                    parent.canRemove = false;
                }
                if (!parent.canModify) {
                    parent.canRemove = false;
                }
            }
        }

        vm.togglePermission = function (permissionGroup, permission, propertyName) {
            propagateState(permission, propertyName, !permission[propertyName]);
            validatePermissionGroupState(permissionGroup);
        };

        vm.togglePermissionGroup = function (permissionGroup, propertyName) {
            var newState = !permissionGroup[propertyName];
            propagateState(permissionGroup, propertyName, newState);
            _.each(vm.getEditPermissions(permissionGroup.permissionDefinitions), function (permission) {
                propagateState(permission, propertyName, newState);
            });
        };

        vm.toggleAction = function (permission, propertyName) {
            permission[propertyName] = !permission[propertyName];
        };

        function getActivePermissions() {
            var activePermissions = [];
            var checked = [
                { definition: "view", value: "canView" },
                { definition: "modify", value: "canModify" },
                { definition: "remove", value: "canRemove" },
                { definition: "action", value: "canAction" }

            ];
            _.each(vm.roleDetails.permissionGroupDefinitions, function (pgd) {
                _.each(pgd.permissionDefinitions, function (pd) {
                    var permissionSet = {};
                    var anyPropertySet = false;
                    _.each(checked, function (check) {
                        if (pd[check.definition] === "Editable" && pd[check.value]) {
                            permissionSet[check.value] = true;
                            anyPropertySet = true;
                        }
                    });
                    if (anyPropertySet) {
                        permissionSet.permissionCode = pd.permissionCode;
                        activePermissions.push(permissionSet);
                    }
                });
            });
            return activePermissions;
        }

        vm.saveRole = function (isValid) {
            if (isValid) {
                var activePermissions = getActivePermissions();
                rolesService.saveRole(
                    {
                        id: vm.roleId,
                        name: vm.roleDetails.name,
                        activePermissions: activePermissions
                    })
                    .then(function () {
                        $state.go("viewRoles");
                    },
                        function (errors) {
                            if (errors && errors.modelStateSummary && errors.modelStateSummary.name) {
                                notificationService.error("This role name already exists.");
                            } else {
                                notificationService.error("Unexpected error while saving the role.");
                            }
                        });
            }
        };


        getPermissions();

    }
];
