"use strict";
var angular = require("angular");

var module = angular.module("billing.module", ["common.module"]);
require("./states.js").configure(module);

module.controller("viewSundryChargesController", require("./controllers/ViewSundryChargesController"));
module.controller("addOrEditSundryChargeController", require("./controllers/AddOrEditSundryChargeController"));
module.controller("viewSundryChargeController", require("./controllers/ViewSundryChargeController"));
module.service("billingService", require("./services/BillingService")); 
module.controller("selectResidentSundryCharge.controller", require("./controllers/SelectResidentSundryChargeController"));