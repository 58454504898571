function tablePopoverDirective(): ng.IDirective {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            options: "=",
            data: "=",
        },
        controller: "tablePopover.controller",
        controllerAs: "vm",
        link: function(scope, element, attrs, ctrl) {
            scope.$watchCollection('data', function(val) { // watch for data change
                ctrl.updateData(scope.data); // pass updated data to controller 
           });
        }  
    };
}

export = tablePopoverDirective;