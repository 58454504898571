import * as _ from "underscore";
import moment = require('moment');
import localStorage = require("angular-local-storage");
import { SearchResult } from '../../residents/Models';
import { AuthenticatedUser } from '../../authentication/Models';
import { IResidentsService } from '../../residents/services/IResidentsService';
import { INotificationService } from '../../common/services/INotificationService';
import { IReferenceDataService } from '../../common/services/IReferenceDataService';
import { SundryCharge, Resident } from '../Models';
import BillingService = require('../services/BillingService');
import { IDialogService } from '../../common/services/IDialogService';
import { IOrganisationFacilityService } from '../../organisation/services/IOrganisationFacilityService';
import { SimpleGLAccountMapping } from '../../organisation/Models';
import type { IStateService, IStateParamsService } from "angular-ui-router";
import { ILocalStorageServiceProvider, ILocalStorageService } from "angular-local-storage";

class AddOrEditSundryChargeController {
    static $inject = ["residents.service", "notification.service", "billingService", "$state", "$stateParams",
        "referenceData.service", "dialog.service", "localStorageService", "localStorageService", "organisation.facility.service"];

    public sundryCharge: SundryCharge;
    public searchResultObject: string;
    public descriptionSuggestions: string[];
    public saveSundryChargeForm: ng.IFormController;
    public subtotal: number;
    public perResident: number;
    public gst: number;
    public totalAmount: number;
    public totalFraction: number;
    public totalDecimal: string;
    public sundryChargeMappings: Array<SimpleGLAccountMapping>;

    public gstTypes = ["Free", "GST"]

    private sundryChargeId = 0;
    constructor(
        private residentService: IResidentsService,
        private readonly notificationService: INotificationService,
        private billingService: BillingService,
        private readonly state: IStateService,
        private stateParams: IStateParamsService,
        private referenceDataService: IReferenceDataService,
        private dialogService: IDialogService,
        private localStorageServiceProvider: ILocalStorageServiceProvider,
        private localStorageService: ILocalStorageService,
        private organisationFacilityService: IOrganisationFacilityService) {

        this.sundryChargeId = stateParams.sundryChargeId;
        this.localStorageServiceProvider.setPrefix("");
        this.initialize();
    }

    public initialize = () => {
        this.searchResultObject = "results";
        this.organisationFacilityService.getSundryChargesGLAccountMappings().then(response => {
            this.sundryChargeMappings = response;
            if(this.stateParams.sundryCharge) {
                this.initializeSundryCharge(this.stateParams.sundryCharge);
            }
            else if(this.sundryChargeId != 0) {
                this.getSundryCharge();
            }
            else {
                this.sundryCharge = <SundryCharge> {
                    residents: <Resident[]>[],
                    draft: true
                };

                this.referenceDataService.getGstRate().then(response => {
                    this.sundryCharge.gstRate = response;
                }, () => {
                    this.notificationService.error("Unexpected error while getting the gst rate.");
                });
        
                this.subtotal = 0;
                this.gst = 0;
                this.totalAmount = 0;
                this.totalFraction = 0;
                this.totalDecimal = "00";
                
                this.addResident();
            }
        }, () => {
            this.notificationService.error("Unexpected error while getting the sundry account.");
        });
    }

    public getSundryCharge = () => {
        this.billingService.getSundryCharge(this.sundryChargeId)
            .then(result => {
                result.sundryAccountId = result.residents[0].sundryAccountId;
                this.initializeSundryCharge(result);
            }, () => {
                this.notificationService.error("Unexpected error while loading sundry charge details.");
            });
    }

    private initializeSundryCharge(sundryCharge: SundryCharge) {
        this.sundryCharge = sundryCharge;

        if (!this.sundryCharge.splitBillEvenly) {
            this.setResidentInitialValue();
        }
        this.updateAmounts();
    }

    public residentSearch = (searchString, httpCancelerPromise) => {
        return this.residentService.searchCurrentAndDepartedResidents(searchString, httpCancelerPromise)
            .then(result => {
                return { results: result }
            });
    };

    public onSelected = (index, item) => {
        let resident = this.sundryCharge.residents[index];
        if (item) {
            resident.residentId = item.id;
            resident.residencyId = item.residencyId;
            resident.firstName = item.firstName;
            resident.lastName = item.lastName;
        }
        else {
            resident.residentId = null;
            resident.residencyId = null;
            resident.firstName = null;
            resident.lastName = null;
        }
    }

    public save = (isFormValid: boolean) => {
        if (!this.validateGrid() || !isFormValid)
            return;

        this.populateResidentsWithGlobalSundryValues();

        this.billingService.saveSundryCharge(this.sundryCharge).then((result) => {
            this.state.go("viewSundryCharges");
        },
        () => {
            this.notificationService.error("Unexpected error while saving the sundry charge.");
        });
    };

    public addResident = () => {
        this.sundryCharge.residents.push(<Resident>{ gstType: "GST", description: this.sundryCharge.description });
    };

    public removeResident = (index) => {
        if (this.sundryCharge.residents.length === 1) {
            return;
        }

        this.sundryCharge.residents.splice(index, 1);
        this.updateAmounts();
    }

    public updateDescription = () => {
        _.forEach(this.sundryCharge.residents, (resident) => {
            if (!resident.description || resident.description === '') {
                resident.description = this.sundryCharge.description;
            }
        });
    }

    public updateAmounts = () => {
        this.subtotal = 0;
        this.gst = 0;

        if (this.sundryCharge.total && this.sundryCharge.splitBillEvenly) {
            this.subtotal = this.sundryCharge.total;
        }
        else {
            this.subtotal = _.chain(this.sundryCharge.residents)
                .map((obj) => { return Number(obj.amount); })
                .flatten()
                .reduce((memo, amnt) => { return memo + this.getAmount(amnt); }, 0)
                .value();
        }

        if (this.sundryCharge.gstInclusive || this.sundryCharge.splitBillEvenly) {
            this.totalAmount = this.subtotal;
        }
        else {
            this.gst = _.chain(this.sundryCharge.residents)
                .map((obj) => {
                    if (obj.gstType == "Free") {
                        return Number(0);
                    }
                    else {
                        return Number(obj.amount * this.sundryCharge.gstRate / 100);
                    }
                })
                .flatten()
                .reduce((memo, amnt) => {
                    return memo + this.getAmount(amnt);
                }, 0)
                .value();

            this.totalAmount = this.subtotal + this.gst;
        }

        this.totalFraction = this.getTotalFraction();
        this.totalDecimal = this.getTotalDecimal();
        this.perResident = this.getPerResident();
    }

    private getAmount = (amnt: number) => {
        if (!amnt || amnt === NaN) {
            return 0;
        }

        return amnt;
    }

    public getTotalFraction = (): number => {
        if (!this.sundryCharge && !this.totalAmount && this.totalAmount === NaN) {
            return 0;
        }

        return parseInt(this.totalAmount.toString());
    };

    public getTotalDecimal(): string {
        if (!this.sundryCharge && !this.totalAmount && this.totalAmount === NaN) {
            return "00";
        }

        var numParts = this.totalAmount.toString().split(".");
        if (numParts.length <= 1) {
            return "00";
        }
        if (numParts[1].length == 1) {
            return numParts[1] + "0";
        }
        return numParts[1];
    }

    public getPerResident = (): number => {
        if (this.sundryCharge.residents.length > 0) {
            return this.totalAmount / this.sundryCharge.residents.length;
        }
        else
            return 0;
    }

    public populateResidentsWithGlobalSundryValues = () => {
        if (this.sundryCharge.gstInclusive) {
            _.forEach(this.sundryCharge.residents, (resident) => {
                resident.gstType = "GST";
            });
        }
        if (this.sundryCharge.splitBillEvenly) {
            _.forEach(this.sundryCharge.residents, (resident) => {
                resident.description = this.sundryCharge.description;
                resident.sundryAccountId = this.sundryCharge.sundryAccountId;
            });
        }
    }

    public validateGrid = () => {
        let validationSuccess = true;
        if (this.sundryCharge.splitBillEvenly) {
            return validationSuccess;
        }
        for (let i = 0; i < this.sundryCharge.residents.length; i++) {
            let sundryResident = this.sundryCharge.residents[i];
            if (!sundryResident.residencyId || sundryResident.residencyId === 0) {
                if (this.saveSundryChargeForm["resident" + i] != null) {
                    this.saveSundryChargeForm["resident" + i].$setValidity('resident', false)
                }
                validationSuccess = false;
            }
            else {
                if (this.saveSundryChargeForm["resident" + i] != null) {
                    this.saveSundryChargeForm["resident" + i].$setValidity('resident', true)
                }
            }
        }

        return validationSuccess;
    }

    public navigateToView() {
        if (this.sundryChargeId != 0) {
            this.state.go("viewSundryCharge", { sundryChargeId: this.sundryChargeId });
        }
        else {
            this.state.go("viewSundryCharges");
        }
    }

    public navigateToReview = (isFormValid: boolean) => {
        this.saveSundryChargeForm.$setSubmitted();
        if (!this.validateGrid() || !isFormValid) {
            return;
        }

        this.populateResidentsWithGlobalSundryValues();

        this.sundryCharge.isDirty = this.saveSundryChargeForm.$dirty;
        this.saveSundryChargeForm.$setPristine();
        this.sundryCharge.total = this.totalAmount;
        this.sundryCharge.modifiedOnUtc = moment().startOf('day').toDate();
        let user = <AuthenticatedUser>this.localStorageService.get("user");
        this.sundryCharge.modifiedByName = user.name;
        _.each(this.sundryCharge.residents, (resident) => {
            resident.sundryAccountDescription = _.find(this.sundryChargeMappings, (mapping) => {
                return mapping.id === resident.sundryAccountId;
            }).otherInfo;
        });
        this.state.go("reviewSundryCharge", {
            sundryChargeId: this.sundryChargeId,
            sundryCharge: this.sundryCharge
        });
    }

    public deleteSundryCharge = function () {
        this.dialogService.openActionDialog("Delete Charge?",
            "Are you sure you want to delete this charge?",
            "sundry-delete",
            "Delete",
            () => {
                this.billingService.deleteSundryCharge(this.sundryCharge.id)
                    .then(
                    () => {
                        this.state.go("viewSundryCharges");
                    },
                    () => {
                        this.notificationService.error("Unexpected error while saving the sundry charge.");
                    });
            },
            "btn-danger btn-inline-block-xsm");
    }

    private selectedResidentsCallback = (selectResidents: Resident[]) =>{
        this.sundryCharge.residents = selectResidents;
        this.updateAmounts();
    }

    public clearSundryResidentsSelected = (isChecked) => {
        if (isChecked) {
            this.sundryCharge.residents = _.filter(this.sundryCharge.residents, (resident) => {
                return resident.residencyId != null;
            });
        }
        else {
            this.setResidentInitialValue();
            this.updateAmounts();
        }
    }

    public setResidentInitialValue = () => {
        this.sundryCharge.residents.forEach((resident) => {
            resident.initialValue = <SearchResult>{
                id: resident.residentId,
                residencyId: resident.residencyId,
                firstName: resident.firstName,
                lastName: resident.lastName
            }
        });
    }
    public selectResident = () => {
        var modalConfig = {
            data: this.sundryCharge.residents,
            callback: this.selectedResidentsCallback
        }
        this.dialogService.openDialog("app/billing/partials/selectResidentSundryChargeDialog.html",
            "selectResidentSundryCharge.controller", modalConfig);
    }
}

export = AddOrEditSundryChargeController;