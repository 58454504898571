import { FinancialSummaryAndUnitCostsDto, IngoingContributionRate, DeferredManagementFeeDto } from "../Models";
import _ = require("underscore");
import { IIndependentLivingService } from "../services/IIndependentLivingService";

class ContractDetailComponent implements ng.IComponentOptions {

  public bindings: any = {
    contractData: "=",
    dmfData: "="
  };

  public templateUrl: string = 'app/independentLiving/partials/contractDetail.html';

  public controller: any = class ContractDetailComponent {
    static $inject = ["EffectiveDateTypes", "independentLiving.service", "$rootScope"];

    public contractData: FinancialSummaryAndUnitCostsDto;
    public dmfData: DeferredManagementFeeDto;
    public ingoingContributionList: IngoingContributionRate[];
    public ingoingContributionOptions: any;
    public dMFDataExist: boolean;
    public isBillingIntegratedFacility: boolean;

    constructor(public readonly EffectiveDateTypes: any, private readonly iluService: IIndependentLivingService,
      private readonly $rootScope: any) {

      this.isBillingIntegratedFacility = this.$rootScope.isBillingIntegratedFacility;
      if (!this.isBillingIntegratedFacility) {
        this.fillIngoingContributionList();
      }
      this.ingoingContributionEffectiveDatePopoverConfig();
      this.checkDmfDataExist();
    }

    public fillIngoingContributionList() {
      this.ingoingContributionList = new Array<IngoingContributionRate>();
      let index = 1;
      var totalValue = 0;

      this.contractData.effectiveDates = _.sortBy(this.contractData.effectiveDates, "effectiveFromDate").reverse();
      this.contractData.effectiveDates.forEach(effectiveDate => {
        this.ingoingContributionList.push({ id: index, startDate: effectiveDate.effectiveFromDate, endDate: new Date(), value: Number(effectiveDate.value) });
        ++index;
        totalValue = totalValue + Number(effectiveDate.value);
      });
      this.contractData.ingoingContribution = totalValue;
    }

    public checkDmfDataExist = () => {
      if (this.dmfData.minimumRate || this.dmfData.deferredManagementFeesSchedule.length > 0) {
        this.dMFDataExist = true;
      }
    }

    public getTotalDMFPercentageForTheTimePeriod(index) {
      return this.iluService.getTotalDMFPercentageForTheTimePeriod(index, this.dmfData.deferredManagementFeesSchedule);
    }

    public ingoingContributionEffectiveDatePopoverConfig = () => {
      this.ingoingContributionOptions = {
        id: "ingoingcontribution-link",
        target: 'a#ingoingcontribution-link',
        autoClose: true,
        popoverCssClass: 'fee-effective-popover',
        columns: [{
          headerText: 'AMOUNT',
          headerCssClass: 'support-col',
          dataFieldName: 'value',
          cellCssClass: 'support-col',
          filterName: 'currency'
        }, {
          headerText: 'PAYMENT DATE',
          headerCssClass: 'effective-date-col',
          dataFieldName: 'startDate',
          cellCssClass: 'effective-date-col',
          filterName: 'date',
          format: 'dd MMM yyyy'
        }]

      };
    }


  }
}

export = ContractDetailComponent;