"use strict";
module.exports = {
	configure: function (module) {
		module.config([
			"$stateProvider",
			function ($stateProvider) {
				$stateProvider.state("viewMedicareEvents", {
					url: "/funding/medicare-events",
					templateUrl: "app/funding/partials/viewMedicareEvents.html",
					controller: "viewMedicareEvents.controller as vm",
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.fundingMedicareEvents.canView;
								});
							}
						]
					},
					onEnter: ["$rootScope", function($rootScope) {
						$rootScope.hideFooter = true;
					}]
				});

				$stateProvider.state("viewMedicareStatements", {
					url: "/funding/medicare-statements",
					templateUrl: "app/funding/partials/viewMedicareStatements.html",
					controller: "viewMedicareStatements.controller as vm",
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.fundingReviewFundingStatements.canAction;
								});
							}
						]
					}
				});

				$stateProvider.state("viewFundingRates", {
					url: "/funding/view-funding-rates",
					templateUrl: "app/funding/partials/viewFundingRates.html",
					controller: "viewFundingRates.controller as vm",
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.fundingScheduleOfCareRates.canView;
								});
							}
						]
					}
				});

				$stateProvider.state("viewAccommodationFundingRates", {
					url: "/funding/view-accommodation-rates",
					templateUrl: "app/funding/partials/viewAccommodationFundingRates.html",
					controller: "AccommodationFundingRates.controller as vm",
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.fundingScheduleOfAccommodationRates.canView;
								});
							}
						]
					}
				});

				$stateProvider.state("viewFeesAndCharges", {
					url: "/funding/view-fees-and-charges",
					templateUrl: "app/funding/partials/viewFeesAndCharges.html",
					controller: "FeesAndCharges.controller as vm",
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.feesScheduleOfFeesAndCharges.canView;
								});
							}
						]
					}
				});

				// start state definition for statementNavigator
				$stateProvider.state("statementNavigator", {
					url: "/statementNavigator",
					template: "<statement-navigator month-and-year='monthAndYear'></statement-navigator>",
					controller: [
						"$stateParams",
						"$scope",
						function ($stateParams, $scope) {
							$scope.monthAndYear = $stateParams.monthAndYear;
						}
					],
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.fundingReviewFundingStatements.canAction;
								});
							}
						]
					}
				});
				// state definitions for statementNavigator.viewExpectedStatement and childs
				$stateProvider.state("statementNavigator.viewExpectedStatement", {
					url: "/view-expected-monthly-statement/{monthAndYear:string}/",
					template: "<view-expected-monthly-statement month-and-year='monthAndYear' statement='statement' statementLoaded='statementLoaded' set-expected-total-callback='$ctrl.setExpectedTotalCallback({totalAmountPaid: totalAmountPaid, isClaimFinalised: isClaimFinalised})'></view-expected-monthly-statement>",
					controller: [
						"$stateParams",
						"$scope",
						function ($stateParams, $scope) {
							$scope.monthAndYear = $stateParams.monthAndYear;
							$scope.statement = $stateParams.statement;
							$scope.statementLoaded = $stateParams.statementLoaded;
						}
					],
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.fundingReviewFundingStatements.canAction;
								});
							}
						]
					}
				});

				$stateProvider.state("statementNavigator.viewExpectedStatement.summary", {
					url: "summary",
					templateUrl: "app/funding/partials/viewExpectedStatementSummary.html",
					controller: "viewExpectedStatementSummary.controller as vm",
					params: { statement: null }
				});

				$stateProvider.state("statementNavigator.viewExpectedStatement.residents", {
					url: "residents",
					templateUrl: "app/funding/partials/viewExpectedStatementResidents.html",
					controller: "viewExpectedStatementResidents.controller as vm",
					params: { statement: null }
				});

				$stateProvider.state("statementNavigator.viewExpectedStatement.payments", {
					url: "payments",
					templateUrl: "app/funding/partials/viewExpectedStatementPayment.html",
					controller: "viewExpectedStatementPayment.controller as vm",
					params: { statement: null },
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.fundingReviewFundingStatements.canAction;
								});
							}
						]
					}
				});

				$stateProvider.state("statementNavigator.viewExpectedStatement.supported-ratio", {
					url: "supported-ratio",
					templateUrl: "app/funding/partials/viewExpectedStatementSupportedRatio.html",
					controller: "viewExpectedStatementSupportedRatio.controller as vm",
					params: { statement: null }
				});

				$stateProvider.state("statementNavigator.viewExpectedStatement.daily-subsidies", {
					url: "daily-subsidies",
					templateUrl: "app/funding/partials/viewExpectedStatementDailySubsidies.html",
					controller: "viewExpectedStatementDailySubsidies.controller as vm",
					params: { statement: null }
				});
				// end of state definitions for statementNavigator.viewExpectedStatement and childs

				// state definitions for statementNavigator.viewStatement and childs
				$stateProvider.state("statementNavigator.viewStatement", {
					url: "/viewStatement/{monthAndYear:string}/",
					templateUrl: "app/funding/partials/viewMonthlyStatement.html",
					controller: "viewMonthlyStatement.controller as vm",
					params: { statement: null },
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.fundingReviewFundingStatements.canAction;
								});
							}
						]
					}
				});
				$stateProvider.state("statementNavigator.viewStatement.summary", {
					url: "summary",
					templateUrl: "app/funding/partials/viewStatementSummary.html",
					controller: "viewStatementSummary.controller as vm",
					params: { statement: null },
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.fundingReviewFundingStatements.canAction;
								});
							}
						]
					}
				});

				$stateProvider.state("statementNavigator.viewStatement.residents", {
					url: "residents",
					templateUrl: "app/funding/partials/viewStatementResidents.html",
					controller: "viewStatementResidents.controller as vm",
					params: { statement: null },
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.fundingReviewFundingStatements.canAction;
								});
							}
						]
					}
				});

				$stateProvider.state("statementNavigator.viewStatement.payments", {
					url: "payments",
					template: "<actual-statement-payment month-and-year='monthAndYear' statement='statement'></actual-statement-payment>",
					controller: [
						"$stateParams",
						"$scope",
						function ($stateParams, $scope) {
							$scope.monthAndYear = $stateParams.monthAndYear;
							$scope.statement = $stateParams.statement;
						}
					],
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.fundingReviewFundingStatements.canAction;
								});
							}
						]
					}
				});

				$stateProvider.state("statementNavigator.viewStatement.supported-ratio", {
					url: "supported-ratio",
					templateUrl: "app/funding/partials/viewStatementSupportedRatio.html",
					controller: "viewStatementSupportedRatio.controller as vm",
					params: { statement: null },
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.fundingReviewFundingStatements.canAction;
								});
							}
						]
					}
				});

				$stateProvider.state("statementNavigator.viewStatement.daily-subsidies", {
					url: "daily-subsidies",
					templateUrl: "app/funding/partials/viewStatementDailySubsidies.html",
					controller: "viewStatementDailySubsidies.controller as vm",
					params: { statement: null },
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.fundingReviewFundingStatements.canAction;
								});
							}
						]
					}
				});
				$stateProvider.state("statementNavigator.viewStatement.provider-comments", {
					url: "provider-comments",
					templateUrl: "app/funding/partials/viewStatementProviderComments.html",
					controller: "viewStatementProviderComments.controller as vm",
					params: { statement: null },
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.fundingReviewFundingStatements.canAction;
								});
							}
						]
					}
				});
				// end of state definitions for statementNavigator.viewStatement and childs

				// state definitions for statementNavigator.varianceStatement and childs
				$stateProvider.state("statementNavigator.varianceStatement", {
					url: "/variance-statement/{monthAndYear:string}/",
					template: "<variance-statement month-and-year='monthAndYear'></variance-statement>",
					controller: [
						"$stateParams",
						"$scope",
						function ($stateParams, $scope) {
							$scope.monthAndYear = $stateParams.monthAndYear;
						}
					],
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.fundingReviewFundingStatements.canAction;
								});
							}
						]
					}
				});

				$stateProvider.state("statementNavigator.varianceStatement.summary", {
					url: "summary",
					template: "<variance-statement-summary month-and-year='monthAndYear'></variance-statement-summary>",
					controller: [
						"$stateParams",
						"$scope",
						function ($stateParams, $scope) {
							$scope.monthAndYear = $stateParams.monthAndYear;
						}
					]
					,
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.fundingReconciliation.canAction;
								});
							}
						]
					}
				});

				$stateProvider.state("statementNavigator.varianceStatement.payments", {
					url: "payments",
					template: "<variance-statement-payment month-and-year='monthAndYear'></variance-statement-payment>",
					controller: [
						"$stateParams",
						"$scope",
						function ($stateParams, $scope) {
							$scope.monthAndYear = $stateParams.monthAndYear;
						}
					]
					,
					resolve: {
						authorized: [
							"authentication.service",
							function (auth) {
								return auth.userHasPermissionDeferred(function (x) {
									return x.fundingReconciliation.canAction;
								});
							}
						]
					}
				});
				// end of state definitions for statementNavigator.varianceStatement and childs
				// end of statementNavigator states
			}
		]);
	}
};
