import { IluStatus } from "../organisation/Models";

export class MovementsReportFilter {
    dateRange: DateRange;
    toDate: Date;
    fromDate: Date;
    careType: CareType;
    movementStatus: MovementStatus;
}

export class EnquirySourceReportFilter {
    dateRange: DateRange;
    toDate: Date;
    fromDate: Date;
    careType: CareType;
    admissionsOnly: boolean;
    facilityId: number;
    facilityName: string;
    activeUserFacilitiesId: number[];
}

export class BillingExtractFilter {
    toDate: Date;
    fromDate: Date;
    careType: string;
    facilityId: number;
}

export class FinancialDetailsReportFilter {
    facilityId: number;
    supportStatus: string;
}

export class PalliativeAssessmentsReportFilter {
    facilityId: number;
}

export class PotentialReassessmentsReportFilter {
    facilityId: number;
    reasonId: number;
}

export class SettlementsReportFilter {
    facilityId: number;
    unSettledOnly: boolean;
}

export class ClassificationHistoryReportFilter {
    toDate: Date;
    fromDate: Date;
    careType: string;
    facilityId: number;
}

export class ReassessmentRequestsReportFilter {
    toDate: Date | null;
    fromDate: Date | null;
    status: string;
    facilityId: number;
}

export enum DateRange {
    MonthToDate = "Month", //Numeric values such as 0, 1, 2 do not handle default value to get selected automatically on page load. Value="0" does not select the checkbox by default.
    YearToDate = "Year",
    ChooseDates = "Custom"
}

export enum CareType {
    Permanent = "Permanent",
    Respite = "Respite",
    Both = "Both",
    ILU = "ILU",
    All = "All"
}

export enum MovementStatus {
    Admissions = "Admissions",
    Departures = "Departures",
    Both = "Both"
}

export enum FacilityType {
    AgeCare = 1,
    IndependentLiving = 2
}

export enum SupportStatusEnum {
    Supported = 1,
    Unsupported = 2,
    Bond = 3,
    Charge = 4,
    Respite = 5,
    Unknown = 6
}

export enum ExternalSystemType {

    Clinical = 1,
    Techone = 2,
    Attache = 3
}

export class SupportStatusItem {
    id: number;
    label: string;
}

export class RoomsReportFilter {
    facilityLocations: String;
    roomTypes: String;
    roomStatus: String;
    supportStatus: String;
}
export class UnitsReportFilter {
    facilityLocations: number[];
    unitTypes: number[];
    unitStatus: IluStatus[];
}

export class RoomStatus {
    id: Number;
    name: String;
    checked: Boolean;

    constructor(id: Number, name: String, checked: Boolean) {
        this.id = id;
        this.name = name;
        this.checked = checked;
    }
}

export class SupportStatus {
    id: Number;
    name: String;
    checked: Boolean;

    constructor(id: Number, name: String, checked: Boolean) {
        this.id = id;
        this.name = name;
        this.checked = checked;
    }
}

export class AccommodationType {
    id: Number;
    name: String;
    checked: Boolean;

    constructor(id: Number, name: String, checked: Boolean) {
        this.id = id;
        this.name = name;
        this.checked = checked;
    }
}

export class FacilityLocation {
    id: Number;
    name: String;
    checked: Boolean;
    accommodationTypes: AccommodationType[];

    constructor(id: Number, name: String, checked: Boolean) {
        this.id = id;
        this.name = name;
        this.checked = checked;
    }
}