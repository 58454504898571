module.exports = [
    "data.service", function (dataService) {
        
        function getDashboardInfo() {
            return dataService.get("dashboard");
        }
        function getIluDashboardInfo() {
            return dataService.get("ilu-dashboard");
        }

        return {
            getDashboardInfo: getDashboardInfo,
            getIluDashboardInfo: getIluDashboardInfo
        }
    }
];