"use strict";

module.exports = function () {
    return function(input) {
        if (input) {
            return 'Yes';
        }
        return 'No';
    }
}
