"use strict";

module.exports = ["$provide", function ($provide) {
    $provide.decorator('$state', ["$delegate", "$rootScope", function ($delegate, $rootScope) {
        $rootScope.$on('$stateChangeStart', function (event, state, params) {
            $delegate.next = state;
            $delegate.toParams = params;
        });
        return $delegate;
    }]);
}];

