import * as _ from 'underscore';
import { FacilityDto } from "../../authentication/Models";
import { IFacilityService } from "../../authentication/services/IFacilityService";
import { INotificationService } from "../../common/services/INotificationService";
import { IDateFormattingService } from "../../common/services/IDateFormattingService";
import { IReportingService } from "../services/IReportingService";
import { SettlementsReportFilter} from "../Models";
import { IDownloadFileService } from '../../common/services/IDownloadFileService';
import { IInfiniteLoaderFactory, IInfiniteLoaderService } from '../../common/services/IInfiniteLoaderService';

class SettlementsReportComponent implements ng.IComponentOptions {
    public bindings: any = {};

    public templateUrl: string = 'app/reporting/partials/settlementsReport.html';

    public controller: any = class SettlementsReportController {
        static $inject = ["facility.service", "notification.service", "reporting.service", "downloadFile.service", "infiniteLoader.service"];

        infiniteLoader: IInfiniteLoaderService;
        facilities: FacilityDto[];
        selectedFacility: FacilityDto;
        unSettledOnly: boolean;
        reportFilters: SettlementsReportFilter;
        totalCount: number;

        settlementsReportData: any[];

        constructor(private readonly facilityService: IFacilityService,
            private readonly notificationService: INotificationService,
            private readonly reportingService: IReportingService,
            private readonly downloadFileService : IDownloadFileService,
            private readonly infiniteLoaderFactory: IInfiniteLoaderFactory){

            this.initialiseFilters();
            this.loadReportData();
        }   
        
        private initialiseFilters = () => {
            this.reportFilters = {
                facilityId: 0,
                unSettledOnly: false
            };

            this.facilities = this.facilityService.activeUserFacilities.filter(x => x.facilityType == "IndependentLiving");     
            if (this.facilities.length == 1) {
                this.selectedFacility = this.facilities[0];
            }
        }

        private loadReportData = () => {
            this.settlementsReportData = [];
            this.totalCount = 0;
            this.infiniteLoader = this.infiniteLoaderFactory.instance(this.loadSettlements,
                (result, totalCount) => {                    
                    this.settlementsReportData = this.settlementsReportData.concat(result);
                    this.totalCount = totalCount;
                },
                () => {
                    console.log('Unexpected error occurred while loading report data.');
                });
        }

        private loadSettlements = (notIncludingItemsAfter: string, skip: number, take: number) => {
            return this.reportingService.getSettlementsReportData(notIncludingItemsAfter, skip, take, this.reportFilters);
        }

        public updateFilters = () => {

            if (this.selectedFacility != null) {
                this.reportFilters.facilityId = this.selectedFacility.id;
            }
            else {
                this.reportFilters.facilityId = 0;
            }

            this.reportFilters.unSettledOnly = this.unSettledOnly;
            this.loadReportData();
        }

        public exportReport = () : void => {
            this.reportingService.getSettlementsReportExcelStream(this.reportFilters).then((response) => {
                this.downloadFile(response, 'SettlementsReport.xlsx');
            },
            () => {
                this.notificationService.error("Error occurred while exporting settlements report");
            }); 
        }

        private downloadFile = (response: ArrayBuffer, fileName: string) => {
            this.downloadFileService.downloadFile(response, fileName);
        }
    }
}

export = SettlementsReportComponent;