import * as angular from "angular";
import { DropdownOption } from "../../common/Models";
import { FacilityDto } from "../../authentication/Models";
import { IFacilityService } from "../../authentication/services/IFacilityService";
import { INotificationService } from "../../common/services/INotificationService";
import { IReportingService } from "../services/IReportingService";
import { ReassessmentRequestsReportFilter } from "../Models";
import { IDownloadFileService } from '../../common/services/IDownloadFileService';

class ReassessmentRequestsReportComponent implements ng.IComponentOptions {
    public bindings: any = {};

    public templateUrl: string = "app/reporting/partials/reassessmentRequestsReport.html";

    public controller: any = class ClassificationHistoryReportController {
        static $inject = ["facility.service", "notification.service", "reporting.service", "downloadFile.service", "$filter"];

        public stati: DropdownOption[];
        public selectedStatus: DropdownOption;
        public facilities: FacilityDto[];
        public selectedFacility: FacilityDto;
        public fromDate: Date | null;
        public toDate: Date | null;

        constructor(
            facilityService: IFacilityService,
            private readonly notificationService: INotificationService,
            private readonly reportingService: IReportingService,
            private readonly downloadFileService: IDownloadFileService,
        ) {
            this.setStati();
            this.selectedStatus = this.stati[1];
            this.facilities = facilityService.activeUserFacilities.filter((x) => x.facilityType == "AgeCare");
            if (this.facilities.length == 1) this.selectedFacility = this.facilities[0];
        }

        private setStati = (): void => {
            this.stati = [
                { value: "0", displayText: "All" },
                { value: "1", displayText: "Pending" },
                { value: "2", displayText: "Complete" },
                { value: "3", displayText: "Recalled" }
            ];
        };

        public export = (): void => {
            let filter = new ReassessmentRequestsReportFilter();
            filter.fromDate = this.fromDate;
            filter.toDate = this.toDate;
            filter.status = this.selectedStatus.displayText;
            filter.facilityId = this.selectedFacility == null ? 0 : this.selectedFacility.id;

            this.reportingService.getReassessmentRequestsReportExcelStream(filter).then(
                (response) => {
                    this.downloadFile(response, "ReassessmentRequests.xlsx");
                },
                () => {
                    this.notificationService.error("Error occurred while exporting reassessment requests");
                }
            );
        };

        private downloadFile = (response: ArrayBuffer, fileName: string) => {
            this.downloadFileService.downloadFile(response, fileName);
        }
    };
}

export = ReassessmentRequestsReportComponent;
