"use strict";

module.exports = [
    "leads.service",
    "$stateParams",
    "notification.service",
    "featureFlags.service",
    "$state",
    "$scope",
    "$rootScope",
    function (leadsService, $stateParams, notification, featureFlagsService, $state, $scope, $rootScope) {

        var vm = this;
        var residentId = $stateParams.residentId;
        vm.currentStateName = $state.current.name;
        vm.enablePrivateResidents = featureFlagsService.featureFlags.systemConfigurationFlags.enablePrivateResidents;
        vm.existingPrivateResidencyFlag = false;
        // register watching state
        var unbindWatchHandler = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            vm.currentStateName = toState.name;
        });

        // unregister watching state
        $scope.$on('$destroy', function () {
            if (unbindWatchHandler) {
                unbindWatchHandler();
            }
        });

        vm.financialsTabIsActive = function () {
            switch (vm.currentStateName) {
                case "editLead.financialsFunding":
                case "editLead.financialsFees":
                case "viewLead.financialsFees":
                case "viewLead.financialsFunding":
                    return true;
                default:
                    return false;
            }
        };

        function getLead(residentId) {
            leadsService.getLead(residentId).then(function (resident) {
                vm.resident = resident;
                vm.resident.isLead = true;
                vm.existingCareType = vm.resident.careType;
              if(vm.enablePrivateResidents) 
              {
                  vm.existingPrivateResidencyFlag = vm.resident.isPrivateResidency;
              }
            }, function () {
                notification.error("Unable to edit record. Either your computer is offline, or Resident Manager is having problems of its own.");
            });
        }

        vm.cancel = function () {
            $state.go("viewLead", { "residentId": vm.resident.id });
        };

        vm.save = function (isvalid) {
            if (isvalid) {
                if(vm.existingCareType != vm.resident.careType)
                {
                    vm.resident.isCareTypeChange = true;
                }
                if(vm.enablePrivateResidents && vm.existingPrivateResidencyFlag!= vm.resident.isPrivateResidency)
                {
                    vm.resident.isPrivateResidencyChange = true;
                }
                vm.resident.medicareExpiryYear = parseInt(vm.resident.medicareExpiryYear);
                leadsService.updateLead({ resident: vm.resident }).then(function () {
                    notification.success("Saved successfully.");
                    vm.cancel();
                }, function (errors) {
                    if (errors && errors.modelStateSummary && errors.modelStateSummary.debtor) {
                        notification.error("Debtor ID already exists.");
                    } else {
                        notification.error("Unable to save changes. Either your computer is offline, or Resident Manager is having problems of its own.");
                    }                     
                });
            }
        };

        getLead(residentId);

        vm.refreshCallback = function () { getLead(residentId); };
    }
];
