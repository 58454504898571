import { IFacilityService } from "../../authentication/services/IFacilityService";
import { INotificationService } from "../../common/services/INotificationService";
import { FacilityDto } from "../../authentication/Models";
import { ClinicalIntegrationDto } from "../Models";
import _ = require("underscore");
import moment = require("moment");
import { DropdownOption } from "../../common/Models";
import { IFundingService } from "../../funding/Interfaces";
import { MedicareStatementMonthYear } from "../../funding/Models";
import { IDownloadFileService } from "../../common/services/IDownloadFileService";
import { IAppSettings } from '../../common/configs/IAppSettings';

class DownloadActualStatementComponent implements ng.IComponentOptions {

    public templateUrl: string = 'app/customerCare/partials/downloadUploadActualStatement.html';

    public controller: any = class DownloadActualStatementComponentController {
        static $inject = ["facility.service", "notification.service", 
        "funding.service", "downloadFile.service", "data.service", "appSettings", "Upload"];

        public facilities: FacilityDto[];
        public selectedFacility: FacilityDto;
        public monthYearFilters: DropdownOption[] = [];
        public selectedMonthYearFilter: DropdownOption;
        public clinicalIntegrationItems: ClinicalIntegrationDto[];
        public uploadedFileName: string;

        constructor(private readonly facilityService: IFacilityService,
            private readonly notificationService: INotificationService,
            private readonly fundingService: IFundingService,
            private readonly downloadFileService : IDownloadFileService,
            private readonly dataService: any,
            private readonly appSettings: IAppSettings,
            private readonly upload: any) {
            this.fetchAndSetFacilities();
            this.changeFacility();
        }

        public getMedicareStatementsMonthAndYear = (facilityId : number): void => {
            this.fundingService.getFinalisedMonths(facilityId)
                .then(result => {
 
                    let statements = result.reduce(function (acc, current) {
                        return acc.concat(current.statementMonths);
                    }, [] as MedicareStatementMonthYear[]);
                    this.setMonthYearFilters(statements); 
                 }, () => {
                    this.notificationService.error("Unable to display statements month and year. Either your computer is offline, or Resident Manager is having problems of its own.");
                });
        } 

        public changeFacility = () => {
            this.getMedicareStatementsMonthAndYear(this.selectedFacility.id);
        }
        public isDownloadStatementDisabled = () => {
            var status = this.selectedFacility==null || this.selectedMonthYearFilter==null;
            return status;
        }
        private setMonthYearFilters = (statements): void => {
            this.monthYearFilters = [];
            statements.forEach(statement => {
                this.monthYearFilters.push({ value: statement.statementId , 
                    displayText:  this.getMonthName(statement.statementMonth)+ " " +statement.statementYear}); 
            });
            this.selectedMonthYearFilter = this.monthYearFilters[0];
        }
        getMonthName(month: number) {
            return moment().month(month - 1).format('MMMM');
        }

        getFullMonthAndYear(month: number, year: number) {
            var fullMonth = month.toString().length === 1 ? '0' + month : month;
            return fullMonth + '' + year;
        }
  
        public formatDateTime = (dateTime: Date): string => {
            return moment(dateTime).format("DD-MM-YYYY HH:mm:ss");
        }

        private fetchAndSetFacilities = (): void => {
            this.facilities = this.facilityService.activeUserFacilities.filter(x => x.facilityType == "AgeCare");
            this.selectedFacility = this.facilityService.selectedFacility;//this.facilities[0];
        }

        public downloadStatement = () : void => {
            this.fundingService.downloadStatement(Number(this.selectedMonthYearFilter.value))
            .then((response) => {
                this.downloadFile(response, 'expectedStatement.xml');
                },
                () => {
                    this.notificationService.error(
                        "Unable to download statement. Either your computer is offline, or Resident Manager is having problems of its own.");
                }
            );
        }
        private downloadFile = (response: ArrayBuffer, fileName: string) => {
            this.downloadFileService.downloadFile(response, fileName);
        }

        public uploadActualStatement = (files) => {
            if (!files || files.length === 0) 
                return;

            let file = files[0];
            if (!file) 
                return;

            this.uploadedFileName = file.name;
  
            var baseUrl = this.appSettings.ApiUrl + "funding/upload-actual-statement" + "?expectedStatementId=" + encodeURIComponent(this.selectedMonthYearFilter.value) + "&fileName=" + encodeURIComponent(file.name);
            var url = this.dataService.addSelectedFacilityId(baseUrl);
    
            this.dataService.getHttpConfig()
                .then( (config) => {
                return this.upload.upload({
                    url: url,
                    method: "POST",
                    headers: config.headers,
                    file: file
                }).then( (resp) => {
                    this.notificationService.success("Successfully uploaded actual statement.");                        
                },  (errors) => {
                    if (errors && errors.data && errors.data.modelState && errors.data.modelState.responsePayloadBlobReference) {
                        this.notificationService.error("The actual statement for this month hasn't been fetched yet. Click 'Get statement' on the statements page before uploading.");
                    } else {
                        this.notificationService.error("Unexpected error occurred while uploading actual statement.");
                    }               
                });  
            });
        }
    }
}

export = DownloadActualStatementComponent