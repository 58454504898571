"use strict";
const _ = require("underscore");

module.exports = ["appSettings", "$rootScope", function (appSettings, $rootScope) {

    $rootScope.signalRHubUrl = appSettings.SignalRUrl + "/hubs";

    $rootScope.connectHub = function () {
        $.connection.hub.url = appSettings.SignalRUrl;
        var maintenanceHub = $.connection.maintenanceHub;

        maintenanceHub.client.underMaintenance = function () {
            alert("Apologies, If you were working on something just now it might not have saved. We're working on updating Resident Manager for you and will be back soon.\n\nThanks for your patience.");
            location.reload();
        };

        $.connection.hub.start({ jsonp: true }).done(function () {
        });
    }
}];
