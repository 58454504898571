"use strict";

var angular = require("angular");

module.exports = [
    "leads.service",
    "notification.service",
    "modalConfig",
    "$state",
    function(leadsService, notification, modalConfig, $state) {
        var vm = this;
        angular.extend(vm, modalConfig);

        vm.bindHide = function($hide) {
            vm.$hide = $hide;
        };

        vm.markAsProspect = function(isFormValid) {
            if (isFormValid) {
                leadsService.markLeadAsProspect(vm.resident.id, vm.resident)
                    .then(function() {
                            notification.success("Lead has been marked as prospect successfully");
                            vm.$hide();
                            $state.reload();
                        },
                        function() {
                            notification.error("Unexpected error occurred while marking lead as prospect.");
                        });
            }
        };
    }
];