"use strict";

module.exports = function () {
    return {
        restrict: "E",
        replace: false,
        scope: {
            enquiryId: "="
        },
        controller: "enquiryFinanceDetail.controller",
        controllerAs: "vm",
        templateUrl: "app/independentLiving/directives/partials/enquiryFinanceDetail.html"
    };
}
