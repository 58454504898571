class State {
    static inject = ['$rootScope'];
    Configure = (module: ng.IModule) => {
        module.config(["$stateProvider", ($stateProvider) => {
            $stateProvider.state("clinicalIntegration", {
                url: "/customerCare/clinicalIntegration/{operationType:string}",
                template: "<clinical-integration operation-type='operationType'/>",
                controller: [
                    "$stateParams", "$scope", function ($stateParams, $scope) {      
                        $scope.operationType = $stateParams.operationType;                     
                    }
                ],
                resolve: {
                    authorized: ['$rootScope', ($rootScope) => {
                        return $rootScope.isSystemUser;
                    }]
                }
            });    
            $stateProvider.state("downloadUploadActualStatement", {
                url: "/customerCare/downloadUploadActualStatement",
                template: "<download-upload-actual-statement/>",
                controller: [
                    "$stateParams", "$scope", function ($stateParams, $scope) {      
                      
                    }
                ],
                resolve: {
                    authorized: ['$rootScope', ($rootScope) => {
                        return $rootScope.isSystemUser;
                    }]
                }
            });        
        }]);
    }
}

export = State;