import 'jquery';
import '@cgross/angular-notify'
import 'angucomplete-alt';
import 'angular';
import 'angular-chosen-localytics';
import 'angular-local-storage';
import 'angular-messages';
import 'angular-moment';
import 'angular-sanitize';
import 'angular-strap';
import 'angular-ui-mask';
import 'babel-polyfill';
import 'bootstrap';
import 'croppie';
import 'angular-ui-sortable';
import 'moment';
import 'ng-file-upload';
import 'ng-infinite-scroll';
import 'ng-mask';
import 'oidc-client';
import 'signalr';
import 'toastr';
import 'ui-select';
import 'underscore';
import 'angular-ui-validate';
import "angular-ui-bootstrap";
import "ng-tags-input";

import 'bootstrap/dist/css/bootstrap.css';
import '../lib/customResmanOverrides/chosen.css';
import '../lib/customResmanOverrides/chosen.jquery'
import 'toastr/build/toastr.css';
import 'croppie/croppie.css';
import 'ui-select/dist/select.css';
import 'select2/select2.css';
import "ng-tags-input/build/ng-tags-input.css";
