"use strict";
const angular = require("angular");
const _ = require("underscore");
const moment = require("moment");

module.exports = [
    "residents.service",
    "notification.service",
    "modalConfig",
    "referenceData.service",
    "$state",
    function (residentsService, notification, modalConfig, referenceDataService, $state) {
        var vm = this;
        vm.resident = {};
        angular.extend(vm.resident, modalConfig.resident);
        vm.existingAcfi = angular.copy(modalConfig.existingAcfi);
        vm.bindHide = function ($hide) {
            vm.$hide = $hide;
        };

        function getAcfiRates() {
            referenceDataService.getAcfiRates()
                .then(function (resp) {
                    vm.acfiRatesReference = resp;
                });
        }

        getAcfiRates();

        vm.getDailyRate = function () {
            if (vm.acfiRatesReference &&
                vm.existingAcfi &&
                vm.existingAcfi.submissionDateUtc &&
                vm.existingAcfi.adl &&
                vm.existingAcfi.beh &&
                vm.existingAcfi.chc) {
                var utcNow = moment.utc().toDate();
                var submissionDate = moment(vm.existingAcfi.submissionDateUtc);
                var applicableRates = _.sortBy(_.filter(vm.acfiRatesReference,
                    function (acfiRate) {
                        var startDate = moment.utc(acfiRate.startDateUtc);
                        var endDate = acfiRate.endDateUtc === null ? null : moment.utc(acfiRate.endDateUtc);
                        return startDate.isSameOrBefore(utcNow) &&
                            (endDate === null || endDate.isAfter(submissionDate));
                    }),
                    function (acfiRate) { return acfiRate.startDateUtc; })
                    .reverse();

                var adlRate = 0, behRate = 0, chcRate = 0;

                if (vm.existingAcfi.adl !== "Nil")
                    adlRate = _.find(applicableRates, { acfiDomain: "ADL", acfiLevel: vm.existingAcfi.adl }).dailyRate;

                if (vm.existingAcfi.beh !== "Nil")
                    behRate = _.find(applicableRates, { acfiDomain: "BEH", acfiLevel: vm.existingAcfi.beh }).dailyRate;

                if (vm.existingAcfi.chc !== "Nil")
                    chcRate = _.find(applicableRates, { acfiDomain: "CHC", acfiLevel: vm.existingAcfi.chc }).dailyRate;

                return adlRate + behRate + chcRate;
            }
            return null;
        }

        vm.saveExistingAcfi = function (isExistingAcfiValid) {
            if (isExistingAcfiValid) {
                residentsService.saveExistingAcfi(vm.resident.id, vm.existingAcfi)
                    .then(function () {
                        vm.$hide();
                        $state.reload();
                        notification.success("ACFI saved.");
                    },
                        function () {
                            notification.error("Unable to save ACFI. Either your computer is offline, or Resident Manager is having problems of its own.");
                        });
            }
        };
    }
];
