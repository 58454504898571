import { IReportingService } from './IReportingService';
import { MovementsReportFilter, BillingExtractFilter, FinancialDetailsReportFilter, EnquirySourceReportFilter, UnitsReportFilter, RoomsReportFilter, SettlementsReportFilter, ClassificationHistoryReportFilter, PalliativeAssessmentsReportFilter, ReassessmentRequestsReportFilter, PotentialReassessmentsReportFilter } from '../Models';
import * as moment from 'moment';
import * as _ from 'underscore';

class ReportingService implements IReportingService {
    static $inject = ["data.service"];

    constructor(private readonly dataService: any) {}

    getMovementsReportData =
        (notIncludingItemsAfter: any, skip: number, take: number, reportFilters: MovementsReportFilter): any => {            
            const updatedFilterObject = _.extend({},
                _.omit(reportFilters, 'fromDate', 'toDate'),
                {
                    fromDateUtcMidnight: moment.utc(reportFilters.fromDate).startOf("day").toDate(),
                    toDateUtcMidnight: moment.utc(reportFilters.toDate).startOf("day").toDate(),
                    notIncludingItemsAfter: notIncludingItemsAfter,
                    skip: skip,
                    take: take
                });

            return this.dataService.get("reporting/movements-report-data",
                true,
                updatedFilterObject);
        }

    getMovementsReportExcelStream = (reportFilters: MovementsReportFilter): any => {       
        const updatedFilterObject = _.extend({},
            _.omit(reportFilters, 'fromDate', 'toDate'),
            {              
                fromDateUtcMidnight: moment.utc(reportFilters.fromDate).startOf("day").toDate(),
                toDateUtcMidnight: moment.utc(reportFilters.toDate).startOf("day").toDate()
            });

        return this.dataService.getArrayBuffer("reporting/movements-report-data-excel",
            true,
            updatedFilterObject
        );
    }

    getEnquirySourceReportData =
        (notIncludingItemsAfter: any, skip: number, take: number, reportFilters: EnquirySourceReportFilter): any => {
            const updatedFilterObject = _.extend({},
                _.omit(reportFilters, 'fromDate', 'toDate'),
                {
                    fromDateUtcMidnight: moment.utc(reportFilters.fromDate).startOf("day").toDate(),
                    toDateUtcMidnight: moment.utc(reportFilters.toDate).startOf("day").toDate(),
                    notIncludingItemsAfter: notIncludingItemsAfter,
                    skip: skip,
                    take: take
                });

            return this.dataService.get("reporting/enquiry-source-report-data",
                true,
                updatedFilterObject);
        }

    getEnquirySourceReportExcelStream = (reportFilters: EnquirySourceReportFilter): any => {       
        const updatedFilterObject = _.extend({},
            _.omit(reportFilters, 'fromDate', 'toDate'),
            {              
                fromDateUtcMidnight: moment.utc(reportFilters.fromDate).startOf("day").toDate(),
                toDateUtcMidnight: moment.utc(reportFilters.toDate).startOf("day").toDate()
            });

        return this.dataService.getArrayBuffer("reporting/enquiry-source-report-data-excel",
            true,
            updatedFilterObject
        );
    }


    getUnitsReportData =
        (notIncludingItemsAfter: any, skip: number, take: number, reportFilters: UnitsReportFilter): any => {             
            const updatedFilterObject = _.extend({}, reportFilters, {
                                    notIncludingItemsAfter: notIncludingItemsAfter,
                                    skip: skip,
                                    take: take
                });
            return this.dataService.getWithCancellationRequest("reporting/units-report-data/", true, updatedFilterObject);          
        }

    getSettlementsReportData =
        (notIncludingItemsAfter: any, skip: number, take: number, reportFilters: SettlementsReportFilter): any => {             
            const updatedFilterObject = _.extend({}, reportFilters, {
                                    notIncludingItemsAfter: notIncludingItemsAfter,
                                    skip: skip,
                                    take: take
                });
            return this.dataService.getWithCancellationRequest("reporting/settlements-report-data/", true, updatedFilterObject);          
        }

    getUnitReportExcelStream = (reportFilters: UnitsReportFilter): any => {       
               return this.dataService.getArrayBuffer("reporting/unit-report-data-excel", true, reportFilters);
    }

    getSettlementsReportExcelStream = (reportFilters: SettlementsReportFilter): any => {       
        return this.dataService.getArrayBuffer("reporting/settlements-report-data-excel", false, reportFilters);
}

    getRoomsReportExcelStream = (reportFilters: RoomsReportFilter): any => {
        return this.dataService.getArrayBuffer("reporting/rooms-report-data-excel", true, reportFilters);
    }

    getFacilityLocationsWithAccommodationTypes = (): any => {      
        return this.dataService.get("accommodations/locationAccommodationTypes", true);
    }

    getBillingExtractCsvStream = (billingExtractFilter: BillingExtractFilter): any => {
        return this.dataService.getArrayBuffer('reporting/billing-extract-csv', false, billingExtractFilter);
    }
    
    getExportForAttacheCsvStream = (billingExtractFilter: BillingExtractFilter): any => {
        return this.dataService.getArrayBuffer('reporting/export-for-attache-csv', false, billingExtractFilter);
    }

    getfinancialDetailsReportExcelStream = (financialDetailsReportFilter: FinancialDetailsReportFilter): any => {
        return this.dataService.getArrayBuffer("reporting/financial-details-report-excel", false, financialDetailsReportFilter);
    }

    getpalliativeAssessmentsReportExcelStream = (palliativeAssessmentsReportFilter: PalliativeAssessmentsReportFilter): any => {
        return this.dataService.getArrayBuffer("reporting/palliative-assessments-report-excel", false, palliativeAssessmentsReportFilter);
    }

    getExpectedPaymentsReportExcelStream = (monthAndYear) : any =>{
        return this.dataService.getArrayBuffer("reporting/expected-payments-report-data-excel", false, {monthAndYear});
    }

    getActualPaymentsReportExcelStream = (monthAndYear) : any =>{
        return this.dataService.getArrayBuffer("reporting/actual-payments-report-data-excel", false, {monthAndYear});
    }

    getAdjustmentSummaryReportExcelStream = (monthAndYear) : any =>{
        return this.dataService.getArrayBuffer("reporting/adjustment-summary-report-data-excel", false, {monthAndYear});
    }

    getVarianceStatementExcelStream = (monthAndYear, selectedFilter) : any =>{
        return this.dataService.getArrayBuffer("reporting/variance-statement-data-excel", false, {monthAndYear, selectedFilter});
    }
    
    getClassificationHistoryReportCsvStream = (classificationHistoryReportFilter: ClassificationHistoryReportFilter) : any => {
        return this.dataService.getArrayBuffer("reporting/classification-history-report", false, classificationHistoryReportFilter);
    }

    getReassessmentRequestsReportExcelStream = (reassessmentRequestsReportFilter: ReassessmentRequestsReportFilter) : ng.IPromise<any> => {
        return this.dataService.getArrayBuffer("reporting/reassessment-requests-report-excel", false, reassessmentRequestsReportFilter);
    }
    
    getPotentialReassessmentsReportExcelStream = (potentialReassessmentsReportFilter: PotentialReassessmentsReportFilter) : any => {
        return this.dataService.getArrayBuffer("reporting/potential-reassessments-report-excel", false, potentialReassessmentsReportFilter);
    }
}

export = ReportingService;