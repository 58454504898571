"use strict";

var moment = require("moment");

module.exports = [
    "residents.service",
    "independentLiving.service",
    "notification.service",
    "$state",
    "modalConfig",
    "$scope",
    "dialog.service",
    function (residentsService, iluService, notification, $state, modalConfig, $scope, dialogService) {
        var vm = this;

        vm.reverseEvent = function () {
            var reversalAction = null;
            switch (modalConfig.selectedEvent.requestType) {
                case "ResidentialEntry":
                case "ResidentialEntryCorrection":
                    reversalAction = residentsService.reverseAdmission(modalConfig.selectedEvent.residentId, 
                        modalConfig.selectedEvent.uniqueEventId);
                    break;
                case "ResidentialDeparture":
                case "ResidentialDepartureCorrection":
                    reversalAction = residentsService.reverseDeparture(modalConfig.selectedEvent.residentId, 
                        modalConfig.selectedEvent.uniqueEventId);
                    break;
                case "ResidentialILUDepartureCorrection":
                    reversalAction = iluService.reverseDeparture(modalConfig.selectedEvent.residencyId);               
                    modalConfig.onModalCloseCallback = 
                        function reverseModalCloseCallback() {
                            if (modalConfig.selectedEvent.fromILU) {
                                $state.go('viewIndependentLivingAllocation.events', { enquiryId: modalConfig.selectedEvent.enquiryId, residentId: modalConfig.selectedEvent.residentId , 'viewDepartedMode': false});
                            }
                            else 
                            {
                                $state.reload();
                            }
                        };    
                    break;
                case "ResidentialLeave":
                case "ResidentialLeaveUpdated":
                case "ResidentialLeaveReturned":
                case "ResidentialLeaveReversed":
                    reversalAction = residentsService.reverseLeave(modalConfig.selectedEvent.residentId, 
                        modalConfig.selectedEvent.residentLeaveMedicareEvent.residentLeaveId);
                    break;
                case "IluAdmission":
                    reversalAction = iluService.reverseAdmission(modalConfig.selectedEvent.enquiryId);
                    break;
            }

            if (!reversalAction) return;
       
            reversalAction.then(
                function () {
                    notification.success("Event reversed.");
                    $scope.$hide();
                    //this dialog can be called from multiple screens. Reload the page if no onModalCloseCallback has been passed.
                    if (modalConfig.onModalCloseCallback != undefined) {
                        modalConfig.onModalCloseCallback();
                    }
                    else {
                        $state.reload();
                    }
                },
                function (rejection) {
                        // get error message returned from backend regardless of the property name.
                        let errorMessage = rejection.data.modelState[Object.keys(rejection.data.modelState)[0]];
                        if (errorMessage)
                        {
                            dialogService.openMessageDialog(
                                "Reverse departure",
                                "Departure cannot be reversed.<br/>" +
                                "<span class='error-details'>" + errorMessage.toString() + "</span>",
                                "event-reverse-error");
                        }
                        else
                        {
                            notification.error("Unable to reverse event. Either your computer is offline, or Resident Manager is having problems of its own.");
                        }
                }
            );
        };
    }
];
