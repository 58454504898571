"use strict";
module.exports = {
    configure: function (module) {

        module.config([
            "$stateProvider",
            function ($stateProvider) {
                $stateProvider.state("createLead", {
                    url: "/createLead",
                    templateUrl: "app/leads/partials/createLead.html",
                    controller: "createLead.controller as vm",
                    resolve: {
                        authorized: ["authentication.service", function (auth) {
                            return auth.userHasPermissionDeferred(function (x) { return x.leadsDetails.canModify; });
                        }]
                    }
                });

                $stateProvider.state("editLead", {
                    url: "/editLead/{residentId:int}",
                    templateUrl: "app/leads/partials/editLead.html",
                    controller: "editLead.controller as vm",
                    resolve: {
                        authorized: ["authentication.service", "$state", function (auth, $state) {
                            if ($state.next.name === "editLead.financialsFunding") {
                                return auth.userHasPermissionDeferred(function (x) { return x.leadsFinancials.canModify; });
                            } else {
                                return auth.userHasPermissionDeferred(function (x) { return x.leadsDetails.canModify; });
                            }
                        }]
                    }
                });

                $stateProvider.state("editLead.financialsFunding", {
                    url: "/financials/funding",
                    template: "<resident-finance-editor-funding resident-id='residentId' />",
                    controller: [
                        "$stateParams", "$scope", function ($stateParams, $scope) {
                            $scope.residentId = $stateParams.residentId;
                        }
                    ],
                    resolve: {
                        authorized: ["authentication.service", function (auth) {
                            return auth.userHasPermissionDeferred(function (x) { return x.leadsFinancials.canModify; });
                        }]
                    }
                });

                $stateProvider.state("editLead.financialsFees", {
                    url: "/financials/{isPrivateResidency:bool}/fees",
                    template: "<resident-finance-editor-fees resident-id='residentId' is-private-residency='isPrivateResidency'/>",
                    controller: [
                        "$stateParams", "$scope", function ($stateParams, $scope) {
                            $scope.residentId = $stateParams.residentId;
                        }
                    ],
                    resolve: {
                        authorized: ["authentication.service", function (auth) {
                            return auth.userHasPermissionDeferred(function (x) { return x.leadsFinancials.canModify; });
                        }]
                    }
                });

                $stateProvider.state("viewLead", {
                    url: "/viewLead/{residentId:int}",
                    templateUrl: "app/leads/partials/viewLead.html",
                    controller: "viewLead.controller as vm",
                    resolve: {
                        authorized: ["authentication.service", "$state", function (auth, $state) {
                            switch ($state.next.name) {
                                case "viewLead.financialsFunding":
                                case "viewLead.financialsFees":
                                    return auth.userHasPermissionDeferred(function (x) { return x.leadsFinancials.canView; });
                                default:
                                    return auth.userHasPermissionDeferred(function (x) { return x.leadsDetails.canView; });
                            }
                        }]
                    }
                });

                $stateProvider.state("viewLead.financialsFunding", {
                    url: "/financials/funding",
                    template: "<resident-finance-funding resident-id='residentId' />",
                    controller: [
                        "$stateParams", "$scope", function ($stateParams, $scope) {
                            $scope.residentId = $stateParams.residentId;
                        }
                    ],
                    resolve: {
                        authorized: ["authentication.service", function (auth) {
                            return auth.userHasPermissionDeferred(function (x) { return x.leadsFinancials.canView; });
                        }]
                    }
                });

                $stateProvider.state("viewLead.financialsFees", {
                    url: "/financials/{isPrivateResidency:bool}/fees",
                    template: "<resident-finance-fees resident-id='residentId' is-private-residency='isPrivateResidency'/>",
                    controller: [
                        "$stateParams", "$scope", function ($stateParams, $scope) {
                            $scope.residentId = $stateParams.residentId;
                        }
                    ],
                    resolve: {
                        authorized: ["authentication.service", function (auth) {
                            return auth.userHasPermissionDeferred(function (x) { return x.leadsFinancials.canView; });
                        }]
                    }
                });

                $stateProvider.state("viewLead.financialsTransaction", {
                    url: "/financials/{isPrivateResidency:bool}/transaction",
                    template: "<resident-finance-transaction resident-id='residentId' is-private-residency='isPrivateResidency'/>",
                    controller: ["$stateParams", "$scope", function ($stateParams, $scope) {
                        $scope.residentId = $stateParams.residentId;
                    }],
                    resolve: {
                        authorized: ["authentication.service", function (auth) {
                            return auth.userHasPermissionDeferred(function (x) { return x.leadsFinancials.canView; });
                        }]
                    }
                });

                $stateProvider.state("viewLead.timeline", {
                    url: "/timeline",
                    template: "<resident-timeline resident-id='residentId' />",
                    controller: [
                        "$stateParams", "$scope", function ($stateParams, $scope) {
                            $scope.residentId = $stateParams.residentId;
                        }
                    ],
                    resolve: {
                        authorized: ["authentication.service", function (auth) {
                            return auth.userHasPermissionDeferred(function (x) { return x.leadsDetails.canView; });
                        }]
                    }
                });

                $stateProvider.state("viewLead.events", {
                    url: "/events",
                    template: "<resident-event-history resident-id='residentId'></resident-event-history>",
                    controller: [
                        "$stateParams", "$scope", function ($stateParams, $scope) {
                            $scope.residentId = $stateParams.residentId;
                        }
                    ],
                    resolve: {
                        authorized: [
                            "authentication.service", function (auth) {
                                return auth.userHasPermissionDeferred(function (x) { return x.leadsDetails.canView; });
                            }
                        ]
                    }
                });

                $stateProvider.state("viewLead.notes", {
                    url: "/notes",
                    template: "<notes resident-id='residentId' />",
                    controller: [
                        "$stateParams", "$scope", function ($stateParams, $scope) {
                            $scope.residentId = $stateParams.residentId;
                        }
                    ]
                });

                $stateProvider.state("leadsDashboard", {
                    url: "/leads",
                    template: "<leads-dashboard></leads-dashboard>"
                });

                $stateProvider.state("archivedLeads", {
                    url: "/archivedLeads",
                    template: "<archived-leads></archived-leads>"
                });
            }
        ]);
    }
};
