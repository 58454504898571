"use strict";

module.exports = function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "residentId": "="
        },
        controller: "residentPhotoWithMenu.controller",
        templateUrl: "app/residents/directives/partials/residentPhotoWithMenu.html"
    };
}
