import * as moment from 'moment';
import { IReportingService } from '../../reporting/services/IReportingService';
import { IDownloadFileService } from '../../common/services/IDownloadFileService';
import {ExpectedStatementPaymentTypes } from '../enum/ExpectedStatementPaymentTypes';

class ExpectedStatementPaymentComponent implements ng.IComponentOptions {
    public bindings: any = {
        monthAndYear: '=',
        statement: '='
    };

    public templateUrl: string = 'app/funding/partials/viewExpectedStatementPayments.html';

    public controller: any = class ExpectedStatementPaymentComponentController {
        static $inject = ["reporting.service", "downloadFile.service"];

        public statement: any;
        public monthAndYear: string;
        public period: string;

        constructor(
            private readonly reportingService: IReportingService,
            private readonly downloadFileService : IDownloadFileService) {

                this.period = moment(this.monthAndYear, "MYYYY").format("MMMM YYYY");   
        }

        public exportToExcel = () => {
            this.reportingService.getExpectedPaymentsReportExcelStream(this.monthAndYear).then((response) => {
                this.downloadFileService.downloadFile(response, 'PaymentsReport.xlsx');
            });
        }

        public isNOTExcluded(payment:any)
        {
            let paymentType = payment.paymentType.toString();
            return paymentType !== ExpectedStatementPaymentTypes.AnACC &&
                   paymentType !== ExpectedStatementPaymentTypes.FacilityTotalApprovedBedsPayment
        }
    }
}
export = ExpectedStatementPaymentComponent;