"use strict";

var angular = require("angular");


module.exports = [
    "accr.service",
    "notification.service",
    "modalConfig",
    "$state",
    "$window",
    function (accrService, notification, modalConfig, $state, $window) {
        var vm = this;
        angular.extend(vm, modalConfig);

        vm.bindHide = function ($hide) {
            vm.$hide = $hide;
        };

        accrService.getAccr(vm.residentId).then(function (result) {
            angular.extend(vm, result);
        });

        vm.saveAndView = function () {
            if(vm.isBackgroundFormDirty == true) {
                if(!$window.confirm("Do you want to leave this page? Changes you made may not be saved.")) return;
            }

            accrService.updateFromAccr(vm.residentId).then(function () {
                notification.success("Record details have been updated.");
                $state.reload();
                vm.$hide();
            }, function () {
                notification.error("Unable to update record. Either your computer is offline, or Resident Manager is having problems of its own.");
            });
        };
    }
];
