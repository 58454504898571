"use strict";
const _ = require("underscore");

module.exports =
[     "$scope", "data.service", "appSettings", "notification.service", "Upload", "dialog.service",
      function ($scope, dataService, appSettings, notification, upload, dialogService) {

        function refreshDocuments() {
          var url = $scope.documentController + "/" + $scope.documentParentId + "/documents";
          dataService.get(url).then(function (documents) {
            $scope.documents = _.sortBy(documents, "documentName");;
          }, function () {
            notification.error("Unexpected error occurred while loading documents.");
          });
        }

        function uploadDocument(fileName, fileExtension, file, newDocumentPlaceholder) {
          var baseUrl = appSettings.ApiUrl + $scope.documentUploadController + "/" + $scope.documentUploadParentId + "/upload?name=" + encodeURIComponent(fileName) + "&extension=" + encodeURIComponent(fileExtension);
          var url = dataService.addSelectedFacilityId(baseUrl);

          return dataService.getHttpConfig()
            .then(function (config) {
              return upload.upload({
                url: url,
                method: "POST",
                headers: config.headers,
                file: file
              }).then(function (resp) {
                newDocumentPlaceholder.isUploading = false;
                if(resp.data.result.residentDocuments){
                  _.forEach(resp.data.result.residentDocuments, (residentDocument) => {
                    if (residentDocument.residentId === $scope.documentParentId) {
                      newDocumentPlaceholder.id = residentDocument.documentId;
                      newDocumentPlaceholder.addedBy = residentDocument.addedBy;
                      newDocumentPlaceholder.dateAdded = residentDocument.dateAdded;
                    }
                });
                }
                else{
                  newDocumentPlaceholder.id = resp.data.result.id;
                  newDocumentPlaceholder.addedBy = resp.data.result.addedBy;
                  newDocumentPlaceholder.dateAdded = resp.data.result.dateAdded;
                }

                
              }, function (resp) {
                notification.error("Unexpected error occurred while uploading document.");
                $scope.documents = _.filter($scope.documents, function(doc) { return doc !== newDocumentPlaceholder });
              }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                newDocumentPlaceholder.progress = progressPercentage;
              });
            });
        }

        function fileNameConfirmationAction(value, innerMessages, fileExtension, file) {
          if ((value || "").trim() === "") {
            innerMessages.length = 0;
            innerMessages.push("Please enter file name");
            return false;
          }
          else if (_.findWhere($scope.documents, { documentName: value })) {
            innerMessages.length = 0;
            innerMessages.push("This document name already exists");
            return false;
          } else {
            // put placeholder in the list
            var newDocumentPlaceholder = { documentName: value, isUploading: true, progress: 0 };
            $scope.documents.push(newDocumentPlaceholder);
            $scope.documents = _.sortBy($scope.documents, "documentName");

            $scope.uploadDocument(value, fileExtension, file, newDocumentPlaceholder); // referring through scope for unit tests
            return true;
          }
        }

        function addDocument(files) {
          if (!files || files.length === 0) return;

          var file = files[0];
          if (!file) return;

          var fileParts = file.name.split(".");
          var originalName = file.name;
          var fileExtension = "";
          if (fileParts.length > 1) {
            fileExtension = fileParts.pop();
            originalName = fileParts.join(".");
          }

          // get unique file name
          dialogService.openInputDialog("Add document",
              "Title",
              originalName,
              60,
              "add-document",
              "Add",
              function(value, innerMessages) {
                return fileNameConfirmationAction(value, innerMessages, fileExtension, file);
              },
              "btn-primary btn-inline-block-xsm");
        }

        function closePopover(popover) {
          popover.$hide();
        };

        function createLink() {
          return angular.element('<a></a>');
        }

        function generateDocumentLink(documentId) {
          dataService.get($scope.documentController + "/" + $scope.documentParentId + "/document/" + documentId + "/token").then(function (documentAccessKey) {
            var newUrl = appSettings.ApiUrl + $scope.documentController + "/" + $scope.documentParentId + "/document/" + documentId + "/view?documentAccessToken=" + documentAccessKey;

            var downloadLink = $scope.createLink(); // referring through scope for unit tests
            downloadLink.attr('href', newUrl);
            downloadLink[0].click();
          }, function () {
            notification.error("Unexpected error occurred while loading document.");
          });
        };

        function deleteDocument(documentId) {
          dataService.delete($scope.documentController + "/" + $scope.documentParentId + "/document/" + documentId + "/delete").then(function () {
            $scope.refreshDocuments();
          }, function () {
            notification.error("Unexpected error occurred while deleting document.");
          });
        };

        $scope.refreshDocuments = refreshDocuments; // only for unit tests
        $scope.createLink = createLink; // only for unit tests
        $scope.uploadDocument = uploadDocument; // only for unit tests
        $scope.fileNameConfirmationAction = fileNameConfirmationAction; // only for unit tests
        $scope.addDocument = addDocument;
        $scope.closePopover = closePopover;
        $scope.generateDocumentLink = generateDocumentLink;
        $scope.deleteDocument = deleteDocument;
        $scope.documents = [];
        refreshDocuments();
      }
];
