"use strict";

module.exports = function () {
    return {
        restrict: "AE",
        replace: false,
        scope: {},
        controller: "loggedInUserInfo.controller",
        controllerAs: "vm",
        templateUrl: "app/authentication/directives/partials/loggedInUserInfo.html"
    };
}
